import React, { useState, useEffect } from "react";
import { Divider, Grid, Paper, Tooltip } from "@mui/material";
import PageTitle from "../../../Typography/PageTitle";
import {
  Trash2Icon,
  MapHoverIcon,
  CalenderIcon,
  HexaIcon,
  RateIcon,
  CompassIcon,
  MappinIcon,
  WeightIcon,
  UserIcon,
  EmailIcon,
  PhoneIcon,
  InfoIcon,
  RupeeIcon,
  InfoRedIcon,
} from "../../../../icons";
import { useCommonStyles } from "../../../../styles/common";
import TrackingCard from "../../../Cards/TrackingCard";
import TrackingNoDataFound from "../../../NoDataScreens/Tracking";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  BOOKING_STATUS_CONSTANTS,
  cancelBookingReasons,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  LOADING_TEXT,
  LOAD_OPTIONS,
  LOAD_OPTIONS_WBG,
  LOAD_TYPES,
  PLATFORM_USERS,
  STATUS_JSX,
  TIME_FORMAT_STRING,
  TRACKING_STATUS_CONSTANTS,
} from "../../../../utils/constants";
import moment from "moment";
import EnhancedTable from "../../../Table";
import CommonModal from "../../../Modal";
import CommonInputGroup from "../../../Input/InputGroup";
import CommonMaterialInput from "../../../Input/MaterialInput";
import {
  LoadOptions,
  LoadOptionsForWeight,
  TrackingStatusObject,
} from "../../../../utils/ObjectTypes";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../../Button";
import { handleCheckValidation } from "../../../../ValidationHelpers";
import { useHistory, useParams } from "react-router-dom";
import {
  acceptBookingFromVendor,
  allVendorFleets,
  confirmBookingFromClient,
  getBookingbyId,
  getVendorBookingbyId,
  negotiateBookingFromVendor,
  reconsiderBokkingFromClient,
  rejectBookingFromVendor,
  updateTrckingDetails,
  fetchSpecificBookingRequest
} from "../../../../ReduxActions";
import toast from "react-hot-toast";
import MaterialSingleSearchDropdown from "../../../Dropdown/SingleSearchDropdown";
import CommonTextArea from "../../../TextArea";
import { areaFormattedValue } from "../../../../utils/common";
import BasicSelect from "../../../Dropdown/SimpleSelect";
import PaymentDetailsCard from "../../../Cards/PaymentDetailsCard";
import axiosInstance from "../../../../utils/interceptor";
import { API_URLS } from "../../../../utils/apiUrls";
import { BookingService } from "../../../../sevices";
import { createBookingRequestSummary } from "../../../../helpers/CommonHelper";
import SearchInput from "../../../SearchInput";
interface Data {
  company_name: string;
  total_delivery: string;
  total_branches: number;
  days: string;
  success_rate: number;
  rate: string;
  status: string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "total_delivery",
    numeric: false,
    disablePadding: false,
    label: "Delivery",
  },
  {
    id: "total_branches",
    numeric: false,
    disablePadding: false,
    label: "Branches",
  },
  {
    id: "days",
    numeric: false,
    disablePadding: false,
    label: "Days",
  },
  {
    id: "success_rate",
    numeric: false,
    disablePadding: false,
    label: "Success Rate",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];
interface IData {
  rate_last_updated_at: string;
  remarks: string;
  rate: any;
  status?: string;
  rate_unit?: string;
  total_cost?: string;
}

interface IHeadCell {
  disablePadding?: boolean;
  id?: keyof IData;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const rateHistorycolumns: readonly IHeadCell[] = [
  {
    id: "rate_last_updated_at",
    numeric: false,
    disablePadding: false,
    label: "Last added on",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "total_cost",
    numeric: false,
    disablePadding: false,
    label: "Total Cost",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
  // {
  //   id: "status",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Status",
  // }
];

const structuredSchemaForRateHistory = (data: IData[]) => {
  if (data?.length) {
    return data.map((items) => ({
      rate_last_updated_at: moment(items.rate_last_updated_at).format(
        TIME_FORMAT_STRING
      ),
      remarks: items.remarks || "NA",
      rate: "₹" + items.rate + "/" + items.rate_unit,
      // status : STATUS_JSX[items.status],
      total_cost: "₹" + (items?.total_cost || "N/A"),
    }));
  } else return [];
};

export const structuredSchemaForBookingRequest = (data) => {
  if (data?.length)
    return data.map((item) => ({
      company_name: item?._vendor?.company_name || "NA",
      total_delivery: "N/A",
      total_branches: "N/A",
      days: "3-4 days",
      success_rate: "New",
      status: STATUS_JSX[item.status],
      rate: item.rate ? "₹ " + item?.rate + "/" + item?.rate_unit : "-",
      id: item.id,
    }));
  return [];
};

export const structuredSchemaForTruck = (data) => {
  if (data?.length) {
    return data.map((truck) => ({
      key: truck.id,
      value: truck.id,
      text: truck.truck_no,
    }));
  }
  return [];
};

interface Props {
  itemDetails?: any;
  routeKey?: any;
  bookingData?: any;
  match?: any;
}

interface IValidationObject {
  rate?: string;
  rate_unit?: string;
}

interface IBookingCancelValidationObject {
  remarks?: string;
}

interface ITrackingDocumentFile {
  name?: string;
  reference?: string;
  url?: string;
  uploaded_at?: string;
}

interface ITrackingMeta {
  files?: ITrackingDocumentFile[];
}

interface ItrackingValidationObject {
  truck_id?: string;
  status?: string;
  last_updated_at?: string;
  remarks?: string;
  loading_weight?: any;
  loading_weight_unit?: string;
  unloading_weight?: any;
  unloading_weight_unit?: string;
  meta?: ITrackingMeta;
}

const ViewBookingDetails: React.FC<Props> = (props) => {
  // material common classes
  const classes = useCommonStyles();

  const history = useHistory();

  // props destructuring
  const { routeKey, bookingData, match } = props;
  // route param
  const { id } = useParams<{ id: string }>();
  // dispatch event
  const dispatch = useDispatch();
  // selectors
  const accpetBookingSelector = useSelector(
    (state: any) => state.BookingReducer.acceptBooking
  );
  const rejectBookingSelector = useSelector(
    (state: any) => state.BookingReducer.rejectBooking
  );
  const negotiateBookingSelector = useSelector(
    (state: any) => state.BookingReducer.negotiateBooking
  );
  const updateTrackingSelector = useSelector(
    (state: any) => state.BookingReducer.updateTracking
  );
  const confirmBookingSelector = useSelector(
    (state: any) => state.BookingReducer.confirmBooking
  );
  const reconsiderBookingSelector = useSelector(
    (state: any) => state.BookingReducer.reconsiderBooking
  );
  const allVendorFleetsSelector = useSelector(
    (state: any) => state.FleetReducer.getAllVendorFleets
  );
  const searchBookingRequestSelector = useSelector(
    (state: any) => state.BookingReducer.searchSpecificbookingReq
  );
  // react states hook
  const [bookingDetail, setBookingDetail] = useState<any>(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelBookingRemarks, setCancelBookingRemarks] = useState("");
  const [opneRateHistoryModal, setOpneRateHistoryModal] = useState(false);
  const [rateDetails, setRateDetails] = useState({
    rate: "",
    rate_unit: "kg",
    remarks: "",
  });
  const [trackingOldDetails, setTrackingOldDetails] = useState({
    truck_id: "",
    status: "",
    last_updated_at: "",
    remarks: "",
    loading_weight: null,
    loading_weight_unit: "",
    unloading_weight: null,
    unloading_weight_unit: "",
    meta: null,
  });

  const [trackingDetails, setTrackingDetails] = useState({
    truck_id: "",
    status: "",
    last_updated_at: "",
    remarks: "",
    loading_weight: null,
    loading_weight_unit: "",
    unloading_weight: null,
    unloading_weight_unit: "",
    meta: null,
  });
  const [trackingFiles, setTrackingFiles] = useState<any>([]);
  const [trackingValidationObj, setTrackingValidationObj] =
    useState<ItrackingValidationObject>({});
  const [validationObj, setValidationObj] = useState<IValidationObject>({});
  const [cancelBookingValidationObj, setCancelBookingValidationObj] =
    useState<IBookingCancelValidationObject>({});
  const [requestsId, setRequestsId] = useState(null);
  const [selectedRateHistory, setSelectedRateHistory] = useState([]);
  const [allTrucks, setAllTrucks] = useState([]);
  const [searchReqValue, setSearchReqValue] = useState("");
  const [resultReqText, setResultReqText] = useState(false);
  const [total, setTotal] = useState(null);

  const downloadBookingPaymentSlip = async (bookingId) => {
    if (bookingDetail?.id && routeKey && [BOOKING_STATUS_CONSTANTS.awaiting_fulfillment, BOOKING_STATUS_CONSTANTS.completed].includes(bookingDetail?.status)) {
      let toastId = toast.loading(LOADING_TEXT.processing);
      await BookingService.downloadBookingPaymentSlip({
        user: routeKey,
        id: bookingId
      })
        .then((result) => {
          toast.dismiss(toastId);
          toast.success("Payment Slip Downloaded successfully")
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss(toastId);
          toast.error("Some error occured");
        });
    }
    else {
      toast.error("This operation is not allowed")
    }
  }

  // getBooking details by id
  const refreshBookingDetails = () => {
    if (routeKey === PLATFORM_USERS.CLIENT) {
      dispatch(getBookingbyId({ user: routeKey, id: id }));
    } else if (
      routeKey === PLATFORM_USERS.VENDOR ||
      routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
      routeKey === PLATFORM_USERS.ADMIN
    ) {
      const request = match?.path === APP_USER_ROUTES[routeKey].REQUEST_DETAILS;
      if (request) {
        dispatch(getVendorBookingbyId({ user: routeKey, requestId: id }));
      } else {
        dispatch(getVendorBookingbyId({ user: routeKey, id: id }));
      }
    }
  };
  const getSearchBookingRequest = (search?: string) => {
    routeKey === PLATFORM_USERS.ADMIN ?
    dispatch(fetchSpecificBookingRequest({ user: routeKey, id:id, search,booking_id:id }))
    :
    dispatch(fetchSpecificBookingRequest({ user: routeKey, id:id, search }));
    
  };
  // tracking input change details
  const handleInputChangeTrackingdetails = (key, value) => {
    setTrackingDetails({ ...trackingDetails, [key]: value });
  };
  // rate input changes function
  const handleInputChange = (key, value) => {
    setRateDetails({ ...rateDetails, [key]: value });
  };
  // fetch all vendor trucks
  useEffect(() => {
    if (
      routeKey === PLATFORM_USERS.VENDOR ||
      routeKey === PLATFORM_USERS.VENDOR_BRANCH
    ) {
      dispatch(allVendorFleets({ user: routeKey, type: "all" }));
    }
    // Handling for admin
    else if (routeKey === PLATFORM_USERS.ADMIN) {
      dispatch(
        allVendorFleets({
          user: routeKey,
          vendor_id: bookingDetail?._vendor?.id,
          type: "all",
        })
      );
    }
  }, [bookingDetail]);

  // all vendor truck selector
  useEffect(() => {
    if (allVendorFleetsSelector.status === API_CONSTANTS.success) {
      setAllTrucks([
        ...structuredSchemaForTruck(allVendorFleetsSelector.data?.data),
      ]);
    }
  }, [allVendorFleetsSelector]);
  // set booking details
  useEffect(() => {
    setBookingDetail(bookingData);
    setRateDetails({
      ...rateDetails,
      rate_unit: bookingData?.materials[0]?.weight_unit || "kg",
    });
    if (
      routeKey === PLATFORM_USERS.VENDOR ||
      routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
      routeKey === PLATFORM_USERS.ADMIN ||
      routeKey === PLATFORM_USERS.CLIENT
    ) {
      setTrackingDetails({
        ...bookingData?._tracking,
        truck_id: bookingData?._tracking?._truck?.id,
        loading_weight_unit: bookingData?.materials[0]?.weight_unit
          ? bookingData?.materials[0]?.weight_unit
          : "tonne",
        unloading_weight_unit: bookingData?.materials[0]?.weight_unit
          ? bookingData?.materials[0]?.weight_unit
          : "tonne",
      });

      setTrackingOldDetails(bookingData?._tracking);
      if (bookingData?._tracking?.meta?.files && bookingData?._tracking?.meta?.files !== null)
        setTrackingFiles(bookingData?._tracking?.meta?.files);
    }
  }, [bookingData]);

  useEffect(() => {
    let toastId: any;
    if (searchBookingRequestSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (searchBookingRequestSelector.status === API_CONSTANTS.success) 
    {
      setBookingDetail({
        ...bookingDetail,
        ["booking_requests"]: searchBookingRequestSelector?.data
      });
      setTotal(searchBookingRequestSelector?.data.length);
      setResultReqText(true);
      toast.dismiss(toastId);
    }
    if (
      searchBookingRequestSelector.status === API_CONSTANTS.error 
    ) {
      toast.dismiss(toastId);
      toast.error(
        searchBookingRequestSelector.data || DEFAULT_ERROR_MESSAGE.failed
      );
    }
  }, [searchBookingRequestSelector]);
  // File Upload
  const onFileUploadChange = (event: any) => {
    const file = event.currentTarget.files[0];
    let data = new FormData();
    if (file) {
      data.append("file", file);
      let toastId = toast.loading(LOADING_TEXT.processing);
      axiosInstance
        .post(API_URLS.static.uploadFile, data)
        .then((response: any) => {
          const currentTime = moment().format();
          const updatedFiles = [
            ...trackingFiles,
            { ...response, uploaded_at: currentTime },
          ];

          if (
            (bookingDetail?._tracking?.id &&
              trackingFiles &&
              routeKey === PLATFORM_USERS.VENDOR) ||
            routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
            routeKey === PLATFORM_USERS.ADMIN
          ) {
            dispatch(
              updateTrckingDetails({
                user: routeKey,
                id: bookingDetail?._tracking?.id,
                body: {
                  booking_id: id,
                  meta: {
                    files: updatedFiles,
                  },
                },
              })
            );
            toast.dismiss(toastId);
            handleInputChangeTrackingdetails("meta", {
              files: updatedFiles,
            });
            setTrackingFiles(updatedFiles);
          }
        })
        .catch((e) => {
          toast.dismiss(toastId);
          console.log(e);
        });
    }
  };

  // Download file
  const downloadFile = (item: any) => {
    window.open(item?.url, "_blank", "noopener,noreferrer");
  };

  const deleteFile = (item: any, index: number) => {
    const oldFiles = trackingDetails?.meta?.files;
    if (oldFiles.length > 0) {
      oldFiles.splice(
        oldFiles.findIndex((a) => a.url === item.url),
        1
      );
      if (
        (bookingDetail?._tracking?.id &&
          trackingFiles &&
          routeKey === PLATFORM_USERS.VENDOR) ||
        routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
        routeKey === PLATFORM_USERS.ADMIN
      ) {
        dispatch(
          updateTrckingDetails({
            user: routeKey,
            id: bookingDetail?._tracking?.id,
            body: {
              booking_id: id,
              meta: {
                files: oldFiles,
              },
            },
          })
        );
        handleInputChangeTrackingdetails("meta", {
          files: oldFiles,
        });
        setTrackingFiles(oldFiles);
      }
    } else {
      alert("No files found");
    }
  };

  // Update teacking details for the files
  useEffect(() => {}, [trackingFiles]);

  //table actions for client side to consider and confirm booking
  const actions = [
    {
      id: "confirm",
      label: "Confirm",
      callbackArguments: ["id"],
      hidden: {
        key: "status",
        value: [
          STATUS_JSX["rejected"],
          STATUS_JSX["expired"],
          STATUS_JSX["completed"],
          STATUS_JSX["awaiting_fulfillment"],
        ],
      },
      onClick: (id) => {
        setConfirmModal(true);
        setRequestsId(id);
      },
    },
    {
      id: "reconsider",
      label: "Reconsider",
      callbackArguments: ["id"],
      hidden: {
        key: "status",
        value: [
          STATUS_JSX["rejected"],
          STATUS_JSX["expired"],
          STATUS_JSX["completed"],
          STATUS_JSX["pending"],
          STATUS_JSX["awaiting_fulfillment"],
        ],
      },
      onClick: (id) => {
        reconsiderClientRequest(id);
      },
    },
    {
      id: "rate_history",
      label: "Rate history",
      callbackArguments: ["id", "rate_history"],
      onClick: (id) => {
        setOpneRateHistoryModal(true);
        addedRateHistory(id);
      },
    },
    {
      id: "copy_details",
      label: "Copy Booking Details",
      callbackArguments: ["id"],
      onClick: (id) => {
        const copiedText = createBookingRequestSummary(id, bookingDetail);
        navigator.clipboard.writeText(`${copiedText}`);
        toast.success("Copied Successfully")
      },
    },
  ];

  // Admin Actions
  const adminActions = [
    {
      id: "copy_details",
      label: "Copy Booking Details",
      callbackArguments: ["id"],
      onClick: (id) => {
        const copiedText = createBookingRequestSummary(id, bookingDetail);
        navigator.clipboard.writeText(`${copiedText}`);
        toast.success("Copied Successfully")
      },
    },
  ];

  // add rate history
  const addedRateHistory = (id) => {
    if (id) {
      const filterRateHistory = bookingDetail?.booking_requests?.find(
        (items) => items.id === id
      )?.rate_history;
      setSelectedRateHistory(structuredSchemaForRateHistory(filterRateHistory));
    }
  };
  // reconsider booking request client side
  const reconsiderClientRequest = (id) => {
    dispatch(reconsiderBokkingFromClient({ user: routeKey, id: id }));
  };
  // reconsider booking details selector client side
  useEffect(() => {
    let toastId: any;
    if (reconsiderBookingSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (reconsiderBookingSelector.status === API_CONSTANTS.success) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
    }
    if (reconsiderBookingSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
      toast.error(
        reconsiderBookingSelector.data || DEFAULT_ERROR_MESSAGE.failed
      );
    }
  }, [reconsiderBookingSelector]);
  // confirm request function for client side
  const confirmRequest = () => {
    if (
      // bookingDetail?.type === LOAD_TYPES.FULL_TRUCK &&
      routeKey === PLATFORM_USERS.CLIENT
    ) {
      dispatch(confirmBookingFromClient({ user: routeKey, id: requestsId }));
    } else if (
      routeKey === PLATFORM_USERS.VENDOR ||
      routeKey === PLATFORM_USERS.VENDOR_BRANCH
    ) {
      handleSendRateFromVendor();
    }
    setRequestsId(null);
  };
  // complete booking
  const completeBooking = () => {
    dispatch(
      confirmBookingFromClient({
        user: routeKey,
        update_id: id,
        body: { status: "completed" },
      })
    );
  };

  // cancel booking
  const cancelBooking = () => {
    setCancelModal(true);
  };

  // cancel booking
  const cancelBookingReq = () => {
    const validation: IBookingCancelValidationObject = handleCheckValidation({
      remarks: cancelBookingRemarks,
    });
    setCancelBookingValidationObj(validation);
    if (validation && !validation.remarks) {
      dispatch(
        confirmBookingFromClient({
          user: routeKey,
          update_id: id,
          body: { status: "cancelled", remarks: cancelBookingRemarks },
        })
      );
    }
  };

  // confirm booking details selector client side
  useEffect(() => {
    let toastId: any;
    if (confirmBookingSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (confirmBookingSelector.status === API_CONSTANTS.success) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      refreshBookingDetails();
      setConfirmModal(false);
    }
    if (confirmBookingSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
      toast.error(confirmBookingSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [confirmBookingSelector]);
  // open confirm modal for vendor side
  const accpetConfirm = (e) => {
    e.preventDefault();
    setConfirmModal(true);
  };
  const acceptBookingForPtl = (e) => {
    e.preventDefault();
    dispatch(
      acceptBookingFromVendor({
        user: routeKey,
        id: id,
        body: {
          rate: bookingDetail?.rate,
          rate_unit:
            bookingDetail?.materials[0]?.weight_unit ||
            bookingDetail?.rate_unit,
        },
      })
    );
  };
  // negotiate rate
  const handleNegotiateRate = (e) => {
    e.preventDefault();
    const validation: IValidationObject = handleCheckValidation(rateDetails);
    setValidationObj(validation);
    if (validation && !validation.rate) {
      dispatch(
        negotiateBookingFromVendor({
          user: routeKey,
          id: id,
          body: { ...rateDetails, rate: Number(rateDetails.rate) },
        })
      );
    }
  };
  // negotiate selector response
  useEffect(() => {
    let toastId: any;
    if (negotiateBookingSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (negotiateBookingSelector.status === API_CONSTANTS.success) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      refreshBookingDetails();
    }
    if (negotiateBookingSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
      toast.error(
        negotiateBookingSelector.data || DEFAULT_ERROR_MESSAGE.failed
      );
    }
  }, [negotiateBookingSelector]);
  // submit rate function for vendor side
  const handleSendRateFromVendor = () => {
    const validation: IValidationObject = handleCheckValidation(rateDetails);
    setValidationObj(validation);
    if (validation && !validation.rate) {
      dispatch(
        acceptBookingFromVendor({
          user: routeKey,
          id: id,
          body: { ...rateDetails, rate: Number(rateDetails.rate) },
        })
      );
    }
  };
  // accept booking details selector vendor side
  useEffect(() => {
    let toastId: any;
    if (accpetBookingSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (accpetBookingSelector.status === API_CONSTANTS.success) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      refreshBookingDetails();
      setConfirmModal(false);
    }
    if (accpetBookingSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
      toast.error(accpetBookingSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [accpetBookingSelector]);
  // reject booking details selector vendor side
  useEffect(() => {
    let toastId: any;
    if (rejectBookingSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (rejectBookingSelector.status === API_CONSTANTS.success) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      refreshBookingDetails();
    }
    if (rejectBookingSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
      toast.error(rejectBookingSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [rejectBookingSelector]);
  // reject request function for vendor side
  const rejectRequest = () => {
    dispatch(rejectBookingFromVendor({ user: routeKey, id: id }));
  };
  // check user for client
  const checkUser = () => {
    return routeKey === PLATFORM_USERS.CLIENT;
  };
  // handle submit tracking details
  const submitTrackingDetails = (e) => {
    e.preventDefault();
    const validation: ItrackingValidationObject =
      handleCheckValidation(trackingDetails);
    setTrackingValidationObj(validation);

    // Logic for Loading and Unloading weight (Loading true for Not dispatched, Unloading true for In-transit, or Both false)
    let loadingWeight = false;
    let unloadingWeight = false;

    const body = {
      ...trackingDetails,
      booking_id: id,
    };

    if (
      trackingOldDetails.status === TRACKING_STATUS_CONSTANTS.not_dispatched &&
      trackingDetails.status === TRACKING_STATUS_CONSTANTS.dispatch
    ) {
      body.loading_weight = Number(trackingDetails.loading_weight);
      body.loading_weight_unit = trackingDetails.loading_weight_unit;
      loadingWeight = true;
    } else {
      delete body.loading_weight;
      delete body.loading_weight_unit;
    }

    if (
      (trackingOldDetails.status === TRACKING_STATUS_CONSTANTS.in_transit ||
        trackingOldDetails.status === TRACKING_STATUS_CONSTANTS.dispatch) &&
      trackingDetails.status === TRACKING_STATUS_CONSTANTS.delivered
    ) {
      body.unloading_weight = Number(trackingDetails.unloading_weight);
      body.unloading_weight_unit = trackingDetails.unloading_weight_unit;
      unloadingWeight = true;
    } else {
      delete body.unloading_weight;
      delete body.unloading_weight_unit;
    }

    if (
      validation &&
      !validation.truck_id &&
      !validation.status &&
      !validation.last_updated_at &&
      ((loadingWeight === true &&
        unloadingWeight === false &&
        trackingDetails.loading_weight) ||
        (loadingWeight === false &&
          unloadingWeight === true &&
          trackingDetails.unloading_weight) ||
        (loadingWeight === false && unloadingWeight === false))
    ) {
      dispatch(
        updateTrckingDetails({
          user: routeKey,
          id: bookingDetail?._tracking?.id,
          body: body,
        })
      );
    }
  };
  // update tracking details selector vendor side
  useEffect(() => {
    let toastId: any;
    if (updateTrackingSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (updateTrackingSelector.status === API_CONSTANTS.success) {
      toast.dismiss();
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      refreshBookingDetails();
    }
    if (updateTrackingSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
      toast.dismiss();
      toast.error(updateTrackingSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [updateTrackingSelector]);

  const getTruckNoWithTruckOwner = () => {
    const {
      _tracking: { _truck },
    } = bookingDetail;
    if (_truck?.truck_no && _truck?.owner_name && _truck?.contact_no) {
      return (
        _truck?.truck_no?.toUpperCase() +
        " " +
        `(${_truck?.owner_name || ""} , ${_truck?.contact_no || ""})`
      );
    } else if (_truck?.truck_no) {
      return _truck?.truck_no?.toUpperCase();
    } else {
      return "";
    }
  };

  const handleRequestSearch = (value: string) => {
    if (value !== "") {
      setSearchReqValue(value)
      getSearchBookingRequest(value)
    }
    else {
      toast.error("Please enter a value")
    }

  }

  const handleEnterReqSearch = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      handleRequestSearch(value)
    }
  }

  const resetSearchReq = () => {
    setSearchReqValue("");
    setResultReqText(false);
    refreshBookingDetails()
  }

  return (
    <>
      {confirmModal && (
        <CommonModal
          open={confirmModal}
          setOpen={setConfirmModal}
          agreeButtonText={"Confirm"}
          cancelButtonText={"Cancel"}
          headingText={checkUser() ? "Confirm Booking" : "Accept Request"}
          content={`Are you sure you want to ${checkUser()
            ? "confirm the booking"
            : "accept this request and have available truck"
            }? Once ${checkUser() ? "confirmed" : "accepted and confirmed by the client, "
            } it cannot be cancelled ${checkUser() ? "." : "."}`}
          onClickAgree={confirmRequest}
        />
      )}
      {opneRateHistoryModal && (
        <CommonModal
          maxWidth={"lg"}
          headingText="Rate History"
          open={opneRateHistoryModal}
          setOpen={setOpneRateHistoryModal}
          content={
            <>
              <EnhancedTable
                columns={rateHistorycolumns}
                rows={selectedRateHistory}
              />
            </>
          }
        />
      )}
      {cancelModal && (
        <CommonModal
          maxWidth={"lg"}
          headingText="Cancel Booking"
          open={cancelModal}
          setOpen={setCancelModal}
          agreeButtonText={"Cancel Booking"}
          cancelButtonText={"Don't Cancel"}
          content={
            <>
              <div className="w-[48rem]">
                <BasicSelect
                  label="Select the valid reason for cancellation"
                  options={cancelBookingReasons}
                  onChange={(e) => setCancelBookingRemarks(e.target.value)}
                  validationKey={cancelBookingValidationObj?.remarks}
                />
              </div>
            </>
          }
          onClickAgree={cancelBookingReq}
        />
      )}
      <Grid container>
        <Grid item lg={12} xs={12}>
          <PageTitle className="sm:flex items-center mb-0">
            {match.path === APP_USER_ROUTES[routeKey].REQUEST_DETAILS
              ? "Booking Request"
              : "Booking Details"}
            {" - "}
            {bookingDetail?.booking_no}
            <span className="block mt-4 sm:mt-0 sm:ml-4">{STATUS_JSX[bookingDetail?.status]}</span>
          </PageTitle>
          <div className="text-brand-text-title mt-4 sm:mt-2 mb-6 sm:flex justify-start items-center text-xs">
            <p className="text-xs">
              Created on{" "}
              {moment(bookingDetail?.created_at).format(TIME_FORMAT_STRING)}
            </p>
            <div className="flex items-center mt-2 sm:mt-0">
            <div className="mr-2 sm:mx-2 w-1 h-1 bg-brand-text-title rounded-full" />{" "}
            {LOAD_OPTIONS_WBG[bookingDetail?.type]}
            </div>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Paper
            className={"bg-brand-primary-white" + " " + classes.boxShadowNone}
          >
            <Grid container item className=" p-6">
              <Grid
                item
                lg={4}
                xs={12}
                md={6}
                display="flex"
                alignItems="top"
                className="py-4 pr-4"
              >
                <CalenderIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                <div>
                  <p className="text-sm font-bold text-brand-text-tableHead">
                    {"Pickup Date"}
                  </p>
                  <p className="text-sm text-brand-text-title">
                    {moment
                      .utc(bookingDetail?.pickup_at)
                      .format(TIME_FORMAT_STRING)}
                  </p>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                xs={12}
                md={6}
                display="flex"
                alignItems="top"
                className="py-4 pr-4"
              >
                <MappinIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                <div>
                  <p className="text-sm font-bold text-brand-text-tableHead">
                    {"Pickup Location"}
                  </p>
                  <p className="text-sm text-brand-text-title">
                    <span>
                      {" "}
                      {bookingDetail?._pickup_location
                        ? areaFormattedValue(bookingDetail?._pickup_location)
                        : bookingDetail?.pickup_address || "N/A"}
                    </span>
                    {bookingDetail?.pickup_address && (
                      <>
                        <br />
                        <span className="text-xxs">
                          Address: {bookingDetail?.pickup_address || "NA"}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </Grid>
              <Grid
                item
                lg={4}
                xs={12}
                md={6}
                display="flex"
                alignItems="top"
                className="py-4 pr-4"
              >
                <MappinIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                <div>
                  <p className="text-sm font-bold text-brand-text-tableHead">
                    {"Drop Location"}
                  </p>
                  <p className="text-sm text-brand-text-title">
                    <span>
                      {" "}
                      {bookingDetail?._drop_location
                        ? areaFormattedValue(bookingDetail?._drop_location)
                        : bookingDetail?.drop_address || "N/A"}
                    </span>
                    {bookingDetail?.drop_address && (
                      <>
                        <br />
                        <span className="text-xxs">
                          Address: {bookingDetail?.drop_address || "NA"}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </Grid>
              <Grid item lg={12} xs={12}>
                <hr className="border-brand-extra-background mt-2" />
              </Grid>
              <Grid container item lg={12} xs={12} className={classes.mt1}>
                <Grid item lg={12} xs={12}>
                  <p className="text-base font-bold inline-block">
                    Material Details
                  </p>
                </Grid>
                {bookingDetail?.materials?.map((item, index) => (
                  <>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      md={6}
                      display="flex"
                      alignItems="center"
                      className="py-4 pr-4"
                    >
                      <HexaIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                      <div>
                        <p className="text-sm font-bold text-brand-text-tableHead">
                          Material
                        </p>
                        <p className="text-sm text-brand-text-title">
                          {item.material_type}
                        </p>
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      md={6}
                      display="flex"
                      alignItems="center"
                      className="py-4 pr-4"
                    >
                      <WeightIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                      <div>
                        <p className="text-sm font-bold text-brand-text-tableHead">
                          Weight
                        </p>
                        <p className="text-sm text-brand-text-title">
                          {item.weight} {item.weight_unit}
                        </p>
                      </div>
                    </Grid>
                    <Grid item lg={4} xs={12} md={6}></Grid>
                    {index <= bookingDetail?.materials?.length - 2 && (
                      <Grid item lg={12} xs={12}>
                        <hr className="border-brand-extra-grey my-2" />
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
              <Grid item lg={12} xs={12}>
                <hr className="border-brand-extra-background mt-2" />
              </Grid>
              <Grid container item lg={12} xs={12} className={classes.mt1}>
                <Grid item lg={12} xs={12}>
                  <p className="text-base font-bold inline-block">
                    Rate Details
                  </p>
                </Grid>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  md={6}
                  display="flex"
                  alignItems="center"
                  className="py-4 pr-4"
                >
                  <RupeeIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                  <div>
                    <p className="text-sm font-bold text-brand-text-tableHead">
                      Rate
                    </p>
                    <p className="text-sm text-brand-text-title">
                      {bookingDetail?.rate
                        ? "₹ " +
                        bookingDetail?.rate +
                        "/" +
                        bookingDetail?.rate_unit
                        : "-"}
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  md={6}
                  display="flex"
                  alignItems="center"
                  className="py-4 pr-4"
                >
                  <RateIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                  <div>
                    <p className="text-sm font-bold text-brand-text-tableHead">
                      Total cost
                    </p>
                    <p className="text-sm text-brand-text-title">
                      {"₹ " + (bookingDetail?.total_cost || "N/A")}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid item lg={12} xs={12}>
                <hr className="border-brand-extra-background mt-2" />
              </Grid>
              <Grid item lg={12} xs={12} marginTop="1rem" marginBottom="1rem">
                <p className="text-base font-bold text-brand-text-tableHead">
                  Remarks
                </p>
                <p className="text-brand-text-title text-sm text-light py-2">
                  {bookingDetail?.remarks || "NA"}
                </p>
              </Grid>
              <Grid item lg={12} xs={12}>
                <hr className="border-brand-extra-background mt-2" />
              </Grid>
              {(routeKey === PLATFORM_USERS.ADMIN ||
                routeKey === PLATFORM_USERS.CLIENT) &&
                bookingDetail?.status === BOOKING_STATUS_CONSTANTS.cancelled &&
                bookingDetail?.remarks_history && (
                  <>
                    <p className="text-base font-bold inline-block my-4">
                      Cancellation Reason
                    </p>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      md={12}
                      display="flex"
                      alignItems="center"
                      className="py-4 pr-4"
                    >
                      <div className="bg-brand-extra-bg_blue px-3 py-3 rounded-lg flex items-center">
                        <InfoIcon className="stroke-brand-primary-blue rounded-lg mr-2" />
                        <p className="text-base text-brand-text-title">
                          {
                            bookingDetail?.remarks_history.find(
                              (x) =>
                                x.status === BOOKING_STATUS_CONSTANTS.cancelled
                            ).remarks
                          }
                        </p>
                      </div>
                    </Grid>
                  </>
                )}

              {(routeKey === PLATFORM_USERS.VENDOR ||
                routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
                routeKey === PLATFORM_USERS.ADMIN) && (
                  <>
                    <Grid container item lg={12} xs={12} className={classes.mt1}>
                      <Grid item lg={12} xs={12}>
                        <p className="text-base font-bold inline-block">
                          Client Details
                        </p>
                      </Grid>
                      {bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.confirmed ||
                        bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.completed ||
                        bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.processing ||
                        bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.awaiting_fulfillment ||
                        routeKey === PLATFORM_USERS.ADMIN ? (
                        <>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4 pr-4"
                          >
                            <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Company Name
                              </p>
                              <p
                                className="text-sm text-brand-text-title truncate w-40"
                                title={
                                  bookingDetail?._client?.company_name || "NA"
                                }
                              >
                                {bookingDetail?._client?.company_name || "NA"}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4 pr-4"
                          >
                            <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Name
                              </p>
                              <p
                                className="text-sm text-brand-text-title truncate w-40"
                                title={bookingDetail?._client?.name || "NA"}
                              >
                                {bookingDetail?._client?.name || "NA"}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4 pr-4"
                          >
                            <PhoneIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Phone No
                              </p>
                              <p className="text-sm text-brand-text-title">
                                {bookingDetail?._client?.contact_number || "NA"}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4"
                          >
                            <EmailIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Email
                              </p>
                              <p
                                className="text-sm text-brand-text-title"
                                title={bookingDetail?._client?.email || "NA"}
                              >
                                {bookingDetail?._client?.email || "NA"}
                              </p>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          item
                          lg={12}
                          xs={12}
                          md={12}
                          display="flex"
                          alignItems="center"
                          className="py-4 pr-4"
                        >
                          <div className="bg-brand-extra-bg_blue px-3 py-3 rounded-lg flex items-center">
                            <InfoIcon className="stroke-brand-primary-blue rounded-lg mr-2" />
                            {(routeKey === PLATFORM_USERS.VENDOR ||
                              routeKey === PLATFORM_USERS.VENDOR_BRANCH) && (
                                <p className="text-sm  text-brand-text-title">
                                  This details will only be visible once you accept
                                  the request
                                </p>
                              )}
                            {routeKey === PLATFORM_USERS.ADMIN && (
                              <p className="text-sm text-brand-text-title">
                                This details will only be visible once vendor
                                accept the request
                              </p>
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <hr className="border-brand-extra-background mt-2" />
                    </Grid>
                  </>
                )}
              {(routeKey === PLATFORM_USERS.CLIENT ||
                routeKey === PLATFORM_USERS.ADMIN) && (
                  <>
                    <Grid container item lg={12} xs={12} className={classes.mt1}>
                      <Grid item lg={12} xs={12}>
                        <p className="text-base font-bold inline-block">
                          Vendor Details
                        </p>
                      </Grid>
                      {bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.confirmed ||
                        bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.completed ||
                        bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.processing ||
                        bookingDetail?.status ===
                        BOOKING_STATUS_CONSTANTS.awaiting_fulfillment ||
                        routeKey === PLATFORM_USERS.ADMIN ? (
                        <>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4 pr-4"
                          >
                            <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead w-40">
                                Company Name
                              </p>
                              <p className="text-sm text-brand-text-title">
                                {bookingDetail?._vendor?.company_name || "NA"}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4 pr-4"
                          >
                            <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Name
                              </p>
                              <p
                                className="text-sm text-brand-text-title truncate w-40"
                                title={bookingDetail?._vendor?.name || "NA"}
                              >
                                {bookingDetail?._vendor?.name || "NA"}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4 pr-4"
                          >
                            <PhoneIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Phone No
                              </p>
                              <p className="text-sm text-brand-text-title">
                                {bookingDetail?._vendor?.contact_number || "NA"}
                              </p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={6}
                            display="flex"
                            alignItems="center"
                            className="py-4"
                          >
                            <EmailIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg sm:px-3 py-3 w-12 h-12 mr-4" />
                            <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                Email
                              </p>
                              <p className="text-sm text-brand-text-title">
                                {bookingDetail?._vendor?.email || "NA"}
                              </p>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          item
                          lg={12}
                          xs={12}
                          md={12}
                          display="flex"
                          alignItems="center"
                          className="py-4 pr-4"
                        >
                          <div className="bg-brand-extra-bg_blue px-3 py-3 rounded-lg flex items-center">
                            <InfoIcon className="stroke-brand-primary-blue rounded-lg mr-2" />
                            {
                              <p className="text-sm text-brand-text-title">
                                These details will only be visible once you
                                confirm the booking with a vendor
                              </p>
                            }
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <hr className="border-brand-extra-background mt-2" />
                    </Grid>
                  </>
                )}
              {(routeKey === PLATFORM_USERS.VENDOR ||
                routeKey === PLATFORM_USERS.VENDOR_BRANCH) &&
                //bookingDetail?.type === LOAD_TYPES.FULL_TRUCK && // Commenting because of new Negotiate flow in PTL
                bookingDetail?.rate_history?.length ? (
                <>
                  <Grid container item lg={12} xs={12} className={classes.mt1}>
                    <Grid item lg={12} xs={12}>
                      <p className="text-base font-bold inline-block">
                        Rate History
                      </p>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      md={12}
                      display="flex"
                      alignItems="center"
                      className="pt-4"
                    >
                      <EnhancedTable
                        columns={rateHistorycolumns}
                        rows={structuredSchemaForRateHistory(
                          bookingDetail?.rate_history
                        )}
                        stroke={true}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* Skipping it for now since PTL logic of Rate accept moved as similar to FTL */}
              {false && (routeKey === PLATFORM_USERS.VENDOR ||
                routeKey === PLATFORM_USERS.VENDOR_BRANCH) &&
                bookingDetail?.type === LOAD_TYPES.PART_TRUCK &&
                bookingDetail?.status === BOOKING_STATUS_CONSTANTS.pending && (
                  <>
                    <form onSubmit={acceptBookingForPtl} className="w-full">
                      <Grid
                        container
                        item
                        lg={12}
                        xs={12}
                        className={classes.mt1}
                      >
                        <Grid item lg={1} md={2} xs={12}>
                          <div className="md:mr-4 sm:mr-0 mb-2 ">
                            <CommonButton
                              text="Reject"
                              onClick={rejectRequest}
                              className=" box-border mt-0 border-brand-text-title text-brand-text-tableHead bg-brand-text-white hover:bg-brand-extra-background"
                            />
                          </div>
                        </Grid>
                        <Grid item lg={2} md={2} xs={12}>
                          <div className="mb-2">
                            <CommonButton
                              text="Accept Request"
                              className="mt-0"
                              type="submit"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
              {(routeKey === PLATFORM_USERS.VENDOR ||
                routeKey === PLATFORM_USERS.VENDOR_BRANCH) &&
                //bookingDetail?.type === LOAD_TYPES.FULL_TRUCK &&   // Commenting because of new Negotiate flow in PTL
                bookingDetail?.status !== BOOKING_STATUS_CONSTANTS.confirmed &&
                bookingDetail?.status !== BOOKING_STATUS_CONSTANTS.completed &&
                bookingDetail?.status !== BOOKING_STATUS_CONSTANTS.rejected &&
                bookingDetail?.status !==
                BOOKING_STATUS_CONSTANTS.awaiting_fulfillment &&
                bookingDetail?.status !== BOOKING_STATUS_CONSTANTS.expired &&
                bookingDetail?.status !==
                BOOKING_STATUS_CONSTANTS.cancelled && (
                  <>
                    <form
                      onSubmit={
                        bookingDetail?.status ===
                          BOOKING_STATUS_CONSTANTS.pending
                          ? accpetConfirm
                          : handleNegotiateRate
                      }
                      className="w-full"
                    >
                      <Grid
                        container
                        item
                        lg={12}
                        xs={12}
                        className={classes.mt1}
                      >
                        <Grid item lg={12} xs={12}>
                          <p className="text-base font-bold inline-block">
                            Send Rate
                          </p>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          md={6}
                          display="flex"
                          alignItems="center"
                          className={classes.mb1 + " " + classes.pr4}
                        >
                          {/* <RateIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" /> */}
                          <CommonInputGroup
                            rupee={true}
                            label="Rate"
                            type="number"
                            options={LoadOptions}
                            value={rateDetails.rate}
                            selectValue={rateDetails.rate_unit}
                            placeholder="Rate"
                            onChange={(e) =>
                              handleInputChange("rate", e.target.value)
                            }
                            onChangeSelect={(e) =>
                              handleInputChange("rate_unit", e.target.value)
                            }
                            validationKey={validationObj?.rate}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          md={6}
                          display="flex"
                          alignItems="center"
                          className={classes.mb1}
                        >
                          {/* <RateIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" /> */}
                          <CommonMaterialInput
                            label="Remarks"
                            value={rateDetails.remarks}
                            onChange={(e) =>
                              handleInputChange("remarks", e.target.value)
                            }
                            placeholder="Remarks"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          md={4}
                          display="flex"
                          alignItems="center"
                          className="py-4 pr-4"
                        >
                          {(routeKey === PLATFORM_USERS.VENDOR ||
                            routeKey === PLATFORM_USERS.VENDOR_BRANCH) &&
                            allTrucks.length === 0 && (
                              <>
                                <div className="bg-brand-light-red  px-3 py-3 rounded-lg flex items-center hidden">
                                  <InfoRedIcon className="rounded-lg mr-2" />
                                  <p className="text-sm text-brand-text-title">
                                    You don't have trucks to accept this
                                    booking.
                                    <span
                                      className="cursor-pointer pl-1 font-semibold underline text-brand-primary-blue"
                                      onClick={() =>
                                        history.push({
                                          pathname:
                                            APP_USER_ROUTES[routeKey].FLEETS,
                                        })
                                      }
                                    >
                                      Add truck
                                    </span>
                                  </p>
                                </div>
                              </>
                            )}
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{
                            "@media (max-width:576px)": {
                              "&": {
                                flexWrap: "wrap-reverse",
                              },
                            },
                          }}
                        >
                          {bookingDetail?.status ===
                            BOOKING_STATUS_CONSTANTS.pending ? (
                            <>
                              <Grid item lg={3} md={3} xs={12}>
                                <div className="md:mr-4 sm:mr-0 mb-2 ">
                                  <CommonButton
                                    text="Reject"
                                    onClick={rejectRequest}
                                    className=" box-border mt-0 border-brand-text-title text-brand-text-tableHead bg-brand-text-white hover:bg-brand-extra-background"
                                  />
                                </div>
                              </Grid>
                              <Grid item lg={4} md={4} xs={12}>
                                <div className="mb-2">
                                  <CommonButton
                                    text="Accept Request"
                                    className="mt-0"
                                    type="submit"
                                  />
                                </div>
                              </Grid>
                            </>
                          ) : (
                            <Grid item lg={4} md={4} xs={12}>
                              <div className="mb-2">
                                <CommonButton
                                  text="Send Rate"
                                  className="mt-0"
                                  type="submit"
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {(bookingDetail?.status === BOOKING_STATUS_CONSTANTS.confirmed ||
        bookingDetail?.status ===
        BOOKING_STATUS_CONSTANTS.awaiting_fulfillment ||
        bookingDetail?.status === BOOKING_STATUS_CONSTANTS.completed ||
        (routeKey === PLATFORM_USERS.CLIENT &&
          bookingDetail?.status === BOOKING_STATUS_CONSTANTS.completed)) && (
          <Grid container>
            <Grid item lg={12} xs={12} className="py-8">
              <PageTitle className="mb-0">Tracking Details</PageTitle>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper
                className={
                  "bg-brand-primary-white  p-6" + " " + classes.boxShadowNone
                }
              >
                {bookingDetail?._tracking ? (
                  <TrackingCard
                    vehicle_no={getTruckNoWithTruckOwner()}
                    status={bookingDetail?._tracking?.status}
                    history={bookingDetail?._tracking?.last_updated_history}
                  />
                ) : (
                  <TrackingNoDataFound />
                )}
                {(routeKey === PLATFORM_USERS.CLIENT && trackingDetails &&
                  trackingDetails.meta &&
                  trackingDetails.meta.files &&
                  trackingDetails.meta.files.length > 0) ? (
                  <>
                    <Grid item lg={12} md={12} xs={12}>
                      <hr className="border-brand-extra-grey mt-8 my-2" />
                      <div className="mt-4 mb-2">
                        <span className="text-brand-extra-icon font-medium text-sm">
                          Uploaded Documents
                        </span>
                      </div>
                      <div className="flex flex-wrap">
                        {trackingDetails &&
                          trackingDetails.meta &&
                          trackingDetails.meta.files &&
                          trackingDetails.meta.files.length > 0 ? (
                          <>
                            {trackingDetails.meta.files.map((item, index) => (
                              <>
                                <div className="mb-4">
                                  <span
                                    id="badge-dismiss-default"
                                    className="inline-flex items-center py-2 px-3 mr-4 text-sm font-medium text-blue-800 bg-brand-light-blue rounded dark:bg-blue-200 dark:text-blue-800"
                                  >
                                    <span className="text-xs">{item.name}</span>
                                    <button
                                      title="Download File"
                                      onClick={() => downloadFile(item)}
                                      type="button"
                                      className="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-300 dark:hover:text-blue-900"
                                      data-dismiss-target="#badge-dismiss-default"
                                      aria-label="Remove"
                                    >
                                      <svg
                                        fill="#000000"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        className="w-4 h-4"
                                      >
                                        <path d="M256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM244.7 395.3l-112-112c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9l64 0 0-96c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 96 64 0c6.5 0 12.3 3.9 14.8 9.9s1.1 12.9-3.5 17.4l-112 112c-6.2 6.2-16.4 6.2-22.6 0z" />
                                      </svg>
                                    </button>
                                    {routeKey === PLATFORM_USERS.VENDOR ||
                                      routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
                                      routeKey === PLATFORM_USERS.ADMIN ? (
                                      <>
                                        <span className="inline-flex ml-2">
                                          |
                                        </span>
                                        <button
                                          onClick={() => deleteFile(item, index)}
                                          type="button"
                                          className="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-300 dark:hover:text-blue-900"
                                          data-dismiss-target="#badge-dismiss-default"
                                          aria-label="Remove"
                                        >
                                          <svg
                                            fill="#000000"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            className="w-4 h-4"
                                          >
                                            <path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                                          </svg>
                                          <span className="sr-only">
                                            Delete File
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          <div><p className="text-sm">No documents found</p></div>
                        )}
                      </div>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                {(routeKey === PLATFORM_USERS.VENDOR ||
                  routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
                  routeKey === PLATFORM_USERS.ADMIN) &&
                  trackingOldDetails.status !==
                  TRACKING_STATUS_CONSTANTS.delivered &&
                  (bookingDetail?.status === BOOKING_STATUS_CONSTANTS.confirmed ||
                    bookingDetail?.status ===
                    BOOKING_STATUS_CONSTANTS.awaiting_fulfillment) ? (
                  <form onSubmit={submitTrackingDetails}>
                    <p className="mt-8"></p>
                    <Grid container>
                      <Grid item lg={12} md={12} xs={12}>
                        <MaterialSingleSearchDropdown
                          label="Select Truck"
                          value={trackingDetails.truck_id}
                          disabled={trackingOldDetails.truck_id ? true : false}
                          onChange={(value) => {
                            handleInputChangeTrackingdetails("truck_id", value);
                          }}
                          placeholder="Select Truck"
                          validationKey={trackingValidationObj.truck_id}
                          options={allTrucks}
                        />
                        {(routeKey === PLATFORM_USERS.VENDOR ||
                          routeKey === PLATFORM_USERS.VENDOR_BRANCH) &&
                          allTrucks.length === 0 && (
                            <>
                              <div className="py-2 rounded-lg flex items-center">
                                <span
                                  className="cursor-pointer text-sm font-semibold underline text-brand-primary-blue"
                                  onClick={() =>
                                    history.push({
                                      pathname: APP_USER_ROUTES[routeKey].FLEETS,
                                    })
                                  }
                                >
                                  Add truck
                                </span>
                              </div>
                            </>
                          )}
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                        <MaterialSingleSearchDropdown
                          label="Tracking Status"
                          options={TrackingStatusObject}
                          value={trackingDetails.status}
                          onChange={(value) => {
                            handleInputChangeTrackingdetails("status", value);
                          }}
                          validationKey={trackingValidationObj.status}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <CommonMaterialInput
                          label="Last Updated"
                          type="datetime-local"
                          placeholder="Date Time"
                          onChange={(e) => {
                            handleInputChangeTrackingdetails(
                              "last_updated_at",
                              e.target.value
                            );
                          }}
                          validationKey={trackingValidationObj.last_updated_at}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xs={12}
                        md={6}
                        display="flex"
                        alignItems="center"
                        className="pb-4 pr-4"
                      >
                        {/* <RateIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" /> */}
                        <CommonInputGroup
                          label="Loading Weight (Required for dispatching)"
                          type="number"
                          options={LoadOptionsForWeight}
                          value={trackingDetails.loading_weight}
                          selectValue={trackingDetails.loading_weight_unit}
                          disabled={
                            trackingOldDetails?.status !==
                            TRACKING_STATUS_CONSTANTS.not_dispatched ||
                            trackingDetails.status !==
                            TRACKING_STATUS_CONSTANTS.dispatch
                          }
                          placeholder="Loading Weight"
                          onChange={(e) =>
                            handleInputChangeTrackingdetails(
                              "loading_weight",
                              e.target.value
                            )
                          }
                          onChangeSelect={(e) =>
                            handleInputChangeTrackingdetails(
                              "loading_weight_unit",
                              e.target.value
                            )
                          }
                          validationKey={
                            trackingOldDetails?.status ===
                              TRACKING_STATUS_CONSTANTS.not_dispatched
                              ? trackingValidationObj.loading_weight
                              : ""
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        xs={12}
                        md={6}
                        display="flex"
                        alignItems="center"
                        className="pb-4 pr-4"
                      >
                        {/* <RateIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" /> */}
                        <CommonInputGroup
                          label="UnLoading Weight (Required for delivering)"
                          type="number"
                          options={LoadOptionsForWeight}
                          value={trackingDetails.unloading_weight}
                          selectValue={trackingDetails.unloading_weight_unit}
                          placeholder="UnLoading Weight"
                          onChange={(e) =>
                            handleInputChangeTrackingdetails(
                              "unloading_weight",
                              e.target.value
                            )
                          }
                          disabled={
                            trackingOldDetails?.status !==
                            TRACKING_STATUS_CONSTANTS.dispatch ||
                            trackingDetails.status !==
                            TRACKING_STATUS_CONSTANTS.delivered
                          }
                          onChangeSelect={(e) =>
                            handleInputChangeTrackingdetails(
                              "unloading_weight_unit",
                              e.target.value
                            )
                          }
                          validationKey={
                            trackingOldDetails?.status ===
                              TRACKING_STATUS_CONSTANTS.in_transit ||
                              trackingOldDetails?.status ===
                              TRACKING_STATUS_CONSTANTS.dispatch
                              ? trackingValidationObj.unloading_weight
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <CommonTextArea
                          label="Remarks"
                          value={trackingDetails.remarks}
                          onChange={(e) => {
                            handleInputChangeTrackingdetails(
                              "remarks",
                              e.target.value
                            );
                          }}
                        />
                      </Grid>
                      <Grid
                        className=""
                        item
                        lg={1}
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <CommonButton
                          text="Update"
                          type="submit"
                          className={`mt-8 ${
                            trackingOldDetails.status ===
                            TRACKING_STATUS_CONSTANTS.delivered
                            ? "bg-opacity-50 hover:bg-opacity-50"
                            : ""
                            }`}
                          disabled={
                            trackingOldDetails.status ===
                            TRACKING_STATUS_CONSTANTS.delivered
                          }
                        />
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  ""
                )}

                {(routeKey === PLATFORM_USERS.VENDOR || routeKey === PLATFORM_USERS.VENDOR_BRANCH || routeKey === PLATFORM_USERS.ADMIN) ? (<>

                  <Grid item lg={12} md={12} xs={12}>
                    <div className="mt-8 mb-2">
                      <hr className="border-brand-extra-grey mt-2 mb-4" />
                      <span className="text-brand-extra-icon font-medium text-sm">
                        Documents
                      </span>
                    </div>
                    <div className="flex flex-wrap">
                      {trackingDetails &&
                        trackingDetails.meta &&
                        trackingDetails.meta.files &&
                        trackingDetails.meta.files.length > 0 ? (
                        <>
                          {trackingDetails.meta.files.map((item, index) => (
                            <>
                              <div className="mb-4">
                                <span
                                  id="badge-dismiss-default"
                                  className="inline-flex items-center py-2 px-3 mr-4 text-sm font-medium text-blue-800 bg-brand-light-blue rounded dark:bg-blue-200 dark:text-blue-800"
                                >
                                  <span className="text-xs">{item.name}</span>
                                  <button
                                    title="Download File"
                                    onClick={() => downloadFile(item)}
                                    type="button"
                                    className="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-300 dark:hover:text-blue-900"
                                    data-dismiss-target="#badge-dismiss-default"
                                    aria-label="Remove"
                                  >
                                    <svg
                                      fill="#000000"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      className="w-4 h-4"
                                    >
                                      <path d="M256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM244.7 395.3l-112-112c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9l64 0 0-96c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 96 64 0c6.5 0 12.3 3.9 14.8 9.9s1.1 12.9-3.5 17.4l-112 112c-6.2 6.2-16.4 6.2-22.6 0z" />
                                    </svg>
                                  </button>
                                  {routeKey === PLATFORM_USERS.VENDOR ||
                                    routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
                                    routeKey === PLATFORM_USERS.ADMIN ? (
                                    <>
                                      <span className="inline-flex ml-2">
                                        |
                                      </span>
                                      <button
                                        title="Delete File"
                                        onClick={() =>
                                          deleteFile(item, index)
                                        }
                                        type="button"
                                        className="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-300 dark:hover:text-blue-900"
                                        data-dismiss-target="#badge-dismiss-default"
                                        aria-label="Remove"
                                      >
                                        <svg
                                          fill="#000000"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          className="w-4 h-4"
                                        >
                                          <path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                                        </svg>
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                      <div className="cursor-pointer border border-dashed border-brand-primary-blue relative w-36 py-2 h-9 px-4 rounded-lg">
                        <div className="cursor-pointer text-center absolute top-0 right-0 left-0 m-auto h-full">
                          <div className="cursor-pointer flex w-full h-full justify-center items-center">
                            <p className="cursor-pointer text-brand-primary-blue text-xs font-semibold">
                              + Upload Document
                            </p>
                            <input
                              name="file"
                              type="file"
                              className="cursor-pointer text-center absolute top-0 right-0 left-0 m-auto h-full w-full opacity-0 z-50"
                              onChange={(event: any) =>
                                onFileUploadChange(event)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>

                </>) : ''}
              </Paper>
            </Grid>
          </Grid>
        )}
      {trackingDetails?.unloading_weight &&
        (bookingDetail?.status === BOOKING_STATUS_CONSTANTS.confirmed ||
          bookingDetail?.status ===
          BOOKING_STATUS_CONSTANTS.awaiting_fulfillment ||
          bookingDetail?.status === BOOKING_STATUS_CONSTANTS.completed) && (
          <Grid container>
            <Grid item lg={12} xs={12} className="py-8">
              <PageTitle className="mb-0">Payment Details</PageTitle>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper
                className={
                  "bg-brand-primary-white  p-6" + " " + classes.boxShadowNone
                }
              >
                {bookingDetail?._tracking ? (
                  <>
                    <PaymentDetailsCard
                      bookingDetails={bookingDetail}
                      trackingDetails={trackingDetails}
                    />
                    <Grid container>
                      <Grid item lg={4} xs={12} className="">
                        <CommonButton
                          text="Download Payment Slip"
                          onClick={() =>
                            downloadBookingPaymentSlip(bookingDetail?.id)
                          }
                          className="mt-4"
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      {(bookingDetail?.status === BOOKING_STATUS_CONSTANTS.confirmed ||
        bookingDetail?.status ===
        BOOKING_STATUS_CONSTANTS.awaiting_fulfillment) &&
        routeKey === PLATFORM_USERS.CLIENT && (
          <Paper
            className={
              classes.boxShadowNone + " " + classes.p1t5 + " " + classes.mt1
            }
          >
            <Grid container>
              <Grid item lg={2} xs={12} className="">
                <CommonButton
                  text="Complete Booking"
                  onClick={completeBooking}
                  className="mt-0"
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      {bookingDetail?.status === BOOKING_STATUS_CONSTANTS.unconfirmed &&
        routeKey === PLATFORM_USERS.CLIENT && (
          <Paper
            className={
              classes.boxShadowNone + " " + classes.p1t5 + " " + classes.mt1
            }
          >
            <Grid
              container
              justifyItems="right"
              display="flex"
              justifyContent="right"
            >
              <Grid item lg={2} xs={12} className="">
                <CommonButton
                  text="Cancel Booking"
                  onClick={cancelBooking}
                  className="mt-0"
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      {
        // bookingDetail?.type === LOAD_TYPES.FULL_TRUCK &&
        // bookingDetail?.status === BOOKING_STATUS_CONSTANTS.unconfirmed &&
        (routeKey === PLATFORM_USERS.CLIENT ||
          routeKey === PLATFORM_USERS.ADMIN) && (
          <Grid container className="pt-4">
            <Grid item lg={12} xs={12} className="pt-4">
              <PageTitle className="mb-0">Booking Requests</PageTitle>
            </Grid>
            <Grid 
              container
              className="mb-2 justify-start sm:justify-end"
              alignItems="center"
            >
              <Grid item lg={2} md={4} xs={8}
                style={{ maxWidth: "80%" }}
              >
                <SearchInput
                  placeholder="Search here"
                  handleSearch={handleRequestSearch}
                  handleEnterSearch={handleEnterReqSearch}
                  resultText={resultReqText}
                  total={total}
                  resetSearch={resetSearchReq}
                  searchValue={searchReqValue}
                  filter={null}
                />
              </Grid>
            </Grid>
            <Grid item lg={12} xs={12}>
              <EnhancedTable
                columns={columns}
                rows={structuredSchemaForBookingRequest(
                  bookingDetail?.booking_requests
                )}
                actions={routeKey === PLATFORM_USERS.ADMIN ? adminActions : actions}
              />
            </Grid>
          </Grid>
        )
      }
    </>
  );
};

export default ViewBookingDetails;
