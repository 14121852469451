import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_SUCCESS_MESSAGE,
  LOADING_TEXT,
} from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import { ParamsTypes, UserObject } from "../../../../utils/ObjectTypes";
import ComingSoon from "../../../ComingSoon";
import {
  deleteBranches,
  getAllBranchList,
} from "../../../../ReduxActions/branch.action";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import DeleteModal from "../../../Modal/DeleteModal";

interface Data {
  name: string;
  email: number;
  type: string;
  phone: string;
  pincode: string;
  zones: string;
  city: string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
  makeLinkable?: boolean;
  onClickAction?: any;
}

interface Props {
  key?: string;
  match?: any;
  history?: any;
  routeKey?: string;
  userProfileData?: UserObject;
}

const Branches: React.FC<Props> = (props) => {
  const { key, match, history, userProfileData, routeKey } = props;

  const param = routeKey;

  // dispatch
  const dispatch = useDispatch();
  // selector
  const allBranches = useSelector(
    (state: any) => state.BranchReducer.allBranches
  );
  const deleteBranchSelector = useSelector(
    (state: any) => state.BranchReducer.deleteBranch
  );

  const [allBranchData, setallBranchData] = useState([]);
  const [branchesMeta, setBranchesMeta] = useState({});
  const [branchId, setBranchId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState({
    allBranch: false,
    deleteBranch: false,
  });

  const getAllBranches = (page?: number, limit?: number) => {
    dispatch(getAllBranchList({ user: param, page, limit }));
  };

  useEffect(() => {
    getAllBranches(1, 10);
  }, []);

  useEffect(() => {
    let toastId: any;
    if (allBranches.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, allBranch: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (allBranches.status === API_CONSTANTS.success && loading.allBranch) {
      setLoading({ ...loading, allBranch: false });
      setallBranchData(allBranches.data?.data);
      setBranchesMeta(allBranches.data?.meta);
      toast.dismiss(toastId);
    }
    if (allBranches.status === API_CONSTANTS.error && loading.allBranch) {
      setLoading({ ...loading, allBranch: false });
      toast.dismiss(toastId);
      toast.error(allBranches.data);
    }
  }, [allBranches]);

  useEffect(() => {
    let toastId: any;
    if (deleteBranchSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, deleteBranch: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (
      deleteBranchSelector.status === API_CONSTANTS.success &&
      loading.deleteBranch
    ) {
      setLoading({ ...loading, deleteBranch: false });
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      getAllBranches();
    }
    if (
      deleteBranchSelector.status === API_CONSTANTS.error &&
      loading.deleteBranch
    ) {
      setLoading({ ...loading, deleteBranch: false });
      toast.dismiss(toastId);
      toast.error(deleteBranchSelector.data);
    }
  }, [deleteBranchSelector]);
  const columns: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      callBackArguments: ["id"],
      customCell: false,
      makeLinkable: true,
      onClickAction: (id) => {
        handleViewBranchDetail(id);
      },
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "pincode",
      numeric: false,
      disablePadding: false,
      label: "Pincode",
    },
    {
      id: "zones",
      numeric: false,
      disablePadding: false,
      label: "Zones",
    },
    {
      id: "city",
      numeric: false,
      disablePadding: false,
      label: "City",
    },
  ];
  const actions = [
    {
      id: "view",
      label: "View",
      callbackArguments: ["id"],
      onClick: (id) => {
        handleViewBranchDetail(id);
      },
    },
    {
      id: "edit",
      label: "Edit",
      callbackArguments: ["id"],
      onClick: (id) => {
        handleEditBranch(id);
      },
    },
    {
      id: "delete",
      label: "Delete",
      callbackArguments: ["id"],
      onClick: (id) => {
        setBranchId(id);
        setOpenDeleteModal(true);
      },
    },
  ];

  const handleViewBranchDetail = (id) => {
    history.push(APP_USER_ROUTES[param].SHOW_VENDOR_BRANCH_ALIAS + "/" + id);
  };

  const handleEditBranch = (id) => {
    history.push(APP_USER_ROUTES[param].EDIT_BRANCHES_ALIAS + "/" + id);
  };

  const handleDeleteBranch = () => {
    dispatch(deleteBranches({ id: branchId, user: param, body: {} }));
    setOpenDeleteModal(false);
  };

  const addBranch = () => {
    history.push(APP_USER_ROUTES[param].ADD_BRANCHES);
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headingText="Delete Branch"
        agreeButtonText="Delete"
        cancelButtonText="Cancel"
        onClickAgree={handleDeleteBranch}
        content={<p>Are you sure ? you want to delete this Branch.</p>}
      />
      <div className="">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={12}>
            <PageTitle className="mb-0">Branches</PageTitle>
          </Grid>
          <Grid item lg={2} md={4} xs={12} className="pl-12">
            <Button
              text="Add Branch"
              type="button"
              onClick={addBranch}
              className="mt-0"
            />
          </Grid>
        </Grid>
        <Grid container className=" mt-8">
          {/* <ComingSoon {...props}/> */}
          <TableComponent
            columns={columns}
            rows={allBranchData}
            actions={actions}
            onPageChange={getAllBranches}
            meta={branchesMeta}
            onClickFunc={addBranch}
            description="You don't have any branches yet"
            text="+ Add Branch"
          />
        </Grid>
      </div>
    </>
  );
};

export default Branches;
