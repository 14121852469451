import React from 'react';
import { PLATFORM_USERS } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';
import ClientComponent from '../../components/role/admin/ShowClients';

interface Props{

  userProfileData ?: UserObject;

}

const ShowClients: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  
  return (
    <>
      {
        userProfileData.role === PLATFORM_USERS.ADMIN &&
        <>        
          <ClientComponent {...props}/>
        </>
      }
    </>
  )
}

export default ShowClients