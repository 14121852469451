import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import VendorBookings from '../../components/role/vendor/Bookings';
import VendorPayments from '../../components/role/vendor/Payments';
import ClientBookings from '../../components/role/client/Bookings';
import ClientPayemnts from '../../components/role/client/Payments';
import { PLATFORM_USERS } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';
import ShowStatesForAdminComponent from '../../components/role/admin/Locations/states';
import ShowCitiesForAdminComponent from '../../components/role/admin/Locations/cities';
import ShowPincodesForAdminComponent from '../../components/role/admin/Locations/pincodes';


interface Props{

  userProfileData ?: UserObject;

}

export const ShowStatesAdminService: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  return (
    <>
      {
        userProfileData.role === PLATFORM_USERS.ADMIN &&
        <>        
          <ShowStatesForAdminComponent {...props}/>
        </>
      }
    </>
  )
}

export const ShowCitiesAdminService: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  return (
    <>
      {
        userProfileData.role === PLATFORM_USERS.ADMIN &&
        <>        
          <ShowCitiesForAdminComponent {...props}/>
        </>
      }
    </>
  )
}

export const ShowPincodesAdminService: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  return (
    <>
      {
        userProfileData.role === PLATFORM_USERS.ADMIN &&
        <>        
          <ShowPincodesForAdminComponent {...props}/>
        </>
      }
    </>
  )
}