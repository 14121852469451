import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
  bookingRequestStatusFilterVendor,
  BOOKING_STATUS_CONSTANTS,
} from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";
import BasicSelect from "../../../Dropdown/SimpleSelect";
import { fetchAllBookingRequests } from "../../../../ReduxActions";
import SearchInput from "../../../SearchInput";
interface Data {
  booking_id: number;
  route: string;
  material: any;
  client: string;
  type: string;
  pickup_at: string;
  status: string;
  pickup_address: string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const requestColumns: readonly HeadCell[] = [
  {
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "route",
    numeric: false,
    disablePadding: false,
    label: "Route",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "material",
    numeric: false,
    disablePadding: false,
    label: "Material",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "pickup_at",
    numeric: false,
    disablePadding: false,
    label: "Pickup at",
  },
];

interface Props {
  key?: string;
  match?: any;
  routeKey?: string;
  history?: any;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const BookingRequests: React.FC<Props> = (props) => {
  let uelparams: any = useQuery();
  const [filterBookingsReq, setFilterBookingsReq] = useState({
    status: BOOKING_STATUS_CONSTANTS.all,
  });
  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  
  const allbookingsRequestsSelector = useSelector(
    (state: any) => state.BookingReducer.bookingRequests
  );
  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request: false,
  });
  const [allBookingrequests, setAllBookingrequests] = useState<any[]>([]);
  const [bookingRequestMeta, setBookingRequestMeta] = useState({});
 
  const [searchReqValue, setSearchReqValue] = useState("");
  const [resultReqText, setResultReqText] = useState(false);

  const getAllBookingRequests = (
    page?: number,
    limit?: number,
    search?: string,
    status?: string
  ) => {
    dispatch(fetchAllBookingRequests({ user: param, page, limit, search, status }));
  };
  
  useEffect(() => {
    const bookingsReqFilter = uelparams.get("bookingsreq");
    bookingsReqFilter &&
      setFilterBookingsReq({
        status: BOOKING_STATUS_CONSTANTS[bookingsReqFilter],
      });
    getAllBookingRequests(1, 10,searchReqValue, bookingsReqFilter);
  }, []);

  useEffect(() => {
    let toastId: any;
    if (allbookingsRequestsSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, request: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (
      allbookingsRequestsSelector.status === API_CONSTANTS.success &&
      loading.request
    ) {
      setLoading({ ...loading, request: false });
      setAllBookingrequests([
        ...allbookingsRequestsSelector?.data?.data?.map((items) => {
          items.booking_id = (
            <p
              onClick={() => viewRequestDetails(items.id)}
              className="text-brand-primary-blue cursor-pointer"
            >
              {items.booking_id}
            </p>
          );
          return items;
        }),
      ]);
      setBookingRequestMeta(allbookingsRequestsSelector.data?.meta);
      searchReqValue !== "" && setResultReqText(true)
      toast.dismiss(toastId);
    }
    if (
      allbookingsRequestsSelector.status === API_CONSTANTS.error &&
      loading.request
    ) {
      setLoading({ ...loading, request: false });
      toast.dismiss(toastId);
      toast.error(
        allbookingsRequestsSelector.data || DEFAULT_ERROR_MESSAGE.failed
      );
    }
  }, [allbookingsRequestsSelector]);

  // table request booking actions
  const requestActions = [
    {
      id: "view",
      label: "View Details",
      callbackArguments: ["id"],
      onClick: (id) => {
        viewRequestDetails(id);
      },
    },
  ];

  // view requests details
  const viewRequestDetails = (id) => {
    history.push(APP_USER_ROUTES[param].REQUEST_DETAILS_ALIAS + "/" + id);
  };

  const handleBookingReqFilter = (key, value) => {
    if (value === BOOKING_STATUS_CONSTANTS.all) {
      setFilterBookingsReq({
        ...filterBookingsReq,
        [key]: value,
      });
      getAllBookingRequests(1, 10,searchReqValue);
    } else {
      setFilterBookingsReq({
        ...filterBookingsReq,
        [key]: value,
      });
      getAllBookingRequests(1, 10, searchReqValue, value);
    }
  };

  const handleRequestSearch = (value: string) => {
    if (value !== "") {
      setSearchReqValue(value)
      if (filterBookingsReq["status"] === BOOKING_STATUS_CONSTANTS.all) {
        getAllBookingRequests(1, 10, value)
      }
      else {
        getAllBookingRequests(1, 10, value, filterBookingsReq["status"])
      }
    }
    else {
      toast.error("Please enter a value")
    }

  }

  const handleEnterReqSearch = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      handleRequestSearch(value)
    }

  }

  const resetSearchReq = () => {
    setSearchReqValue("");
    setResultReqText(false)
    if (filterBookingsReq["status"] === BOOKING_STATUS_CONSTANTS.all) {
      getAllBookingRequests(1, 10, "")
    }
    else {
      getAllBookingRequests(1, 10, "", filterBookingsReq["status"])
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item lg={12} md={4} xs={12} className={classes.mb1}>
          <PageTitle className="mb-0">All Bookings Requests</PageTitle>
          <p className="text-brand-text-title text-sm">
            Requests which are not yet confirmed by the client{" "}
          </p>
        </Grid>
        <Grid
          container
          className="my-2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={4} hidden>
            <p className="mt-4 text-sm">Filter Bookings</p>
          </Grid>
          <Grid item lg={2} md={4} xs={8}>
            <BasicSelect
              label="Filter bookings"
              options={bookingRequestStatusFilterVendor}
              onChange={(e) => handleBookingReqFilter("status", e.target.value)}
              value={filterBookingsReq["status"]}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={8}
              style={{ maxWidth: "80%" }}
            >
              <SearchInput
                placeholder="Search here"
                handleSearch={handleRequestSearch}
                handleEnterSearch={handleEnterReqSearch}
                resultText={resultReqText}
                total={bookingRequestMeta["total"]}
                resetSearch={resetSearchReq}
                searchValue={searchReqValue}
                filter={bookingRequestStatusFilterVendor.filter(item => {return item.value === filterBookingsReq.status})}
              />
            </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-16">
          {/* <ComingSoon {...props}/> */}
          <TableComponent
            columns={requestColumns}
            rows={allBookingrequests}
            actions={requestActions}
            onPageChange={getAllBookingRequests}
            meta={bookingRequestMeta}
            status={
              filterBookingsReq["status"] !== BOOKING_STATUS_CONSTANTS.all
                ? filterBookingsReq["status"]
                : undefined
            }
            searchValue={searchReqValue}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BookingRequests;
