import React from 'react'
import Branches from './Service'
import PageTitle from '../../components/Typography/PageTitle'

const BranchesContainer = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <Branches {...props}/>
    </div>
  )
}

export default BranchesContainer