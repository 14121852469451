import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from '../../../Button';
import { Grid } from "@mui/material";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT} from '../../../../utils/constants';
import { Link, useLocation, useParams } from "react-router-dom";
import { ParamsTypes } from "../../../../utils/ObjectTypes";
import ComingSoon from "../../../ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import { admminApproveDisapproveVendor, fetchAllBookingRequests, fetchAllPincodesForAdmin, fetchAllStatesForAdmin, fetchAllVendorBookings, fetchAllVendors, getStatesData } from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";
import { PLATFORM_USERS } from '../../../../utils/constants';
import VendorPreferencesModal from "../Bookings/VendorPreferencesModal";
import { staructureCityData, structureStateData } from "../../../../utils/common";

interface Data {
  pincode: string;
  user_count: string,
  state_name: string,
  city_name: string,
  district: string,
  office_name: string,
  region_name: string,
  fallback_state_name: string;
  created_at: string;
}

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
  getCustomColumn? : boolean;
  getColData?: (row: any) => void;
}

const columns: readonly HeadCell[] = [
  {
    id: "pincode",
    numeric: false,
    disablePadding: false,
    label: "Pincode",
    callBackArguments : ['id'],
    customCell: false,
  },
  {
    id: "user_count",
    numeric: false,
    disablePadding: false,
    label: "Users",
  },
  {
    id: "state_name",
    numeric: false,
    disablePadding: false,
    label: "State",
    getCustomColumn: true,
    getColData: (row: any) => (
      <Link
        to={`${APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PINCODES}?search=${row.state_name}`}
        className="text-brand-primary-blue text-sm"
      >
        {row.state_name}
      </Link>
    ),
  },
  {
    id: "city_name",
    numeric: false,
    disablePadding: false,
    label: "City",
    getCustomColumn: true,
    getColData: (row: any) => (
      <Link
        to={`${APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PINCODES}?search=${row.city_name}`}
        className="text-brand-primary-blue text-sm"
      >
        {row.city_name}
      </Link>
    ),
  },
  {
    id: "district",
    numeric: false,
    disablePadding: false,
    label: "District",
  },
  {
    id: "office_name",
    numeric: false,
    disablePadding: false,
    label: "Office",
  },
  {
    id: "region_name",
    numeric: false,
    disablePadding: false,
    label: "Region",
  },
  {
    id: "fallback_state_name",
    numeric: false,
    disablePadding: false,
    label: "Fallback State",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Added On",
  },
];

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ShowPincodesForAdmin: React.FC<Props> = (props) => {

  let urlParams: any = useQuery();

  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors 
  const allPincodesSelector = useSelector((state: any)=>state.UserReducer.allPincodes)

  const searchQuery = urlParams.get("search");

  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request : false,
  });
  const [allPincodes, setAllPincodes] = useState([]);
  const [allPincodesMeta, setAllPincodesMeta] = useState({});
  
  const getAllPincodes = (page?: number, limit?: number, search?: string) => {
    if(!search) {
      search = urlParams.get("search");
    }
    dispatch(fetchAllPincodesForAdmin({ user : param, page, limit, search}));
  }

  useEffect(() => {
    if(searchQuery)
      getAllPincodes(1,50, searchQuery);
    else
     getAllPincodes(1,50);
  }, [searchQuery])

  useEffect(() => {
    let toastId : any;
    if(allPincodesSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allPincodesSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      setAllPincodes([...allPincodesSelector?.data?.data]);
      setAllPincodesMeta(allPincodesSelector.data?.meta);
      toast.dismiss(toastId);
    }
    if(allPincodesSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allPincodesSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allPincodesSelector])

  
  
  // table confirmed booking actions
  const actions = [
    {
      id: "view",
      label: "View"
    }
  ];

  // view booking details
  const viewVendorDetails = (id) => {
    history.push(APP_USER_ROUTES[param].SHOW_VENDORS_ALIAS+'/'+id)
  }

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">
            All Pincodes
          </PageTitle>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <TableComponent 
            columns={columns} 
            rows={allPincodes} 
            actions={actions} 
            onPageChange={getAllPincodes} 
            meta={allPincodesMeta} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowPincodesForAdmin;
