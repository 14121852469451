import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { GET_ALL_PAYMENTS, SET_ALL_PAYMENTS, ADD_PAYEMNTS, SET_ADD_PAYMENTS, GET_PAYMENTS_BY_ID, SET_PAYMENTS_BY_ID, EDIT_PAYMENTS, SET_EDIT_PAYMENTS, DELETE_PAYMENTS, SET_DELETE_PAYMENTS, GET_BOOKING_BY_PAYMENT_ID, SET_GET_BOOKING_BY_PAYMENT_ID } from "../ReduxConstants/PaymentConstants";
import { PaymentService } from "../sevices";
import { API_CONSTANTS } from "../utils/constants";
import { modifySchema } from "./Schema.tsx";
import { structuredPaymentsSchema } from "./Schema.tsx/PaymentsSchema";

const getAllPayments = (payload) => ({
    type : GET_ALL_PAYMENTS,
    payload
})

const setAllPayments = (payload) => ({
    type : SET_ALL_PAYMENTS,
    payload
})

const addpayments = (payload) => ({
    type : ADD_PAYEMNTS,
    payload
})

const setAddPayments = (payload) => ({
    type : SET_ADD_PAYMENTS,
    payload
})

const getbyId = (payload) => ({
    type : GET_PAYMENTS_BY_ID,
    payload
})

const setGetbyId = (payload) => ({
    type : SET_PAYMENTS_BY_ID,
    payload
})

const edit = (payload) => ({
    type : EDIT_PAYMENTS,
    payload
})

const setEdit = (payload) => ({
    type : SET_EDIT_PAYMENTS,
    payload
})

const deletePayment = (payload) => ({
    type : DELETE_PAYMENTS,
    payload
})

const setDelete = (payload) => ({
    type : SET_DELETE_PAYMENTS,
    payload
})

const getBookingWithPayment = (payload) => ({
    type : GET_BOOKING_BY_PAYMENT_ID,
    payload
})

const setBookingWithPayment = (payload) => ({
    type : SET_GET_BOOKING_BY_PAYMENT_ID,
    payload
})

export const fetchAllPaymentsDetails = (data) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getAllPayments(data));
      await PaymentService.getAll(data)
        .then((result) => {
          dispatch(setAllPayments(modifySchema(((result)), API_CONSTANTS.success)));
        })
        .catch((error) => {
          dispatch(setAllPayments(modifySchema(error,API_CONSTANTS.error)));
        });
    };
};

export const addNewPayment = (data: { user : string, body: any }) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(addpayments(data));
      await PaymentService.create(data)
        .then((result) => {
          dispatch(setAddPayments(modifySchema((result), API_CONSTANTS.success)));
        })
        .catch((error) => {
          dispatch(setAddPayments(modifySchema(error,API_CONSTANTS.error)));
        });
    };
;};

export const updatePayments = (data: { user : string, body: any, id: string }) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        await dispatch(edit(data));
        await PaymentService.edit(data)
        .then((result) => {
            dispatch(setEdit(modifySchema((result), API_CONSTANTS.success)));
        })
        .catch((error) => {
            dispatch(setEdit(modifySchema(error,API_CONSTANTS.error)));
        });
    };
};

export const deletePayments = (data: { user : string, id: string }) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(deletePayment(data));
      await PaymentService.remove(data)
        .then((result) => {
          dispatch(setDelete(modifySchema((result), API_CONSTANTS.success)));
        })
        .catch((error) => {
          dispatch(setDelete(modifySchema(error,API_CONSTANTS.error)));
        });
    };
};

export const getPayemntById = (data: { user : string, id: string }) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getbyId(data));
      await PaymentService.getById(data)
        .then((result) => {
          dispatch(setGetbyId(modifySchema((result), API_CONSTANTS.success)));
        })
        .catch((error) => {
          dispatch(setGetbyId(modifySchema(error,API_CONSTANTS.error)));
        });
    };
};

export const bookingbyPaymentId = (data: { user : string, id: string }) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getBookingWithPayment(data));
      await PaymentService.bookingById(data)
        .then((result) => {
          dispatch(setBookingWithPayment(modifySchema((result), API_CONSTANTS.success)));
        })
        .catch((error) => {
          dispatch(setBookingWithPayment(modifySchema(error,API_CONSTANTS.error)));
        });
    };
};