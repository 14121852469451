import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import PageTitle from "../../../Typography/PageTitle";
import { Grid } from "@mui/material";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT, PLATFORM_USERS} from '../../../../utils/constants';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStatesForAdmin } from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";

interface Data {
  name: string;
  cities_count: string;
  pincodes_count: string;
  created_at: string;
}

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
  getCustomColumn? : boolean;
  getColData?: (row: any) => void;
}

const columns: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    callBackArguments : ['id'],
    customCell: false,
  },
  {
    id: "cities_count",
    numeric: false,
    disablePadding: false,
    label: "No. of Cities",
  },
  {
    id: "pincodes_count",
    numeric: false,
    disablePadding: false,
    label: "No. of Pincodes",
    getCustomColumn: true,
    getColData: (row: any) => (
      <Link
        to={`${APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PINCODES}?search=${row.name}`}
        className="text-brand-primary-blue text-sm"
      >
        {row.pincodes_count}
      </Link>
    ),
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Added On",
  },
];

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}

const ShowStatesForAdmin: React.FC<Props> = (props) => {
  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors 
  const allStatesSelector = useSelector((state: any)=>state.UserReducer.allStates)


  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request : false,
  });
  const [allStates, setAllStates] = useState([]);
  
  const getAllStates = () => {
    dispatch(fetchAllStatesForAdmin({ user : param}));
  }

  useEffect(() => {
    getAllStates();
  }, [])

  useEffect(() => {
    let toastId : any;
    if(allStatesSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allStatesSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      setAllStates([...allStatesSelector?.data?.data]);
      toast.dismiss(toastId);
    }
    if(allStatesSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allStatesSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allStatesSelector])

  
  
  // table confirmed booking actions
  const actions = [
    {
      id: "view",
      label: "View"
    }
  ];

  // view booking details
  const viewVendorDetails = (id) => {
    history.push(APP_USER_ROUTES[param].SHOW_VENDORS_ALIAS+'/'+id)
  }

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">
            All States
          </PageTitle>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <TableComponent columns={columns} rows={allStates} actions={actions} onPageChange={getAllStates} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowStatesForAdmin;
