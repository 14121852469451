import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageDark from "../../assets/img/splash-image.png";
import { GithubIcon, TwitterIcon } from "../../icons";
import { Input, Label, Button } from "@windmill/react-ui";
import {
  API_CONSTANTS,
  LOADING_TEXT,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  PLATFORM_USERS,
  APP_USER_ROUTES,
  PLATFORM_USERS_NAME,
} from "../../utils/constants";
import { handleCheckValidation } from "../../ValidationHelpers";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../ReduxActions/index";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ParamsTypes } from "../../utils/ObjectTypes";
import CommonInput from "../../components/Input/MaterialInput";
import { capitalize, getFirstLastNameSepration } from "../../utils/common";
import AppLogo from "../../components/AppLogo";

interface Props {
  history?: any;
  routeKey?: string;
}

interface ValidationObject {
  name?: string;
  companyName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  privacy?: boolean;
  master_key?: string;
}

const Register: React.FC<Props> = (props) => {
  // console.log(props);

  const { history, routeKey } = props;

  const dispatch = useDispatch();

  const param = routeKey;

  const registerSelector = useSelector(
    (state: any) => state.AuthReducer.register
  );

  const [registerDetails, setRegisterDetails] = useState({
    name: "",
    companyName: "",
    email: "",
    password: "",
    confirmPassword: "",
    privacy: false,
  });

  const [validationObj, setValidationObj] = useState<ValidationObject>({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setRegisterDetails({ ...registerDetails, [key]: value });
  };

  useEffect(() => {
    let toastId: any;
    if (registerSelector?.status === API_CONSTANTS.loading) {
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (registerSelector?.status === API_CONSTANTS.success && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.varification);
      history.push(APP_USER_ROUTES[param].LOGIN);
    }
    if (registerSelector?.status === API_CONSTANTS.error && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(registerSelector.data || DEFAULT_ERROR_MESSAGE.error1);
    }
  }, [registerSelector]);

  const onSubmit = (e) => {
    e.preventDefault();
    const validation: ValidationObject = handleCheckValidation(registerDetails);
    setValidationObj(validation);
    if (
      param === PLATFORM_USERS.VENDOR &&
      validation &&
      !validation.email &&
      !validation.password &&
      !validation.privacy &&
      !validation.name &&
      !validation.companyName
    ) {
      /// callback login function
      dispatch(
        registerUser({
          body: {
            first_name: getFirstLastNameSepration(registerDetails.name)
              .first_name,
            last_name: getFirstLastNameSepration(registerDetails.name)
              .last_name,
            company_name: registerDetails.companyName,
            email: registerDetails.email,
            password: registerDetails.password,
          },
          user: param,
        })
      );
    } else if (
      param === PLATFORM_USERS.CLIENT &&
      validation &&
      !validation.email &&
      !validation.name &&
      !validation.password &&
      !validation.companyName
    ) {
      dispatch(
        registerUser({
          body: {
            first_name: getFirstLastNameSepration(registerDetails.name)
              .first_name,
            last_name: getFirstLastNameSepration(registerDetails.name)
              .last_name,
            email: registerDetails.email,
            password: registerDetails.password,
          },
          user: param,
        })
      );
    } else if (
      param === PLATFORM_USERS.ADMIN &&
      validation &&
      !validation.email &&
      !validation.name &&
      !validation.password
    ) {
      dispatch(
        registerUser({
          body: {
            first_name: getFirstLastNameSepration(registerDetails.name)
              .first_name,
            last_name: getFirstLastNameSepration(registerDetails.name)
              .last_name,
            email: registerDetails.email,
            password: registerDetails.password,
          },
          user: param,
        })
      );
    }
  };

  return (
    <div className="flex items-center min-h-screen p-6 bg-brand-extra-background">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-card">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-44 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className=" object-cover w-full h-full"
              src={ImageDark}
              alt="splash-image"
            />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <div className=" mb-4">
                <AppLogo/>
              </div>
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Sign Up for  {PLATFORM_USERS_NAME[param]}
              </h1>
              <form onSubmit={onSubmit}>
                <CommonInput
                  type="text"
                  label="Name"
                  placeholder="Name"
                  value={registerDetails.name}
                  onChange={(e: any) =>
                    handleInputChange("name", e.target.value)
                  }
                  validationKey={validationObj?.name}
                />
                {(param === PLATFORM_USERS.CLIENT || param === PLATFORM_USERS.VENDOR) && (
                  <CommonInput
                    type="text"
                    label="Company Name"
                    placeholder="Company Name"
                    value={registerDetails.companyName}
                    onChange={(e: any) =>
                      handleInputChange("companyName", e.target.value)
                    }
                    validationKey={validationObj?.companyName}
                  />
                )}
                <CommonInput
                  type="email"
                  label="Email"
                  placeholder="john@doe.com"
                  value={registerDetails.email}
                  onChange={(e: any) =>
                    handleInputChange("email", e.target.value)
                  }
                  validationKey={validationObj?.email}
                />
                <CommonInput
                  type="password"
                  label="Password"
                  placeholder="Password"
                  value={registerDetails.password}
                  onChange={(e: any) =>
                    handleInputChange("password", e.target.value)
                  }
                  validationKey={validationObj?.password}
                />
                <Label className="mt-6" check>
                  <Input
                    type="checkbox"
                    onClick={(e) =>
                      handleInputChange("privacy", e.target.checked)
                    }
                    required
                    className="ring-0 focus:ring-0"
                  />
                  <span className="ml-2 text-brand-extra-icon font-medium">
                    I agree to the{" "}
                    <span className="underline">privacy policy</span>
                  </span>
                </Label>

                <Button
                  type="submit"
                  block
                  className="mt-4 py-3 bg-brand-primary-blue hover:bg-brand-hover-blue text-brand-extra-background"
                >
                  Register
                </Button>
              </form>

              {/* <hr className="my-8" />

              <Button block layout="outline">
                <GithubIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                Github
              </Button>
              <Button block className="mt-4" layout="outline">
                <TwitterIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                Twitter
              </Button> */}

              <p className="mt-4">
                <Link
                  className="text-sm text-brand-extra-icon font-medium hover:underline"
                  to={APP_USER_ROUTES[param].LOGIN}
                >
                  Already have an account? Login
                </Link>
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
export default Register;
