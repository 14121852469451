import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllVendorBookings } from '../../../../ReduxActions';
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT } from '../../../../utils/constants';
import DashboardComponent from '../../client/Dashboard/DashBoardComponent';

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}
/**
 * 
 * @param props { key, match, routeKey, history }
 * @returns 
 */
const DashboardClient: React.FC<Props> = (props) => {
  // props
  const { key, match, routeKey, history } = props;
  const param = routeKey;
  // dispatch
  const dispatch = useDispatch();
  // selectors 
  const allBookingSelector = useSelector((state:any)=>state.BookingReducer.allVendorBookings);
  const [allBookings, setAllBookings] = useState([]);
  const [loading, setLoading] = useState({
    get : false,
  });

  useEffect(() => {
    dispatch(fetchAllVendorBookings({ user : param}));
  }, [])

  useEffect(() => {
    let toastId : any;
    if(allBookingSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, get:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allBookingSelector.status === API_CONSTANTS.success && loading.get){
      setLoading({...loading, get:false});
      setAllBookings([...allBookingSelector?.data?.data?.map((items)=>{
        items.booking_id = <p onClick={()=>viewBookingDetails(items.id)} className="text-brand-primary-blue cursor-pointer">{items.booking_id}</p>
        return items
      })]);
      toast.dismiss(toastId);
    }
    if(allBookingSelector.status === API_CONSTANTS.error && loading.get){
      setLoading({...loading, get:false});
      toast.dismiss(toastId);
      toast.error(allBookingSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allBookingSelector])

    // view booking details
    const viewBookingDetails = (id) => {
      history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS+'/'+id)
    }
  
  return (
    <>
      <DashboardComponent {...props} bookingData={allBookings} branchData={[]}/>
    </>
  )
}

export default DashboardClient