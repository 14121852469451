import React from 'react';
import ViewBranchDetails from '../../components/role/vendor/Branches/ViewBranchDetails';
import { PLATFORM_USERS } from '../../utils/constants';

interface Props{
    routeKey ?: string;
}  

const BranchDetails: React.FC<Props> = (props) => {

    const { routeKey } = props;

  return (
    <div className="py-6 px-6 overflow-x-auto">
        {
            routeKey === PLATFORM_USERS.VENDOR &&
            <ViewBranchDetails {...props}/>
        }
    </div>
  )
}

export default BranchDetails