
interface IZoneObject {
    name : string;
    pincodes : any[];
    id : string;
}

export const structuredZoneSchema = ( response ) =>{
    if(response?.data?.length){
        response.data = response?.data?.map((items: IZoneObject)=>({
            name : items.name,
            pincodes : items.pincodes,
            id : items.id
        }))
    }
    return response;
}