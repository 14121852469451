import React, { useState, useMemo, useEffect } from "react";
import { FtlImage, PtlImage } from "../../../../icons";
import { API_CONSTANTS, LOAD_OPTIONS, LOAD_TYPES, PLATFORM_USERS_NAME } from "../../../../utils/constants";
import MaterialSearchDropdown from "../../../Dropdown/MaterialSearchDropdown";
import Modal from "../../../Modal";
import { Grid } from "@mui/material";
import { UserService } from "../../../../sevices";
import toast from "react-hot-toast";
import MaterialMultiSearchDropdown from "../../../Dropdown/MaterialMultiSearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getStatesData } from "../../../../ReduxActions";
interface Props {
  open?: boolean;
  setOpen?: any;
  onClickCards?: any;
}

const VendorPreferencesModal = (props) => {
  const { open, setOpen, bookingId, param, vendorId, allStatesData, pastVendorPreferences } = props;
  const [validationObj, setValidationObj] = useState<any>({});
  const [searchText, setSearchText] = useState<string>("");
  const [preferenceValues, setPreferenceValues] = useState<any[]>([]);
  const [formatedPreferenceValues, setFormatedPreferenceValues] = useState<any[]>([]);
  const [inputValues, setInputValues] = useState([]);
  const dispatch = useDispatch();

  const handleInputChange = (key, value) => {
    console.log({value})
    //setInputValues(value);
    //setPreferenceValues(value);
    console.log("dd", value)
    setPreferenceValues(value.map((i) => { return { type: "state", value: i.value, text: i.text, key: i.key }}));
    setFormatedPreferenceValues(value.map((i) => { return { type: "state", value: i.value, text: i.text, key: i.key }}))
  };

  useEffect(() => {
    setFormatedPreferenceValues(pastVendorPreferences.map((item, index) => ({ key : index, value : item.preference_value, text: item.preference_value })));
  }, [open]);

  useEffect(() => {
    if(searchText && searchText.length>=2){
      dispatch(
        getStatesData({
          location : searchText
        })
      )
    }
  }, [searchText])

  const handleSubmit = () => {
    if (preferenceValues.length > 0) {
      UserService.postUserPreferences({
        user: param,
        data: { vendor_id: vendorId , preferences: preferenceValues },
      })
        .then((result: any) => {
          console.log(result);
          if (result) {
            toast.success("Successfully Updated");
            setOpen(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        setOpen={setOpen}
        headingText="Preferences for Vendors"
        maxWidth={"md"}
        content={
          <>
            <Grid item lg={12} xs={12} className="">
              <div className="w-screen" />
              <MaterialSearchDropdown
                label="Select States"
                options={allStatesData.map((item, index) => ({key : index, value : item.text, text: item.text}))}
                value={formatedPreferenceValues}
                onChange={(value) => handleInputChange("name", value)}
                //onChangeText={(e) => setSearchText(e.target.value)}
                placeholder="Type state names"
              />
            </Grid>
          </>
        }
        onClickAgree={handleSubmit}
        agreeButtonText="Submit"
        cancelButtonText="Cancel"
      />
    </div>
  );
};

export default VendorPreferencesModal;
