import React from "react";
import { Label, Input } from "@windmill/react-ui";
import { HelperText } from "@windmill/react-ui";
import { TextField, Box, InputAdornment } from "@mui/material";
import { useCommonStyles } from "../../styles/common";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

interface Props {
  value?: string | number;
  onChange?: any;
  label?: string;
  validationKey?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  rupee ?: boolean;
}

const CommonMaterialInput: React.FC<Props> = (props) => {
  const classes = useCommonStyles();
  const { value, onChange, label, validationKey, type, placeholder, disabled, rupee } =
    props;
  return (
    <div className="mt-4 flex justify-start w-full flex-col">
      {label && (
        <span className="text-brand-extra-icon font-medium text-sm mb-1">
          {label}
        </span>
      )}
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        className="flex"
      >
        <TextField
          fullWidth
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          InputProps={{
            className: classes.bg_white + " " + classes.p3px +" "+ classes.pr0 +" "+classes.pl0 + " " + classes.fontSizeSmall + " " + classes.inputHeight+" "+"ring-0 ring-offset-0",
            startAdornment:(
              rupee &&
              <InputAdornment position="start">
                <CurrencyRupeeIcon fontSize="small" className={classes.mlpt5}/>
              </InputAdornment>
            ),
            inputProps: type==='number'?{ min: 0 }:{},
            sx:{
              '&:hover' : {
                border :"1px solid #3466E5",
              },
              '&:hover .MuiOutlinedInput-notchedOutline':{
                border : '0px solid',
              },
              '&:focus-within' : {
                border :"1px solid #3466E5",
              },
              '&:focus-within .MuiOutlinedInput-notchedOutline':{
                border : '0px solid',
              },
            }
          }}
          sx={{
            // width: "75%",
            '& .MuiOutlinedInput-notchedOutline' : {
              border :'1px solid #EBEEF3'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline' :{
              border :'1px solid #EBEEF3 !important'
            },
            '& input':{
              padding:'7.5px 14px',
              paddingLeft:'9px',
              fontSize:'14px'
            },
          }}
          disabled={disabled}
          helperText={validationKey}
          error={validationKey ? true : false}
          FormHelperTextProps={{
            className: classes.ml0,
            style:{marginLeft:'0'}
          }}
        />
      </Box>
    </div>
  );
};

export default CommonMaterialInput;
