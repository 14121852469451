import React from 'react'
import { RouteIcon } from '../../icons'

const TrackingNoDataFound = () => {
  return (
    <div className=' w-full flex justify-center items-center p-12'>
        <div className='text-center'>
            <RouteIcon className=' bg-brand-extra-bg_blue w-12 h-12 p-3 mx-auto'/>
            <p className='text-brand-text-title text-base mt-4'>Tracking details will only be visible once vendor accept the request</p>
        </div>
    </div>
  )
}

export default TrackingNoDataFound