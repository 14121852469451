import React, { useState, useEffect } from 'react';
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from '../../../Button';
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT} from '../../../../utils/constants';
import { Link, useParams } from "react-router-dom";
import { ParamsTypes, UserObject } from "../../../../utils/ObjectTypes";
import ComingSoon from '../../../ComingSoon';
import { fetchAllVendorRelatedBranch } from '../../../../ReduxActions/branch.action';
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import DeleteModal from "../../../Modal/DeleteModal";

interface Data {
    name: string;
    email: number;
    type: string;
    phone : string;
    pincode : string;
    zones : string;
    city : string;
    is_email_verified : string;
    is_approved : string;
  }
  
  interface HeadCell {
    disablePadding ?: boolean;
    id ?: keyof Data;
    label ?: string;
    numeric ?: boolean;
    callBackArguments ? : Array<string>;
    customCell ?: any;
    makeLinkable?: boolean;
  }
  
  const columns: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      callBackArguments : ['id'],
      customCell: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "is_approved",
      numeric: false,
      disablePadding: false,
      label: "Approved",
    },
    {
      id: "pincode",
      numeric: false,
      disablePadding: false,
      label: "Pincode",
    },
    {
      id: "zones",
      numeric: false,
      disablePadding: false,
      label: "Zones",
    },
    {
      id: "city",
      numeric: false,
      disablePadding: false,
      label: "City",
    },
    {
      id: "is_email_verified",
      numeric: false,
      disablePadding: false,
      label: "Verified",
    },
  ];
  
  interface Props {
    key ?: string;
    match ?: any;
    history ?: any;
    routeKey ?: string;
    userProfileData ?: UserObject;
    id?:any;
  }

const Branches : React.FC<Props> = (props) => {

    const { key, match, history, userProfileData, routeKey, id } = props;

    const param = routeKey;

    // dispatch
    const dispatch = useDispatch();
    // selector
    const allBranches = useSelector(
      (state: any) => state.BranchReducer.vendorBranches
    );

    const [allBranchData, setallBranchData] = useState([]);
    const [allBranchMeta, setallBranchMeta] = useState({});
    const [loading, setLoading] = useState({
      allBranch: false,
      deleteBranch: false,
    });

    const getAllBranches = (page?:number, limit?:number) => {
      dispatch(fetchAllVendorRelatedBranch({ user: param, vendor_id: id, page, limit }));
    };
  
    useEffect(() => {
      getAllBranches(1, 10);
    }, []);

    useEffect(() => {
      let toastId: any;
      if (allBranches.status === API_CONSTANTS.loading) {
        setLoading({ ...loading, allBranch: true });
        toastId = toast.loading(LOADING_TEXT.fetching);
      }
      if (allBranches.status === API_CONSTANTS.success && loading.allBranch) {
        setLoading({ ...loading, allBranch: false });
        setallBranchData(allBranches.data?.data);
        setallBranchMeta(allBranches.data?.meta);
        toast.dismiss(toastId);
      }
      if (allBranches.status === API_CONSTANTS.error && loading.allBranch) {
        setLoading({ ...loading, allBranch: false });
        toast.dismiss(toastId);
        toast.error(allBranches.data);
      }
    }, [allBranches]);

  return (
    <>
      <div className=''>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item lg={2} md={4} xs={12}>
            <PageTitle className="mb-0">
              Branches
            </PageTitle>
          </Grid>
          <Grid item lg={2} md={4} xs={12} className="pl-12">
          </Grid>
        </Grid>
        <Grid container className='mt-4'>
          {/* <ComingSoon {...props}/> */}
        <TableComponent columns={columns} rows={allBranchData} stroke={true} onPageChange={getAllBranches} meta={allBranchMeta}/>
        </Grid>
      </div>
    </>
  )
}

export default Branches;