import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {} from "../ReduxConstants";
import {
  GET_ALL_BRANCHES,
  GET_ALL_BRANCHES_SUCCESS,
  GET_ALL_BRANCHES_ERROR,
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_ERROR,
  ADD_BRANCHES,
  ADD_BRANCHES_SUCCESS,
  ADD_BRANCHES_ERROR,
  EDIT_BRANCHES,
  EDIT_BRANCHES_SUCCESS,
  EDIT_BRANCHES_ERROR,
  DELETE_BRANCHES,
  DELETE_BRANCHES_SUCCESS,
  DELETE_BRANCHES_ERROR,
  GET_VENDOR_BRANCHES,
  SET_VENDOR_BRANCHES,
  GET_BRANCH_COUNT,
  SET_BRANCH_COUNT
} from "../ReduxConstants/BranchesConstants";
import { BranchService } from "../sevices/branch.service";
import { structureBranchData } from "./Schema.tsx/BranchSchema";
import { modifySchema } from "./Schema.tsx";
import { API_CONSTANTS } from "../utils/constants";

const getAllBranches = (payload: object) => ({
  type: GET_ALL_BRANCHES,
  payload: payload,
});

const getAllBranchesSuccess = (payload: object) => ({
  type: GET_ALL_BRANCHES_SUCCESS,
  payload: payload,
});

const getAllBranchesError = (payload: object) => ({
  type: GET_ALL_BRANCHES_ERROR,
  payload: payload,
});

const getBranch = (payload: object) => ({
  type: GET_BRANCH,
  payload: payload,
});

const getBranchSuccess = (payload: object) => ({
  type: GET_BRANCH_SUCCESS,
  payload: payload,
});

const getBranchError = (payload: object) => ({
  type: GET_BRANCH_ERROR,
  payload: payload,
});

const addBranch = (payload: object) => ({
  type: ADD_BRANCHES,
  payload: payload,
});

const addBranchSuccess = (payload: object) => ({
  type: ADD_BRANCHES_SUCCESS,
  payload: payload,
});

const addBranchError = (payload: object) => ({
  type: ADD_BRANCHES_ERROR,
  payload: payload,
});

const editBranch = (payload: object) => ({
  type: EDIT_BRANCHES,
  payload: payload,
});

const editBranchSuccess = (payload: object) => ({
  type: EDIT_BRANCHES_SUCCESS,
  payload: payload,
});

const editBranchError = (payload: object) => ({
  type: EDIT_BRANCHES_ERROR,
  payload: payload,
});

const deleteBranch = (payload: object) => ({
  type: DELETE_BRANCHES,
  payload: payload,
});

const deleteBranchSuccess = (payload: object) => ({
  type: DELETE_BRANCHES_SUCCESS,
  payload: payload,
});

const deleteBranchError = (payload: object) => ({
  type: DELETE_BRANCHES_ERROR,
  payload: payload,
});

const getAllVendorBranches = (payload: object) => ({
  type: GET_VENDOR_BRANCHES,
  payload: payload,
});

const setAllVendorBranches = (payload: object) => ({
  type: SET_VENDOR_BRANCHES,
  payload: payload,
});

const getBranchCounts = (payload: object) => ({
  type: GET_BRANCH_COUNT,
  payload: payload,
});

const setBranchCounts = (payload: object) => ({
  type: SET_BRANCH_COUNT,
  payload: payload,
});

export const getAllBranchList = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllBranches(data));
    await BranchService.getAllBranches(data)
      .then((result) => {
        dispatch(getAllBranchesSuccess(structureBranchData(result)));
      })
      .catch((error) => {
        dispatch(getAllBranchesError(error));
      });
  };
};

export const getBranchData = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getBranch(data));
    await BranchService.getBranch(data)
      .then((result) => {
        dispatch(getBranchSuccess(result));
      })
      .catch((error) => {
        dispatch(getBranchError(error));
      });
  };
};

export const addBranches = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(addBranch(data));
    await BranchService.addBranch(data)
      .then((result) => {
        dispatch(addBranchSuccess(result));
      })
      .catch((error) => {
        dispatch(addBranchError(error));
      });
  };
};

export const editBranches = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(editBranch(data));
    await BranchService.updateBranch(data)
      .then((result) => {
        dispatch(editBranchSuccess(result));
      })
      .catch((error) => {
        dispatch(editBranchError(error));
      });
  };
};

export const deleteBranches = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(deleteBranch(data));
    await BranchService.deleteBranch(data)
      .then((result) => {
        dispatch(deleteBranchSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteBranchError(error));
      });
  };
};

export const fetchAllVendorRelatedBranch = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllVendorBranches(data));
    await BranchService.getAllBranchesForVendor(data)
    .then((result) => {
      dispatch(
        setAllVendorBranches(
          modifySchema(structureBranchData(result), API_CONSTANTS.success)
        )
      );
    })
    .catch((error) => {
      dispatch(
        setAllVendorBranches(
          modifySchema(error, API_CONSTANTS.error)
        )
      );
    });
  };
};

export const fetchBranchCount = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getBranchCounts(data));
    await BranchService.getBranchCount(data)
    .then((result) => {
      dispatch(
        setBranchCounts(
          modifySchema(result, API_CONSTANTS.success)
        )
      );
    })
    .catch((error) => {
      dispatch(
        setBranchCounts(
          modifySchema(error, API_CONSTANTS.error)
        )
      );
    });
  };
};
