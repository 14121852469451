import React, { useState } from "react";
import { useHistory } from "react-router";
import AppLogo from "../../components/AppLogo";
import { SelectUserClientIcon, SelectUserVendorIcon } from "../../icons";
import { APP_USER_ROUTES, LOAD_TYPES, PLATFORM_USERS } from "../../utils/constants";
import Button from '../../components/Button';
import toast from "react-hot-toast";

const SelectUserType = () => {
    const [userType, setUserType] = useState('');
    const onButtonClick = () => {
        if(userType){
            history.push(APP_USER_ROUTES[userType].LOGIN + '?utm_source=pwa1.0');
        }else{
            toast.error('Please select one option')
        }
    }
    const history = useHistory();
    const onClickCards = (e: any,userType: string) => {
        e.preventDefault()
      setUserType(userType)
    };
    return (
        <div className="max-w-md mx-auto h-full">
            <div className="flex justify-center mt-8">
                <AppLogo />
            </div>
            <div className="flex flex-col items-center my-16">
                <div className="text-brand-text-tablehead font-bold text-2xl">
                    Who are you? 
                </div>
                <div className="text-brand-text-icon text-lg">
                   Login as a client or Transporter
                </div>
            </div>
            <div className="flex items-stretch justify-content-center space-x-4 m-4">
                <div
                    onClick={(e) => onClickCards(e, PLATFORM_USERS.CLIENT)}
                    className={`p-6 mt-2 hover:fill-brand-primary-blue text-brand-text-tableHead fill-brand-text-tableHead rounded-lg border border-brand-extra-border  hover:border-brand-primary-blue hover:text-brand-primary-blue cursor-pointer ${ userType === PLATFORM_USERS.CLIENT ?'fill-brand-primary-blue text-brand-text-tableHead border-brand-primary-blue text-brand-primary-blue': 'border-brand-extra-border ' }`}
                >
                    <div className="mx-auto overflow-hidden relative w-16 h-16 bg-brand-extra-bg_blue rounded-full flex justify-center items-center">
                        <SelectUserClientIcon className="mx-auto" />
                    </div>
                    <p className="text-center mt-2 font-bold hover:text-brand-primary-blue mb-2">
                        Client
                    </p>
                    <p className="text-xs text-center hover:text-brand-primary-blue mb-2">
                        You want to create a new booking for your cargo
                    </p>
                </div>

                <div
                    onClick={(e) => onClickCards(e,PLATFORM_USERS.VENDOR)}
                    className={`p-6 mt-2 hover:fill-brand-primary-blue text-brand-text-tableHead fill-brand-text-tableHead rounded-lg border border-brand-extra-border hover:border-brand-primary-blue hover:text-brand-primary-blue cursor-pointer ${ userType === PLATFORM_USERS.VENDOR ?'fill-brand-primary-blue text-brand-text-tableHead border-brand-primary-blue text-brand-primary-blue': 'border-brand-extra-border ' }`}
                >
                    <div className="mx-auto overflow-hidden relative w-16 h-16 bg-brand-extra-bg_blue rounded-full flex justify-center items-center">
                        <SelectUserVendorIcon className="mx-auto" />
                    </div>

                    <p className="text-center mt-2 font-bold hover:text-brand-primary-blue mb-2">
                        Transporter
                    </p>
                    <p className="text-xs text-center hover:text-brand-primary-blue mb-2">
                        You want to receive bookings and deliver the cargo
                    </p>
                </div>
            </div>
            <div className="mt-16 mx-auto w-full">
                <div className="m-4 max-w-md">
                    <Button text={"Continue"} onClick={() => onButtonClick()} />
                </div>
            </div>
            
        </div>
    );
};

export default SelectUserType;
