import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import VendorDashboard from '../../components/role/vendor/Dashboard';
import AdminDashboard from '../../components/role/admin/Dashboard';
import { PLATFORM_USERS } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';
import DashboardClient from '../../components/role/client/Dashboard';

interface Props{

  userProfileData ?: UserObject;

}

const DashboardMain: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  
  return (
    <>
      {
        (userProfileData.role === PLATFORM_USERS.VENDOR||userProfileData.role === PLATFORM_USERS.VENDOR_BRANCH) &&
        <>        
          <VendorDashboard {...props}/>
          {/* <VendorPayments {...props}/> */}
        </>
      }
      {
        userProfileData.role === PLATFORM_USERS.ADMIN &&
        <>        
          <AdminDashboard {...props}/>
          {/* <VendorPayments {...props}/> */}
        </>
      }
      {
        userProfileData.role === PLATFORM_USERS.CLIENT &&
        <>        
          <DashboardClient {...props}/>
        </>
      }
    </>
  )
}

export default DashboardMain