import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { IUpdateUserObject } from "../utils/ObjectTypes";
import { downloadURI } from "../utils/common";

export const BookingService = {
    /**
     * Client Api functions to fetch all Booking related informations
     * @param data { user : 'client'}
     * @returns response 
     */

    getSpecificBookingRequestSearch: async (data: { user: string, id: string, search?: string , booking_id?: string}) => {
        const request = { data }
        const result = await axiosInstance.get(API_URLS[data.user].specificBookingRequestSearch(data.id) + `?search=${data.search}${data.booking_id ? `&booking_id=${data.booking_id}` : ``}`);
        return result
    },

    getAllClientBookings: async (data: { user: string, page?: number, limit?: number, status?: string, search?: string }) => {
        const request = { data }
        const result = await axiosInstance.get(API_URLS[data.user].allBookings + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ``}${data.search !== "" ? `&search=${data.search}` : ``}`);
        return result
    },

    allVendorList: async (data: { user: string, pickup?: any, drop?: any, is_rate_selected?: boolean }) => {
        const request = { data }
        const result = await axiosInstance.get(API_URLS[data.user].vendorList + `?from_pincode=${data.pickup}&to_pincode=${data.drop}${data.is_rate_selected ? `&is_rate_selected=${data.is_rate_selected}` : `&is_rate_selected=false`}`);
        return result
    },

    bookingbyId: async (data: { user: string, id: string }) => {
        const request = { data }
        const result = await axiosInstance.get(API_URLS[data.user].getBooking(data.id));
        return result
    },

    bookPtl: async (data: { user: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.post(API_URLS[data.user].createBookingPtl, request);
        return result
    },

    bookFtl: async (data: { user: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.post(API_URLS[data.user].createBookingFtl, request);
        return result
    },

    confirmBooking: async (data: { user: string, id?: string, update_id?: string, body: any }) => {
        const request = { ...data.body }
        if (data?.update_id) return axiosInstance.patch(API_URLS[data.user].updateBookingOrder(data?.update_id), request)
        const result = await axiosInstance.patch(API_URLS[data.user].confirmBooking(data.id));
        return result
    },

    reconsiderBooking: async (data: { user: string, id: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.patch(API_URLS[data.user].reconsiderBooking(data.id));
        return result
    },



    /**
     * Vendor Api functions to fetch all Booking related informations
     * @param data { user : 'vendor_hq || vendor_branch'}
     * @returns response 
     */
    getAllVendorBookings: async (data: { user: string, page?: number, limit?: number, status?: string, search?: string }) => {
        const result = await axiosInstance.get(API_URLS[data.user].allBookings + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ''}${data.search !== "" ? `&search=${data.search}` : ``}`);
        return result
    },

    bookingsRequests: async (data: { user: string, page?: number, limit?: number, status?: string, search?: string }) => {
        const result = await axiosInstance.get(API_URLS[data.user].bookingRequests + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ''}${data.search !== "" ? `&search=${data.search}` : ``}`);
        return result
    },

    bookingDetailsVendor: async (data: { user: string, id?: string, requestId?: string }) => {
        if (data?.requestId) return axiosInstance.get(API_URLS[data.user].bookingRequestDetails(data.requestId));
        return axiosInstance.get(API_URLS[data.user].bookingDetails(data.id));
    },

    acceptBooking: async (data: { user: string, id: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.put(API_URLS[data.user].acceptBooking(data.id), request);
        return result
    },

    rejectBooking: async (data: { user: string, id: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.patch(API_URLS[data.user].rejectBooking(data.id), request);
        return result
    },

    negotiateBooking: async (data: { user: string, id: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.put(API_URLS[data.user].negotiateBooking(data.id), request);
        return result
    },

    updateTracking: async (data: { user: string, id: string, body: any }) => {
        const request = { ...data.body }
        const result = await axiosInstance.put(API_URLS[data.user].updateTracking(data.id), request);
        return result
    },

    /**
     * Admin Api functions to fetch all booking related informations
     * @param data { user : 'admin'}
     * @returns response 
     */
    getAllBookingsForAdmin: async (data: { user: string, page?: number, limit?: number }) => {
        const result = await axiosInstance.get(API_URLS[data.user].allBookings + `?page=${data?.page}&limit=${data?.limit}`);
        return result
    },

    getAllBookingRequestsForAdmin: async (data: { user: string, page?: number, limit?: number }) => {
        const result = await axiosInstance.get(API_URLS[data.user].bookingRequests + `?page=${data?.page}&limit=${data?.limit}`);
        return result
    },

    getBookingByIDAdmin: async (data: { user: string, id?: string }) => {
        const result = await axiosInstance.get(API_URLS[data.user].bookingDetails(data?.id));
        return result
    },

    getBookingRequestsByIDAdmin: async (data: { user: string, id?: string }) => {
        const result = await axiosInstance.get(API_URLS[data.user].bookingRequestDetails(data?.id));
        return result
    },

    getAllBookingsForVendor: async (data: { user: string, vendor_id?: string, branch_id?: string, page?: number, limit?: number, status?: string }) => {
        if (data?.branch_id) return axiosInstance.get(API_URLS[data.user].getBranchBookings(data?.branch_id) + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ''}`)
        const result = await axiosInstance.get(API_URLS[data.user].getVendorBookings(data?.vendor_id) + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ''}`);
        return result
    },

    getAllBookingRequestsForVendor: async (data: { user: string, vendor_id: string, branch_id: string, page?: number, limit?: number, status?: string }) => {
        if (data?.branch_id) return axiosInstance.get(API_URLS[data.user].getBranchBookingRequests(data?.branch_id) + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ''}`)
        const result = await axiosInstance.get(API_URLS[data.user].getVendorBookingRequests(data?.vendor_id) + `?page=${data?.page}&limit=${data?.limit}${data.status ? `&status=${data.status}` : ''}`);
        return result
    },

    bookingStats: async (data: { user: string, query?: string }) => {
        const result = await axiosInstance.get(API_URLS[data.user].stats + '?date=' + data?.query);
        return result
    },

    downloadBookingPaymentSlip: async (data: { user: string, id?: string }) => {
        axiosInstance.get(API_URLS[data.user].downloadBookingPaymentSlip(data.id));

        const response = await axiosInstance.get(API_URLS[data.user].downloadBookingPaymentSlip(data.id),
            { params: {}, responseType: "blob" }
        );
        let fileName =
            response?.headers["content-disposition"].split("filename=")[1] ||
            "Unknown";
        const url = window.URL.createObjectURL(
            new Blob([response.data], {
                type: "application/pdf",
            })
        );

        // Trigger Download URI for the file
        downloadURI(url, fileName);

        return response;
    },

}