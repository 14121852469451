import {
  GET_PINCODE,
  GET_PINCODE_ERROR,
  GET_PINCODE_SUCCESS,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_ERROR,
  GET_COUNTRY,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
} from "../ReduxConstants/StaticConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  country: SubstateObject;
  states: SubstateObject;
  pincodeData: SubstateObject;
  city: SubstateObject;
}

const initialState: InitialState = {
  country: {
    data: null,
    status: null,
  },
  states: {
    data: null,
    status: null,
  },
  pincodeData: {
    data: null,
    status: null,
  },
  city: {
    data: null,
    status: null,
  },
};

const StaticReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PINCODE: {
      return {
        ...initialState,
        pincodeData: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_PINCODE_SUCCESS: {
      return {
        ...initialState,
        pincodeData: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_PINCODE_ERROR: {
      return {
        ...initialState,
        pincodeData: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_STATES: {
      return {
        ...initialState,
        states: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_STATES_SUCCESS: {
      return {
        ...initialState,
        states: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_STATES_ERROR: {
      return {
        ...initialState,
        states: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_COUNTRY: {
      return {
        ...initialState,
        country: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_COUNTRY_SUCCESS: {
      return {
        ...initialState,
        country: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_COUNTRY_ERROR: {
      return {
        ...initialState,
        country: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_CITY: {
      return {
        ...initialState,
        city: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_CITY_SUCCESS: {
      return {
        ...initialState,
        city: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_CITY_ERROR: {
      return {
        ...initialState,
        city: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default StaticReducer;
