import React, { useState } from "react";
import { TextField, Box } from "@mui/material";
import { useCommonStyles } from "../../styles/common";
import { Button } from '@windmill/react-ui';
import SearchIcon from '@mui/icons-material/Search';
import Close from "@mui/icons-material/Close";


interface Props {
    resultText?: boolean;
    validationKey?: string;
    placeholder?: string;
    handleSearch: any;
    total: number | undefined;
    resetSearch: any;
    searchValue: string;
    handleEnterSearch: any;
    filter : any;
}

const SearchInput: React.FC<Props> = (props) => {
    const classes = useCommonStyles();
    const [searchText, searchTextsetter] = useState('');
    const handleSearchText = (e) => {
        searchTextsetter(e)
    }

    const { placeholder, handleSearch, resultText, total, resetSearch, searchValue, handleEnterSearch ,filter} = props;
    const reset = () => {
        searchTextsetter("");
        resetSearch()
    }
    return (
        <div className="mt-4 flex justify-start w-full flex-col">

            <Box className=" w-full font-dm_sans relative flex flex-col md:flex-row">
                {
                    resultText !== false &&
                    <div  className="flex items-center md:self-center mt-6 w-max md:mr-12 mt-5">
                        <div className="text-brand-text-title text-sm" >
                            <span>{total} results found for <span className="italic">"{searchValue}"</span>{filter && filter.length>0 && <span> in <span className="italic">{filter[0]?.text}</span></span>}</span>
                        </div>
                        <button className="ml-2.5 w-4" onClick={() => reset()}><Close className="text-brand-text-title"/></button>
                    </div>
                }
                <TextField
                    fullWidth
                    className="p-0"
                    style={{
                        width: "300px"
                    }}
                    onKeyUp={(e) => handleEnterSearch(e, searchText)}
                    placeholder={placeholder}
                    type="search"
                    onChange={e => handleSearchText(e.target.value)}
                    value={searchText}
                    InputProps={{
                        className: classes.bg_white + " " + classes.p3px + " " + classes.pr0 + " " + classes.fontSizeSmall + " " + classes.inputHeight,
                        sx: {
                            '&:hover': {
                                border: "0px solid #3466E5",
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #3466E5'
                            },
                            '&:focus-within .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #3466E5',
                            },
                            '&': {
                                marginTop: '20px',
                                padding: "0"
                            },
                            '& input': {
                                padding: '6px 14px',
                                fontSize: '14px'
                            }
                        },
                        endAdornment: (
                            <Button
                                className={"bg-brand-primary-blue hover:bg-brand-hover-blue text-brand-extra-background focus:ring-0 rounded rounded-tl-none rounded-bl-none "}
                                onClick={() => handleSearch(searchText)}
                            >
                                <SearchIcon
                                    className="text-brand-primary-white"
                                
                                />
                            </Button>
                        )
                    }}
                />
            </Box>
        </div >
    );
};

export default SearchInput;