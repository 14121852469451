import React from 'react'
import ComingSoon from '../../components/ComingSoon';
import VendorBranches from '../../components/role/vendor/Branches';
import { PLATFORM_USERS } from '../../utils/constants';

interface Props{
  routeKey ?: string;
}

const Branches: React.FC<Props> = (props) => {

  const { routeKey } = props;

  return (
    <div>
      {
        routeKey === PLATFORM_USERS.VENDOR ?
        <VendorBranches {...props}/>
        :
        <ComingSoon {...props}/>
      }
    </div>
  )
}

export default Branches