import React, { useState, useEffect } from "react";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { capitalize, Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, PLATFORM_USERS } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import { LoadOptions, ParamsTypes } from "../../../../utils/ObjectTypes";
import { handleCheckValidation } from "../../../../ValidationHelpers";
import MaterialSingleSearchDropdown from "../../../Dropdown/SingleSearchDropdown";
import CommonInputGroup from '../../../Input/InputGroup';
import { useDispatch, useSelector } from "react-redux";
import { addRateCardes, editRateCardes, getRateCardData } from "../../../../ReduxActions/ratecard.action";
import toast from "react-hot-toast";
import { getAllZoneList } from "../../../../ReduxActions/zone.action";
import Loader from '../../../Loader';
import CommonMaterialInput from "../../../Input/MaterialInput";
import CommonModal from "../../../Modal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { structurePincodeCityData } from "../../../../utils/common";
import { useCommonStyles } from "../../../../styles/common";

interface Props {
  match?: any;
  routeKey ?: any;
  history ?: any;
}

interface IRateCardDetailObject{
    from_zone_id ?: string,
    to_zone_id ?: string,
    rate ?: string ,
    rate_unit ?: string,
}

interface IRateUnit {
  rate : string,
  rate_unit: string,
  offer: string,
  offer_price: string
}

const LOAD_TYPE = [
  {
    key: 'ftl',
    value : 'ftl',
    text : 'Full Truck Load'
  },
  {
    key : 'ptl',
    value : 'ptl',
    text : 'Part Truck Load'
  }
]

const AddEditRateCards:React.FC<Props> = (props) => {

  const { routeKey, history } = props;

  const { id } = useParams<{id : string}>();
  const param = routeKey;

  const classes = useCommonStyles()

  // dispatch 
  const dispatch = useDispatch();
  //selectors
  const addRatecardSelector = useSelector((state: any)=>state.RatecardReducer.addRatecard);
  const ratecardSelector = useSelector((state: any)=>state.RatecardReducer.ratecard);
  const editRatecardSelector = useSelector((state: any)=>state.RatecardReducer.editRatecard);
  const allZoneSelector = useSelector((state: any)=> state.ZoneReducer.allZones);

  const [loading, setLoading] = useState({ rateCard : false, addRatecard:false, editRatecard:false, zones:false});
  const [rateCardDetails, setrateCardDetails] = useState<IRateCardDetailObject>({
    from_zone_id : '',
    to_zone_id : '',
    rate : null,
    rate_unit : 'tonne'
  });
  const [rate_unit, setRate_unit] = useState<IRateUnit[]>([
    {
      rate: '',
      rate_unit: 'tonne',
      offer: '',
      offer_price: ''
    },
    {
      rate: '',
      rate_unit: 'kg',
      offer: '',
      offer_price: ''
    },
    {
      rate: '',
      rate_unit: 'cft',
      offer: '',
      offer_price: ''
    },
  ])
  const [zoneData, setZoneData] = useState([]);
  const [listAllZoneData, setlistAllZoneData] = useState([])
  const [validationObj, setValidationObj] = useState<IRateCardDetailObject>({});
  const [openZoneListModal, setOpenZoneListModal] = useState(false);
  const [rateUnitValidationObj, setRateUnitValidationObj] = useState<any[]>([])

  useEffect(() => {
    dispatch( getAllZoneList({ user: param }));
    if(id){
      dispatch( getRateCardData({ id : id, user: param}));
    }else {      
      setrateCardDetails({
          from_zone_id : '',
          to_zone_id : '',
          rate : null,
          rate_unit : 'tonne'
        })
      }
  }, [])

  // zone text in dropdown
  const getZoneDropdownText = (zone) => {
    return capitalize(zone.name) + " (" + zone?.pincodes?.length + " pincodes)";
  }

  useEffect(() => {
    let toastId : any;
    if(allZoneSelector.status === API_CONSTANTS.loading){
        // toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, zones:true});
    }
    if(allZoneSelector.status === API_CONSTANTS.success){
        setLoading({...loading, zones:false});
        setlistAllZoneData(allZoneSelector.data?.data)
        setZoneData([...allZoneSelector.data?.data?.map((i)=>({text : getZoneDropdownText(i), value:i.id, key:i.id}))]);
        toast.dismiss(toastId);
    }
    if(allZoneSelector.status === API_CONSTANTS.error && loading.zones){
        setLoading({...loading, zones:false});
        toast.dismiss(toastId);
        // toast.error(allZoneSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allZoneSelector])

  useEffect(() => {
    let toastId : any;
    if(ratecardSelector.status === API_CONSTANTS.loading){
        // toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, rateCard:true});
    }
    if(ratecardSelector.status === API_CONSTANTS.success && loading.rateCard){
        setLoading({...loading, rateCard:false});
        setrateCardDetails({
            from_zone_id : ratecardSelector.data?.from_zone_id,
            to_zone_id : ratecardSelector.data?.to_zone_id,
        })
        setRate_unit(ratecardSelector.data?.rates);
        toast.dismiss(toastId);
    }
    if(ratecardSelector.status === API_CONSTANTS.error && loading.rateCard){
        setLoading({...loading, rateCard:false});
        toast.dismiss(toastId);
        toast.error(ratecardSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [ratecardSelector])

  useEffect(() => {
    let toastId : any;
    if(addRatecardSelector.status === API_CONSTANTS.loading){
        toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, addRatecard:true});
    }
    if(addRatecardSelector.status === API_CONSTANTS.success && loading.addRatecard){
        setLoading({...loading, addRatecard:false});
        toast.dismiss(toastId);
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
        history.push(APP_USER_ROUTES[param].RATECARD);
    }
    if(addRatecardSelector.status === API_CONSTANTS.error && loading.addRatecard){
        setLoading({...loading, addRatecard:false});
        toast.dismiss(toastId);
        toast.error(addRatecardSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [addRatecardSelector])

  useEffect(() => {
    let toastId : any;
    if(editRatecardSelector.status === API_CONSTANTS.loading){
        toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, editRatecard:true});
    }
    if(editRatecardSelector.status === API_CONSTANTS.success && loading.editRatecard){
        setLoading({...loading, editRatecard:false});
        toast.dismiss(toastId);
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
    }
    if(editRatecardSelector.status === API_CONSTANTS.error && loading.editRatecard){
        setLoading({...loading, editRatecard:false});
        toast.dismiss(toastId);
        toast.error(editRatecardSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [editRatecardSelector])
  
  const handleInputChange = (key, value) => {
    setrateCardDetails({ ...rateCardDetails, [key]: value});
  }

  const handleChangeRateUnit = (index, key, value) => {
    rate_unit[index][key]=value;
    setRate_unit([...rate_unit])
  }

  // handle check rate _ unit validation
  const handleValidateRateUnit = () => { 
    setRateUnitValidationObj([]);
    let rateVald = [];
    rate_unit.forEach((item, ind)=>{
      const vald:any = handleCheckValidation(item);
      vald.offer = Number(item.offer)>100 ? "Not more than 100" : vald?.offer;
      rateVald.push(vald);
    })
    setRateUnitValidationObj([...rateVald])
    return rateVald;
   }

  const onSubmit = (e) => {
      e.preventDefault();
      const validation:IRateCardDetailObject = handleCheckValidation(rateCardDetails);
      setValidationObj(validation);
      const rateValidation: any[] = handleValidateRateUnit();
      if(validation && !validation.from_zone_id && !validation.to_zone_id && rateValidation.every((i:any)=>(!i?.offer&&!i?.rate&&!i?.offer_price)) ){
        // console.log(rateCardDetails);
        const rateUnitDetails = [...rate_unit.map((i:any)=>{
          i.rate = Number(i.rate);
          i.offer = Number(i.offer);
          i.offer_price = Number(i.offer_price);
          return i;
        })]
        if(id){
            dispatch(editRateCardes({ user: param, id: id, body :{ ...rateCardDetails, rates: rateUnitDetails}}))
        }else{
            dispatch(addRateCardes({ user: param, body : { ...rateCardDetails, rates: rateUnitDetails }}))
        }     
      }
  }

  const handleClickOpenZoneList = () => {
    setOpenZoneListModal(true);
  }

  return (
    <>
      {
        loading.zones||loading.rateCard ?
        <Loader loadingText={LOADING_TEXT.fetching}/>
        :
        <>
          <CommonModal
            open={openZoneListModal}
            setOpen={setOpenZoneListModal}
            headingText="Zone Details"
            cancelButtonText="Cancel"
            content={
              <>
                <p>Click on the zone name to see which areas and pincodes it cover</p>
                <div className="mt-4">
                  {
                    listAllZoneData.map((i)=>                   
                    <Accordion 
                      className={classes.boxShadowNone+" " + " border border-brand-extra-grey rounded-lg mb-4"} 
                      sx={{
                        borderRadius:'5px',
                        '&::before':{
                          display:'none'
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{capitalize(i?.name)} - {i?.pincodes?.length} pincodes</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {
                            structurePincodeCityData(i?.pincodes)?.map((item)=>
                            <p className="text-xs font-medium text-brand-text-title p-2 rounded-full bg-brand-extra-bg_blue w-fit-content mb-2">{item?.text}</p>
                            )
                          }
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    )
                  }
                </div>
              </>
            }
          />
          <div className="">
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item lg={3} md={4} xs={12}>
                <PageTitle>{id ? 'Edit' : 'Add'} Rate Card</PageTitle>
              </Grid>
            </Grid>
            <form onSubmit={onSubmit}>
              <Grid container className="px-8 py-8 bg-brand-primary-white">
                  <Grid item lg={12} md={12} xs={12}>
                    <h5 className="text-xl font-bold text-brand-text-title">Zones</h5>
                    <p className="text-sm font-light text-brand-primary-blue cursor-pointer mt-3 w-fit-content" onClick={handleClickOpenZoneList}>View all Zones</p>
                  </Grid>
                  <Grid item lg={6} xs={12} className="md:pr-6">
                      <MaterialSingleSearchDropdown
                      label="From Zone"
                      placeholder={`Select your starting zone`}
                      onChange={(value) =>
                        handleInputChange('from_zone_id', value)
                      }
                      options={zoneData}
                      value={rateCardDetails.from_zone_id}
                      validationKey={validationObj.from_zone_id}
                      />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                      <MaterialSingleSearchDropdown
                      label="To Zone"
                      placeholder={`Select your destination zone`}
                      onChange={(value) =>
                        handleInputChange('to_zone_id',value)
                      }
                      options={zoneData}
                      value={rateCardDetails.to_zone_id}
                      validationKey={validationObj.to_zone_id}
                      />
                  </Grid>
                  <Grid item lg={12} marginTop="1rem">
                      <p className="text-xl text-brand-text-title">Rates & Offer</p>
                  </Grid>
                  {
                    rate_unit.map((r, index)=>
                    <Grid container>
                      <Grid item lg={6} md={12} xs={12} className="md:pr-6">
                          <CommonInputGroup
                          label={`Rates in ${capitalize(r.rate_unit)}`}
                          placeholder={`Enter your price`}
                          type='number'
                          onChange={(e) =>
                            handleChangeRateUnit(index, 'rate', e.target.value)
                          }
                          validationKey={rateUnitValidationObj[index]?.rate}
                          value={r.rate}
                          selectValue={(LoadOptions.find(item=>item.value===r.rate_unit)).text}
                          onChangeSelect={(e)=>handleChangeRateUnit(index, 'rate_unit',e.target.value)}
                          options={LoadOptions}
                          rupee={true}
                          group={true}
                          />
                      </Grid>
                      <Grid item lg={3} md={12} xs={12} className="md:pr-6">
                          <CommonInputGroup
                          label="Offer"
                          placeholder={`Enter your offer`}
                          type='number'
                          onChange={(e) =>
                            handleChangeRateUnit(index, 'offer', e.target.value)
                          }
                          value={r.offer}
                          selectValue={'%'}
                          options={[{text:'%', value:'%'}]}
                          group={true}
                          validationKey={rateUnitValidationObj[index]?.offer}
                          />
                      </Grid>
                      <Grid item lg={3} md={12} xs={12} className="md:pr-6">
                          <CommonMaterialInput
                          label="Offer Price"
                          placeholder={`Enter your price`}
                          type='number'
                          onChange={(e) =>
                            handleChangeRateUnit(index, 'offer_price', e.target.value)
                          }
                          value={r.offer_price}
                          rupee={true}
                          validationKey={rateUnitValidationObj[index]?.offer_price}
                          />
                      </Grid>
                    </Grid>
                    )
                  }
                  <Grid container lg={12} xs={12}>
                    <Grid item lg={1} md={4} xs={12} marginTop="1rem">
                      <Button text="Save" type="submit"/>
                    </Grid>
                  </Grid>
              </Grid>
            </form>
          </div>
        </>
      }
    </>
  );
};

export default AddEditRateCards;
