import React, { useState, useEffect } from "react";
import { Card, CardContent, Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import RateReviewCard from "../../../Cards/RateCard";
import PageTitle from "../../../Typography/PageTitle";
import CommonInput from "../../../Input";
import CommonButton from "../../../Button";
import CommonMaterialInput from "../../../Input/MaterialInput";
import { useCommonStyles } from "../../../../styles/common";
import { handleCheckValidation } from "../../../../ValidationHelpers";
import { fetchAllVendorList } from "../../../../ReduxActions";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT } from "../../../../utils/constants";
import toast from "react-hot-toast";
import MaterialSingleSearchDropdown from "../../../Dropdown/SingleSearchDropdown";
import SearchLocationPincode from "../../../SearchLocationDropdown";
import { structure30MinuteIntervalTimeArray } from "../../../../utils/common";
import RateReviewCardV2 from "../../../Cards/RateCardV2";

interface Props {
  setVendorDetails?: any;
  setShowForm?: any;
  routeKey?: any;
  vendorDetails ?: any;
  searchData ?: {
    pickup: string,
    drop: string,
    date: string,
    time: string,
  };
  setSearchData?:any;
  vendors ?: any[];
  setVendors ?: any;
}

interface ISearchObject {
  pickup?: string;
  drop?: string;
  date?: string;
  time?: string;
}

const ShowVendors: React.FC<Props> = (props) => {
  // material classes
  const classes = useCommonStyles();
  // props destructuring
  const { setVendorDetails, setShowForm, routeKey, vendorDetails, searchData, setSearchData, vendors, setVendors } = props;

  // dispatch action
  const dispatch = useDispatch();

  // selectors
  const allVendorSelector = useSelector(
    (state: any) => state.BookingReducer.allVendors
  );
  const pincodeDataSelector = useSelector(
    (state: any) => state.StaticReducer.pincodeData
  );

  // state hooks
  const [validationObj, setvalidationObj] = useState<ISearchObject>({});
  const [loading, setLoading] = useState(false);
  const [cityPincodeData, setCityPincodeData] = useState([]);
  const [fromAndToPincode, setFromAndToPincode] = useState({
    from : null,
    to : null
  })

  useEffect(() => {
    if (pincodeDataSelector.status === API_CONSTANTS.success) {
      setCityPincodeData((pincodeDataSelector.data?.data));
    }
  }, [pincodeDataSelector]);

  useEffect(() => {
    let toastId:any;
    if (allVendorSelector.status === API_CONSTANTS.loading) {
      setLoading(true);
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if (allVendorSelector.status === API_CONSTANTS.success && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      setVendors(allVendorSelector.data?.data);
    }
    if (allVendorSelector.status === API_CONSTANTS.error && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(allVendorSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allVendorSelector]);

  const handleChangeInput = (key, value) => {
    setSearchData({ ...searchData, [key]: value });
    if(key==='pickup'){
      const fromPincode = cityPincodeData.find(i=>i.id===value);
      setFromAndToPincode({...fromAndToPincode, from : fromPincode});
    }else if(key==='drop'){
      const dropPincode = cityPincodeData.find(i=>i.id===value);
      setFromAndToPincode({...fromAndToPincode, to : dropPincode});
    }
  };

  const handleClickCard = (itemDetails, index, activeRate) => {
    /**
     * Temporary Selected Date and time of input field
     */
    setVendorDetails({...itemDetails, date : searchData.date, time: searchData.time, rate_card_id: Number(itemDetails.id), rate_id : Number(activeRate?.id), rate_unit: activeRate?.rate_unit});
    // setShowForm(true);
  };

  const handleClickCardV2 = (itemDetails, index, activeVendor) => {
    /**
     * Temporary Selected Date and time of input field
     */
    setVendorDetails({...itemDetails, date : searchData.date, time: searchData.time, vendor_id: activeVendor.id, pickup_location:fromAndToPincode?.from?.id , drop_location: fromAndToPincode?.to?.id});
    // setShowForm(true);
  };

  const handleSearchVendor = (e) => {
    e.preventDefault();
    const validation: ISearchObject = handleCheckValidation(searchData);
    setvalidationObj(validation);
    if (
      validation &&
      !validation.pickup
    ) {
      dispatch(fetchAllVendorList({ user: routeKey, pickup: fromAndToPincode.from?.pincode, drop: fromAndToPincode?.to?.pincode, is_rate_selected: false }));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <PageTitle className="mb-4">Book Now (PTL only)</PageTitle>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12}>
          <form onSubmit={handleSearchVendor}>
            <Card className={classes.rateCardsBoxShadow}>
              <CardContent style={{ padding: "1rem" }}>
                <Grid container>
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    className="px-4 border-brand-extra-border border-r"
                  >
                    <SearchLocationPincode
                      label="Pickup Location"
                      // className="border-0 pl-0 placeholder:text-bold text-extra-text-border text-lg hover:border-0 focus:border-0 focus:ring-0 focus-visible:border-0"
                      context="pincode"
                      value={searchData.pickup}
                      placeholder="Select Pickup Location"
                      onChange={(value) =>
                        handleChangeInput("pickup", value)
                      }
                      location="from"
                      validationKey={validationObj.pickup}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    className="px-4 border-brand-extra-border border-r"
                  >
                    <SearchLocationPincode
                      label="Drop Location"
                      // className="border-0 pl-0 placeholder:text-bold text-extra-text-border text-lg hover:border-0 focus:border-0 focus:ring-0 focus-visible:border-0"
                      context="pincode"
                      value={searchData.drop}
                      placeholder="Select Drop Location"
                      onChange={(value) =>
                        handleChangeInput("drop", value)
                      }
                      location="to"
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} className="px-4 border-brand-extra-border border-r">
                    <CommonInput
                      label="Date"
                      type="date"
                      className="border-0 pl-0 hover:border-0 focus:border-0 focus:ring-0 focus-visible:border-0 shadow-none placeholder:text-brand-extra-placeholder"
                      placeholder="Select Date"
                      onChange={(e) =>
                        handleChangeInput("date", e.target.value)
                      }
                      value={searchData.date}
                      outline={true}
                      // validationKey={validationObj.date}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12} className="px-4">
                    <MaterialSingleSearchDropdown
                      label="Time"
                      // type="datetime-local"
                      // className="border-0 pl-0 placeholder:text-bold text-extra-text-border text-lg hover:border-0 focus:border-0 focus:ring-0 focus-visible:border-0"
                      placeholder="Select Time"
                      onChange={(value) =>
                        handleChangeInput("time", value)
                      }
                      value={searchData.time}
                      // validationKey={validationObj.time}
                      options={structure30MinuteIntervalTimeArray()}
                      outline={true}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="px-4"
                  >
                    <CommonButton
                      text="Search"
                      className="mx-auto mt-0"
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <PageTitle className="my-8 mb-4">Vendors</PageTitle>
        </Grid>
        {vendors.length ? vendors.map((item, index) => (
          <Grid item lg={6} xs={12} md={6} className="px-8 py-4 pl-0" key={index}>
            <RateReviewCardV2
              key={index}
              name={item?.name}
              source={fromAndToPincode?.from?.state?.name || 'NA'}
              destination={fromAndToPincode?.to?.state?.name || 'NA'}
              rates={[]}
              mindays={3}
              maxdays={4}
              booklink="/book"
              ratings={4}
              vendor_id={item.id}
              onClick={(activeVendor) => {
                handleClickCardV2(item, index, activeVendor);
              }}
              branches={item.total_branches}
            />
          </Grid>
        ))
        :
        <Grid item lg={12} xs={12} className="p-40">
          <p className="mx-auto font-normal text-brand-text-title text-lg text-center">In case no branches are found, you can use the request branch link for customised solution or adding a new branch</p>
          <p className="mx-auto font-normal text-brand-primary-blue text-lg text-center"><a href="https://forms.gle/jJZ3skubMaEWJbx59" target="_blank">Request a new branch</a>.</p>
        </Grid>
      }
      </Grid>
    </>
  );
};

export default ShowVendors;
