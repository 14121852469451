import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { BookingService, UserService } from "../sevices";
import {
  CREATE_BOOKING_FTL,
  CREATE_BOOKING_PTL,
  ALL_VENDOR_LIST,
  GET_ALL_BOOKING_REQUESTS,
  GET_ALL_CLIENT_BOOKINGS,
  GET_ALL_VENDOR_BOOKINGS,
  GET_BOOKING,
  GET_VENDOR_BOOKING_DETAILS,
  SET_ALL_BOOKING_REQUESTS,
  SET_BOOKING,
  SET_CREATE_BOOKING_FTL,
  SET_CREATE_BOOKING_PTL,
  SET_ALL_VENDOR_LIST,
  SET_GET_ALL_CLIENT_BOOKINGS,
  SET_GET_ALL_VENDOR_BOOKINGS,
  SET_VENDOR_BOOKING_DETAILS,
  CONFIRM_CLIENT_BOOKING_REQUEST,
  SET_CONFIRM_CLIENT_BOOKING_REQUEST,
  CLIENT_RECOSIDER_BOOKING,
  SET_CLIENT_RECONSIDER_BOOKING,
  VENDOR_ACCEPT_BOOKING_REQUEST,
  SET_VENDOR_ACCEPT_BOOKING_REQUEST,
  VENDOR_REJECT_BOOKING_REQUEST,
  SET_VENDOR_REJECT_BOOKING_REQUEST,
  VENDOR_NEGOTIATE_BOOKING_REQUEST,
  SET_VENDOR_NEGOTIATE_BOOKING_REQUEST,
  UPDATE_TRACKING,
  SET_UPDATE_TRACKING,
  GET_VENDOR_BOOKINGS,
  SET_VENDOR_BOOKINGS,
  GET_VENDOR_BOOKING_REQUESTS,
  SET_VENDOR_BOOKING_REQUESTS,
  GET_BOOKING_STATS,
  SET_BOOKING_STATS,
  GET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS,
  SET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS
} from "../ReduxConstants";
import { API_CONSTANTS ,PLATFORM_USERS} from "../utils/constants";
import { modifySchema } from "./Schema.tsx";
import {
  structuredAllBookingSchemaForClient,
  structuredAllBookingSchemaForVendor,
  structuredBookingDataForVendor,
  structuredVendorListbySearchData,
  structuredAllBookingRequestsSchemaForVendor,
  structuredVendorListbySearchDataV2,
} from "./Schema.tsx/BookingSchema";

/**
 * client side booking actions
 * @param payload { data | data :{ body , user} }
 * @returns { status : loading|success|error , data }
 */

 const getSpecificBookingRequestSearch = (payload: object) => ({
  type: GET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS,
  payload: payload,
});

const setSpecificBookingRequestSearch = (payload: object) => (
  {
  type: SET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS,
  payload: payload,
});
const getAllClientBookings = (payload: object) => ({
  type: GET_ALL_CLIENT_BOOKINGS,
  payload: payload,
});

const setGetAllClientBookings = (payload: object) => ({
  type: SET_GET_ALL_CLIENT_BOOKINGS,
  payload: payload,
});

const getBooking = (payload: object) => ({
  type: GET_BOOKING,
  payload: payload,
});

const setgetBooking = (payload: object) => ({
  type: SET_BOOKING,
  payload: payload,
});

const createPtlBooking = (payload: object) => ({
  type: CREATE_BOOKING_PTL,
  payload: payload,
});

const setcreatePtlBooking = (payload: object) => ({
  type: SET_CREATE_BOOKING_PTL,
  payload: payload,
});

const createFtlBooking = (payload: object) => ({
  type: CREATE_BOOKING_FTL,
  payload: payload,
});

const setcreateFtlBooking = (payload: object) => ({
  type: SET_CREATE_BOOKING_FTL,
  payload: payload,
});

const allVendorLists = (payload: object) => ({
  type: ALL_VENDOR_LIST,
  payload: payload,
});

const setallVendorLists = (payload: object) => ({
  type: SET_ALL_VENDOR_LIST,
  payload: payload,
});

const clientConfirmBooking = (payload: object) => ({
  type: CONFIRM_CLIENT_BOOKING_REQUEST,
  payload: payload,
});

const setClientConfirmBooking = (payload: object) => ({
  type: SET_CONFIRM_CLIENT_BOOKING_REQUEST,
  payload: payload,
});

const clientReconsiderBookingRequest = (payload: object) => ({
  type: CLIENT_RECOSIDER_BOOKING,
  payload: payload,
});

const setClientReconsiderBookingRequest = (payload: object) => ({
  type: SET_CLIENT_RECONSIDER_BOOKING,
  payload: payload,
});

/**
 * vendor side booking actions
 * @param payload { data | data :{ body , user} }
 * @returns { status : loading|success|error , data }
 */
const getAllVendorBookings = (payload: object) => ({
  type: GET_ALL_VENDOR_BOOKINGS,
  payload: payload,
});

const setGetAllVendorBookings = (payload: object) => ({
  type: SET_GET_ALL_VENDOR_BOOKINGS,
  payload: payload,
});

const getAllBookingRequests = (payload: object) => ({
  type: GET_ALL_BOOKING_REQUESTS,
  payload: payload,
});

const setgetAllBookingRequests = (payload: object) => ({
  type: SET_ALL_BOOKING_REQUESTS,
  payload: payload,
});

const vendorBookingDetails = (payload: object) => ({
  type: GET_VENDOR_BOOKING_DETAILS,
  payload: payload,
});

const setvendorBookingDetails = (payload: object) => ({
  type: SET_VENDOR_BOOKING_DETAILS,
  payload: payload,
});

const vendorAcceptBookingRequest = (payload: object) => ({
  type: VENDOR_ACCEPT_BOOKING_REQUEST,
  payload: payload,
});

const setVendorAcceptBookingRequest = (payload: object) => ({
  type: SET_VENDOR_ACCEPT_BOOKING_REQUEST,
  payload: payload,
});

const vendorRejectBookingRequest = (payload: object) => ({
  type: VENDOR_REJECT_BOOKING_REQUEST,
  payload: payload,
});

const setVendorRejectBookingRequest = (payload: object) => ({
  type: SET_VENDOR_REJECT_BOOKING_REQUEST,
  payload: payload,
});

const vendorNegotiateBookingRequest = (payload: object) => ({
  type: VENDOR_NEGOTIATE_BOOKING_REQUEST,
  payload: payload,
});

const setVendorNegotiateBookingRequest = (payload: object) => ({
  type: SET_VENDOR_NEGOTIATE_BOOKING_REQUEST,
  payload: payload,
});

const updateTracking = (payload: object) => ({
  type: UPDATE_TRACKING,
  payload: payload,
});

const setUpdateTracking = (payload: object) => ({
  type: SET_UPDATE_TRACKING,
  payload: payload,
});

/**
 * Admin Side Booking Actions
 * @param data 
 * @returns payload, status
 */

const getVendorBookings = (payload: object) => ({
  type: GET_VENDOR_BOOKINGS,
  payload: payload,
});

const setVendorBookings = (payload: object) => ({
  type: SET_VENDOR_BOOKINGS,
  payload: payload,
});

const getVendorBookingRequests = (payload: object) => ({
  type: GET_VENDOR_BOOKING_REQUESTS,
  payload: payload,
});

const setVendorBookingRequests = (payload: object) => ({
  type: SET_VENDOR_BOOKING_REQUESTS,
  payload: payload,
});

const getBookingStats = (payload: object) => ({
  type: GET_BOOKING_STATS,
  payload: payload,
});

const setBookingStats = (payload: object) => ({
  type: SET_BOOKING_STATS,
  payload: payload,
});

/**
 * Client Side Actions
 * @param data { user, body }
 * @returns Response
 */
export const fetchAllClientBookings = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllClientBookings(data));
    await BookingService.getAllClientBookings(data)
      .then((result) => {
        dispatch(
          setGetAllClientBookings(
            modifySchema(
              structuredAllBookingSchemaForClient(result),
              API_CONSTANTS.success
            )
          )
        );
      })
      .catch((error) => {
        dispatch(
          setGetAllClientBookings(modifySchema(error, API_CONSTANTS.error))
        );
      });
  };
};

export const fetchAllVendorList = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(allVendorLists(data));
    await BookingService.allVendorList(data)
      .then((result) => {
        dispatch(
          setallVendorLists(
            modifySchema(
              structuredVendorListbySearchDataV2(result),
              API_CONSTANTS.success
            )
          )
        );
      })
      .catch((error) => {
        dispatch(setallVendorLists(modifySchema(error, API_CONSTANTS.error)));
      });
  };
};

export const getBookingbyId = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getBooking(data));
    await BookingService.bookingbyId(data)
      .then((result) => {
        dispatch(setgetBooking(modifySchema(result, API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setgetBooking(modifySchema(error, API_CONSTANTS.error)));
      });
  };
};

export const fetchSpecificBookingRequest = (data) => {
 
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getSpecificBookingRequestSearch(data));
    await BookingService.getSpecificBookingRequestSearch(data)
    
      .then((result) => {
       dispatch(setSpecificBookingRequestSearch(modifySchema(result?.data, API_CONSTANTS.success)))
      })
      .catch((error) => {
        dispatch(setSpecificBookingRequestSearch(modifySchema(error, API_CONSTANTS.error)));
      });
  };
};

export const createBookingForClientPtl = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(createPtlBooking(data));
    await BookingService.bookPtl(data)
      .then((result) => {
        dispatch(
          setcreatePtlBooking(modifySchema(result, API_CONSTANTS.success))
        );
      })
      .catch((error) => {
        dispatch(setcreatePtlBooking(modifySchema(error, API_CONSTANTS.error)));
      });
  };
};

export const createBookingForClientFtl = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(createFtlBooking(data));
    await BookingService.bookFtl(data)
      .then((result) => {
        dispatch(
          setcreateFtlBooking(modifySchema(result, API_CONSTANTS.success))
        );
      })
      .catch((error) => {
        dispatch(setcreateFtlBooking(modifySchema(error, API_CONSTANTS.error)));
      });
  };
};

export const confirmBookingFromClient = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(clientConfirmBooking(data));
    await BookingService.confirmBooking(data)
      .then((result) => {
        dispatch(
          setClientConfirmBooking(modifySchema(result, API_CONSTANTS.success))
        );
      })
      .catch((error) => {
        dispatch(
          setClientConfirmBooking(modifySchema(error, API_CONSTANTS.error))
        );
      });
  };
};

export const reconsiderBokkingFromClient = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(clientReconsiderBookingRequest(data));
    await BookingService.reconsiderBooking(data)
      .then((result) => {
        dispatch(
          setClientReconsiderBookingRequest(
            modifySchema(result, API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setClientReconsiderBookingRequest(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

/**
 * Vendor Side Actions
 * @param data { user, body }
 * @returns Response
 */
export const fetchAllVendorBookings = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllVendorBookings(data));
    await BookingService.getAllVendorBookings(data)
      .then((result) => {
        dispatch(
          setGetAllVendorBookings(
            modifySchema(
              structuredAllBookingSchemaForVendor(result),
              API_CONSTANTS.success
            )
          )
        );
      })
      .catch((error) => {
        dispatch(
          setGetAllVendorBookings(modifySchema(error, API_CONSTANTS.error))
        );
      });
  };
};

export const fetchAllBookingRequests = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllBookingRequests(data));
    await BookingService.bookingsRequests(data)
      .then((result) => {
        dispatch(
          setgetAllBookingRequests(
            modifySchema(
              structuredAllBookingRequestsSchemaForVendor(result),
              API_CONSTANTS.success
            )
          )
        );
      })
      .catch((error) => {
        dispatch(
          setgetAllBookingRequests(modifySchema(error, API_CONSTANTS.error))
        );
      });
  };
};

export const getVendorBookingbyId = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(vendorBookingDetails(data));
    await BookingService.bookingDetailsVendor(data)
      .then((result) => {
        dispatch(
          setvendorBookingDetails(
            modifySchema(
              structuredBookingDataForVendor(result),
              API_CONSTANTS.success
            )
          )
        );
      })
      .catch((error) => {
        dispatch(
          setvendorBookingDetails(modifySchema(error, API_CONSTANTS.error))
        );
      });
  };
};

export const acceptBookingFromVendor = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(vendorAcceptBookingRequest(data));
    await BookingService.acceptBooking(data)
      .then((result) => {
        dispatch(
          setVendorAcceptBookingRequest(
            modifySchema(result, API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setVendorAcceptBookingRequest(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

export const rejectBookingFromVendor = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(vendorRejectBookingRequest(data));
    await BookingService.rejectBooking(data)
      .then((result) => {
        dispatch(
          setVendorRejectBookingRequest(
            modifySchema(result, API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setVendorRejectBookingRequest(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

export const negotiateBookingFromVendor = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(vendorNegotiateBookingRequest(data));
    await BookingService.negotiateBooking(data)
      .then((result) => {
        dispatch(
          setVendorNegotiateBookingRequest(
            modifySchema(result, API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setVendorNegotiateBookingRequest(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

export const updateTrckingDetails = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(updateTracking(data));
    await BookingService.updateTracking(data)
      .then((result) => {
        dispatch(
          setUpdateTracking(
            modifySchema(result, API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setUpdateTracking(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

/**
 * Admin 
 * @param data 
 * @returns Response (data, status) 
 */
export const fetchVendorRelatedBookings = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getVendorBookings(data));
    await BookingService.getAllBookingsForVendor(data)
      .then((result) => {
        dispatch(
          setVendorBookings(
            modifySchema(structuredAllBookingSchemaForVendor(result), API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setVendorBookings(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

export const fetchVendorRelatedBookingRequests = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getVendorBookingRequests(data));
    await BookingService.getAllBookingRequestsForVendor(data)
      .then((result) => {
        dispatch(
          setVendorBookingRequests(
            modifySchema(structuredAllBookingRequestsSchemaForVendor(result), API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setVendorBookingRequests(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};

export const fetchBookingStats = (data: { user: string, query: string }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getBookingStats(data));
    await BookingService.bookingStats(data)
      .then((result) => {
        dispatch(
          setBookingStats(
            modifySchema((result), API_CONSTANTS.success)
          )
        );
      })
      .catch((error) => {
        dispatch(
          setBookingStats(
            modifySchema(error, API_CONSTANTS.error)
          )
        );
      });
  };
};
