import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useCommonStyles } from "../../styles/common";
import { debounceMethod } from "../../utils/common";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type Options = {
  value: string;
  text: string;
};

interface Props {
  value?: any[];
  onChange?: any;
  onChangeText?: any;
  validationKey?: any;
  label?: string;
  options?: Array<Options>;
  disabled?: boolean;
  searchType?: boolean;
  placeholder?: string;
}

interface IDropdownVal {
  text?: string;
  value?: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 *
 * @param props { value, onChange, validationKey, label, options, onChangeText, disabled, searchType, placeholder }
 * @returns JSX
 */
const MaterialMultiSearchDropdown: React.FC<Props> = (props) => {
  // common style for material Ui components
  const classes = useCommonStyles();
  // props destructuring
  const {
    value,
    onChange,
    validationKey,
    label,
    options,
    onChangeText,
    disabled,
    searchType,
    placeholder,
  } = props;
  // input chnage value in input field
  const handleChange = (e, newValues) => {
    onChange(newValues);
  };


  const updateDebouceText = React.useCallback(
    debounceMethod(onChangeText, 500),
    []
  );

  return (
    <>
      <Stack spacing={3} className=" w-full mt-4 font-dm_sans">
        <label  htmlFor="mui_search_multiple_select" className="text-brand-extra-icon font-medium text-sm">
          {label}
        </label>
        <Autocomplete
          multiple
          id="mui_search_multiple_select"
          options={options.length?options:[{text:"",value:""}]}
          disableCloseOnSelect
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) => option.text === value.text}
          onChange={handleChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.text}
            </li>
          )}
          style={{
            marginTop:'0.25rem'
          }}
          sx={{
            '& .MuiOutlinedInput-root' :{
              padding:'3px !important',
              fontSize:'14px',
            },
            '& .MuiOutlinedInput-notchedOutline' : {
              border :'1px solid #EBEEF3'
            },
            '&:hover .MuiOutlinedInput-notchedOutline':{
              border : '1px solid #3466E5 !important'
            },
            '&:focus-within .MuiOutlinedInput-notchedOutline':{
              border :"1px solid #3466E5 !important",
            },
          }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              placeholder={placeholder||'Type to search'}
              onChange={updateDebouceText}
              helperText={validationKey}
              error={validationKey?true:false}
              FormHelperTextProps={{
                className:classes.ml0 + " " + classes.p32px
              }}
            />
          )}
        />
      </Stack>
    </>
  );
};

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
];

export default MaterialMultiSearchDropdown;
