import { capitalize } from "@mui/material";
import moment from "moment";
import { TIME_FORMAT_STRING } from "../../utils/constants";

interface IFleetSchemaObject {
    truck_no : string,
    load_capacity : number,
    load_capacity_unit : string,
    id : string,
    remarks : string,
    owner_name : string,
    contact_no : string,
    ownership : string,
    created_at : string,
}

export const structuredFleetSchema = (response) =>{
    if(response?.data?.length) {
        response.data = response?.data?.map((res:IFleetSchemaObject)=>({
            truck_no : res.truck_no,
            load_capacity : res.load_capacity + ' ' + res.load_capacity_unit,
            owner_name : (res.owner_name),
            contact_no : (res.contact_no),
            ownership : res.ownership,
            // load_capacity_unit : res.load_capacity_unit,
            id : res.id,
            remarks : res.remarks,
            created_at: res?.created_at ? moment(res?.created_at).format(TIME_FORMAT_STRING): 'NA',
        }))
    }
    return response;
}