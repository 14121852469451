import React from 'react';
import { useParams } from 'react-router-dom';
import RateCard from '../../components/role/vendor/RateCard';
import { APP_USER_ROUTES, PLATFORM_USERS } from '../../utils/constants';
import { ParamsTypes } from '../../utils/ObjectTypes';

interface Props {
  match ?: any;
  routeKey ?: string;
}

const RateCardContainer = (props) => {

  const { match, routeKey } = props;
  const  param  = routeKey;

  return (
    <div className="py-6 px-6 overflow-x-auto">
      {
        (param === PLATFORM_USERS.VENDOR||param === PLATFORM_USERS.VENDOR_BRANCH) &&
        <RateCard {...props}/>
      }
    </div>
  )
}

export default RateCardContainer