/**
 *    APPLICATION ROUTES
 */

import { RouteObject } from "./ObjectTypes";

export const APP_NAME = "TransportGanj";

export const LOADING_TEXT = {
  processing: "Processing...",
  fetching: "Fetching...",
  loading: "Loading...",
};

export const DEFAULT_SUCCESS_MESSAGE = {
  varification: "Email verification link sent to email.",
  success: "Success",
};

export const DEFAULT_ERROR_MESSAGE = {
  error1: "Error while fetching",
  prompt:
    "Your email is not verified. Please check your inbox to verify the email.",
  failed: "Failed",
};

export const PLATFORM_USERS = {
  ADMIN: "admin",
  VENDOR: "vendor_hq",
  VENDOR_BRANCH: "vendor_branch",
  CLIENT: "client",
};

export const PLATFORM_USERS_NAME = {
  [PLATFORM_USERS.CLIENT]: "Client",
  [PLATFORM_USERS.VENDOR]: "Vendor",
  [PLATFORM_USERS.VENDOR_BRANCH]: "Vendor Branch",
  [PLATFORM_USERS.ADMIN]: "Admin",
};

export const LOAD_TYPES = {
  FULL_TRUCK: "ftl",
  PART_TRUCK: "ptl",
  FULL_PART_TRUCK :"ftl|ptl",
};

export const APP_USER_ROUTES = {
  [PLATFORM_USERS.ADMIN]: {
    LOGIN: "/admin/login",
    LOGOUT: "/admin/logout",
    REGISTER: "/admin/register",
    BOOKINGS: "/admin/bookings",
    BOOKING_DETAILS: "/admin/booking/details/:id",
    BOOKING_DETAILS_ALIAS: "/admin/booking/details",
    REQUEST_DETAILS: "/admin/request/details/:id",
    REQUEST_DETAILS_ALIAS: "/admin/request/details",
    FORGOT_PASSWORD: "/admin/forgot/password",
    RESET_PASSWORD: "/admin/reset/password",
    DASHBOARD: "/admin/dashboard",
    BRANCHES: "/admin/branches",
    FLEETS: "/admin/fleets",
    ADD_FLEETS: "/admin/fleets/add",
    EDIT_FLEETS: "/admin/fleets/edit/:id",
    EDIT_FLEETS_ALIAS: "/admin/fleets/edit",
    LOCATIONS: "/admin/locations",
    ZONES: "/admin/zones",
    ADD_ZONES: "/admin/zones/add",
    EDIT_ZONE: "/admin/zones/edit/:id",
    EDIT_ZONE_ALIAS: "/admin/zones/edit",
    PAYMENTS: "/admin/payments",
    ADD_LOCATIONS: "/admin/locations/add",
    SETTINGS: "/admin/settings",
    VENDORS: "/admin/vendors",
    SHOW_VENDORS: "/admin/vendor/:id",
    SHOW_VENDORS_ALIAS: "/admin/vendor",
    CLIENTS: "/admin/clients", 
    SHOW_CLIENTS: "/admin/clients/:id",
    SHOW_CLIENTS_ALIAS: "/admin/clients",
    CITIES: "/admin/locations/cities",
    STATES: "/admin/locations/states",
    PINCODES: "/admin/locations/pincodes",
  },
  [PLATFORM_USERS.CLIENT]: {
    LOGIN: "/client/login",
    LOGOUT: "/client/logout",
    REGISTER: "/client/register",
    FORGOT_PASSWORD: "/client/forgot/password",
    RESET_PASSWORD: "/client/reset/password",
    DASHBOARD: "/client/dashboard",
    BOOKINGS: "/client/bookings",
    BOOKING_CREATE: "/client/booking/create/:type",
    BOOKING_CREATE_ALIAS: (param) => `/client/booking/create/${param}`,
    BOOKING_DETAILS: "/client/booking/details/:id",
    BOOKING_DETAILS_ALIAS: "/client/booking/details",
    FLEETS: "/client/fleets",
    PAYMENTS: "/client/payments",
    OFFERS: "/client/offers",
    SETTINGS: "/client/settings",
  },
  [PLATFORM_USERS.VENDOR]: {
    LOGIN: "/vendor/login",
    LOGOUT: "/vendor/logout",
    REGISTER: "/vendor/register",
    FORGOT_PASSWORD: "/vendor/forgot/password",
    RESET_PASSWORD: "/vendor/reset/password",
    DASHBOARD: "/vendor/dashboard",
    BOOKINGS: "/vendor/bookings",
    BOOKINGS_REQUESTS: "/vendor/brequests",
    BOOKING_DETAILS: "/vendor/booking/details/:id",
    BOOKING_DETAILS_ALIAS: "/vendor/booking/details",
    REQUEST_DETAILS: "/vendor/request/details/:id",
    REQUEST_DETAILS_ALIAS: "/vendor/request/details",
    BRANCHES: "/vendor/branches",
    ADD_BRANCHES: "/vendor/branches/add",
    EDIT_BRANCHES: "/vendor/branches/edit/:id",
    EDIT_BRANCHES_ALIAS: "/vendor/branches/edit",
    SHOW_VENDOR_BRANCH: "/vendor/branch/:id",
    SHOW_VENDOR_BRANCH_ALIAS: "/vendor/branch",
    FLEETS: "/vendor/fleets",
    ADD_FLEETS: "/vendor/fleets/add",
    EDIT_FLEETS: "/vendor/fleets/edit/:id",
    EDIT_FLEETS_ALIAS: "/vendor/fleets/edit",
    RATECARD: "/vendor/rate-card",
    ADD_RATECARD: "/vendor/rate-card/add",
    EDIT_RATECARD: "/vendor/rate-card/edit/:id",
    EDIT_RATECARD_ALIAS: "/vendor/rate-card/edit",
    PAYMENTS: "/vendor/payments",
    ADD_PAYMENTS: "/vendor/payments/add",
    EDIT_PAYMENTS: "/vendor/payments/edit/:id",
    EDIT_PAYMENTS_ALIAS: "/vendor/payments/edit",
    OFFERS: "/vendor/offers",
    SETTINGS: "/vendor/settings",
  },
  [PLATFORM_USERS.VENDOR_BRANCH]: {
    LOGIN: "/vendor/branch/login",
    LOGOUT: "/vendor/branch/logout",
    REGISTER: "/vendor/branch/register",
    FORGOT_PASSWORD: "/vendor/branch/forgot/password",
    RESET_PASSWORD: "/vendor/branch/reset/password",
    DASHBOARD: "/vendor/branch/dashboard",
    BOOKINGS_REQUESTS: "/vendor/branch/brequests",
    BOOKINGS: "/vendor/branch/bookings",
    BOOKING_DETAILS: "/vendor/branch/booking/details/:id",
    BOOKING_DETAILS_ALIAS: "/vendor/branch/booking/details",
    REQUEST_DETAILS: "/vendor/branch/request/details/:id",
    REQUEST_DETAILS_ALIAS: "/vendor/branch/request/details",
    // BRANCHES: "/vendor/branch/branches",
    // ADD_BRANCHES: "/vendor/branch/branches/add",
    // EDIT_BRANCHES: "/vendor/branch/branches/edit/:id",
    // EDIT_BRANCHES_ALIAS: "/vendor/branch/branches/edit",
    RATECARD: "/vendor/branch/rate-card",
    ADD_RATECARD: "/vendor/branch/rate-card/add",
    EDIT_RATECARD: "/vendor/branch/rate-card/edit/:id",
    EDIT_RATECARD_ALIAS: "/vendor/branch/rate-card/edit",
    FLEETS: "/vendor/branch/fleets",
    ADD_FLEETS: "/vendor/branch/fleets/add",
    EDIT_FLEETS: "/vendor/branch/fleets/edit/:id",
    EDIT_FLEETS_ALIAS: "/vendor/branch/fleets/edit",
    PAYMENTS: "/vendor/branch/payments",
    ADD_PAYMENTS: "/vendor/branch/payments/add",
    EDIT_PAYMENTS: "/vendor/branch/payments/edit/:id",
    EDIT_PAYMENTS_ALIAS: "/vendor/branch/payments/edit",
    SETTINGS: "/vendor/branch/settings",
  },
  external: {
    LANDINGPAGE: "/",
    SELECTUSERTYPE: "/user",
    NOT_FOUND: "/404",
  },
};

export const FORM_ERROR_MESSAGES = {
  REQUIRED_MESSAGE: "Required!",
  EMPTY_MESSAGE: "Please select a value",
  INVALID_EMAIL_ADDRESS: "Please enter a valid email address",
  PASSWORD_CRITERIA: "Password should be of atleast 8 characters",
  INVALID_NAME: "Please enter a valid full name",
  INVALID_NUMBER: "please enter valid contact number",
  INVALID_INPUT: "please enter a valid input",
  INVALID_DOMAIN: "please enter a valid domain",
};

export const API_CONSTANTS = {
  success: 1,
  error: -1,
  loading: 0,
};

export const HTTP_METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const toastOptions = {
  success: {
    style: {
      background: "#F3FCF4",
      border: "1px solid #4FD564",
      borderRadius: "4px",
    },
  },
  error: {
    style: {
      background: "#FDF6F6",
      border: "1px solid #EC4140",
      borderRadius: "4px",
    },
  },
};

export const APP_PAGES_NAME = {
  DASHBOARD: "dashboard",
  CONFIRMED_BOOKINGS: "Confirmed Bookings",
  BOOKINGS: "bookings",
  BOOKINGS_REQUESTS: "Booking Requests",
  BRANCHES: "branches",
  RATECARD: "rate cards",
  FLEET: "trucks",
  OFFERS: "offers",
  LOCATIONS: "locations",
  ZONES: "zones",
  PAYMENTS: "payments",
  SETTINGS: "settings",
  CLIENTS: "clients",
  VENDORS: "vendors",
  PINCODES: "pincodes",
  STATES: "states",
  CITIES: "cities",
};

export const APP_NAVIGATIONS = [
  {
    key: APP_PAGES_NAME.DASHBOARD,
    path: (param) => APP_USER_ROUTES[param].DASHBOARD,
    navIcon: "GridIcon",
    navIconHover: "GridHoverIcon",
    subRoutes: [],
  },
  {
    key: APP_PAGES_NAME.BOOKINGS_REQUESTS,
    path: (param) => APP_USER_ROUTES[param].BOOKINGS_REQUESTS,
    navIcon: "BookingIcon",
    navIconHover: "BookingHoverIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].REQUEST_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].REQUEST_DETAILS,
    ]
  },
  {
    key: APP_PAGES_NAME.BOOKINGS,
    path: (param) => APP_USER_ROUTES[param].BOOKINGS,
    navIcon: "BookingIcon",
    navIconHover: "BookingHoverIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKING_CREATE, 
      APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].REQUEST_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].REQUEST_DETAILS,
    ],
  },
  {
    key: APP_PAGES_NAME.CONFIRMED_BOOKINGS,
    path: (param) => APP_USER_ROUTES[param].BOOKINGS,
    navIcon: "BookingIcon",
    navIconHover: "BookingHoverIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKING_CREATE, 
      APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].REQUEST_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BOOKING_DETAILS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].REQUEST_DETAILS,
    ],
  },
  {
    key: APP_PAGES_NAME.BRANCHES,
    path: (param) => APP_USER_ROUTES[param].BRANCHES,
    navIcon: "MapIcon",
    navIconHover: "MapHoverIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_BRANCHES,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_BRANCHES,
    ],
  },
  {
    key: APP_PAGES_NAME.FLEET,
    path: (param) => APP_USER_ROUTES[param].FLEETS,
    navIcon: "TruckIcon",
    navIconHover: "TruckHoverIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_FLEETS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_FLEETS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].ADD_FLEETS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].EDIT_FLEETS,
    ],
  },
  {
    key: APP_PAGES_NAME.RATECARD,
    path: (param) => APP_USER_ROUTES[param].RATECARD,
    navIcon: "RateCardIcon",
    navIconHover: "RateCardIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_RATECARD,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_RATECARD,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].ADD_RATECARD,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].EDIT_RATECARD,
    ],
  },
  {
    key: APP_PAGES_NAME.ZONES,
    path: (param) => APP_USER_ROUTES[param].ZONES,
    navIcon: "MappinIcon",
    navIconHover: "MappinIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].ADD_ZONES,
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].EDIT_ZONE,
    ],
  },
  {
    key: APP_PAGES_NAME.OFFERS,
    path: (param) => APP_USER_ROUTES[param].OFFERS,
    navIcon: "GiftIcon",
    navIconHover: "GiftHoverIcon",
    subRoutes: [],
  },
  {
    key: APP_PAGES_NAME.CLIENTS,
    path: (param) => APP_USER_ROUTES[param].CLIENTS,
    navIcon: "UsersIco",
    navIconHover: "UsersIco",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].SHOW_CLIENTS
    ],
  },
  {
    key: APP_PAGES_NAME.VENDORS,
    path: (param) => APP_USER_ROUTES[param].VENDORS,
    navIcon: "UsersIco",
    navIconHover: "UsersIco",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].SHOW_VENDORS
    ],
  },
  {
    key: APP_PAGES_NAME.PINCODES,
    path: (param) => APP_USER_ROUTES[param].PINCODES,
    navIcon: "MappinIcon",
    navIconHover: "MappinIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PINCODES,
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].STATES,
      APP_USER_ROUTES[PLATFORM_USERS.ADMIN].CITIES,
    ],
  },
  {
    key: APP_PAGES_NAME.PAYMENTS,
    path: (param) => APP_USER_ROUTES[param].PAYMENTS,
    navIcon: "CreditCardIcon",
    navIconHover: "CreditCardHoverIcon",
    subRoutes: [
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_PAYMENTS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_PAYMENTS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].ADD_PAYMENTS,
      APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].EDIT_PAYMENTS,
    ],
  },
  {
    key: APP_PAGES_NAME.SETTINGS,
    path: (param) => APP_USER_ROUTES[param].SETTINGS,
    navIcon: "SettingsIcon",
    navIconHover: "SettingsHoverIcon",
    subRoutes: [],
  },
];

export const getUserNavigationBasedOnFilerKeys = (keys: Array<string>) => {
  if (keys.length) {
    const filteredNavigation = APP_NAVIGATIONS.filter(
      (r: RouteObject, index) => {
        if (keys.includes(r.key)) return r;
      }
    );
    return filteredNavigation;
  }
  return [APP_NAVIGATIONS[0]];
};

export const APP_USER_RELATED_NAVIGATION = {
  [PLATFORM_USERS.ADMIN]: getUserNavigationBasedOnFilerKeys([
    APP_PAGES_NAME.DASHBOARD,
    APP_PAGES_NAME.BOOKINGS,
    APP_PAGES_NAME.ZONES,
    APP_PAGES_NAME.VENDORS,
    APP_PAGES_NAME.CLIENTS,
    APP_PAGES_NAME.LOCATIONS,
    APP_PAGES_NAME.PINCODES,
    APP_PAGES_NAME.STATES,
    APP_PAGES_NAME.CITIES,
  ]),
  [PLATFORM_USERS.VENDOR]: getUserNavigationBasedOnFilerKeys([
    APP_PAGES_NAME.BOOKINGS_REQUESTS,
    APP_PAGES_NAME.CONFIRMED_BOOKINGS,
    APP_PAGES_NAME.DASHBOARD,
    APP_PAGES_NAME.FLEET,
    //APP_PAGES_NAME.RATECARD,
    APP_PAGES_NAME.PAYMENTS,
    APP_PAGES_NAME.SETTINGS,
    APP_PAGES_NAME.BRANCHES,
  ]),
  [PLATFORM_USERS.VENDOR_BRANCH]: getUserNavigationBasedOnFilerKeys([
    APP_PAGES_NAME.BOOKINGS_REQUESTS,
    APP_PAGES_NAME.CONFIRMED_BOOKINGS,
    APP_PAGES_NAME.DASHBOARD,
    APP_PAGES_NAME.FLEET,
    //APP_PAGES_NAME.RATECARD,
    APP_PAGES_NAME.PAYMENTS,
    APP_PAGES_NAME.SETTINGS,
    // APP_PAGES_NAME.BRANCHES,
  ]),
  [PLATFORM_USERS.CLIENT]: getUserNavigationBasedOnFilerKeys([
    APP_PAGES_NAME.BOOKINGS,
    APP_PAGES_NAME.DASHBOARD,
    APP_PAGES_NAME.PAYMENTS,
    APP_PAGES_NAME.SETTINGS,
  ]),
};

export const STATUS_JSX = {
    unconfirmed : <p className="truncate w-fit-content text-brand-extra-darkOrange border border-brand-extra-darkOrange rounded-lg text-center text-xs px-4  py-1 bg-brand-light-orange tracking-wider font-bold">UNCONFIRMED</p>,
    confirmed : <p className="truncate w-fit-content text-brand-primary-green border border-brand-primary-green rounded-lg text-center text-xs px-4 py-1 bg-brand-light-green tracking-wider font-bold">CONFIRMED</p>,
    completed : <p className="truncate w-fit-content text-brand-primary-blue border border-brand-primary-blue rounded-lg text-center text-xs px-4 py-1 bg-brand-light-blue tracking-wider font-bold">COMPLETED</p>,
    processing : <p className="truncate w-fit-content text-brand-primary-purple border border-brand-primary-purple rounded-lg text-center text-xs px-4 py-1 bg-brand-disabled-purple tracking-wider font-bold">PROCESSING</p>,
    awaiting_fulfillment : <p className="truncate w-fit-content text-brand-primary-purple border border-brand-primary-purple rounded-lg text-center text-xs px-4 py-1 bg-brand-disabled-purple tracking-wider font-bold">AWAITING DELIVERY</p>,
    pending : <p className="truncate w-fit-content text-brand-extra-yellow border border-brand-extra-yellow rounded-lg text-center text-xs px-4 py-1 bg-brand-disabled-yellow tracking-wider font-bold">PENDING</p>,
    cancelled : <p className="truncate w-fit-content text-brand-primary-red border border-brand-primary-red rounded-lg text-center text-xs px-4 py-1 bg-brand-light-red tracking-wider font-bold">CANCELLED</p>,
    expired : <p className="truncate w-fit-content text-brand-text-title border border-brand-primary-title rounded-lg text-center text-xs px-4 py-1 bg-brand-extra-background tracking-wider font-bold">EXPIRED</p>,
    rejected : <p className="truncate w-fit-content text-brand-primary-red border border-brand-primary-red rounded-lg text-center text-xs px-4 py-1 bg-brand-light-red tracking-wider font-bold">REJECTED</p>,
}

export const LOAD_OPTIONS = {
    ftl : <p className="truncate w-fit-content bg-brand-extra-grey text-xs text-center text-brand-text-title px-4 py-1 rounded-xl">Full Truck Load</p>,
    ptl : <p className="truncate w-fit-content bg-brand-extra-grey text-xs text-center text-brand-text-title px-4 py-1 rounded-xl">Part Truck Load</p>,
    'ftl|ptl' : <p className="truncate w-fit-content bg-brand-extra-grey text-xs text-center text-brand-text-title px-4 py-1 rounded-xl">Full/Part Truck Load</p>
}
export const LOAD_OPTIONS_WBG = {
    ftl : <p className="truncate w-fit-content text-sm text-center text-brand-text-title  py-1 rounded-xl">Full Truck Load</p>,
    ptl : <p className="truncate w-fit-content text-sm text-center text-brand-text-title  py-1 rounded-xl">Part Truck Load</p>,
    'ftl|ptl' : <p className="truncate w-fit-content text-sm text-center text-brand-text-title  py-1 rounded-xl">Full/Part Truck Load</p>
}

export const BOOKING_STATUS_CONSTANTS = {
  all: 'all',
  unconfirmed : 'unconfirmed',
  confirmed : 'confirmed',
  completed : 'completed',
  processing : 'processing',
  cancelled : 'cancelled',
  expired : 'expired',
  rejected : 'rejected',
  pending : 'pending',
  awaiting_fulfillment :'awaiting_fulfillment'
}

export const TIME_FORMAT_STRING = 'DD MMMM YYYY, h:mm A';

export const TRACKING_STATUS_CONSTANTS = {
  in_transit : 'in_transit',
  delivered : 'delivered',
  dispatch : 'dispatched',
  not_dispatched : 'not_dispatched',
}

export const timeDurationForGraphChnage = [
  {
    key: 'Last 7 days',
    text: 'Last 7 days',
    value: 'week'
  },
  {
    key: 'Last 1 Month',
    text: 'Last 1 month',
    value: 'month'
  },
  {
    key: 'Last 3 months',
    text: 'Last 3 months',
    value: '3_month'
  },
  {
    key: 'Last 6 months',
    text: 'Last 6 months',
    value: '6_month'
  },
  {
    key: 'Last 1 year',
    text: 'Last 1 year',
    value: 'year'
  },
]

export const PAYMENTS_METHODS = [
  {
    key: 'cash',
    text: 'Cash',
    value: 'cash'
  },
  {
    key: 'debit_card',
    text: 'Debit Card',
    value: 'debit_card'
  },
  {
    key: 'credit_card',
    text: 'Credit Card',
    value: 'credit_card'
  },
  {
    key: 'neft',
    text: 'NEFT',
    value: 'neft'
  },
  {
    key: 'rtgs',
    text: 'RTGS',
    value: 'rtgs'
  },
  {
    key: 'cheque',
    text: 'Cheque',
    value: 'cheque'
  },
  {
    key: 'upi',
    text: 'UPI',
    value: 'upi'
  },
  {
    key: 'others',
    text: 'Others',
    value: 'others'
  },
]

export const PAYMENT_STATUS = [
  {
    key: 'succeded',
    text: 'Succeded',
    value: 'succeded'
  },
  {
    key: 'initiated',
    text: 'Initiated',
    value: 'initiated'
  },
  {
    key: 'refunded',
    text: 'Refunded',
    value: 'refunded'
  },
  {
    key: 'cancelled',
    text: 'Cancelled',
    value: 'cancelled'
  },
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending'
  },
  {
    key: 'failed',
    text: 'Failed',
    value: 'failed'
  },
]

export const TAX_TYPES = [
  {
    key: 'gst',
    text: 'Gst',
    value: 'gst'
  },
  {
    key: 'sales',
    text: 'Sales',
    value: 'sales'
  },
]

export const bookingStatusFilterClient = [
  {
    key: 'all',
    text: 'All Bookings',
    value: BOOKING_STATUS_CONSTANTS.all
  },
  {
    key: 'open_bookings',
    text: 'Open Bookings',
    value: BOOKING_STATUS_CONSTANTS.unconfirmed
  },
  {
    key: 'confirmed_bookings',
    text: 'Confirmed Bookings',
    value: BOOKING_STATUS_CONSTANTS.confirmed
  },
  {
    key: 'live_bookings',
    text: 'Live Bookings',
    value: BOOKING_STATUS_CONSTANTS.awaiting_fulfillment
  },
  {
    key: 'completed_bookings',
    text: 'Completed Bookings',
    value: BOOKING_STATUS_CONSTANTS.completed
  },
]

export const bookingStatusFilterVendor = [
  {
    key: 'all',
    text: 'All Bookings',
    value: BOOKING_STATUS_CONSTANTS.all
  },
  {
    key: 'confirmed_bookings',
    text: 'Confirmed Bookings',
    value: BOOKING_STATUS_CONSTANTS.confirmed
  },
  {
    key: 'live_bookings',
    text: 'Live Bookings',
    value: BOOKING_STATUS_CONSTANTS.awaiting_fulfillment
  },
  {
    key: 'completed_bookings',
    text: 'Completed Bookings',
    value: BOOKING_STATUS_CONSTANTS.completed
  },
  {
    key: 'cancelled_bookings',
    text: 'Cancelled Bookings',
    value: BOOKING_STATUS_CONSTANTS.cancelled
  },
]

export const bookingStatusFilterAdmin = [
  {
    key: 'all',
    text: 'All Bookings',
    value: BOOKING_STATUS_CONSTANTS.all
  },
  {
    key: 'open_bookings',
    text: 'Open Bookings',
    value: BOOKING_STATUS_CONSTANTS.unconfirmed
  },
  {
    key: 'confirmed_bookings',
    text: 'Confirmed Bookings',
    value: BOOKING_STATUS_CONSTANTS.confirmed
  },
  {
    key: 'live_bookings',
    text: 'Live Bookings',
    value: BOOKING_STATUS_CONSTANTS.awaiting_fulfillment
  },
  {
    key: 'cancelled',
    text: 'Cancelled',
    value: BOOKING_STATUS_CONSTANTS.cancelled
  },
  {
    key: 'completed',
    text: 'Completed',
    value: BOOKING_STATUS_CONSTANTS.completed
  }
]

export const bookingRequestStatusFilterClient = [
  {
    key: 'all',
    text: 'All Bookings',
    value: BOOKING_STATUS_CONSTANTS.all
  },
  {
    key: 'pending',
    text: 'Pending',
    value: BOOKING_STATUS_CONSTANTS.pending
  },
  {
    key: 'processing',
    text: 'Processing',
    value: BOOKING_STATUS_CONSTANTS.processing
  },
  {
    key: 'completed',
    text: 'Completed',
    value: BOOKING_STATUS_CONSTANTS.completed
  },
  {
    key: 'rejected',
    text: 'Rejected',
    value: BOOKING_STATUS_CONSTANTS.rejected
  },
  {
    key: 'expired',
    text: 'Expired',
    value: BOOKING_STATUS_CONSTANTS.expired
  },
  
]
export const bookingRequestStatusFilterVendor = [
  {
    key: 'all',
    text: 'All Requests',
    value: BOOKING_STATUS_CONSTANTS.all
  },
  {
    key: 'pending',
    text: 'Pending',
    value: BOOKING_STATUS_CONSTANTS.pending
  },
  {
    key: 'processing',
    text: 'Processing',
    value: BOOKING_STATUS_CONSTANTS.processing
  },
  {
    key: 'rejected',
    text: 'Rejected',
    value: BOOKING_STATUS_CONSTANTS.rejected
  },
  {
    key: 'expired',
    text: 'Expired',
    value: BOOKING_STATUS_CONSTANTS.expired
  },
  
]

export const bookingRequestStatusFilterAdmin = [
  {
    key: 'all',
    text: 'All Bookings',
    value: BOOKING_STATUS_CONSTANTS.all
  },
  {
    key: 'pending',
    text: 'Pending',
    value: BOOKING_STATUS_CONSTANTS.pending
  },
  {
    key: 'processing',
    text: 'Processing',
    value: BOOKING_STATUS_CONSTANTS.processing
  },
  {
    key: 'completed',
    text: 'Completed',
    value: BOOKING_STATUS_CONSTANTS.completed
  },
  {
    key: 'rejected',
    text: 'Rejected',
    value: BOOKING_STATUS_CONSTANTS.rejected
  },
  {
    key: 'expired',
    text: 'Expired',
    value: BOOKING_STATUS_CONSTANTS.expired
  },
  
]

export const cancelBookingReasons = [
  {
    key: 'Rate is not matching with my target',
    text: 'Rate is not matching with my target',
    value: 'Rate is not matching with my target'
  },
  {
    key: 'Rejected Material Quality',
    text: 'Rejected Material Quality',
    value: 'Rejected Material Quality'
  },
  {
    key: 'Order Cancel',
    text: 'Order Cancel',
    value: 'Order Cancel'
  },
  {
    key: 'Other',
    text: 'Other',
    value: 'Other'
  },
]

export const ROUTE_CHECK_REGEX = /(client|vendor|admin)$/gm;