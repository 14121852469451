import moment from "moment";
import { RightArrowIcon } from "../../icons";
import { LOAD_OPTIONS, STATUS_JSX, TIME_FORMAT_STRING } from "../../utils/constants";

export const structuredAllBookingSchemaForClient = (response) =>{
    if(response?.data?.length){
        response.data = response?.data?.map((resp)=>({
            id : resp.id,
            booking_id : resp.booking_no,
            route : resp?._pickup_location?.district + ' → '+ (resp?._drop_location?.district||'N/A'),
            material : resp?.materials?.length+' item(s)',
            type : LOAD_OPTIONS[resp.type],
            pickup_at : moment.utc(resp.pickup_at).format(TIME_FORMAT_STRING),
            pickup_address: resp?.pickup_address ||'N/A',
            status : STATUS_JSX[resp.status],
            activity: resp.activity,
            //last_activity_updated_at: resp.last_activity_updated_at? (moment(resp.last_activity_updated_at).fromNow() + (resp.count_by_status.processing ? ` (${resp.count_by_status.processing} vendors responded)`: '')) : "NA",
            last_activity_updated_at: resp.last_activity_updated_at? (moment(resp.last_activity_updated_at).fromNow()) : "NA",
            final_unit_price: resp.rate ? "₹ " + resp?.rate + "/" + resp?.rate_unit : "-", // Picked from actual booking detail
            least_unit_price: resp.activity[0] ? (resp.activity[0]?.rate ? "₹ " + resp.activity[0]?.rate  + "/" + resp.activity[0]?.rate_unit  : "-" ) : "-", // Formulated at backend out of all requests and rate submitted by vendors
            count_by_status: resp.count_by_status,
        }));
    }
    return response
}

export const structuredAllBookingSchemaForVendor = (response) =>{
    if(response?.data?.length){
        response.data = response?.data?.map((resp)=>({
            id : resp.id,
            booking_id : resp.booking_no,
            route : resp?._pickup_location?.district + ' → '+(resp?._drop_location?.district||'N/A'),
            pickup_address: resp?.pickup_address ||'N/A',
            material : resp?.materials?.length+ ' item(s)',
            client : resp?._client?.name||'N/A',
            type : LOAD_OPTIONS[resp.type],
            pickup_at : moment.utc(resp.pickup_at).format(TIME_FORMAT_STRING),
            final_unit_price: resp.rate ? "₹ " + resp?.rate + "/" + resp?.rate_unit : "-",
            status : STATUS_JSX[resp.status],
        }));
    }
    return response
}

export const structuredAllBookingRequestsSchemaForVendor = (response) =>{
    if(response?.data?.length){
        response.data = response?.data?.map((resp)=>({
            id : resp.id,
            booking_id : resp?._booking?.booking_no,
            pickup_address: resp?._booking?.pickup_address ||'N/A',
            route : resp?._booking?._pickup_location?.district + ' → '+ (resp?._booking?._drop_location?.district||'N/A'),
            material : resp?._booking?.materials?.length+' item(s)',
            type : LOAD_OPTIONS[resp?._booking?.type],
            pickup_at : moment.utc(resp?._booking?.pickup_at).format(TIME_FORMAT_STRING),
            status : STATUS_JSX[resp.status],
            remarks: resp?._booking?.remarks,
            remarks_history: resp?._booking?.remarks_history,
        }));
    }
    return response
}

export const structuredBookingDataForVendor = (response) =>{
    if( response?._booking ){
        return { ...response, ...response?._booking, 
            status : response.status,
            rate : response?.rate,
            total_cost : response?.total_cost,
            rate_unit : response?.rate_unit,
            remarks: response?._booking?.remarks,
            remarks_history: response?.remarks_history,
         }
    }
    return response
}

export const structuredVendorListbySearchData = response =>{
    if(response?.data?.length){
        response.data = response?.data?.map((items)=>({
            from_zone : items?.from_zone?.name,
            from_zone_pincodes : items?.from_zone?.pincodes,
            to_zone : items?.to_zone?.name||'N/A',
            to_zone_pincodes : items?.to_zone?.pincodes,
            vendor_id : items?._vendor?.id,
            vendor_name : items?._vendor?.name,
            total_branches : items?._vendor?.total_branches,
            rates : items?.rates,
            id : items.id
        }))
    }
    return response;
}

export const structuredVendorListbySearchDataV2 = response =>{
    return response;
}