import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import {theme} from '../styles/theme';

interface IMuiThemeProps {
    children : HTMLElement;
}

const ThemeProvider = (props) => {
    const { children } = props;
  return (
    <MuiThemeProvider theme={theme}>
        {
            children
        }
    </MuiThemeProvider>
  )
}

export default ThemeProvider