import { AxiosResponse } from "axios";
import { history } from "../helpers/history";
import { APP_USER_ROUTES, PLATFORM_USERS } from "./constants";
import { UserObject } from "./ObjectTypes";

export const getApiResponseErrorMessage = (object :  any) =>{
  if(object && object?.errors?.errors) return object?.errors?.errors?.join(' ');
  else if(object && object?.errors?.message) return object?.errors?.message;
}

export const getResponseFromApiResponse = (object : AxiosResponse) =>{
  const {data, status, headers} = object;
  if(data?.meta || data?.data?.length || data?.data?.length==0 ){
    return { data: data?.data, meta: data?.meta }
  }
  
  // Handling for file download
  if(headers[`content-type`] && (headers[`content-type`] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || headers[`content-type`] === "application/pdf")) {
    return {data:data, headers: headers};
  }
  const response = data?.data;
  return response;
}

export const redirectToUrl = (endpoint:string = null) => {
    return endpoint ? history.push(endpoint) : null;
}

export const downloadURI = (uri:string, name:string) => {
  var link = document.createElement("a");
  link.download = name.trim().replace(/['"]+/g, '');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const extractParamsFromUrl = () => {
  console.log(history)
    const params: any = new URLSearchParams(history.location.search);
    let obj = {};
    for(let value of params.keys()) {
      obj[value] = params.get(value);
    }
    return obj;
  }

  export const checkRegisterRedirection = () => {
    const { location } = history;
    const params:any = extractParamsFromUrl();
    if(params && params.r) {
      return { 
        redirect: true,
        params: location?.search 
      }
    }
    return {
      redirect: false,
    };  
  }

export const parseSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
}

export const getAuthRoute = () => {
    const { redirect, params } =  checkRegisterRedirection();
    return redirect ? `${APP_USER_ROUTES[PLATFORM_USERS.CLIENT].REGISTER}${params}` : `${APP_USER_ROUTES[PLATFORM_USERS.CLIENT].LOGIN}`
} 

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getUserData = (object: UserObject) =>{
  return object;
}

export const getFirstLastNameSepration = ( value : string ) =>{
  const newVal = value.split(' ');
  const first_name = newVal[0];
  const last_name = newVal.slice(1).join(' ');
  return { first_name, last_name }
}

interface ICountryData{
  id ?: string,
  name ?: string,

}

export const structureCountryData = (data: Array<ICountryData>) => {
  return data.map((item : ICountryData) : { value: string, text: string} =>({
    value : item.id,
    text  : item.name 
  }))
}

interface IPincodeObject {
  city_id ?: string;
  created_at ?: string;
  district ?: string;
  division_name ?: string;
  id ?: number|string;
  office_name ?: string;
  pincode ?: string;
  region_name ?: string;
  state_id ?: string;
  state_name ?: string;
  area ?: string
}

export const structurePincodeCityData = (data: Array<IPincodeObject>) =>{
  return data.map((item : IPincodeObject, index) : { value: number|string, text: string, id?: number|string, key?: number} =>({
    key : index,
    value : item.id,
    text  : areaFormattedValue(item),
    id : item.id,
  }))
}

export const structurePincodeCityDataForBookings = (data: Array<IPincodeObject>) =>{
  return data.map((item : IPincodeObject, index) : { value: number|string, text: string, id?: number|string, key?: number} =>({
    key : index,
    value : item.id,
    text  : areaFormattedValue(item),
    id : item.id,
  }))
}

interface IStateObject {
  id ?: number| string;
  name ? : string ;
  state: {name ?: string};
  state_id ?: number;
}

export const structureStateData = (data: Array<IStateObject>) =>{
  return data.map((item: IStateObject) =>({
    id: item.id,
    text: item.name,
    value : item.id,
  }))
}

export const staructureCityData = (data: Array<IStateObject>) =>{
  return data.map((item: IStateObject) =>({
    text: item.name,
    value : item.id,
    state_id: item.state_id,
  }))
}

export const structureCityObjectData = (data : IStateObject) =>{
  return ({
    text : data.name,
    value : data.id,
    state_id : data.state_id
  })
}

interface IReturnType {
  key: string, value:string, text:string
}

export const structure30MinuteIntervalTimeArray = ():IReturnType[] =>{
  let timeArray = [];
  let hourArray = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
  let minuteArray = [`00`, `15`, `30`, `45`];

  hourArray.forEach(hour => {
    minuteArray.forEach(minute => {
      timeArray.push({
        key: `${hour}:${minute}:00 AM`,
        value: `${hour}:${minute}:00 AM`,
        text: `${hour}:${minute} AM`,
      });
    });
  });
  hourArray.forEach(hour => {
    minuteArray.forEach(minute => {
      timeArray.push({
        key: `${hour}:${minute}:00 PM`,
        value: `${hour}:${minute}:00 PM`,
        text: `${hour}:${minute} PM`,
      });
    });
  });

  // Pushing time for End time of the day
  timeArray.push({
    key: `11:59:00 PM`,
    value: `11:59:00 PM`,
    text: `11:59 PM`,
  });

  return timeArray;
}

// get required format of date on some intervals since current date
export const getDateInterval = ( value: string ) =>{
  const date = new Date();
  switch(value){
    case 'year':{
      return `${date.getFullYear()-1}-${date.getMonth()+1}-${date.getDate()}`
    }
    case 'week':{
      const lastWeek = new Date(date.getFullYear(), date.getMonth()+1, date.getDate()-7);
      return `${lastWeek.getFullYear()}-${lastWeek.getMonth()}-${lastWeek.getDate()}`
    }
    case 'month':{
      const lastMonth = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return `${lastMonth.getFullYear()}-${lastMonth.getMonth()}-${lastMonth.getDate()}`
    }
    case '3_month':{
      const last_3Month = new Date(date.getFullYear(), date.getMonth()-2, date.getDate());
      return `${last_3Month.getFullYear()}-${last_3Month.getMonth()}-${last_3Month.getDate()}`
    }
    case '6_month':{
      const last_6Month = new Date(date.getFullYear(), date.getMonth()-5, date.getDate());
      return `${last_6Month.getFullYear()}-${last_6Month.getMonth()}-${last_6Month.getDate()}`
    }
  }
}

// debounce method
export function debounceMethod (cb, delay=1000){
     let timeout
     return (...args) =>{
         if(timeout) clearTimeout(timeout);
         timeout = setTimeout(()=>{
             timeout = null
            cb(...args)
        },delay)
    }
}

// function return pincode, area, city, state formated string
export function areaFormattedValue( data ){
  //return data?.pincode + ', ' + (data?.area||data?.office_name) +', '+ (data?._city?.name||data?.district) +', '+ (data?._state?.name||data?.state_name)
  return data?.pincode + ', ' + (data?._city?.name||data?.district) +', '+ (data?._state?.name||data?.state_name)
}