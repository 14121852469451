import React from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import { capitalize } from '../../utils/common';
import { UserObject } from '../../utils/ObjectTypes';
import DashboardMain from './Service';

interface Props{
  userProfileData ?: UserObject
}

const Dashboard: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <PageTitle className="text-3xl">Welcome Back, {capitalize(userProfileData?.name)}</PageTitle>
        <DashboardMain {...props}/>
    </div>
  )
}

export default Dashboard