import React, { useState, useEffect } from "react";
import PageTitle from "../../../Typography/PageTitle";
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBranches, editBranches, getBranchData } from "../../../../ReduxActions/branch.action";
import toast from "react-hot-toast";
import UserDetails from '../../../UserDetail';

interface Props {
  match?: any;
  routeKey ?: string;
  history ?: any;
}

interface BookingDetailObject{
  first_name ?: string;
  last_name ?: string;
  type ?: string;
  gst_number ?: string;
  email ?: string;
  contact_number ?: string;
}

const LOAD_TYPE = [
  {
    key: 'ftl',
    value : 'ftl',
    text : 'Full Truck Load'
  },
  {
    key : 'ptl',
    value : 'ptl',
    text : 'Part Truck Load'
  }
]

const AddEditBranches:React.FC<Props> = (props) => {

  const { routeKey, history } = props;

  const { id } = useParams<{id : string}>();
  const param = routeKey;

  // dispatch 
  const dispatch = useDispatch();
  //selectors
  const addBranchSelector = useSelector((state: any)=>state.BranchReducer.addBranch);
  const branchSelector = useSelector((state: any)=>state.BranchReducer.branch);
  const editBranchSelector = useSelector((state: any)=>state.BranchReducer.editBranch);

  const [branchDetails, setBranchDetails] = useState<BookingDetailObject>({
    first_name : '',
    last_name  : '',
    type : '',
    gst_number : '',
    email : '',
    contact_number :"",
  });
  const [validationObj, setValidationObj] = useState<BookingDetailObject>({});
  const [loading, setLoading] = useState({
    branch : false, addBranch:false, editBranch:false
  })

  React.useEffect(() => {
    if(id){
      dispatch( getBranchData({ id : id, user: param}));
    }else {      
      setBranchDetails({
        first_name : '',
        last_name  : '',
        type : '',
        gst_number : '',
        email : '',
        contact_number :""
        })
      }
  }, [])

  const handleInputChange = (key, value) => {
    setBranchDetails({ ...branchDetails, [key]: value});
  }

  useEffect(() => {
    let toastId : any;
    if(branchSelector.status === API_CONSTANTS.loading){
        // toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, branch:true});
    }
    if(branchSelector.status === API_CONSTANTS.success && loading.branch){
        setLoading({...loading, branch:false});
        setBranchDetails(branchSelector.data)
        toast.dismiss(toastId);
    }
    if(branchSelector.status === API_CONSTANTS.error && loading.branch){
        setLoading({...loading, branch:false});
        toast.dismiss(toastId);
        toast.error(branchSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [branchSelector])

  useEffect(() => {
    let toastId : any;
    if(addBranchSelector.status === API_CONSTANTS.loading){
        toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, addBranch:true});
    }
    if(addBranchSelector.status === API_CONSTANTS.success && loading.addBranch){
        setLoading({...loading, addBranch:false});
        toast.dismiss(toastId);
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
        history.push(APP_USER_ROUTES[param].BRANCHES);
    }
    if(addBranchSelector.status === API_CONSTANTS.error && loading.addBranch){
        setLoading({...loading, addBranch:false});
        toast.dismiss(toastId);
        toast.error(addBranchSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [addBranchSelector])

  useEffect(() => {
    let toastId : any;
    if(editBranchSelector.status === API_CONSTANTS.loading){
        toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, editBranch:true});
    }
    if(editBranchSelector.status === API_CONSTANTS.success && loading.editBranch){
        setLoading({...loading, editBranch:false});
        toast.dismiss(toastId);
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
    }
    if(editBranchSelector.status === API_CONSTANTS.error && loading.editBranch){
        setLoading({...loading, editBranch:false});
        toast.dismiss(toastId);
        toast.error(editBranchSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [editBranchSelector])

  const onSubmit = (data) => {
      if(id){
          dispatch(editBranches({ user: param, id: id, ...data}))
      }else{
          dispatch(addBranches({ user: param, ...data}))
      }  
  }

  return (
    <div className="">
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={3} md={4} xs={12}>
          <PageTitle>{id ? 'Edit' : 'Add'} Branch</PageTitle>
        </Grid>
      </Grid>
      <UserDetails routeKey={routeKey} history={history} handleSubmitDetails={onSubmit} userProfileData={branchDetails} additional={false} context={id?'edit':'add'}/>
    </div>
  );
};

export default AddEditBranches;
