import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from '../../../Button';
import { Grid } from "@mui/material";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT} from '../../../../utils/constants';
import { Link, useParams } from "react-router-dom";
import { ParamsTypes } from "../../../../utils/ObjectTypes";
import ComingSoon from "../../../ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import { adminApproveDisapproveClient, fetchAllBookingRequests, fetchAllClients, fetchAllVendorBookings } from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";

interface Data {
  name: string;
  address: string;
  company_name: any;
  city: string;
  pincode: string;
  // type: string;
  is_email_verified: string;
  contact_number : string;
  created_at: string;
  last_accessed: string;
  is_approved: string;
}

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    callBackArguments : ['id'],
    customCell: false,
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
    // callBackArguments : ['id'],
    // customCell: false,
  },
  {
    id: "is_email_verified",
    numeric: false,
    disablePadding: false,
    label: "Verified",
  },
  {
    id: "is_approved",
    numeric: false,
    disablePadding: false,
    label: "Approved",
  },
  {
    id: "last_accessed",
    numeric: false,
    disablePadding: false,
    label: "Last Activity",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "pincode",
    numeric: false,
    disablePadding: false,
    label: "Pincode",
  },
  {
    id: "contact_number",
    numeric: false,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Registered on",
  },
];

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}

const ShowClients: React.FC<Props> = (props) => {
  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors 
  const allClientSelector = useSelector((state: any)=>state.UserReducer.allClients)

  const approveSelector = useSelector((state: any)=>state.UserReducer.approve)

  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request : false,
  });
  const [allClients, setAllClients] = useState<any[]>([]);
  const [allClientsMeta, setAllClientsMeta] = useState({});

  const getAllClients = (page?:number, limit?:number) => {
    dispatch(fetchAllClients({ user : param, page, limit }));
  }

  useEffect(() => {
    getAllClients(1, 10)
  }, [])
  
  useEffect(() => {
    let toastId : any;
    if(allClientSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allClientSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      setAllClients([...allClientSelector?.data?.data.map((items)=>{
        items.name = <p onClick={()=>viewClientDetails(items.id)} className="text-brand-primary-blue cursor-pointer">{items.name}</p>
        return items
      })]);
      setAllClientsMeta(allClientSelector.data?.meta);
      toast.dismiss(toastId);
    }
    if(allClientSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allClientSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allClientSelector])

  useEffect(() => {
    if(approveSelector.status ===API_CONSTANTS.success){
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      getAllClients()
    }
  }, [approveSelector])
  
  // table confirmed booking actions
  const actions = [
    {
      id: "view",
      label: "View Details",
      callbackArguments: ["id"],
      onClick: (id) => {viewClientDetails(id)},
    },
    {
      id: "approve/disapprove",
      label: "Approve/Disapprove",
      callbackArguments: ["id"],
      customLabel:(row)=>`${row?.is_approved==="Yes"?"Disapprove":"Approve"}`,
      onClick: (id) => {approveDisapproveClient(id)},
    },
  ];

  const approveDisapproveClient = (id) => {
    dispatch(adminApproveDisapproveClient({ user: routeKey, id}));
  }

  // view booking details
  const viewClientDetails = (id) => {
    history.push(APP_USER_ROUTES[param].SHOW_CLIENTS_ALIAS+'/'+id)
  }

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">
            All Clients
          </PageTitle>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <TableComponent columns={columns} rows={allClients} actions={actions} onPageChange={getAllClients} meta={allClientsMeta} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowClients;
