import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';

interface Props {
    icon?: JSX.Element;
    primaryText ?: any;
    secondaryText ?: any;
}

const ListCard:React.FC<Props> = (props):JSX.Element => {
  const { icon, primaryText, secondaryText } = props;

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem sx={{padding:'0'}}>
        <ListItemAvatar>
          <Avatar sx={{
              borderRadius:'8px',
              backgroundColor:'#F5F8FF'
          }}>
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </ListItem>
    </List>
  );
}

export default ListCard;