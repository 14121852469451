import { capitalize } from "../../utils/common";


export const structuredAllRatecardData = (response) =>{
    if(response?.data?.length){
        response.data = response?.data?.map((items)=>({
            id : items.id,
            from_zone : capitalize(items.from_zone?.name),
            to_zone : capitalize(items.to_zone?.name),
            rate : items?.rates.map(i=><p>{`₹ `+ i?.rate + '/' + i?.rate_unit}</p>),
            active : items.active ? "Yes" : "No"
        }))
    }
    return  response;
}