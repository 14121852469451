// get all rate card
export const GET_ALL_RATE_CARD = 'app/RATE_CARD/getAllRATE_CARD';
export const GET_ALL_RATE_CARD_SUCCESS = 'app/RATE_CARD/getAllRATE_CARDuccess';
export const GET_ALL_RATE_CARD_ERROR = 'app/RATE_CARD/getAllRATE_CARDError';

// get rate card
export const GET_RATE_CARD = 'app/ratecard/getRateCard';
export const GET_RATE_CARD_SUCCESS = 'app/ratecard/getRateCardSuccess';
export const GET_RATE_CARD_ERROR = 'app/ratecard/getRateCardError';

// add rate card
export const ADD_RATE_CARD = 'app/RATE_CARD/addRATE_CARD';
export const ADD_RATE_CARD_SUCCESS = 'app/RATE_CARD/addRATE_CARDuccess';
export const ADD_RATE_CARD_ERROR = 'app/RATE_CARD/addRATE_CARDError';

//edit rate card
export const EDIT_RATE_CARD = 'app/RATE_CARD/editRATE_CARD';
export const EDIT_RATE_CARD_SUCCESS = 'app/RATE_CARD/editRATE_CARDuccess';
export const EDIT_RATE_CARD_ERROR = 'app/RATE_CARD/editRATE_CARDError';

//delete rate card
export const DELETE_RATE_CARD = 'app/RATE_CARD/deleteRATE_CARD';
export const DELETE_RATE_CARD_SUCCESS = 'app/RATE_CARD/deleteRATE_CARDuccess';
export const DELETE_RATE_CARD_ERROR = 'app/RATE_CARD/deleteRATE_CARDError';