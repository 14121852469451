import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const StatusCard = (props) => {
  const { icon, statusText, total, colorClass, onClick } = props;
  return (
    <Card
      onClick={() => onClick()}
      sx={{
        minWidth: "12rem",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
        cursor: props.onClick ? "pointer" : "default",
      }}
    >
      <CardContent sx={{ padding: "1.5rem" }}>
        <div className={`${colorClass} w-fit p-3 rounded-lg mb-4`}>{icon}</div>
        <p className="font-bold text-3xl text-brand-text-primary ">{total}</p>
        <p className="text-base text-brand-text-caption">{statusText}</p>
      </CardContent>
    </Card>
  );
};

export default StatusCard;
