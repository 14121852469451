// get all vendor fleets
export const GET_ALL_VENDOR_FLEETS = 'app/vendor/fleets/getAll';
export const GET_ALL_VENDOR_FLEETS_SUCCESS = 'app/vendor/fleets/getAll/success';
export const GET_ALL_VENDOR_FLEETS_ERROR = 'app/vendor/fleets/getAll/error'

//get single fleet
export const GET_VENDOR_FLEET = 'app/vendor/getfleet';
export const GET_VENDOR_FLEET_SUCCESS = 'app/vendor/getfleet/success';
export const GET_VENDOR_FLEET_ERROR = 'app/vendor/getfleet/error';

//add fleet
export const ADD_VENDOR_FLEET = 'app/vendor/addfleet';
export const ADD_VENDOR_FLEET_SUCCESS = 'app/vendor/addfleet/success';
export const ADD_VENDOR_FLEET_ERROR = 'app/vendor/addfleet/error';

//edit fleet 
export const EDIT_VENDOR_FLEET = 'app/vendor/editfleet';
export const EDIT_VENDOR_FLEET_SUCCESS = 'app/vendor/editfleet/success';
export const EDIT_VENDOR_FLEET_ERROR = 'app/vendor/editfleet/error';

//delete vendor fleet 
export const DELETE_VENDOR_FLEET = 'app/vendor/deletefleet';
export const DELETE_VENDOR_FLEET_SUCCESS = 'app/vendor/deletefleet/success';
export const DELETE_VENDOR_FLEET_ERROR = 'app/vendor/deletefleet/error';

/**
 * Admin side apis
 */
// vendor bookings
export const GET_VENDOR_FLEETS = 'app/admin/vendor/FLEETS';
export const SET_VENDOR_FLEETS = 'app/admin/vendor/setFLEETS';

// get Branch count
export const GET_TRUCK_COUNT = 'app/trucks/count';
export const SET_TRUCK_COUNT = 'app/setTrucks/count';