import React, { lazy } from "react";
import { APP_USER_ROUTES, PLATFORM_USERS } from "../../utils/constants";
import LandingPage from "../LandingPage";
import Login from "../Login";
import Register from "../Register";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from '../ResetPassword';
import Dashboard from '../Dashboard';
import ProfileSettings from '../ProfileSettings';
import Payments from "../Payments";
import Bookings from "../Bookings";
import BookingRequests from "../BookingRequests";
import Logout from '../Logout';
import FleetsContainer from "../Fleet";
import OffersContainer from "../Offers";
import BranchesContainer from "../Branches";
import NotFound from "../NotFound";
import ZoneContainer from "../Zones";
import FleetsAddContainer from "../FleetAdd";
import AddEditZoneContainer from "../Zones/AddEditZone";
import RateCardContainer from "../RateCard";
import AddEditRateCardContainer from "../RateCard/AddEditZone";
import AddEditBranch from "../Branches/AddEditBranch";
import BookingDetails from "../Bookings/BookingDetails";
import AddBookings from "../Bookings/AddBookings";
import Vendors from "../Vendors";
import Clients from "../Clients";
import VendorDetails from "../Vendors/VendorDetails";
import ClientDetails from "../Clients/ClientDetails";
import AddEditPayments from "../Payments/AddEditPayments";
import BranchDetails from "../Branches/BranchDetails";
import SelectUserType from "../SelectUserType";
import { CitiesWrapperForAdmin, PincodesWrapperForAdmin, StatesWrapperForAdmin } from "../Locations";

interface ROUTES {
    path ?: string;
    component ?: any;
    title ?: string;
    routeKey ?: string;
    description ?: string;
    isProtected ?: boolean;
    role ?: Array<string>;
  }

export const ALL_ROUTES : Array< ROUTES >  = [
    {
        title : 'Not Found',
        description :'',
        isProtected : true,
        path : APP_USER_ROUTES.external.NOT_FOUND,
        component : NotFound,
    },
    {
        title:'',
        description: '',
        isProtected: false,
        path: APP_USER_ROUTES.external.SELECTUSERTYPE,
        component: SelectUserType,
    },
    {
        title : 'Login Client',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].LOGIN,
        component : Login
    },
    {
        title : 'Logout Client',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].LOGOUT,
        component : Logout
    },
    {
        title : 'Login Admin',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].LOGIN,
        component : Login
    },
    {
        title : 'Logout Admin',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].LOGOUT,
        component : Logout
    },
    {
        title : 'Login Vendor',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].LOGIN,
        component : Login
    },
    {
        title : 'Logout Vendor',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].LOGOUT,
        component : Logout
    },
    {
        title : 'Login Vendor branch',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR_BRANCH,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].LOGIN,
        component : Login
    },
    {
        title : 'Logout Vendor Branch',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR_BRANCH,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].LOGOUT,
        component : Logout
    },
    // {
    //     title : 'Register Admin',
    //     description :'',
    //     routeKey : PLATFORM_USERS.ADMIN,
    //     isProtected : false,
    //     path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].REGISTER,
    //     component : Register
    // },
    {
        title : 'Register Vendor',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].REGISTER,
        component : Register
    },
    // {
    //     title : 'Register Vendor branch',
    //     description :'',
    //     routeKey : PLATFORM_USERS.VENDOR_BRANCH,
    //     isProtected : false,
    //     path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].REGISTER,
    //     component : Register
    // },
    {
        title : 'Register Client',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].REGISTER,
        component : Register
    },
    // {
    //     title : 'Forgot Password Admin',
    //     description :'',
    //     routeKey : PLATFORM_USERS.ADMIN,
    //     isProtected : false,
    //     path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].FORGOT_PASSWORD,
    //     component : ForgotPassword
    // },
    {
        title : 'Forgot Password Vendor',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].FORGOT_PASSWORD,
        component : ForgotPassword
    },
    {
        title : 'Forgot Password Vendor branch',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR_BRANCH,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].FORGOT_PASSWORD,
        component : ForgotPassword
    },
    {
        title : 'Forgot Password client',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].FORGOT_PASSWORD,
        component : ForgotPassword
    },
    // {
    //     title : 'Reset Password Admin',
    //     description :'',
    //     routeKey : PLATFORM_USERS.ADMIN,
    //     isProtected : false,
    //     path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].RESET_PASSWORD,
    //     component : ResetPassword
    // },
    {
        title : 'Reset Password Vendor',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].RESET_PASSWORD,
        component : ResetPassword
    },
    {
        title : 'Reset Password Vendor Branch',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR_BRANCH,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].RESET_PASSWORD,
        component : ResetPassword
    },
    /**
     * Client Routes
     */
    {
        title : 'Reset Password client',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : false,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].RESET_PASSWORD,
        component : ResetPassword
    },
    {
        title : 'Home',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].DASHBOARD,
        component : Dashboard,
    },
    {
        title : 'Bookings',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKINGS,
        component : Bookings,
    },
    {
        title : 'Create Booking',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKING_CREATE,
        component : AddBookings,
    },
    {
        title : 'Booking Details',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].BOOKING_DETAILS,
        component : BookingDetails,
    },
    // {
    //     title : 'Fleets',
    //     description :'',
    //     routeKey : PLATFORM_USERS.CLIENT,
    //     isProtected : true,
    //     path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].FLEETS,
    //     component : FleetsContainer,
    // },
    {
        title : 'Payments',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].PAYMENTS,
        component : Payments,
    },
    {
        title : 'Settings',
        description :'',
        routeKey : PLATFORM_USERS.CLIENT,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.CLIENT].SETTINGS,
        component : ProfileSettings,
    },
        /**
     * Vendor Branch
     */
         {
            title : 'Home',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].DASHBOARD,
            component : Dashboard,
        },
        {
            title : 'All Booking Requests',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BOOKINGS_REQUESTS,
            component : BookingRequests,
        },
        {
            title : 'Confirmed Bookings',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BOOKINGS,
            component : Bookings,
        },
        {
            title : 'Booking Detail',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BOOKING_DETAILS,
            component : BookingDetails,
        },
        {
            title : 'Booking Requests',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].REQUEST_DETAILS,
            component : BookingDetails,
        },
        // {
        //     title : 'Branches',
        //     description :'',
        //     routeKey : PLATFORM_USERS.VENDOR_BRANCH,
        //     isProtected : true,
        //     path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].BRANCHES,
        //     component : BranchesContainer,
        // },
        {
            title : 'Trucks',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].FLEETS,
            component : FleetsContainer,
        },
        {
            title : 'Add Trucks',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].ADD_FLEETS,
            component : FleetsAddContainer,
        },
        {
            title : 'Edit Truck',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].EDIT_FLEETS,
            component : FleetsAddContainer,
        },
        {
            title : 'Rate Card',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].RATECARD,
            component : RateCardContainer,
        },
        {
            title : 'Add Rate Card',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].ADD_RATECARD,
            component : AddEditRateCardContainer,
        },
        {
            title : 'Edit Rate Card',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].EDIT_RATECARD,
            component : AddEditRateCardContainer,
        },
        {
            title : 'Payments',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].PAYMENTS,
            component : Payments,
        },
        {
            title : 'Add Payments',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].ADD_PAYMENTS,
            component : AddEditPayments,
        },
        {
            title : 'Edit Payments',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].EDIT_PAYMENTS,
            component : AddEditPayments,
        },
        {
            title : 'Settings',
            description :'',
            routeKey : PLATFORM_USERS.VENDOR_BRANCH,
            isProtected : true,
            path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR_BRANCH].SETTINGS,
            component : ProfileSettings,
        },
    /**
     * Vendor Routes
     */
    {
        title : 'Home',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].DASHBOARD,
        component : Dashboard,
    },
    {
        title : 'All Booking Requests',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BOOKINGS_REQUESTS,
        component : BookingRequests,
    },
    {
        title : 'Confirmed Bookings',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BOOKINGS,
        component : Bookings,
    },
    {
        title : 'Booking Detail',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BOOKING_DETAILS,
        component : BookingDetails,
    },
    {
        title : 'Booking Request',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].REQUEST_DETAILS,
        component : BookingDetails,
    },
    {
        title : 'Branches',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].BRANCHES,
        component : BranchesContainer,
    },
    {
        title : 'Add Branch',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_BRANCHES,
        component : AddEditBranch,
    },
    {
        title : 'Edit Branch',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_BRANCHES,
        component : AddEditBranch,
    },
    {
        title : 'Branch Details',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].SHOW_VENDOR_BRANCH,
        component : BranchDetails,
    },
    {
        title : 'Trucks',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].FLEETS,
        component : FleetsContainer,
    },
    {
        title : 'Add Truck',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_FLEETS,
        component : FleetsAddContainer,
    },
    {
        title : 'Edit Truck',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_FLEETS,
        component : FleetsAddContainer,
    },
    {
        title : 'Rate Card',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].RATECARD,
        component : RateCardContainer,
    },
    {
        title : 'Add Rate Card',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_RATECARD,
        component : AddEditRateCardContainer,
    },
    {
        title : 'Edit Rate Card',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_RATECARD,
        component : AddEditRateCardContainer,
    },
    {
        title : 'Payments',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].PAYMENTS,
        component : Payments,
    },
    {
        title : 'Add Payments',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].ADD_PAYMENTS,
        component : AddEditPayments,
    },
    {
        title : 'Edit Payments',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].EDIT_PAYMENTS,
        component : AddEditPayments,
    },
    {
        title : 'Settings',
        description :'',
        routeKey : PLATFORM_USERS.VENDOR,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.VENDOR].SETTINGS,
        component : ProfileSettings,
    },
    /**
     * Admin Routes
     */
     {
        title : 'Home',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].DASHBOARD,
        component : Dashboard,
    },
    {
        title : 'Bookings',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].BOOKINGS,
        component : Bookings,
    },
    {
        title : 'Bookings Detail',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].BOOKING_DETAILS,
        component : BookingDetails,
    },
    {
        title : 'Bookings Requests',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].REQUEST_DETAILS,
        component : BookingDetails,
    },
    {
        title : 'Vendors',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].VENDORS,
        component : Vendors,
    },
    {
        title : 'Vendor Details',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].SHOW_VENDORS,
        component : VendorDetails,
    },
    {
        title : 'Clients',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].CLIENTS,
        component : Clients,
    },
    {
        title : 'Client Details',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].SHOW_CLIENTS,
        component : ClientDetails,
    },
    {
        title : 'Branches',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].BRANCHES,
        component : BranchesContainer,
    },
    {
        title : 'Trucks',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].FLEETS,
        component : FleetsContainer,
    },
    {
        title : 'Add Trucks',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].ADD_FLEETS,
        component : FleetsAddContainer,
    },
    {
        title : 'Edit Trucks',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].EDIT_FLEETS,
        component : FleetsAddContainer,
    },
    {
        title : 'Zones',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].ZONES,
        component : ZoneContainer,
    },
    {
        title : 'Add Zones',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].ADD_ZONES,
        component : AddEditZoneContainer,
    },
    {
        title : 'Edit Zones',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].EDIT_ZONE,
        component : AddEditZoneContainer,
    },
    {
        title : 'Payments',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PAYMENTS,
        component : Payments,
    },
    {
        title : 'States',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].STATES,
        component : StatesWrapperForAdmin,
    },
    {
        title : 'Cities',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].CITIES,
        component : CitiesWrapperForAdmin,
    },
    {
        title : 'Pincodes',
        description :'',
        routeKey : PLATFORM_USERS.ADMIN,
        isProtected : true,
        path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PINCODES,
        component : PincodesWrapperForAdmin,
    },
    // {
    //     title : 'Settings',
    //     description :'',
    //     routeKey : PLATFORM_USERS.ADMIN,
    //     isProtected : true,
    //     path : APP_USER_ROUTES[PLATFORM_USERS.ADMIN].SETTINGS,
    //     component : ProfileSettings,
    // },
    // {
    //     title : 'Tarnsport Ganj',
    //     description :'',
    //     isProtected : false,
    //     path : APP_USER_ROUTES.external.LANDINGPAGE,
    //     component : LandingPage
    // },
]
