import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT } from "../../../../utils/constants";
import { structurePincodeCityData } from "../../../../utils/common";
import { Link, useParams } from "react-router-dom";
import { ParamsTypes } from "../../../../utils/ObjectTypes";
import Input from '../../../Input/MaterialInput';
import { handleCheckValidation } from "../../../../ValidationHelpers";
import CommonDropdown from '../../../Dropdown';
import MaterialSearchDropdown from '../../../Dropdown/MaterialSearchDropdown';
import { dispatch } from "react-hot-toast/dist/core/store";
import { useDispatch, useSelector } from "react-redux";
import { getPinCodeData } from "../../../../ReduxActions/staticAction";
import toast from "react-hot-toast";
import { addZones, editZonees, getZoneData } from "../../../../ReduxActions/zone.action";

interface Props {
  match?: any;
}

interface IZoneDetailObject{
  name ?: string;
  pincodes ?: Array<any>;
}

interface Props {
  history ?: any;
  routeKey ?: string;
}

const AddEditZones:React.FC<Props> = (props) => {
  const { routeKey, history } = props;
  const param = routeKey;

  const { id } = useParams<{ id : string}>();
  // dispatch 
  const dispatch = useDispatch();
  // selectors
  const addZoneSelector = useSelector((state: any) => state.ZoneReducer.addZone);
  const editZoneSelector = useSelector((state: any) => state.ZoneReducer.editZone);
  const zoneSelector = useSelector((state: any) => state.ZoneReducer.zone);
  const pincodeDataSelector = useSelector((state: any)=> state.StaticReducer.pincodeData);
  // react state hooks
  const [zoneDetails, setzoneDetails] = useState<IZoneDetailObject>({
    name : "",
    pincodes : []
  });
  const [validationObj, setValidationObj] = useState<IZoneDetailObject>({});
  const [pincodeValues, setpincodeValues] = useState<number[]>([]);
  const [loading, setLoading] = useState({
    pincode : false,
    zone: false,
    addZone: false,
    editZone: false,
  })
  const [cityPincodeData, setCityPincodeData] = useState([])
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if(id){
      dispatch( getZoneData({user:param, id:id}));
    }
  }, [])
  

  useEffect(() => {
    if(searchText && searchText.length>=3){
      dispatch(
        getPinCodeData({
          location : searchText
        })
      )
    }
  }, [searchText])

  useEffect(() => {
    if(pincodeDataSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, pincode:true});
    }
    if(pincodeDataSelector.status === API_CONSTANTS.success && loading.pincode){
      setCityPincodeData([...structurePincodeCityData(pincodeDataSelector.data?.data)]);
      setLoading({...loading, pincode:false});
    }
    if(pincodeDataSelector.status === API_CONSTANTS.error && loading.pincode){
      toast.error(pincodeDataSelector.data || DEFAULT_ERROR_MESSAGE.failed);
      setLoading({...loading, pincode:false});
    }
  }, [pincodeDataSelector])

  useEffect(() => {
    let toastId : any;
    if(zoneSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, zone:true});
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if(zoneSelector.status === API_CONSTANTS.success && loading.zone){
      setCityPincodeData(structurePincodeCityData(zoneSelector.data?.pincodes));
      setzoneDetails({
        name :zoneSelector.data?.name,
        pincodes : structurePincodeCityData(zoneSelector.data?.pincodes)
      })
      setpincodeValues(zoneSelector.data?.pincodes?.map(i=>i.id))
      toast.dismiss(toastId);
      setLoading({...loading, zone:false});
    }
    if(zoneSelector.status === API_CONSTANTS.error && loading.zone){
      toast.dismiss(toastId);
      toast.error(zoneSelector.data || DEFAULT_ERROR_MESSAGE.failed);
      setLoading({...loading, zone:false});
    }
  }, [zoneSelector])

  useEffect(() => {
    let toastId : any;
    if(addZoneSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, addZone:true});
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if(addZoneSelector.status === API_CONSTANTS.success && loading.addZone){
      setzoneDetails({
        name :addZoneSelector.data?.name,
        pincodes : []
      })
      toast.dismiss(toastId);
      history.push(APP_USER_ROUTES[param].ZONES);
      setLoading({...loading, addZone:false});
    }
    if(addZoneSelector.status === API_CONSTANTS.error && loading.addZone){
      toast.dismiss(toastId);
      toast.error(addZoneSelector.data || DEFAULT_ERROR_MESSAGE.failed);
      setLoading({...loading, addZone:false});
    }
  }, [addZoneSelector])

  useEffect(() => {
    let toastId : any;
    if(editZoneSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, editZone:true});
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if(editZoneSelector.status === API_CONSTANTS.success && loading.editZone){
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      setLoading({...loading, editZone:false});
    }
    if(editZoneSelector.status === API_CONSTANTS.error && loading.editZone){
      toast.dismiss(toastId);
      toast.error(editZoneSelector.data || DEFAULT_ERROR_MESSAGE.failed);
      setLoading({...loading, editZone:false});
    }
  }, [editZoneSelector])
  
  const handleInputChange = (key, value) => {
    setzoneDetails({ ...zoneDetails, [key]: value});
    if(key==='pincodes' && value?.length) setpincodeValues(value.map(i=>i.value))
  }

  /**
   * 
   * @param (id) zone id
   * zonedetails -> zoneData
   * Add and edit zone sunbmit function
   */
  const onSubmit = (e) => {
      e.preventDefault();
      const validation:IZoneDetailObject = handleCheckValidation(zoneDetails);
      setValidationObj(validation);
      if(validation && !validation.name && !validation.pincodes){
        if(id){
          dispatch( editZonees({ id : id, user: param, body : {...zoneDetails, pincodes: pincodeValues} }) )
        }else{
          dispatch( addZones({ user : param, body : { ...zoneDetails, pincodes: pincodeValues }}) );
        }
      }
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={2} md={4} xs={12}>
          <PageTitle>{id ? 'Edit' :'Add'} Zone</PageTitle>
        </Grid>
      </Grid>
      <form onSubmit={onSubmit}>
        <Grid container className="px-8 py-8 bg-brand-primary-white">
            <Grid item lg={12} xs={12}>
                <Input
                 label="Name"
                 placeholder={`Enter region name`}
                 onChange={(e) =>
                   handleInputChange('name', e.target.value)
                 }
                 value={zoneDetails.name}
                 validationKey={validationObj.name}
                />
            </Grid>
            <Grid item lg={12} xs={12} className="">
                <MaterialSearchDropdown
                  label = "Pincode"
                  options={cityPincodeData}
                  value = {zoneDetails.pincodes}
                  onChange = {(value)=>handleInputChange('pincodes', value)}
                  onChangeText={(e)=>setSearchText(e.target.value)}
                  validationKey={validationObj.pincodes}
                  placeholder="Type initial 4 digits"
                  // searchType={true}
                />
            </Grid>
            <Grid item lg={1} md={4} xs={12} marginTop="1rem">
              <Button text="Save" type="submit"/>
            </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AddEditZones;
