import React from 'react';
import AddEditPaymentsComponent from '../../components/role/vendor/Payments/AddEditPayments';
import { PLATFORM_USERS } from '../../utils/constants';

interface Props {
    routeKey ?: string;
}

/**
 * 
 * @param props { routeKey }
 * @returns 
 */
const AddEditPayments: React.FC<Props> = (props): JSX.Element => {
    const { routeKey } = props;
  return (
    <div className="py-6 px-6 overflow-x-auto">
        {
            (routeKey === PLATFORM_USERS.VENDOR||routeKey === PLATFORM_USERS.VENDOR_BRANCH) &&
            <AddEditPaymentsComponent {...props} />
        }
    </div>
  )
}

export default AddEditPayments