import React, {useRef} from 'react';
import { Label, Input } from '@windmill/react-ui';
import { HelperText } from '@windmill/react-ui'

interface Props {
    value ?: string | number;
    onChange ?: any;
    label ?: string;
    validationKey ?: string;
    type ?: string;
    placeholder ?: string;
    disabled ?: boolean;
    className ?: string;
    outline ?: boolean;
}

const CommonInput:React.FC<Props> = (props) => {
    const ref = useRef();
    const { value, onChange, label, validationKey, type, placeholder, disabled, className, outline } = props;
  return (
    <Label className="mt-4" style={disabled?{pointerEvents:'none', opacity:'0.5'}:{}}>
        {
            label && 
            <span className="text-brand-extra-icon font-medium">{label}</span>
        }
        {
            type==='date' ?
            <input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                style={outline ? {boxShadow:'none'} : {}}
                className={`block w-full text-sm focus:outline-none dark:text-gray-300 leading-5 rounded-md focus:border-purple-400 border-gray-300 dark:border-gray-600 focus:ring focus:ring-purple-300 dark:focus:border-gray-600 dark:focus:ring-gray-300 dark:bg-gray-700 mt-1 py-3 rounded-lg border-brand-extra-border placeholder:text-brand-text-placeholder border text-brand-text-primary hover:border-brand-primary-blue focus:border-brand-primary-blue ${className}`}
            />
            :
            <Input className={`mt-1 py-3 rounded-lg border-brand-extra-border placeholder:text-brand-text-placeholder border text-brand-text-primary hover:border-brand-primary-blue focus:ring-1 focus:border-brand-primary-blue ${className}`}
                type={type} 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        }
        {
            validationKey &&
            <HelperText valid={false} className="text-brand-primary-red text-xs">{validationKey}</HelperText>
        }
  </Label>
  )
}

export default CommonInput