import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RateCardService } from "../sevices/ratecard.service";
import {
  GET_ALL_RATE_CARD,
  GET_ALL_RATE_CARD_SUCCESS,
  GET_ALL_RATE_CARD_ERROR,
  GET_RATE_CARD,
  GET_RATE_CARD_SUCCESS,
  GET_RATE_CARD_ERROR,
  ADD_RATE_CARD,
  ADD_RATE_CARD_SUCCESS,
  ADD_RATE_CARD_ERROR,
  EDIT_RATE_CARD,
  EDIT_RATE_CARD_SUCCESS,
  EDIT_RATE_CARD_ERROR,
  DELETE_RATE_CARD,
  DELETE_RATE_CARD_SUCCESS,
  DELETE_RATE_CARD_ERROR
} from "../ReduxConstants/RateCardsContants";
import { structuredAllRatecardData } from "./Schema.tsx/RateCardSchema";

const getAllRateCards = (payload: object) => ({
  type: GET_ALL_RATE_CARD,
  payload: payload,
});

const getAllRateCardsSuccess = (payload: object) => ({
  type: GET_ALL_RATE_CARD_SUCCESS,
  payload: payload,
});

const getAllRateCardsError = (payload: object) => ({
  type: GET_ALL_RATE_CARD_ERROR,
  payload: payload,
});

const getRateCard = (payload: object) => ({
  type: GET_RATE_CARD,
  payload: payload,
});

const getRateCardSuccess = (payload: object) => ({
  type: GET_RATE_CARD_SUCCESS,
  payload: payload,
});

const getRateCardError = (payload: object) => ({
  type: GET_RATE_CARD_ERROR,
  payload: payload,
});

const addRateCard = (payload: object) => ({
  type: ADD_RATE_CARD,
  payload: payload,
});

const addRateCardSuccess = (payload: object) => ({
  type: ADD_RATE_CARD_SUCCESS,
  payload: payload,
});

const addRateCardError = (payload: object) => ({
  type: ADD_RATE_CARD_ERROR,
  payload: payload,
});

const editRateCard = (payload: object) => ({
  type: EDIT_RATE_CARD,
  payload: payload,
});

const editRateCardSuccess = (payload: object) => ({
  type: EDIT_RATE_CARD_SUCCESS,
  payload: payload,
});

const editRateCardError = (payload: object) => ({
  type: EDIT_RATE_CARD_ERROR,
  payload: payload,
});

const deleteRateCard = (payload: object) => ({
  type: DELETE_RATE_CARD,
  payload: payload,
});

const deleteRateCardSuccess = (payload: object) => ({
  type: DELETE_RATE_CARD_SUCCESS,
  payload: payload,
});

const deleteRateCardError = (payload: object) => ({
  type: DELETE_RATE_CARD_ERROR,
  payload: payload,
});

export const getAllRateCardList = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllRateCards(data));
    await RateCardService.getAllRateCard(data)
      .then((result) => {
        dispatch(getAllRateCardsSuccess(structuredAllRatecardData(result)));
      })
      .catch((error) => {
        dispatch(getAllRateCardsError(error));
      });
  };
};

export const getRateCardData = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getRateCard(data));
    await RateCardService.getRateCard(data)
      .then((result) => {
        dispatch(getRateCardSuccess(result));
      })
      .catch((error) => {
        dispatch(getRateCardError(error));
      });
  };
};

export const addRateCardes = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(addRateCard(data));
    await RateCardService.addRateCard(data)
      .then((result) => {
        dispatch(addRateCardSuccess(result));
      })
      .catch((error) => {
        dispatch(addRateCardError(error));
      });
  };
};

export const editRateCardes = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(editRateCard(data));
    await RateCardService.updateRateCard(data)
      .then((result) => {
        dispatch(editRateCardSuccess(result));
      })
      .catch((error) => {
        dispatch(editRateCardError(error));
      });
  };
};

export const deleteRateCardes = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(deleteRateCard(data));
    await RateCardService.deleteRateCard(data)
      .then((result) => {
        dispatch(deleteRateCardSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteRateCardError(error));
      });
  };
};

export const fetchVendorRelatedRatecard = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllRateCards(data));
    await RateCardService.getAllVendorsRateCard(data)
      .then((result) => {
        dispatch(getAllRateCardsSuccess(structuredAllRatecardData(result)));
      })
      .catch((error) => {
        dispatch(getAllRateCardsError(error));
      });
  };
};