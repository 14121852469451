import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonStyles } from "../../../../styles/common";
import { fetchAllBookingRequests, fetchAllVendorBookings, fetchVendorRelatedBookings } from "../../../../ReduxActions";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT} from '../../../../utils/constants';
import toast from "react-hot-toast";
import { Grid } from '@mui/material';
import PageTitle from '../../../Typography/PageTitle';
import TableComponent from "../../../Table";

interface Data {
    booking_id: number;
    route: string;
    material: any;
    client : string;
    type: string;
    pickup_at: string;
    status : string;
  }
  
  interface HeadCell {
    disablePadding ?: boolean;
    id ?: keyof Data;
    label ?: string;
    numeric ?: boolean;
    callBackArguments ? : Array<string>;
    customCell ?: any;
  }
  
const columns: readonly HeadCell[] = [
{
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments : ['id', 'isItemSelected', 'labelId'],
    customCell: false,
},
{
    id: "route",
    numeric: false,
    disablePadding: false,
    label: "Route",
},
{
    id: "material",
    numeric: false,
    disablePadding: false,
    label: "Material",
},
{
    id: "client",
    numeric: false,
    disablePadding: false,
    label: "Client",
},
{
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
},
{
    id: "pickup_at",
    numeric: false,
    disablePadding: false,
    label: "Pickup at",
},
{
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
},
];

const VendorsBooking = (props) => {console.log(props)
   // material Ui common classes
   const classes = useCommonStyles();
   // prop desturcturing
   const { key, match, routeKey, history, id } = props;
   // routekey param
   const param = routeKey;
   // dispatch action
   const dispatch = useDispatch();
   // selectors 
   const allbookingsSelector = useSelector((state: any)=>state.BookingReducer.vendorAllBookings)
   // react states hook
   const [loading, setLoading] = useState({
     all: false,
   });
   const [allBookings, setAllBookings] = useState<any[]>([]);
   const [allBookingMeta, setAllBookingMeta] = useState({})
   
   const getVendorBookings = (page?:number, limit?:number) => {
     dispatch(fetchVendorRelatedBookings({ user : param, vendor_id: id, page, limit }));
   }
   useEffect(() => {
    getVendorBookings(1, 10)
   }, [])

   useEffect(() => {
    let toastId : any;
    if(allbookingsSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allbookingsSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      setAllBookings([...allbookingsSelector?.data?.data?.map((items)=>{
        items.booking_id = <p onClick={()=>viewBookingDetails(items.id)} className="text-brand-primary-blue cursor-pointer">{items.booking_id}</p>
        return items
      })]);
      setAllBookingMeta(allbookingsSelector.data?.meta);
      toast.dismiss(toastId);
    }
    if(allbookingsSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allbookingsSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allbookingsSelector])

    // view booking details
    const viewBookingDetails = (id) => {
        history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS+'/'+id)
    }

  return (
    <div>
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">
            All Bookings
          </PageTitle>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <TableComponent columns={columns} rows={allBookings} stroke={true} onPageChange={getVendorBookings} meta={allBookingMeta}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default VendorsBooking