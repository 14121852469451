import {
  GET_ALL_ZONES,
  GET_ALL_ZONES_SUCCESS,
  GET_ALL_ZONES_ERROR,
  GET_ZONE,
  GET_ZONE_SUCCESS,
  GET_ZONE_ERROR,
  ADD_ZONE,
  ADD_ZONE_SUCCESS,
  ADD_ZONE_ERROR,
  EDIT_ZONE,
  EDIT_ZONE_SUCCESS,
  EDIT_ZONE_ERROR,
  DELETE_ZONE,
  DELETE_ZONE_SUCCESS,
  DELETE_ZONE_ERROR
} from "../ReduxConstants/ZoneConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  allZones: SubstateObject;
  zone: SubstateObject;
  addZone: SubstateObject;
  editZone: SubstateObject;
  deleteZone: SubstateObject;
}

const initialState: InitialState = {
  allZones: {
    data: null,
    status: null,
  },
  zone: {
    data: null,
    status: null,
  },
  addZone: {
    data: null,
    status: null,
  },
  editZone: {
    data: null,
    status: null,
  },
  deleteZone: {
    data: null,
    status: null,
  },
};

const ZoneReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_ZONES: {
      return {
        ...initialState,
        allZones: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_ALL_ZONES_SUCCESS: {
      return {
        ...initialState,
        allZones: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_ALL_ZONES_ERROR: {
      return {
        ...initialState,
        allZones: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_ZONE: {
      return {
        ...initialState,
        zone: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_ZONE_SUCCESS: {
      return {
        ...initialState,
        zone: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_ZONE_ERROR: {
      return {
        ...initialState,
        zone: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case ADD_ZONE: {
      return {
        ...initialState,
        addZone: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case ADD_ZONE_SUCCESS: {
      return {
        ...initialState,
        addZone: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case ADD_ZONE_ERROR: {
      return {
        ...initialState,
        addZone: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case EDIT_ZONE: {
      return {
        ...initialState,
        editZone: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case EDIT_ZONE_SUCCESS: {
      return {
        ...initialState,
        editZone: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case EDIT_ZONE_ERROR: {
      return {
        ...initialState,
        editZone: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case DELETE_ZONE: {
      return {
        ...initialState,
        deleteZone: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case DELETE_ZONE_SUCCESS: {
      return {
        ...initialState,
        deleteZone: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case DELETE_ZONE_ERROR: {
      return {
        ...initialState,
        deleteZone: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default ZoneReducer;
