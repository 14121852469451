import React from 'react';
import { Divider, Grid, Step, Stepper, StepLabel, Box } from '@mui/material';
import { useCommonStyles } from '../../styles/common';
import { TIME_FORMAT_STRING, TRACKING_STATUS_CONSTANTS } from '../../utils/constants';
import moment from 'moment';
import { formatPrice } from '../../helpers/CommonHelper';

interface Props{
    bookingDetails ?: any;
    trackingDetails ?: any,
}

const StepIconComponent: React.FC<{isActive:boolean}> = (props) => {
    const { isActive } = props;
    return(
        <>
        <div className='mb-4 flex items-center justify-between'>
            <div className={'w-6 h-6 rounded-full border-4 ' + ' ' + isActive ? 'bg-brand-primary-green border-brand-disabled-green' : 'bg-brand-extra-grey border-brand-extra-grey'}></div>
        </div>
        </>
    )
}


const PaymentDetailsCard: React.FC<Props> = (props) => {
    const classes = useCommonStyles();
    const {
        bookingDetails ,
        trackingDetails,
    } = props;

    const calculateFreightAmount = () => {
        if(bookingDetails?.rate && trackingDetails?.unloading_weight)
            return formatPrice(bookingDetails?.rate * trackingDetails?.unloading_weight);
        return "NA";
    }

  return (
    <div className='w-full'>
        <Grid container className=''>
            <Grid item lg={4} md={4} xs={12}>
                <p className='text-base text-brand-extra-tableHead font-bold'>Total Freight amount : <span className='font-normal text-brand-text-title'>Rs. {calculateFreightAmount()}</span></p>
                <p className='text-xs text-brand-text-caption mt-1'>(Unloading Weight: {trackingDetails?.unloading_weight} {trackingDetails?.unloading_weight_unit})</p>
            </Grid>
        </Grid>
    </div>
  )
}

export default PaymentDetailsCard