import React from "react";
import VendorBookings from '../../components/role/vendor/Bookings';
import ClientBookings from '../../components/role/client/Bookings';
import AdminBookings from '../../components/role/admin/Bookings';
import { UserObject } from "../../utils/ObjectTypes";
import { PLATFORM_USERS } from "../../utils/constants";

interface Props {
  userProfileData ?: UserObject
}

const Bookings:React.FC<Props> = (props) => {
  const { userProfileData } = props;

  return (
    <>
      {
        userProfileData.role === PLATFORM_USERS.CLIENT &&
        <ClientBookings {...props} key="add"/>
      }
      {
        (userProfileData.role === PLATFORM_USERS.VENDOR || userProfileData.role === PLATFORM_USERS.VENDOR_BRANCH) && 
        <VendorBookings {...props}/>
      }
      {
        userProfileData.role === PLATFORM_USERS.ADMIN && 
        <AdminBookings {...props}/>
      }
    </>
    );
};

export default Bookings;
