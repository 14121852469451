import React from 'react';
import { Label, Textarea } from '@windmill/react-ui';
import { HelperText } from '@windmill/react-ui'

interface Props {
    value ?: string | number;
    onChange ?: any;
    label ?: string;
    validationKey ?: string;
    type ?: string;
    placeholder ?: string;
}

const CommonTextArea:React.FC<Props> = (props) => {
    const { value, onChange, label, validationKey, type, placeholder } = props;
  return (
    <Label className="mt-4">
        {
            label && 
            <span className="text-brand-extra-icon font-medium">{label}</span>
        }
        <Textarea rows="3" className="mt-1 py-3 rounded-lg border-brand-extra-grey placeholder:text-brand-text-placeholder border text-brand-text-primary hover:border-brand-primary-blue focus:border-brand-primary-blue focus:shadow-none focus:ring-0" 
            type={type} 
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
        {
            validationKey &&
            <HelperText valid={false} className="text-brand-primary-red">{validationKey}</HelperText>
        }
  </Label>
  )
}

export default CommonTextArea