import { combineReducers } from "redux";
import UserReducer from "./userReducer";
import AuthReducer from "./authReducer";
import FleetReducer from './fleetReducer';
import StaticReducer from "./staticReducer";
import BranchReducer from "./branchReducer";
import RatecardReducer from "./ratecardReducer";
import ZoneReducer from './zoneReducer';
import BookingReducer from './bookingReducer';
import PaymentReducer from './paymentReducer';

const rootReducer = combineReducers({
    UserReducer,
    AuthReducer,
    FleetReducer,
    StaticReducer,
    BranchReducer,
    RatecardReducer,
    ZoneReducer,
    BookingReducer,
    PaymentReducer
})

export default rootReducer;