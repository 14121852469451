
export const GET_ALL_PAYMENTS = 'app/payments/getALL';
export const SET_ALL_PAYMENTS = 'app/payments/setAll';

export const ADD_PAYEMNTS = 'add/payments/add';
export const SET_ADD_PAYMENTS = 'add/payments/setADD';

export const GET_PAYMENTS_BY_ID = 'app/payments/getById';
export const SET_PAYMENTS_BY_ID = 'app/payments/setById';

export const EDIT_PAYMENTS = 'add/payments/edit';
export const SET_EDIT_PAYMENTS  = 'add/payments/setEdit';

export const DELETE_PAYMENTS = 'add/payments/DELETE';
export const SET_DELETE_PAYMENTS  = 'add/payments/setDELETE';

export const GET_BOOKING_BY_PAYMENT_ID = 'add/payments/BookingByPaymentId';
export const SET_GET_BOOKING_BY_PAYMENT_ID  = 'add/payments/setBookingByPaymentId';

