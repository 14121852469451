
// get pincode
export const GET_PINCODE = 'app/static/getpincode';
export const GET_PINCODE_SUCCESS = 'app/static/getpincode/success';
export const GET_PINCODE_ERROR = 'app/static/getpincode/error';

// get states
export const GET_STATES = 'app/static/getStates';
export const GET_STATES_SUCCESS = 'app/static/getStates/success';
export const GET_STATES_ERROR = 'app/static/getStates/error';

// get country 
export const GET_COUNTRY = 'app/static/getCountry';
export const GET_COUNTRY_SUCCESS = 'app/static/getCountry/Success';
export const GET_COUNTRY_ERROR = 'app/static/getCountry/Error'

// get city
export const GET_CITY = 'app/static/getCity';
export const GET_CITY_SUCCESS = 'app/static/getCity/Success';
export const GET_CITY_ERROR = 'app/static/getCity/Error'