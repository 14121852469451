import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ComingSoonCard = (props) => {
  const { icon, statusText, total, colorClass, onClick } = props;
  return (
    <Card
      onClick={() => onClick()}
      sx={{
        minWidth: "12rem",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
        cursor: props.onClick ? "pointer" : "default",
      }}
    >
      <CardContent sx={{ padding: "1.5rem" }}>
        <div className="relative">
          <div className="w-fit text-right right-0 ml-auto absolute">
            <span className="bg-brand-light-blue text-brand-primary-blue text-xs font-semibold mr-2 px-2.5 py-0.5 rounded border border-brand-primary-blue">Coming soon</span>
          </div>
          <div className={`${colorClass} w-fit p-3 rounded-lg mb-4`}>{icon}</div>
          <p className="font-bold text-xs mb-3 text-brand-text-primary ">{total}</p>
          <p className="text-base text-brand-text-caption">{statusText}</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default ComingSoonCard;
