import React from 'react'
import { useParams } from 'react-router-dom';
import { APP_USER_ROUTES } from '../../utils/constants';
import { ParamsTypes } from '../../utils/ObjectTypes';

interface Props {
    history ?: any;
    match ?: any;
    location ?: any;
    routeKey ?: string;
}

const Logout: React.FC<Props> = (props) => {

    const { history, routeKey } = props;

    const param = routeKey;

    localStorage.clear();
    history.push(APP_USER_ROUTES[param].LOGIN);
  return (
    <></>
  )
}

export default Logout