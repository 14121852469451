import React from 'react';
import AddEditZone from '../../components/role/admin/Zones/AddEditZones';

interface Props {

}

const AddEditZoneContainer: React.FC<Props> = (props) => {
  return (
    <div className='px-6 py-6 overflow-x-auto'>
        <AddEditZone {...props}/>
    </div>
  )
}

export default AddEditZoneContainer