import {
  GET_ALL_BRANCHES,
  GET_ALL_BRANCHES_SUCCESS,
  GET_ALL_BRANCHES_ERROR,
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_ERROR,
  ADD_BRANCHES,
  ADD_BRANCHES_SUCCESS,
  ADD_BRANCHES_ERROR,
  EDIT_BRANCHES,
  EDIT_BRANCHES_SUCCESS,
  EDIT_BRANCHES_ERROR,
  DELETE_BRANCHES,
  DELETE_BRANCHES_SUCCESS,
  DELETE_BRANCHES_ERROR,
  GET_VENDOR_BRANCHES,
  SET_VENDOR_BRANCHES,
  GET_BRANCH_COUNT,
  SET_BRANCH_COUNT
} from "../ReduxConstants/BranchesConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  allBranches: SubstateObject;
  branch: SubstateObject;
  addBranch: SubstateObject;
  editBranch: SubstateObject;
  deleteBranch: SubstateObject;
  vendorBranches: SubstateObject;
  branchCount: SubstateObject;
}

const initialState: InitialState = {
  allBranches: {
    data: null,
    status: null,
  },
  branch: {
    data: null,
    status: null,
  },
  addBranch: {
    data: null,
    status: null,
  },
  editBranch: {
    data: null,
    status: null,
  },
  deleteBranch: {
    data: null,
    status: null,
  },
  vendorBranches:{
    data: null,
    status: null,
  },
  branchCount:{
    data: null,
    status: null,
  },
};

const BranchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_BRANCHES: {
      return {
        ...initialState,
        allBranches: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_ALL_BRANCHES_SUCCESS: {
      return {
        ...initialState,
        allBranches: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_ALL_BRANCHES_ERROR: {
      return {
        ...initialState,
        allBranches: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_BRANCH: {
      return {
        ...initialState,
        branch: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_BRANCH_SUCCESS: {
      return {
        ...initialState,
        branch: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_BRANCH_ERROR: {
      return {
        ...initialState,
        branch: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case ADD_BRANCHES: {
      return {
        ...initialState,
        addBranch: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case ADD_BRANCHES_SUCCESS: {
      return {
        ...initialState,
        addBranch: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case ADD_BRANCHES_ERROR: {
      return {
        ...initialState,
        addBranch: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case EDIT_BRANCHES: {
      return {
        ...initialState,
        editBranch: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case EDIT_BRANCHES_SUCCESS: {
      return {
        ...initialState,
        editBranch: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case EDIT_BRANCHES_ERROR: {
      return {
        ...initialState,
        editBranch: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case DELETE_BRANCHES: {
      return {
        ...initialState,
        deleteBranch: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case DELETE_BRANCHES_SUCCESS: {
      return {
        ...initialState,
        deleteBranch: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case DELETE_BRANCHES_ERROR: {
      return {
        ...initialState,
        deleteBranch: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_VENDOR_BRANCHES: {
      return {
        ...initialState,
        vendorBranches: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_BRANCHES: {
      return {
        ...initialState,
        vendorBranches: {
          data: payload.data,
          status: payload.status
        },
      };
    }
    case GET_BRANCH_COUNT: {
      return {
        ...initialState,
        branchCount: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_BRANCH_COUNT: {
      return {
        ...initialState,
        branchCount: {
          data: payload.data,
          status: payload.status
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default BranchReducer;
