import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ZoneService } from "../sevices/zone.service";
import {
  GET_ALL_ZONES,
  GET_ALL_ZONES_SUCCESS,
  GET_ALL_ZONES_ERROR,
  GET_ZONE,
  GET_ZONE_SUCCESS,
  GET_ZONE_ERROR,
  ADD_ZONE,
  ADD_ZONE_SUCCESS,
  ADD_ZONE_ERROR,
  EDIT_ZONE,
  EDIT_ZONE_SUCCESS,
  EDIT_ZONE_ERROR,
  DELETE_ZONE,
  DELETE_ZONE_SUCCESS,
  DELETE_ZONE_ERROR
} from "../ReduxConstants";
import { structuredZoneSchema } from "./Schema.tsx/ZoneShema";

const getAllZones = (payload: object) => ({
  type: GET_ALL_ZONES,
  payload: payload,
});

const getAllZonesSuccess = (payload: object) => ({
  type: GET_ALL_ZONES_SUCCESS,
  payload: payload,
});

const getAllZonesError = (payload: object) => ({
  type: GET_ALL_ZONES_ERROR,
  payload: payload,
});

const getZone = (payload: object) => ({
  type: GET_ZONE,
  payload: payload,
});

const getZoneSuccess = (payload: object) => ({
  type: GET_ZONE_SUCCESS,
  payload: payload,
});

const getZoneError = (payload: object) => ({
  type: GET_ZONE_ERROR,
  payload: payload,
});

const addZone = (payload: object) => ({
  type: ADD_ZONE,
  payload: payload,
});

const addZoneSuccess = (payload: object) => ({
  type: ADD_ZONE_SUCCESS,
  payload: payload,
});

const addZoneError = (payload: object) => ({
  type: ADD_ZONE_ERROR,
  payload: payload,
});

const editZone = (payload: object) => ({
  type: EDIT_ZONE,
  payload: payload,
});

const editZoneSuccess = (payload: object) => ({
  type: EDIT_ZONE_SUCCESS,
  payload: payload,
});

const editZoneError = (payload: object) => ({
  type: EDIT_ZONE_ERROR,
  payload: payload,
});

const deleteZone = (payload: object) => ({
  type: DELETE_ZONE,
  payload: payload,
});

const deleteZoneSuccess = (payload: object) => ({
  type: DELETE_ZONE_SUCCESS,
  payload: payload,
});

const deleteZoneError = (payload: object) => ({
  type: DELETE_ZONE_ERROR,
  payload: payload,
});

export const getAllZoneList = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllZones(data));
    await ZoneService.getAllZones(data)
      .then((result) => {
        dispatch(getAllZonesSuccess(structuredZoneSchema(result)));
      })
      .catch((error) => {
        dispatch(getAllZonesError(error));
      });
  };
};

export const getZoneData = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getZone(data));
    await ZoneService.getZones(data)
      .then((result) => {
        dispatch(getZoneSuccess(result));
      })
      .catch((error) => {
        dispatch(getZoneError(error));
      });
  };
};

export const addZones = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(addZone(data));
    await ZoneService.addZone(data)
      .then((result) => {
        dispatch(addZoneSuccess(result));
      })
      .catch((error) => {
        dispatch(addZoneError(error));
      });
  };
};

export const editZonees = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(editZone(data));
    await ZoneService.editZones(data)
      .then((result) => {
        dispatch(editZoneSuccess(result));
      })
      .catch((error) => {
        dispatch(editZoneError(error));
      });
  };
};

export const deleteZonees = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(deleteZone(data));
    await ZoneService.deleteZone(data)
      .then((result) => {
        dispatch(deleteZoneSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteZoneError(error));
      });
  };
};
