import React from "react";
import VendorBookingRequests from '../../components/role/vendor/BookingsRequests';
import { UserObject } from "../../utils/ObjectTypes";
import { PLATFORM_USERS } from "../../utils/constants";

interface Props {
  userProfileData ?: UserObject
}

const BookingRequests:React.FC<Props> = (props) => {
  const { userProfileData } = props;

  return (
    <>
      {
        (userProfileData.role === PLATFORM_USERS.VENDOR || userProfileData.role === PLATFORM_USERS.VENDOR_BRANCH) && 
        <VendorBookingRequests {...props}/>
      }
    </>
    );
};

export default BookingRequests;
