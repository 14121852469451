import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, LOAD_TYPES } from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import { LoadOptions, LoadTypes, ParamsTypes } from "../../../../utils/ObjectTypes";
import Input from '../../../Input/MaterialInput';
import { handleCheckValidation } from "../../../../ValidationHelpers";
import CommonDropdown from '../../../Dropdown';
import MaterialSingleSearchDropdown from "../../../Dropdown/SingleSearchDropdown";
import CommonTextArea from "../../../TextArea";
import CommonInputGroup from "../../../Input/InputGroup";
import { RightArrowIcon, Trash2Icon, TrashIcon } from "../../../../icons";
import { createBookingForClientPtl } from "../../../../ReduxActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ShowVendors from './ShowVendor';
import BookingForm from "./BookingForm";

interface Props {
  match?: any;
  routeKey ?: string;
  history ?: any;
}

interface BookingDetailObject{
  pickup_location?: string;
  drop_location?: string;
  date          ?: string;
  time          ?: string;
  remarks       ?: string;
}

interface ImaterialType {
  type : string, weight: string, unit: string
}

/**
 * 
 * @param props { routeKey, history }
 * @returns JSX
 */
const AddBookingPtl:React.FC<Props> = (props) => {
  // props destructuring
  const { routeKey, history } = props;
  // param as routekey
  const  param  = routeKey;
  // param type
  const { type } = useParams<{ type : string }>()
  // dispatch action function
  const dispatch = useDispatch();
  // selectors 
  const createPtlBookingSelector = useSelector((state:any)=> state.BookingReducer.createBookingPtl)
  // react hook states
  const [bookingDetails, setBookingDetails] = useState<BookingDetailObject>({
    pickup_location: "",
    drop_location: "",
    date          : '',
    time          : '',
    remarks       : '',
  });
  const [searchData, setSearchData] = useState({
    pickup: null,
    drop: null,
    date: "",
    time: "",
  });
  const [vendors, setVendors] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [vendorDetails, setVendorDetails] = useState<any>(null);

  React.useEffect(() => {
    if(vendorDetails){
      setBookingDetails({
        ...bookingDetails,
        ...vendorDetails
      })
      setShowForm(true)
    }
  }, [vendorDetails])
  

   React.useEffect(() => {
     let toastId:any;
     if(createPtlBookingSelector.status===API_CONSTANTS.loading){
       setLoading(true);
       toastId=toast.loading(LOADING_TEXT.processing);
     }
     if(createPtlBookingSelector.status===API_CONSTANTS.success && loading){
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      history.push(APP_USER_ROUTES[routeKey].BOOKINGS)
      setLoading(false);
     }
     if(createPtlBookingSelector.status===API_CONSTANTS.error && loading){
      toast.dismiss(toastId);
      toast.error(createPtlBookingSelector.data||DEFAULT_ERROR_MESSAGE.failed);
     }
   }, [createPtlBookingSelector])
   

  // submit details
  const onSubmit = (data) => {
    dispatch(createBookingForClientPtl({ user: routeKey, type: type, body: { ...data, type: LOAD_TYPES.PART_TRUCK, vendor_id : vendorDetails?.vendor_id, is_rate_selected: false }}))
  }

  // on cancel
  const onCancel = () => {
    setShowForm(false);
  }

  return (
    <>
      {
        !showForm ?
        <ShowVendors {...props} 
        setShowForm={setShowForm} 
        setVendorDetails={setVendorDetails} 
        vendorDetails={vendorDetails} 
        searchData={searchData} 
        setSearchData={setSearchData}
        vendors={vendors}
        setVendors={setVendors}
        />
        :
        <div className="">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className=" mb-8"
          >
            <Grid item lg={12} md={4} xs={12} display="flex" alignItems="center">
              <RightArrowIcon className="rotate-180 mr-4 w-6 h-6 cursor-pointer" onClick={onCancel}/>
              <PageTitle className="mb-0">Add Booking</PageTitle>
            </Grid>
          </Grid>
          <BookingForm bookingData={bookingDetails} setBookingData={setBookingDetails} onSubmit={onSubmit} onCancel={onCancel}/>
        </div>
      }
    </>
  );
};

export default AddBookingPtl;
