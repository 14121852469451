import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT, PLATFORM_USERS } from '../../utils/constants';
import { getUserData } from '../../utils/common';
import toast from 'react-hot-toast';
import { getUserProfileDetails } from '../../ReduxActions';
import Loader from '../../components/Loader';

interface Props{
    history ?: any;
}

/**
 * 
 * @param props 
 * @returns JSX
 * Main App Container 
 */
const AppContainer : React.FC<Props> = (props) :JSX.Element => {
  const {  history } = props;
  const [userProfileData, setUserProfileData] = useState(null);
  const [loading, setLoading] = useState(false);

  const userData = useSelector((state:any) => state.UserReducer.userProfile);

  /* User Profile Data Selector */
  useEffect(() => {
    let toastId:any;
    if(userData.status === API_CONSTANTS.loading){
      setLoading(true);
      // toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(userData.status === API_CONSTANTS.success){
      setUserProfileData(getUserData(userData.data));
      localStorage.setItem('userRole', userData?.data?.role);
      setLoading(false);
      // toast.dismiss(toastId);
    }
    if(userData.status === API_CONSTANTS.error && loading){
      setLoading(false);
      toast.dismiss(toastId);
      const userRole = localStorage.getItem('userRole');
      toast.error(userData.data||DEFAULT_ERROR_MESSAGE.error1);
      localStorage.clear();
      if(userRole){
      window.location.pathname=process.env.REACT_APP_API_ENV==='production' ? process.env.REACT_APP_BASENAME + (APP_USER_ROUTES[userRole].LOGIN) : (APP_USER_ROUTES[userRole].LOGIN)
      }
    }
  }, [userData])
  
  
  return (
      <>
      {
        loading ?
        <Loader/>
        :
        <Routes {...props} userProfileData={userProfileData}/>
      }
      </>
  )
};

export default AppContainer ;
