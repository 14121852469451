import React from 'react';
import { useParams } from 'react-router-dom';
import AddBooking from '../../components/role/client/Bookings/AddBooking';
import AddBookingPtl from '../../components/role/client/Bookings/AddBookingPtl';
import { LOAD_TYPES, PLATFORM_USERS } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';

interface Props {
    match ?: any;
    userProfileData ?: UserObject;
    routeKey ?: string;
  }

const AddBookings: React.FC<Props> = (props) => {
    
    const { match, userProfileData, routeKey } = props;
    const  { type }  = useParams<{type : string}>();

  return (
    <div className="py-6 px-6 overflow-x-auto">
        {
            routeKey === PLATFORM_USERS.CLIENT &&
            (
              type === LOAD_TYPES.FULL_TRUCK ?
              <AddBooking {...props}/> :
              (
                type === LOAD_TYPES.PART_TRUCK ?
                <AddBookingPtl {...props}/> :
                "Ohh You took the wrong turn. "
              )
            )
        }
    </div>
  )
}

export default AddBookings