import React,{ useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ImageDark from '../../assets/img/splash-image.png';
import { Label, Input, Button } from '@windmill/react-ui';
import { API_CONSTANTS, LOADING_TEXT, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, PLATFORM_USERS_NAME } from '../../utils/constants';
import { handleCheckValidation } from '../../ValidationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordSendEmail } from '../../ReduxActions/index';
import toast from 'react-hot-toast';
import CommonInput from '../../components/Input/MaterialInput';
import { ParamsTypes } from '../../utils/ObjectTypes';
import AppLogo from '../../components/AppLogo';

interface Props{
  routeKey ?: string;
}

interface ValidationObject{
  email ?: string;
}

const ForgotPassword: React.FC<Props> = (props) => {

  const { routeKey } = props;

  const param = routeKey;

  const dispatch = useDispatch();
  const forgotPasswordSelector = useSelector((state:any)=>state.AuthReducer.forgotPassword)

  const [forgotpasswordDetails, setForgotpasswordDetails] = useState({
    email :''
  });
  const [validationObj, setValidationObj] = useState<ValidationObject>({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setForgotpasswordDetails({...forgotpasswordDetails, [key]:value})
  }

  useEffect(() => {
    let toastId:any;
    if(forgotPasswordSelector?.status === API_CONSTANTS.loading){
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(forgotPasswordSelector?.status === API_CONSTANTS.success && loading){
      setLoading(false);
      toast.dismiss(toastId);
      toast.success(forgotPasswordSelector.data?.message || DEFAULT_SUCCESS_MESSAGE.varification)
    }
    if(forgotPasswordSelector?.status === API_CONSTANTS.error && loading){
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(forgotPasswordSelector.data||DEFAULT_ERROR_MESSAGE.error1);
    }
  }, [forgotPasswordSelector])

  const onSubmit = (e) => {
    e.preventDefault();
    const validation: ValidationObject = handleCheckValidation(forgotpasswordDetails);
    setValidationObj(validation);
    if(validation && !validation.email){
      /// callback send email function
      dispatch(forgotPasswordSendEmail({body : {...forgotpasswordDetails, r: param}, user: param}));
    }
  }


  return (
    <div className="flex items-center min-h-screen p-6 bg-brand-extra-background">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-card">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-72 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className=" object-cover w-full h-full"
              src={ImageDark}
              alt="splash-image"
            />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <div className="mb-4">
                <AppLogo/>
              </div>
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Forgot password for {PLATFORM_USERS_NAME[param]}</h1>
              <form onSubmit={onSubmit}>
                <CommonInput
                  type="email" 
                  label='Email'
                  placeholder="john@doe.com" 
                  value={forgotpasswordDetails.email}
                  onChange={(e:any)=>handleInputChange('email', e.target.value)}
                  validationKey={validationObj?.email}
                />
                <Button type="submit" block className="mt-4 py-3 bg-brand-primary-blue hover:bg-brand-hover-blue text-brand-extra-background">
                  Recover password
                </Button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
