// login constants
export const SET_LOGIN = 'app/Login/setlogin';
export const SET_LOGIN_SUCCESS = 'app/Login/SetLoginSuccess';
export const SET_LOGIN_ERROR = 'app/Login/SetLoginError';
// register constants
export const SET_REGISTER = 'app/REGISTER/setREGISTER';
export const SET_REGISTER_SUCCESS = 'app/REGISTER/SetREGISTERSuccess';
export const SET_REGISTER_ERROR = 'app/REGISTER/SetREGISTERError';
// Resend Email verification Link
export const RESEND_EMAIL_VERIFICATION = 'app/auth/resendEmailVerification';
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'app/auth/resendEmailVerifcationSuccess';
export const RESEND_EMAIL_VERIFICATION_ERROR = 'app/auth/resendEmailVerifcationError';
// forgot password constants
export const SET_FORGOT_PASSWORD = 'app/FORGOT_PASSWORD/setFORGOT_PASSWORD';
export const SET_FORGOT_PASSWORD_SUCCESS = 'app/FORGOT_PASSWORD/SetFORGOT_PASSWORDSuccess';
export const SET_FORGOT_PASSWORD_ERROR = 'app/FORGOT_PASSWORD/SetFORGOT_PASSWORDError';
// reset password constants
export const SET_RESET_PASSWORD = 'app/RESET_PASSWORD/setRESET_PASSWORD';
export const SET_RESET_PASSWORD_SUCCESS = 'app/RESET_PASSWORD/SetRESET_PASSWORDSuccess';
export const SET_RESET_PASSWORD_ERROR = 'app/RESET_PASSWORD/SetRESET_PASSWORDError';
//verify password token
export const GET_VERIFY_PASSWORD_TOKEN = 'app/ResetPassoword/getVerifyPassword';
export const SET_VERIFY_PASSWORD_TOKEN = 'app/ResetPassword/setVerifyPassword';
export const SET_VERIFY_PASSWORD_TOKEN_ERROR = 'app/ResetPassword/setVerifyPasswordError';