import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { IUpdateUserObject } from "../utils/ObjectTypes";

interface IBranchObject{
    user : string,
    id   ?: string,
    body ?: object,
    page?:number, limit?: number
}

export const BranchService = {

    getAllBranches :async (data : IBranchObject) =>{
        const request = { data }
        const result =await axiosInstance.get(API_URLS[data.user].getAllBranches+`?page=${data?.page}&limit=${data?.limit}`);
        return result
    },

    getBranch :async (data : IBranchObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.get(API_URLS[data.user].getIdBasedBranch(data.id))
        return result
    },

    addBranch :async (data : IBranchObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].addNewBranch, request)
        return result
    },

    updateBranch :async (data : IBranchObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.put(API_URLS[data.user].editBranch(data.id), request)
        return result
    },
    
    deleteBranch :async (data : IBranchObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.delete(API_URLS[data.user].deleteBranch(data.id), request)
        return result
    },

    // admin apis
    getAllBranchesForVendor: async(data:{ user: string, vendor_id: string}) =>{
        const result = await axiosInstance.get(API_URLS[data.user].getVendorBranches(data?.vendor_id));
        return result
    },

    // get count 
    getBranchCount: async(data:{ user: string}) =>{
        const result = await axiosInstance.get(API_URLS[data.user].branchCount);
        return result
    },
}