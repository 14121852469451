import { Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmailIcon, InfoIcon, MappinIcon, PhoneIcon, UserIcon } from "../../../../../icons";
import { getBranchData } from "../../../../../ReduxActions/branch.action";
import { useCommonStyles } from "../../../../../styles/common";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
} from "../../../../../utils/constants";
import PageTitle from "../../../../Typography/PageTitle";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import VendorsBooking from "./VendorsBooking";
import VendorsBookingRequests from "./VendorsBookingRequests";
import VendorFleets from "./VendorFleets";
import VendorRatecard from "./VendorRatecard";
import { areaFormattedValue } from "../../../../../utils/common";
const VendorDetails = (props) => {
  const classes=useCommonStyles();
  // props destructuring
  const { routeKey, match, history } = props;
  const param = routeKey;
  // id route param
  const { id } = useParams<{ id; string }>();
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  const branchSelector = useSelector((state: any)=>state.BranchReducer.branch);
  // react state hooks
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch( getBranchData({ id : id, user: param}));
  }, []);

  useEffect(() => {
    let toastId : any;
    if(branchSelector.status === API_CONSTANTS.loading){
        // toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading(true);
    }
    if(branchSelector.status === API_CONSTANTS.success && loading){
        setLoading(false);
        setUserData(branchSelector.data)
        toast.dismiss(toastId);
    }
    if(branchSelector.status === API_CONSTANTS.error && loading){
        setLoading(false);
        toast.dismiss(toastId);
        toast.error(branchSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [branchSelector])
  return (
    <div>
        <Grid container>
          <Grid item lg={12} xs={12}>
              <PageTitle>Vendor Branch Details</PageTitle>
          </Grid>
          <Grid item lg={12} xs={12} >
              <Paper className={"bg-brand-primary-white" + " " + classes.boxShadowNone+" "}>
                  <Grid container item lg={12} xs={12} className={classes.p1t5}>
                      {
                      <>
                          <Grid
                          item
                          lg={4}
                          xs={12}
                          md={6}
                          display="flex"
                          alignItems="center"
                          className="py-4 pr-4"
                          >
                          <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                          <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                              Company Name
                              </p>
                              <p className="text-base text-brand-text-title">
                              {userData?.company_name || "NA"}
                              </p>
                          </div>
                          </Grid>
                          <Grid
                          item
                          lg={4}
                          xs={12}
                          md={6}
                          display="flex"
                          alignItems="center"
                          className="py-4 pr-4"
                          >
                          <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                          <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                              Name
                              </p>
                              <p className="text-base text-brand-text-title">
                              {userData?.name || "NA"}
                              </p>
                          </div>
                          </Grid>
                          <Grid
                          item
                          lg={4}
                          xs={12}
                          md={6}
                          display="flex"
                          alignItems="center"
                          className="py-4 pr-4"
                          >
                          <PhoneIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                          <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                              Phone No
                              </p>
                              <p className="text-base text-brand-text-title">
                              {userData?.contact_number || "NA"}
                              </p>
                          </div>
                          </Grid>
                          <Grid
                          item
                          lg={4}
                          xs={12}
                          md={6}
                          display="flex"
                          alignItems="center"
                          className="py-4"
                          >
                          <EmailIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                          <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                              Email
                              </p>
                              <p className="text-base text-brand-text-title">
                              {userData?.email || "NA"}
                              </p>
                          </div>
                          </Grid>
                          <Grid
                              item
                              lg={4}
                              xs={12}
                              md={6}
                              display="flex"
                              alignItems="center"
                              className="py-4 pr-4"
                          >
                              <MappinIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                              <div>
                              <p className="text-sm font-bold text-brand-text-tableHead">
                                  {"Location"}
                              </p>
                              <p className="text-base text-brand-text-title">
                                  {/* <span>{userData?._pincode?.pincode || 'N/A'}</span>,
                                  <span> {userData?._pincode?.district || 'N/A'}</span> */}
                                  { userData?._pincode ? areaFormattedValue({...userData?._pincode, _city: userData?._city, _state: userData?._state, area: userData?.area }) : "N/A"}
                              </p>
                              </div>
                          </Grid>
                      </>
                      }
                  </Grid>
              </Paper>
          </Grid>
          <Grid item lg={12} xs={12} className={classes.mt1pt5}>
              <Paper className={"bg-brand-primary-white" + " " + classes.boxShadowNone+" "}>
                  <Grid container item lg={12} xs={12} className={classes.p1t5}>
                    <Box className="overflow-x-auto" sx={{ maxWidth: { xs: 320, sm: 480, lg:600 }, bgcolor: 'background.paper' }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                      >
                        <Tab label="Bookings" />
                        <Tab label="Booking Requests" />
                        <Tab label="Fleets" />
                        <Tab label="RateCards" />
                      </Tabs>
                    </Box>
                  </Grid>
                  <Grid item lg={12} xs={12} className={classes.p1t5}>
                      {
                        userData?.id && (
                          value===0 ?
                          <VendorsBooking id={userData?.id} {...props}/>
                          : value===1 ?
                          <VendorsBookingRequests id={userData.id} {...props}/>
                          : value===2 ?
                          <VendorFleets id={userData.id} {...props}/>
                          : value===3 &&
                          <VendorRatecard id={userData.id} {...props}/>
                        )
                      }
                  </Grid>
              </Paper>
          </Grid>
        </Grid>
    </div>
  );
};

export default VendorDetails;
