import React from 'react';
import { Button } from '@windmill/react-ui';

interface ButtonProps{
    text ?: string;
    onClick ?: any;
    type ?: string;
    tag ?: any;
    to ?: string;
    className ?: string;
    disabled ?: boolean;
}

const CommonButton: React.FC<ButtonProps> = (props) => {
    const { text, onClick, type, tag, to, className, disabled } = props;
  return (
    <>
        <Button  disabled={disabled || false} className={`mt-4 py-3 bg-brand-primary-blue hover:bg-brand-hover-blue text-brand-extra-background focus:ring-1 ${className}`} block  type={type} tag={tag} to={to} onClick={onClick}>
            {text}
        </Button>
    </>
  )
}

export default CommonButton