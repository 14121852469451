/**
 * @description Auth helpers file to deal with all authentication related stuff.
 */

 import { redirectToUrl, getAuthRoute } from '../utils/common';
 import {history} from './history';

 const TOKEN = 'token';
 
 const AuthHelpers = {
   isAuthenticated: () => !!localStorage.getItem(TOKEN),
   login: () => {
     // Add api for login
   },
   signup: () => {
     // addd api for signup
   },
   logout: (type) => {
     if(AuthHelpers.isAuthenticated()) {
      //  console.log(AuthHelpers.isAuthenticated())
       if(!type) {
          window.location.href = getAuthRoute();
       } else {
         redirectToUrl(getAuthRoute());
       }
       localStorage.removeItem(TOKEN);
       localStorage.clear();
     }
   },
   saveTokenToLocalStorage: (token) => {
     localStorage.setItem(TOKEN, token);
   },
   removeTokenFromLocalStorage: () => {
     localStorage.removeItem(TOKEN);
   }
 };
 
 export default AuthHelpers;
 