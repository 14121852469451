
/**
 * Client side booking apis
 */

// get specific bookings request
export const GET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS = 'app/common/specificbookingsreq';
export const SET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS = 'app/common/setspecificbookingsreq';

// get all client booking
export const GET_ALL_CLIENT_BOOKINGS = 'app/client/allbookings';
export const SET_GET_ALL_CLIENT_BOOKINGS = 'app/client/setallbookings';

// create booking for client
export const ALL_VENDOR_LIST = 'app/client/vendorlistPtl';
export const SET_ALL_VENDOR_LIST = 'app/client/setvendorlistPtl';

// get booking by id
export const GET_BOOKING = 'app/client/getbooking';
export const SET_BOOKING = 'app/client/setbooking';

//create booking ptl
export const CREATE_BOOKING_PTL = 'app/client/createbookingptl';
export const SET_CREATE_BOOKING_PTL = 'app/client/setcreatebookingptl';

//create booking ftl 
export const CREATE_BOOKING_FTL = 'app/client/createbookingftl';
export const SET_CREATE_BOOKING_FTL = 'app/client/setcreatebookingftl';

// confirm booking request 
export const CONFIRM_CLIENT_BOOKING_REQUEST = 'app/client/confimbookingrequest';
export const SET_CONFIRM_CLIENT_BOOKING_REQUEST = 'app/client/setconfirmbookingrequest';

// reconsider booking
export const CLIENT_RECOSIDER_BOOKING = 'app/client/reconsiderbooking';
export const SET_CLIENT_RECONSIDER_BOOKING = 'app/client/setReconsiderbooking';

/**
 * Vendor side bookig apis
 */

// get all vendor booking api
export const GET_ALL_VENDOR_BOOKINGS = 'app/vendor/allbookings';
export const SET_GET_ALL_VENDOR_BOOKINGS = 'app/vendor/setallbookings';

// get all booking requests
export const GET_ALL_BOOKING_REQUESTS = 'app/vendor/allbookingrequests';
export const SET_ALL_BOOKING_REQUESTS = 'app/vendor/setAllBookingRequests';

// get booking details 
export const GET_VENDOR_BOOKING_DETAILS = 'app/vendor/getbookingdetails';
export const SET_VENDOR_BOOKING_DETAILS = 'app/vendor/setbookingDetails';

// accept booking request
export const VENDOR_ACCEPT_BOOKING_REQUEST = 'app/vendor/acceptbookingrequest';
export const SET_VENDOR_ACCEPT_BOOKING_REQUEST = 'app/vendor/setAcceptBookingRequest';

// reject booking request
export const VENDOR_REJECT_BOOKING_REQUEST = 'app/vendor/Rejectbookingrequest';
export const SET_VENDOR_REJECT_BOOKING_REQUEST = 'app/vendor/setRejectBookingRequest';

// negotiate booking request
export const VENDOR_NEGOTIATE_BOOKING_REQUEST = 'app/vendor/Negotiatebookingrequest';
export const SET_VENDOR_NEGOTIATE_BOOKING_REQUEST = 'app/vendor/setNegotiateBookingRequest';

// update tracking booking request
export const UPDATE_TRACKING = 'app/vendor/UpdateTracking';
export const SET_UPDATE_TRACKING = 'app/vendor/setUpdateTracking';

/**
 * Admin side apis
 */
// vendor bookings
export const GET_VENDOR_BOOKINGS = 'app/admin/vendor/bookings';
export const SET_VENDOR_BOOKINGS = 'app/admin/vendor/setbookings';

// vendor booking requests
export const GET_VENDOR_BOOKING_REQUESTS = 'app/admin/vendor/booking_REQUESTS';
export const SET_VENDOR_BOOKING_REQUESTS = 'app/admin/vendor/setbooking_REQUESTS';

// get booking status
export const GET_BOOKING_STATS = 'app/admin/vendor/bookingstatus';
export const SET_BOOKING_STATS = 'app/admin/vendor/setBookingStatus';