import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface Props {
  width:number,
  height:number,
  data?: {
    name: string,
    uv: number
  }[]
}

const Chart: React.FC<Props> = (props) => {
  const { width, height, data } = props;
  return (
      <BarChart width={width} height={height} data={data} 
      margin={{
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
      }}
      >
        <CartesianGrid strokeDasharray='1 3'/>
        <Bar dataKey="uv" fill="#8884d8" barSize={30}/>
        <XAxis dataKey="name" stroke="#9DACB4" />
        <YAxis stroke="#9DACB4"  interval={1} min={1} startOffset={1} label={{ value: 'Number of bookings', angle: -90, fill:"#9DACB4", position: 'insideLeft', textAnchor: 'middle' }} />
      </BarChart>
  )
}

export default Chart