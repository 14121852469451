import React from "react";
import { Label, Input } from "@windmill/react-ui";
import { HelperText } from "@windmill/react-ui";
import { TextField, Box, InputAdornment } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useCommonStyles } from "../../styles/common";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

type IOptions = {
    text : string;
    value : string;
}

interface Props {
  value?: string | number;
  onChange?: any;
  label?: string;
  validationKey?: string;
  type?: string;
  placeholder?: string;
  onChangeSelect ?: any;
  selectValue ?: string;
  options ?: Array<IOptions>;
  rupee ?: boolean;
  group ?: boolean;
  disabled?: boolean;
}

const CommonInputGroup: React.FC<Props> = (props) => {
  const classes = useCommonStyles();
  const { value, onChange, label, validationKey, type, placeholder, onChangeSelect, selectValue, options, rupee, group, disabled } = props;
  return (
    <div className="mt-4 flex justify-start w-full flex-col">
      {label && (
        <span className="text-brand-extra-icon font-medium text-sm mb-1">{label}</span>
      )}
      <Box
        sx={{
          width: '100%',
          maxWidth: "100%",
        }}
        className="flex"
      >
        <TextField fullWidth 
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e)=>onChange(e)}
            disabled={disabled}
            sx={{
              // width:'75%',
              '& .MuiOutlinedInput-notchedOutline' : {
                border :'1px solid #EBEEF3',
                fontSize:'14px'
              },
            }}
            helperText={validationKey}
            error={validationKey?true:false}
            FormHelperTextProps={{
              className:classes.ml0
            }}
            InputProps={{
              className: classes.bg_white + " " + classes.p3px + " " + classes.pr0 + " " + classes.fontSizeSmall + " " + classes.inputHeight ,
              sx:{
                '&:hover' : {
                  border :"0px solid #3466E5",
                },
                '&:hover .MuiOutlinedInput-notchedOutline':{
                  border : '1px solid #3466E5'
                },
                '&:focus-within .MuiOutlinedInput-notchedOutline':{
                  border : '1px solid #3466E5',
                },
                '& input':{
                  padding:'7.5px 14px',
                  paddingLeft:'9px',
                  fontSize:'14px'
                }
              },
              inputProps: type==='number'?{ min: 0, step:0.001 }:{},
              startAdornment:(
                rupee &&
                <InputAdornment position="start">
                  <CurrencyRupeeIcon fontSize="small" className={classes.mlpt5}/>
                </InputAdornment>
              ),
              endAdornment : (
                <InputAdornment position="end">
                  {
                    group ?
                    <p className="py-3 px-4 bg-brand-extra-background w-fit text-center border-l border-brand-extra-grey">{selectValue}</p>
                    :<Select 
                        value={selectValue}
                        disabled={disabled}
                        onChange={(e)=>onChangeSelect(e)}
                        className={classes.borderRadiusLeft+' '+ classes.mainBackground+ " " + classes.fontSizeSmall + " " + classes.inputHeight}
                        sx={{
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline' :{
                            borderWidth : '0px',
                            borderColor : 'unset',
                            fontSize:'14px'
                          }
                        }}
                    >
                        {
                            options.map((i: IOptions)=>
                                <MenuItem value={i.value}>{i.text}</MenuItem>
                            )
                        }
                    </Select> 
                  }
                </InputAdornment>
              )
          
            }}
        />
      </Box>
    </div>
  );
};

export default CommonInputGroup;
