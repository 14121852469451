import moment from "moment";
import { LOAD_OPTIONS, TIME_FORMAT_STRING } from "../../utils/constants";

export const structuredClientDetails = response =>{
    if(response?.data?.length){
        response.data = response?.data?.map((items)=>({
            name : items.name,
            id : items.id,
            address : items.address,
            company_name : items.company_name,
            city : items?._city?.name,
            pincode : items?._pincode?.pincode,
            type : LOAD_OPTIONS[items?.type],
            contact_number : items?.contact_number,
            is_email_verified : items?.is_email_verified ? 'Yes' : 'No',
            is_approved : items?.is_approved ? 'Yes' : 'No',
            created_at: moment(items?.created_at).format(TIME_FORMAT_STRING),
            last_accessed: items?.last_accessed ? moment(items?.last_accessed).format(TIME_FORMAT_STRING): 'NA',
        }))
    }
    return response;
}

export const structuredVendorsDetails = response =>{
    if(response?.data?.length){
        response.data = response?.data?.map((items)=>({
            name : items.name,
            role: items.role,
            id : items.id,
            address : items.address,
            company_name : items.company_name,
            city : items?._city?.name,
            state : items?._state?.name,
            pincode : items?._pincode?.pincode,
            is_email_verified : items?.is_email_verified ? 'Yes' : 'No',
            is_approved : items?.is_approved ? 'Yes' : 'No',
            type : LOAD_OPTIONS[items?.type],
            created_at: moment(items?.created_at).format(TIME_FORMAT_STRING),
            email: items.email,
            last_accessed: items?.last_accessed ? moment(items?.last_accessed).format(TIME_FORMAT_STRING): 'NA',
            preferences: items?.preferences.filter(pref => pref.type === 'state').map(k => k.preference_value).join(', '),
            preferences_raw: items?.preferences,
        }))
    }
    return response;
}

export const structuredStatesListForAdmin = response =>{
    if(response?.data?.length){
        response.data = response?.data?.map((item)=>({
            name : item.name,
            cities_count: item?.cities_count,
            pincodes_count: item?.pincodes_count,
            created_at: item?.created_at ? moment(item?.created_at).format(TIME_FORMAT_STRING): 'NA',
        }))
    }
    return response;
}

export const structuredCitiesListForAdmin = response =>{
    if(response?.data?.length){
        response.data = response?.data?.map((item)=>({
            name : item.name,
            pincodes_count: +item?.pincodes_count,
            state: item?.state?.name,
            created_at: item?.created_at ? moment(item?.created_at).format(TIME_FORMAT_STRING): 'NA',
        }))
    }
    return response;
}

export const structuredPincodeListForAdmin = response =>{
    if(response?.data?.length){
        response.data = response?.data?.map((item)=>({
            pincode : item.pincode,
            state_name: item?._state?.name,
            city_name: item?._city?.name,
            district: item?.district,
            office_name: item?.office_name,
            region_name: item?.region_name,
            fallback_state_name: item?.state_name,
            user_count: `${item?.count?.vendor_hq} VD | ${item?.count?.vendor_branch} BR | ${item?.count?.client} CL`, 
            created_at: item?.created_at ? moment(item?.created_at).format(TIME_FORMAT_STRING): 'NA',
        }))
    }
    return response;
}