import {
  GET_ALL_RATE_CARD,
  GET_ALL_RATE_CARD_SUCCESS,
  GET_ALL_RATE_CARD_ERROR,
  GET_RATE_CARD,
  GET_RATE_CARD_SUCCESS,
  GET_RATE_CARD_ERROR,
  ADD_RATE_CARD,
  ADD_RATE_CARD_SUCCESS,
  ADD_RATE_CARD_ERROR,
  EDIT_RATE_CARD,
  EDIT_RATE_CARD_SUCCESS,
  EDIT_RATE_CARD_ERROR,
  DELETE_RATE_CARD,
  DELETE_RATE_CARD_SUCCESS,
  DELETE_RATE_CARD_ERROR
} from "../ReduxConstants/RateCardsContants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  allRatecards: SubstateObject;
  ratecard: SubstateObject;
  addRatecard: SubstateObject;
  editRatecard: SubstateObject;
  deleteRatecard: SubstateObject;
}

const initialState: InitialState = {
  allRatecards: {
    data: null,
    status: null,
  },
  ratecard: {
    data: null,
    status: null,
  },
  addRatecard: {
    data: null,
    status: null,
  },
  editRatecard: {
    data: null,
    status: null,
  },
  deleteRatecard: {
    data: null,
    status: null,
  },
};

const RatecardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_RATE_CARD: {
      return {
        ...initialState,
        allRatecards: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_ALL_RATE_CARD_SUCCESS: {
      return {
        ...initialState,
        allRatecards: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_ALL_RATE_CARD_ERROR: {
      return {
        ...initialState,
        allRatecards: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_RATE_CARD: {
      return {
        ...initialState,
        ratecard: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_RATE_CARD_SUCCESS: {
      return {
        ...initialState,
        ratecard: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_RATE_CARD_ERROR: {
      return {
        ...initialState,
        ratecard: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case ADD_RATE_CARD: {
      return {
        ...initialState,
        addRatecard: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case ADD_RATE_CARD_SUCCESS: {
      return {
        ...initialState,
        addRatecard: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case ADD_RATE_CARD_ERROR: {
      return {
        ...initialState,
        addRatecard: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case EDIT_RATE_CARD: {
      return {
        ...initialState,
        editRatecard: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case EDIT_RATE_CARD_SUCCESS: {
      return {
        ...initialState,
        editRatecard: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case EDIT_RATE_CARD_ERROR: {
      return {
        ...initialState,
        editRatecard: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case DELETE_RATE_CARD: {
      return {
        ...initialState,
        deleteRatecard: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case DELETE_RATE_CARD_SUCCESS: {
      return {
        ...initialState,
        deleteRatecard: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case DELETE_RATE_CARD_ERROR: {
      return {
        ...initialState,
        deleteRatecard: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default RatecardReducer;
