import { Windmill } from '@windmill/react-ui'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/css/tailwind.output.css'
import ThemedSuspense from './components/ThemedSuspense'
import { SidebarProvider } from './context/SidebarContext';
import { store } from './helpers/index';
import { Provider } from 'react-redux';
import Loader from './components/Loader';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as serviceWorker from './serviceWorker';
;
// sentry intialized for error logging
process.env.REACT_APP_API_ENV ==='production' && 
Sentry.init({
  dsn: "https://9d5bfe6d869a47609047c667eb3c6690@o1157874.ingest.sentry.io/6363517",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
      <SidebarProvider>
        <Suspense fallback={<Loader />}>
          <Windmill usePreferences>
            <App />
          </Windmill>
        </Suspense>
      </SidebarProvider>
  </Provider>,
  document.getElementById('root'),
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorkerRegistration.register();
serviceWorker.register();