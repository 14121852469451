import React, { useState, useMemo, useEffect } from "react";
import { FtlImage, PtlImage } from "../../../../icons";
import { LOAD_OPTIONS, LOAD_TYPES, PLATFORM_USERS_NAME } from "../../../../utils/constants";
import MaterialSearchDropdown from "../../../Dropdown/MaterialSearchDropdown";
import Modal from "../../../Modal";
import { Grid } from "@mui/material";
import { UserService } from "../../../../sevices";
import toast from "react-hot-toast";
import MaterialMultiSearchDropdown from "../../../Dropdown/MaterialMultiSearchDropdown";
interface Props {
  open?: boolean;
  setOpen?: any;
  onClickCards?: any;
}

const BookingRequestModal = (props) => {
  const { open, setOpen, allVendors, bookingId, param, inputVendors } = props;
  const [validationObj, setValidationObj] = useState<any>({});
  const [searchText, setSearchText] = useState<string>("");
  const [vendorValues, setVendorValues] = useState<any[]>([]);
  const [inputValues, setInputValues] = useState(inputVendors);
  const handleInputChange = (key, value) => {
    setInputValues(value);
    setVendorValues(value.map((i) => i.id));
  };
  useEffect(() => {
    setInputValues(inputVendors);
  }, [open]);

    const filteredVendors = allVendors.filter((item) => {
      return (item.is_approved === 'Yes' && item.is_email_verified === 'Yes');
    });
    const allVendorsDetails = filteredVendors.map((vendor) => {
    return {
      text: `${vendor.company_name} (${vendor.email}) [${vendor.city}, ${vendor.state}] - ${PLATFORM_USERS_NAME[vendor.role]}`,
      value: vendor.company_name,
      id: vendor.id,
      };
    });

  const handleSubmit = () => {
    if (vendorValues.length > 0) {
      UserService.postBookingResquest({
        user: param,
        id: bookingId,
        data: { vendors: vendorValues },
      })
        .then((result: any) => {
          console.log(result);
          if (result) {
            toast.success(result.message);
            setOpen(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        setOpen={setOpen}
        headingText="Send Booking requests to vendors"
        maxWidth={"md"}
        content={
          <>
            <Grid item lg={12} xs={12} className="">
              <div className="w-screen" />
              <MaterialMultiSearchDropdown
                label="Select Vendors"
                options={allVendorsDetails}
                value={inputValues}
                onChange={(value) => handleInputChange("company_name", value)}
                onChangeText={(e) => setSearchText(e.target.value)}
                validationKey={validationObj.company_name}
                placeholder="Type vendor company name"
              />
            </Grid>
          </>
        }
        onClickAgree={handleSubmit}
        agreeButtonText="Submit"
        cancelButtonText="Cancel"
      />
    </div>
  );
};

export default BookingRequestModal;
