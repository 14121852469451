import { ADD_PAYEMNTS, DELETE_PAYMENTS, EDIT_PAYMENTS, GET_ALL_PAYMENTS, GET_BOOKING_BY_PAYMENT_ID, GET_PAYMENTS_BY_ID, SET_ADD_PAYMENTS, SET_ALL_PAYMENTS, SET_DELETE_PAYMENTS, SET_EDIT_PAYMENTS, SET_GET_BOOKING_BY_PAYMENT_ID, SET_PAYMENTS_BY_ID } from "../ReduxConstants/PaymentConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState{
    allPayments :SubstateObject;
    createPayment : SubstateObject;
    editPayment : SubstateObject;
    deletePayment : SubstateObject;
    getPayment : SubstateObject;
    bookingWithPayment : SubstateObject;
}

const initialState:InitialState = {
    allPayments :{
        data:null,
        status:null
    },
    createPayment : {
        data:null,
        status:null
    },
    editPayment : {
        data: null,
        status: null
    },
    deletePayment : {
        data: null,
        status: null
    },
    getPayment : {
        data: null,
        status: null
    },
    bookingWithPayment : {
        data: null,
        status: null
    },
}

const PaymentReducer = ( state=initialState, { type, payload }) => {
    switch(type){
          case GET_ALL_PAYMENTS: {
            return {
              ...initialState,
              allPayments: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ALL_PAYMENTS: {
            return {
              ...initialState,
              allPayments: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case ADD_PAYEMNTS: {
            return {
              ...initialState,
              createPayment: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ADD_PAYMENTS: {
            return {
              ...initialState,
              createPayment: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case EDIT_PAYMENTS: {
            return {
              ...initialState,
              editPayment: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_EDIT_PAYMENTS: {
            return {
              ...initialState,
              editPayment: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case DELETE_PAYMENTS: {
            return {
              ...initialState,
              deletePayment: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_DELETE_PAYMENTS: {
            return {
              ...initialState,
              deletePayment: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case GET_PAYMENTS_BY_ID: {
            return {
              ...initialState,
              getPayment: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_PAYMENTS_BY_ID: {
            return {
              ...initialState,
              getPayment: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case GET_BOOKING_BY_PAYMENT_ID: {
            return {
              ...initialState,
              bookingWithPayment: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_GET_BOOKING_BY_PAYMENT_ID: {
            return {
              ...initialState,
              bookingWithPayment: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
        default :
            return {
                ...state
            }
    }
}

export default PaymentReducer;