import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import {
  CalenderIcon,
  EventCancelIcon,
  EventConfirmIcon,
  FI_MapIcon,
  FI_TruckIcon,
  InfoIcon,
  InfoPurpleIcon,
  InfoRedIcon,
  LiveIcon,
  MappinIcon,
  PendingIcon,
  SavingIcon,
  UserIcon,
  UsersIco,
  WeightScale,
} from "../../../../icons";
import StatusCard from "../../../Cards/Status";
import { useCommonStyles } from "../../../../styles/common";
import Chart from "../../../Chart/ChartCard";
import ListCard from "../../../Cards/ListCard";
import EnhancedTable from "../../../Table";
import MaterialSingleSearchDropdown from "../../../Dropdown/SingleSearchDropdown";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  BOOKING_STATUS_CONSTANTS,
  LOADING_TEXT,
  PLATFORM_USERS,
  STATUS_JSX,
  timeDurationForGraphChnage,
  TIME_FORMAT_STRING,
} from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBookingStats,
  fetchBranchCount,
  fetchDashboardStats,
  fetchTruckCount,
} from "../../../../ReduxActions";
import moment from "moment";
import { getDateInterval } from "../../../../utils/common";
import BasicSelect from "../../../Dropdown/SimpleSelect";
import toast from "react-hot-toast";
import ComingSoonCard from "../../../Cards/ComingSoonCard";

const data = [
  {
    name: "Jan",
    uv: 0,
  },
  {
    name: "Feb",
    uv: 0,
  },
  {
    name: "Mar",
    uv: 0,
  },
  {
    name: "Apr",
    uv: 0,
  },
  {
    name: "May",
    uv: 0,
  },
  {
    name: "Jan",
    uv: 0,
  },
  {
    name: "Jun",
    uv: 0,
  },
  {
    name: "July",
    uv: 0,
  },
  {
    name: "Aug",
    uv: 0,
  },
  {
    name: "Sept",
    uv: 0,
  },
  {
    name: "Oct",
    uv: 0,
  },
  {
    name: "Nov",
    uv: 0,
  },
  {
    name: "Dec",
    uv: 0,
  },
];

interface Props {
  bookingData?: any[];
  transactionData?: any[];
  history?: any;
  routeKey?: string;
  branchData?: any[];
  truckData?: any[];
}
/**
 *
 * @param props { bookingData }
 * @returns
 */
const Dashboard: React.FC<Props> = (props) => {
  // props
  const {
    bookingData,
    transactionData,
    history,
    routeKey,
    branchData,
    truckData,
  } = props;
  // common material classes
  const classes = useCommonStyles();
  // react state
  const [duration, setDuration] = useState("year");
  const [chartData, setChartData] = useState(data);
  const [bookingDetail, setBookingDetail] = useState({
    confirmed: 0,
    unconfirmed: 0,
    cancelled: 0,
    live: 0,
    pending: 0,
    revenue: 0,
    completed: 0,
  });
  const [counts, setCounts] = useState({
    branch: 0,
    truck: 0,
    vendor: 0,
    client: 0,
    pincodes: 0,
    states: 0,
    cities: 0,
  });

  // dispatch
  const dispatch = useDispatch();
  // selector
  const bookingStatsSelector = useSelector(
    (state: any) => state.BookingReducer.bookingStats
  );
  const branchCountSelector = useSelector(
    (state: any) => state.BranchReducer.branchCount
  );
  const truckCountSelector = useSelector(
    (state: any) => state.FleetReducer.truckCount
  );
  const dashboardSelector = useSelector(
    (state: any) => state.UserReducer.dashboardStats
  );

  const getBookingStats = (time) => {
    dispatch(
      fetchBookingStats({ user: routeKey, query: getDateInterval(time) })
    );
  };

  useEffect(() => {
    getBookingStats(duration);
  }, [duration]);

  // booking Stats
  useEffect(() => {
    if (routeKey === PLATFORM_USERS.ADMIN) {
      dispatch(fetchDashboardStats({ user: routeKey }));
      dispatch(fetchTruckCount({ user: routeKey }));
    } else if (routeKey === PLATFORM_USERS.VENDOR) {
      dispatch(fetchBranchCount({ user: routeKey }));
      dispatch(fetchTruckCount({ user: routeKey }));
    } else if (routeKey === PLATFORM_USERS.VENDOR_BRANCH) {
      dispatch(fetchTruckCount({ user: routeKey }));
    }
  }, []);

  useEffect(() => {
    if (branchCountSelector.status === API_CONSTANTS.success) {
      setCounts({ ...counts, branch: branchCountSelector.data });
    }
  }, [branchCountSelector]);

  useEffect(() => {
    if (dashboardSelector.status === API_CONSTANTS.success) {
      setCounts({
        ...counts,
        vendor: dashboardSelector.data?.vendors,
        client: dashboardSelector.data?.clients,
        branch: dashboardSelector.data?.vendor_branches,
        pincodes: dashboardSelector.data?.pincodes,
        states: dashboardSelector.data?.states,
        cities: dashboardSelector.data?.cities,
      });
    }
  }, [dashboardSelector]);

  useEffect(() => {
    if (truckCountSelector.status === API_CONSTANTS.success) {
      setCounts({ ...counts, truck: truckCountSelector.data });
    }
  }, [truckCountSelector]);

  useEffect(() => {
    let toastId: any;
    if (bookingStatsSelector.status === API_CONSTANTS.loading) {
      toastId = toast.loading(LOADING_TEXT.loading);
    }
    if (bookingStatsSelector.status === API_CONSTANTS.success) {
      toast.dismiss(toastId);
      const date = new Date();
      const currentYear = date.getFullYear();
      setBookingDetail({
        cancelled: bookingStatsSelector.data.booking.cancelled,
        confirmed: bookingStatsSelector.data.booking.confirmed,
        unconfirmed: bookingStatsSelector.data.booking.unconfirmed,
        live: bookingStatsSelector.data.booking.live,
        completed: bookingStatsSelector.data.booking.completed,
        pending: bookingStatsSelector.data.booking?.pendingRequests,
        revenue: bookingStatsSelector.data.booking?.revenue,
      });
      setChartData([
        ...chartData.map((item) => {
          item.uv =
            bookingStatsSelector.data.stats.filter(
              (book) =>
                moment(book.created_at).format("MMM") === item.name &&
                moment(book.created_at).format("YYYY") === String(currentYear)
            ).length || 0;
          return item;
        }),
      ]);
    }
    if (bookingStatsSelector.status === API_CONSTANTS.error) {
      toast.dismiss(toastId);
    }
  }, [bookingStatsSelector]);

  const viewAllBookings = () => {
    history.push(APP_USER_ROUTES[routeKey].BOOKINGS);
  };

  const viewAllTransactions = () => {
    history.push(APP_USER_ROUTES[routeKey].PAYMENTS);
  };

  const handleChangeTimeDuration = (value) => {
    setDuration(value);
  };

  return (
    <div>
      <Grid container>
        {(routeKey === PLATFORM_USERS.ADMIN ||
          routeKey === PLATFORM_USERS.CLIENT) && (
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="Open Bookings"
                icon={<PendingIcon className="p-1" />}
                total={bookingDetail.unconfirmed}
                colorClass="bg-brand-light-orange"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].BOOKINGS,
                    search: `?bookings=${BOOKING_STATUS_CONSTANTS.unconfirmed}`,
                  })
                }
            />
            </Grid>
        )}
        {(routeKey === PLATFORM_USERS.VENDOR ||
          routeKey === PLATFORM_USERS.VENDOR_BRANCH ||
          routeKey === PLATFORM_USERS.ADMIN) && (
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="Pending Requests"
                icon={<PendingIcon className="p-1" />}
                total={bookingDetail?.pending}
                colorClass="bg-brand-light-orange"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].BOOKINGS_REQUESTS,
                    search: `?bookingsreq=${BOOKING_STATUS_CONSTANTS.pending}`,
                  })
                }
              />
            </Grid>
          )}
        <Grid item lg={3} className={" pb-8 pr-8"}>
          <StatusCard
            statusText="Confirmed Bookings"
            icon={<EventConfirmIcon />}
            total={bookingDetail.confirmed}
            colorClass="bg-brand-light-green"
            onClick={() =>
              history.push({
                pathname: APP_USER_ROUTES[routeKey].BOOKINGS,
                search: `?bookings=${BOOKING_STATUS_CONSTANTS.confirmed}`,
              })
            }
          />
        </Grid>
        <Grid item lg={3} className={" pb-8 pr-8"}>
          <StatusCard
            statusText="Live Bookings"
            icon={<LiveIcon />}
            total={bookingDetail.live}
            colorClass="bg-brand-light-blue"
            onClick={() =>
              history.push({
                pathname: APP_USER_ROUTES[routeKey].BOOKINGS,
                search: `?bookings=${BOOKING_STATUS_CONSTANTS.awaiting_fulfillment}`,
              })
            }
          />
        </Grid>
        <Grid item lg={3} className={" pb-8 pr-8"}>
          <StatusCard
            statusText="Completed Bookings"
            icon={<InfoPurpleIcon className="p-1 w-8 h-8" />}
            total={bookingDetail.completed}
            colorClass="bg-brand-disabled-purple"
            onClick={() =>
              history.push({
                pathname: APP_USER_ROUTES[routeKey].BOOKINGS,
                search: `?bookings=${BOOKING_STATUS_CONSTANTS.completed}`,
              })
            }
          />
        </Grid>
        {/* <Grid item lg={3} className={' pb-8 pr-8'}>
          <StatusCard statusText="Cancelled Bookings" icon={<EventCancelIcon/>} total={getBookingsWithStatus(BOOKING_STATUS_CONSTANTS.canceled)} colorClass="bg-brand-light-red"/>
        </Grid> */}
        {(routeKey === PLATFORM_USERS.CLIENT) && (
            <>
              <Grid item lg={3} className={" pb-8 pr-8"} >
                <ComingSoonCard
                  statusText="Loading Quantity"
                  icon={<WeightScale className="p-1 w-8 h-8" />}
                  total={"-"}
                  colorClass="bg-brand-light-blue"
                />
              </Grid>
              <Grid item lg={3} className={" pb-8 pr-8"} >
                <ComingSoonCard
                  statusText="UnLoading Quantity"
                  icon={<WeightScale className="p-1 w-8 h-8" />}
                  total={"-"}
                  colorClass="bg-brand-light-blue"
                />
              </Grid>
              <Grid item lg={3} className={" pb-8 pr-8"} >
                <ComingSoonCard
                  statusText="In Transit Quantity"
                  icon={<WeightScale className="p-1 w-8 h-8" />}
                  total={"-"}
                  colorClass="bg-brand-light-blue"
                />
              </Grid>
            </>
        )}
        {routeKey === PLATFORM_USERS.ADMIN && (
          <Grid item lg={3} className={" pb-8 pr-8"}>
            <StatusCard
              statusText="Cancelled Bookings"
              icon={<InfoRedIcon className="p-1 w-8 h-8 font-brand-red" />}
              total={bookingDetail.cancelled}
              colorClass="bg-brand-light-red"
              onClick={() =>
                history.push({
                  pathname: APP_USER_ROUTES[routeKey].BOOKINGS,
                  search: `?bookings=${BOOKING_STATUS_CONSTANTS.cancelled}`,
                })
              }
            />
          </Grid>
        )}
        {routeKey === PLATFORM_USERS.ADMIN && (
          <>
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="No of Clients"
                icon={<UserIcon className="p-1 w-8 h-8" />}
                total={counts.client}
                colorClass="bg-brand-light-blue"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].CLIENTS,
                  })
                }
              />
            </Grid>
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="No of Vendors"
                icon={<UserIcon className="p-1 w-8 h-8" />}
                total={counts.vendor}
                colorClass="bg-brand-light-blue"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].VENDORS,
                  })
                }
              />
            </Grid>
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="No of Branches"
                icon={
                  <FI_MapIcon
                    style={{ paddingLeft: "8px", paddingTop: "8px" }}
                  />
                }
                total={counts.branch}
                colorClass="bg-brand-light-orange"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].BRANCHES,
                  })
                }
              />
              </Grid>
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="No of Pincodes"
                icon={
                  <MappinIcon
                  className=" stroke-brand-primary-green rounded-lg w-8 h-8 p-1.5"
                    style={{ }}
                  />
                }
                total={counts.pincodes}
                colorClass="bg-brand-light-green"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].PINCODES,
                  })
                }
              />
            </Grid>
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="No of States"
                icon={
                  <MappinIcon
                  className=" stroke-brand-primary-green rounded-lg w-8 h-8 p-1.5"
                    style={{ }}
                  />
                }
                total={counts.states}
                colorClass="bg-brand-light-green"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].STATES,
                  })
                }
              />
            </Grid>
            <Grid item lg={3} className={" pb-8 pr-8"}>
              <StatusCard
                statusText="No of Cities"
                icon={
                  <MappinIcon
                  className=" stroke-brand-primary-green rounded-lg w-8 h-8 p-1.5"
                    style={{ }}
                  />
                }
                total={counts.cities}
                colorClass="bg-brand-light-green"
                onClick={() =>
                  history.push({
                    pathname: APP_USER_ROUTES[routeKey].CITIES,
                  })
                }
              />
            </Grid>
          </>
        )}
        {(routeKey === PLATFORM_USERS.VENDOR) && (
            <>
              <Grid item lg={3} className={" pb-8 pr-8"}>
                <StatusCard
                  statusText="No of Branches"
                  icon={
                    <FI_MapIcon
                      style={{ paddingLeft: "8px", paddingTop: "8px" }}
                    />
                  }
                  total={counts.branch}
                  colorClass="bg-brand-light-orange"
                  onClick={() =>
                    history.push({
                      pathname: APP_USER_ROUTES[routeKey].BRANCHES,
                    })
                  }
                />
              </Grid>
            </>
          )}
        {(routeKey === PLATFORM_USERS.VENDOR ||
          routeKey === PLATFORM_USERS.VENDOR_BRANCH) && (
            <>
              <Grid item lg={3} className={" pb-8 pr-8"}>
                <StatusCard
                  statusText="No of Trucks"
                  icon={
                    <FI_TruckIcon
                      style={{ paddingLeft: "8px", paddingTop: "8px" }}
                    />
                  }
                  total={counts.truck}
                  colorClass="bg-brand-light-blue"
                  onClick={() =>
                    history.push({
                      pathname: APP_USER_ROUTES[routeKey].FLEETS,
                    })
                  }
                />
              </Grid>
              <Grid item lg={3} className={" pb-8 pr-8"}>
                <StatusCard
                  statusText="Total Revenue"
                  icon={<SavingIcon />}
                  total={"₹" + bookingDetail.revenue}
                  colorClass="bg-brand-disabled-purple"
                />
              </Grid>
              <Grid item lg={3} className={" pb-8 pr-8"} >
                <ComingSoonCard
                  statusText="Loading Quantity"
                  icon={<WeightScale className="p-1 w-8 h-8" />}
                  total={"-"}
                  colorClass="bg-brand-light-blue"
                />
              </Grid>
              <Grid item lg={3} className={" pb-8 pr-8"} >
                <ComingSoonCard
                  statusText="UnLoading Quantity"
                  icon={<WeightScale className="p-1 w-8 h-8" />}
                  total={"-"}
                  colorClass="bg-brand-light-blue"
                />
              </Grid>
              <Grid item lg={3} className={" pb-8 pr-8"} >
                <ComingSoonCard
                  statusText="In Transit Quantity"
                  icon={<WeightScale className="p-1 w-8 h-8" />}
                  total={"-"}
                  colorClass="bg-brand-light-blue"
                />
              </Grid>
            </>
          )}
      </Grid>
      <Grid container>
        <Grid lg={8} md={12} xs={12} marginBottom="2rem">
          <Paper
            className={classes.boxShadowNone + " " + classes.p1t5}
            sx={{
              "&": {
                overflowX: "auto",
              },
            }}
          >
            <Grid container marginBottom={"1.5rem"}>
              <Grid
                item
                lg={9}
                md={8}
                xs={12}
                display="flex"
                alignItems="center"
              >
                <p className="text-xl font-bold text-brand-text-title">
                  Total Bookings
                </p>
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <BasicSelect
                  options={timeDurationForGraphChnage}
                  onChange={(e) => handleChangeTimeDuration(e.target.value)}
                  value={duration}
                />
              </Grid>
            </Grid>
            {/* Chart Renders here.......................... */}
            <Chart width={680} height={420} data={chartData} />
            {/* Chart ...................................... */}
          </Paper>
        </Grid>
        <Grid lg={4} md={12} xs={12} className="md:pl-8">
          <Paper
            className={classes.boxShadowNone + " " + classes.p1t5}
            sx={{
              "&": {
                overflowX: "auto",
              },
            }}
          >
            <div className="flex justify-between items-center">
              <p className="text-xl font-bold text-brand-text-title">
                Latest Bookings
              </p>
              <p
                className="font-base font-bold text-brand-primary-blue cursor-pointer"
                onClick={viewAllBookings}
              >
                View All
              </p>
            </div>
            <div>
              {bookingData?.slice(0, 7)?.map((item) => (
                <ListCard
                  icon={<CalenderIcon />}
                  primaryText={
                    <p className="text-brand-primary-blue">{item.booking_id}</p>
                  }
                  secondaryText={
                    <p className="text-brand-text-title flex items-center">
                      <span
                        style={{ fontSize: "10px", width: "40%" }}
                        className="truncate w-32"
                        title={item.pickup_at}
                      >
                        {moment.utc(item.pickup_at).format(TIME_FORMAT_STRING)}{" "}
                      </span>{" "}
                      <div className="w-1 h-1 rounded-full bg-brand-text-title mx-2"></div>{" "}
                      {item.type}
                    </p>
                  }
                />
              ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
      {transactionData && (
        <Grid container>
          <Grid item lg={12} xs={12} marginTop="1rem">
            <Paper
              className={classes.boxShadowNone + " " + classes.p1t5}
              sx={{
                "&": {
                  overflowX: "auto",
                },
              }}
            >
              <div className="flex justify-between items-center">
                <p className="text-xl font-bold text-brand-text-title">
                  Latest Transactions
                </p>
                <p
                  className="font-base font-bold text-brand-primary-blue cursor-pointer"
                  onClick={viewAllTransactions}
                >
                  View All
                </p>
              </div>
              <div className="mt-6">
                <EnhancedTable columns={[]} rows={[]} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
