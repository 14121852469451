import { ADMIN_APPROVE_CLIENT, ADMIN_APPROVE_VENDOR, GET_ALL_CITIES, GET_ALL_CLIENTS, GET_ALL_PINCODES, GET_ALL_STATES, GET_ALL_VENDORS, GET_USERS, GET_USER_PROFILE, GET_USER_PROFILE_ERROR, GET_USER_PROFILE_SUCCESS, SET_ADMIN_APPROVE_CLIENT, SET_ADMIN_APPROVE_VENDOR, SET_ALL_CITIES, SET_ALL_CLIENTS, SET_ALL_PINCODES, SET_ALL_STATES, SET_ALL_VENDORS, SET_USERS, SET_USER_DASHBOARD_STATS, UPDATE_USER_PROFILE, UPDATE_USER_PROFILE_ERROR, UPDATE_USER_PROFILE_SUCCESS, USER_DASHBOARD_STATS, USER_DEFAULT_ACTIONS } from "../ReduxConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState{
    default :SubstateObject;
    userProfile :SubstateObject;
    updateUserProfile : SubstateObject;
    allClients : SubstateObject;
    allVendors : SubstateObject;
    allStates : SubstateObject;
    allCities : SubstateObject;
    allPincodes : SubstateObject;
    user : SubstateObject;
    approve : SubstateObject;
    dashboardStats : SubstateObject;
}

const initialState:InitialState = {
    default :{
        data : null,
        status : null,
    },
    userProfile :{
        data: null,
        status : null,
    },
    updateUserProfile : {
        data : null,
        status : null,
    },
    allClients : {
      data: null,
      status: null
    },
    allVendors : {
      data: null,
      status: null
    },
    allStates : {
      data: null,
      status: null
    },
    allCities : {
      data: null,
      status: null
    },
    allPincodes : {
      data: null,
      status: null
    },
    user : {
      data: null,
      status: null
    },
    approve : {
      data: null,
      status: null
    },
    dashboardStats : {
      data: null,
      status: null
    },

}

const UserReducer = ( state=initialState, { type, payload }) => {
    switch(type){
        case USER_DEFAULT_ACTIONS : {
            return {type , payload};
        }
        case GET_USER_PROFILE: {
            return {
              ...initialState,
              userProfile: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case GET_USER_PROFILE_SUCCESS: {
            return {
              ...initialState,
              userProfile: {
                data: payload,
                status: API_CONSTANTS.success,
              },
            };
          }
          case GET_USER_PROFILE_ERROR: {
            return {
              ...initialState,
              userProfile: {
                data: payload,
                status: API_CONSTANTS.error,
              },
            };
          }
        case UPDATE_USER_PROFILE: {
            return {
              ...initialState,
              updateUserProfile: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case UPDATE_USER_PROFILE_SUCCESS: {
            return {
              ...initialState,
              updateUserProfile: {
                data: payload,
                status: API_CONSTANTS.success,
              },
              userProfile :{
                data: payload,
                status: API_CONSTANTS.success,
              }
            };
          }
          case UPDATE_USER_PROFILE_ERROR: {
            return {
              ...initialState,
              updateUserProfile: {
                data: payload,
                status: API_CONSTANTS.error,
              },
            };
          }
          case GET_ALL_CLIENTS: {
            return {
              ...initialState,
              allClients: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ALL_CLIENTS: {
            return {
              ...initialState,
              allClients: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case GET_ALL_VENDORS: {
            return {
              ...initialState,
              allVendors: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ALL_VENDORS: {
            return {
              ...initialState,
              allVendors: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          // States
          case GET_ALL_STATES: {
            return {
              ...initialState,
              allStates: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ALL_STATES: {
            return {
              ...initialState,
              allStates: {
                data: payload.data,
                status: payload.status,
              },
            };
          }

          // Cities
          case GET_ALL_CITIES: {
            return {
              ...initialState,
              allCities: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ALL_CITIES: {
            return {
              ...initialState,
              allCities: {
                data: payload.data,
                status: payload.status,
              },
            };
          }

          // Pincodes
          case GET_ALL_PINCODES: {
            return {
              ...initialState,
              allPincodes: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ALL_PINCODES: {
            return {
              ...initialState,
              allPincodes: {
                data: payload.data,
                status: payload.status,
              },
            };
          }

          case GET_USERS: {
            return {
              ...initialState,
              user: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_USERS: {
            return {
              ...initialState,
              user: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case ADMIN_APPROVE_VENDOR: {
            return {
              ...initialState,
              approve: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ADMIN_APPROVE_VENDOR: {
            return {
              ...initialState,
              approve: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case ADMIN_APPROVE_CLIENT: {
            return {
              ...initialState,
              approve: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_ADMIN_APPROVE_CLIENT: {
            return {
              ...initialState,
              approve: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
          case USER_DASHBOARD_STATS: {
            return {
              ...initialState,
              dashboardStats: {
                data: null,
                status: API_CONSTANTS.loading,
              },
            };
          }
          case SET_USER_DASHBOARD_STATS: {
            return {
              ...initialState,
              dashboardStats: {
                data: payload.data,
                status: payload.status,
              },
            };
          }
        default :
            return {
                ...state
            }
    }
}

export default UserReducer;