import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import VendorBookings from '../../components/role/vendor/Bookings';
import VendorPayments from '../../components/role/vendor/Payments';
import ClientBookings from '../../components/role/client/Bookings';
import ClientPayemnts from '../../components/role/client/Payments';
import { PLATFORM_USERS } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';
import ShowVendorsComponent from '../../components/role/admin/ShowVendors';

interface Props{

  userProfileData ?: UserObject;

}

const ShowVendors: React.FC<Props> = (props) => {
  const { userProfileData } = props;
  
  return (
    <>
      {
        userProfileData.role === PLATFORM_USERS.ADMIN &&
        <>        
          <ShowVendorsComponent {...props}/>
        </>
      }
    </>
  )
}

export default ShowVendors