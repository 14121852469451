import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import PageTitle from "../../../Typography/PageTitle";
import Input from "../../../Input/MaterialInput";
import CommonTextarea from "../../../TextArea";
import CommonInputGroup from "../../../Input/InputGroup";
import Button from "../../../Button";
import { addVendorsFleet, editVendorsFleet, getVendorFleetBasedId } from "../../../../ReduxActions";
import { handleCheckValidation } from "../../../../ValidationHelpers";
import { useDispatch, useSelector } from "react-redux";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, PLATFORM_USERS } from "../../../../utils/constants";
import toast from 'react-hot-toast';
import { useLocation, useParams } from "react-router-dom";
import { LoadOptions, LoadOptionsForWeight, OwnershipArray } from "../../../../utils/ObjectTypes";
import { useCommonStyles } from "../../../../styles/common";
import BasicSelect from "../../../Dropdown/SimpleSelect";

interface Props {
  routeKey: string;
  history ?: any;
}

interface TruckDetailObject {
  truck_no?: string;
  load_capacity?: number;
  load_capacity_unit?: string;
  remarks?: string;
  owner_name ?: string,
  contact_no ?: string,
  ownership ?: string,
}

interface TruckDetailValidationObject {
  truck_no?: string;
  load_capacity?: string;
  load_capacity_unit?: string;
  remarks?: string;
  owner_name ?: string,
  contact_no ?: string,
  ownership ?: string,
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AddEditTrucks: React.FC<Props> = (props) => {
  // classes for materila Ui
  const classes = useCommonStyles()
  // param id for edit truck
  const { id } = useParams<{id : string}>();
  
  const { routeKey, history } = props;
  const dispatch = useDispatch();
  const addTruckSelector = useSelector(
    (state: any) => state.FleetReducer.addVendorFleet
  );
  const getTruckSelector = useSelector(
    (state: any) => state.FleetReducer.getVendorFleet
  );
  const editTruckSelector = useSelector(
    (state: any) => state.FleetReducer.editVendorFleet
  );

  let urlParams: any = useQuery();
  const query_vendor_id = urlParams.get("vendor_id");

  const [truckDetails, setTruckDetails] = useState<TruckDetailObject>({
    truck_no: "",
    load_capacity: 0,
    load_capacity_unit: "tonne",
    owner_name : "",
    contact_no : "",
    ownership  : "owned",
    remarks: "",
  });
  const [validationObj, setvalidationObj] = useState<TruckDetailValidationObject>({});
  const [loading, setLoading] = useState({
    add: false,
    edit: false,
  });

  useEffect(() => {
    if(id){
      dispatch(
        getVendorFleetBasedId({
          id : id,
          user : routeKey,
        })
      )
    }else{
      setTruckDetails({
        owner_name : "",
        contact_no : "",
        ownership  : "owned",
        truck_no: "",
        load_capacity: 0,
        load_capacity_unit: "tonne",
        remarks: "",
      })
    }
  }, [])

  useEffect(() => {
    let toastId:any;
    if (getTruckSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, edit: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (getTruckSelector.status === API_CONSTANTS.success && loading.edit) {
      setLoading({ ...loading, edit: false });
      toast.dismiss(toastId);
      setTruckDetails(getTruckSelector.data);
    }
    if (getTruckSelector.status === API_CONSTANTS.error && loading.edit) {
      setLoading({ ...loading, edit: false });
      toast.dismiss(toastId);
      toast.error(getTruckSelector.data || DEFAULT_ERROR_MESSAGE.error1)
    }
  }, [getTruckSelector]);
  

  const handleInputChange = (key, value) => {
    //if(key==='truck_no' && value.length>12) return setvalidationObj({...validationObj, [key]: 'only 12 characters allowed'}) ;
    setTruckDetails({
      ...truckDetails,
      [key]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation: TruckDetailValidationObject = handleCheckValidation(truckDetails);
    setvalidationObj(validation);
    if (
      validation &&
      !validation.truck_no &&
      !validation.load_capacity &&
      !validation.load_capacity_unit &&
      !validation.owner_name &&
      !validation.contact_no &&
      !validation.ownership
    ) {

      let bodyParams:any = {
        ...truckDetails,
        load_capacity: Number(truckDetails.load_capacity),
      };
      // Check for admin and vendorid
      if(routeKey === PLATFORM_USERS.ADMIN && query_vendor_id) {
        bodyParams.vendor_id = query_vendor_id; 
      }

      if(id){
        dispatch(
          editVendorsFleet({
            body: bodyParams,
            id : id,
            user: routeKey,
          })
        );
      }else{
        dispatch(
          addVendorsFleet({
            body: bodyParams,
            user: routeKey,
          })
        );
      }
    }
  };

  useEffect(() => {
    let toastId:any;
    if (editTruckSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, add: true });
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (editTruckSelector.status === API_CONSTANTS.success && loading.add) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      setLoading({ ...loading, add: false });
    }
    if (editTruckSelector.status === API_CONSTANTS.error && loading.add) {
      setLoading({ ...loading, add: false });
      toast.dismiss(toastId);
      toast.error(editTruckSelector.data);
    }
  }, [editTruckSelector]);

  useEffect(() => {
    let toastId:any;
    if (addTruckSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, add: true });
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (addTruckSelector.status === API_CONSTANTS.success && loading.add) {
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      setLoading({ ...loading, add: false });
      if(routeKey === PLATFORM_USERS.ADMIN && query_vendor_id) {
        history.push(APP_USER_ROUTES[routeKey].SHOW_VENDORS_ALIAS + '/' + query_vendor_id + '?tab=fleets');
      }
      else {
        history.push(APP_USER_ROUTES[routeKey].FLEETS);
      }
    }
    if (addTruckSelector.status === API_CONSTANTS.error && loading.add) {
      setLoading({ ...loading, add: false });
      toast.dismiss(toastId);
      toast.error(addTruckSelector.data);
    }
  }, [addTruckSelector]);

  return (
    <div>
      <PageTitle>{id? 'Edit Truck' :'Add Truck'}</PageTitle>
      <Paper className={classes.p2 + " " + classes.boxShadowNone}>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={6} xs={12} className="md:pr-6">
              <Input
                label="Truck No"
                value={truckDetails.truck_no}
                onChange={(e) => handleInputChange("truck_no", e.target.value)}
                validationKey={validationObj.truck_no}
              />
            </Grid>
            <Grid item lg={6} xs={12} className="">
              <CommonInputGroup
                label="Load Capacity"
                value={truckDetails.load_capacity}
                type="number"
                onChange={(e) =>
                  handleInputChange("load_capacity", e.target.value)
                }
                validationKey={(validationObj.load_capacity)}
                selectValue={truckDetails.load_capacity_unit}
                onChangeSelect={(e) =>
                  handleInputChange("load_capacity_unit", e.target.value)
                }
                options={LoadOptionsForWeight}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12} className="md:pr-6">
              <Input
                label="Emergency Contact Name"
                value={truckDetails.owner_name}
                onChange={(e) => handleInputChange("owner_name", e.target.value)}
                validationKey={validationObj.owner_name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12} className="md:pr-6">
              <Input
                label="Emergency Phone Number"
                value={truckDetails.contact_no}
                onChange={(e) => handleInputChange("contact_no", e.target.value)}
                validationKey={validationObj.contact_no}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12} className="">
              <BasicSelect
                options={OwnershipArray}
                label="Ownership"
                value={truckDetails.ownership}
                onChange={(e) => handleInputChange("ownership", e.target.value)}
                validationKey={validationObj.ownership}
              />
            </Grid>
            <Grid item lg={12} xs={12} className="">
              <CommonTextarea
                label="Remarks"
                value={truckDetails.remarks}
                onChange={(e) => handleInputChange("remarks", e.target.value)}
              />
            </Grid>
            <Grid item lg={1} md={2} xs={12} marginTop="1rem">
              <Button text={id ? "Save" :"Add"} type="submit" />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default AddEditTrucks;
