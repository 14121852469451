export const USER_DEFAULT_ACTIONS = 'app/user/defaultAction';

export const GET_USER_PROFILE = 'app/user/getuserprofile';
export const GET_USER_PROFILE_SUCCESS = 'app/user/getuserprofilesuccess';
export const GET_USER_PROFILE_ERROR = 'app/user/getuserprofileerror';

export const UPDATE_USER_PROFILE = 'app/user/updateuserProfile';
export const UPDATE_USER_PROFILE_SUCCESS = 'app/user/updateuserProfileSuccess';
export const UPDATE_USER_PROFILE_ERROR = 'app/user/updateuserProfileError';

export const GET_ALL_CLIENTS = 'app/user/getAllClients';
export const SET_ALL_CLIENTS = 'app/user/setAllClients';

export const GET_ALL_VENDORS = 'app/user/getAllVendors';
export const SET_ALL_VENDORS = 'app/user/setAllVendors';

export const GET_USERS = 'app/user/get';
export const SET_USERS = 'app/user/set';

export const ADMIN_APPROVE_VENDOR = 'admin/approve/vendor';
export const SET_ADMIN_APPROVE_VENDOR = 'setAdmin/approve/vendor';

export const ADMIN_APPROVE_CLIENT = 'admin/approve/client';
export const SET_ADMIN_APPROVE_CLIENT = 'setAdmin/approve/client';

export const USER_DASHBOARD_STATS = 'app/user/dashboardStats';
export const SET_USER_DASHBOARD_STATS = 'app/user/setdashboardStats';

export const GET_ALL_STATES = 'app/admin/getAllStates';
export const SET_ALL_STATES = 'app/admin/setAllStates';

export const GET_ALL_CITIES = 'app/admin/getAllCities';
export const SET_ALL_CITIES = 'app/admin/setAllCities';

export const GET_ALL_PINCODES = 'app/admin/getAllPincodes';
export const SET_ALL_PINCODES = 'app/admin/setAllPincodes';