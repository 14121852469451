import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBookingbyId } from "../../../../ReduxActions";
import {
  API_CONSTANTS,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
} from "../../../../utils/constants";
import Loader from "../../../Loader";
import ViewBookingDetails from "./ViewBookingDetails";

interface Props {
  routeKey?: string;
}

const BookingDetails = (props) => {
  // props destruturing
  const { routeKey } = props;
  const { id } = useParams<{ id: string }>();

  // dispatch action
  const dispatch = useDispatch();
  //selectors
  const bookingDataSelector = useSelector(
    (state: any) => state.BookingReducer.clientBookingDetails
  );

  const [bookingData, setbookingData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    dispatch(getBookingbyId({ user: routeKey, id: id }));
  }, []);

  useEffect(() => {
    let toastId: any;
    if (bookingDataSelector.status === API_CONSTANTS.loading) {
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (bookingDataSelector.status === API_CONSTANTS.success && loading) {
      setLoading(false);
      setbookingData(bookingDataSelector.data);
      toast.dismiss(toastId);
    }
    if (bookingDataSelector.status === API_CONSTANTS.error && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(bookingDataSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [bookingDataSelector]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <ViewBookingDetails {...props} bookingData={bookingData} />
      )}
    </div>
  );
};

export default BookingDetails;
