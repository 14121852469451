import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";

/**
 * Payment Service For Api Requests
 */
export const PaymentService = {

    getAll :async (data : { user : string, page?:number, limit?: number}) =>{
        const request = { data }
        const result =await axiosInstance.get(API_URLS[data.user].allPayments+`?page=${data?.page}&limit=${data?.limit}`);
        return result
    },
    getById :async (data : { user : string, id: string }) =>{
        const request = { data }
        const result =await axiosInstance.get(API_URLS[data.user].getPaymentbyId(data?.id));
        return result
    },
    remove :async (data : { user : string, id: string }) =>{
        const request = { data }
        const result =await axiosInstance.delete(API_URLS[data.user].deletePayment(data?.id));
        return result
    },
    create :async (data : { user : string, body : any }) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].addPayment, request);
        return result
    },
    edit :async (data : { user : string, body: any, id: string }) =>{
        const request = { ...data.body }
        const result =await axiosInstance.put(API_URLS[data.user].editPayment(data?.id), request);
        return result
    },
    bookingById :async (data : { user : string, id: string }) =>{
        const result =await axiosInstance.get(API_URLS[data.user].bookingwithPaymentId(data?.id));
        return result
    },
    getAllFleetsForVendor: async(data:{ user: string, vendor_id?: string, branch_id?: string}) =>{
        if(data?.branch_id) return axiosInstance.get(API_URLS[data.user].getBranchPayments(data?.branch_id))
        const result = await axiosInstance.get(API_URLS[data.user].getVendorPayments(data?.vendor_id));
        return result
    },

}