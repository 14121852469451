import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { IUpdateUserObject } from "../utils/ObjectTypes";

interface IZoneObject{
    user : string,
    id   : string,
    body ?: object, 
    page?:number, limit?: number
}

export const ZoneService = {

    getAllZones :async (data : IZoneObject) =>{
        const request = { data }
        const result =await axiosInstance.get(API_URLS[data.user].getAllZones+`?page=${data?.page}&limit=${data?.limit}`);
        return result
    },

    getZones :async (data : IZoneObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.get(API_URLS[data.user].getIdBasedZone(data.id))
        return result
    },

    addZone :async (data : IZoneObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].addNewZone, request)
        return result
    },

    editZones :async (data : IZoneObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.put(API_URLS[data.user].editZone(data.id), request)
        return result
    },

    deleteZone :async (data : IZoneObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.delete(API_URLS[data.user].deleteZone(data.id))
        return result
    },
}