import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  GET_ALL_VENDOR_FLEETS,
  GET_ALL_VENDOR_FLEETS_SUCCESS,
  GET_ALL_VENDOR_FLEETS_ERROR,
  GET_VENDOR_FLEET,
  GET_VENDOR_FLEET_SUCCESS,
  GET_VENDOR_FLEET_ERROR,
  ADD_VENDOR_FLEET,
  ADD_VENDOR_FLEET_SUCCESS,
  ADD_VENDOR_FLEET_ERROR,
  EDIT_VENDOR_FLEET,
  EDIT_VENDOR_FLEET_SUCCESS,
  EDIT_VENDOR_FLEET_ERROR,
  DELETE_VENDOR_FLEET,
  DELETE_VENDOR_FLEET_SUCCESS,
  DELETE_VENDOR_FLEET_ERROR,
  GET_VENDOR_FLEETS,
  SET_VENDOR_FLEETS,
  GET_TRUCK_COUNT,
  SET_TRUCK_COUNT
} from "../ReduxConstants";
import { UserService } from "../sevices/user.service";
import { IUpdateUserObject } from "../utils/ObjectTypes";
import { FleetService } from "../sevices";
import { structuredFleetSchema } from "./Schema.tsx/FleetsSchema";
import { modifySchema } from "./Schema.tsx";
import { API_CONSTANTS, PLATFORM_USERS } from "../utils/constants";

const getAllVendorFleets = (payload: object) => ({
  type: GET_ALL_VENDOR_FLEETS,
  payload: payload,
});

const getAllVendorFleetsSuccess = (payload: object) => ({
  type: GET_ALL_VENDOR_FLEETS_SUCCESS,
  payload: payload,
});

const getAllVendorFleetsError = (payload: object) => ({
  type: GET_ALL_VENDOR_FLEETS_ERROR,
  payload: payload,
});

const getVendorFleet = (payload: object) => ({
  type: GET_VENDOR_FLEET,
  payload: payload,
});

const getVendorFleetSuccess = (payload: object) => ({
  type: GET_VENDOR_FLEET_SUCCESS,
  payload: payload,
});

const getVendorFleetError = (payload: object) => ({
  type: GET_VENDOR_FLEET_ERROR,
  payload: payload,
});

const addVendorFleet = (payload: object) => ({
  type: ADD_VENDOR_FLEET,
  payload: payload,
});

const addVendorFleetSuccess = (payload: object) => ({
  type: ADD_VENDOR_FLEET_SUCCESS,
  payload: payload,
});

const addVendorFleetError = (payload: object) => ({
  type: ADD_VENDOR_FLEET_ERROR,
  payload: payload,
});

const editVendorFleet = (payload: object) => ({
  type: EDIT_VENDOR_FLEET,
  payload: payload,
});

const editVendorFleetSuccess = (payload: object) => ({
  type: EDIT_VENDOR_FLEET_SUCCESS,
  payload: payload,
});

const editVendorFleetError = (payload: object) => ({
  type: EDIT_VENDOR_FLEET_ERROR,
  payload: payload,
});

const deleteVendorFleet = (payload: object) => ({
  type: DELETE_VENDOR_FLEET,
  payload: payload,
});

const deleteVendorFleetSuccess = (payload: object) => ({
  type: DELETE_VENDOR_FLEET_SUCCESS,
  payload: payload,
});

const deleteVendorFleetError = (payload: object) => ({
  type: DELETE_VENDOR_FLEET_ERROR,
  payload: payload,
});

const vendorFleets = (payload: object) => ({
  type: GET_VENDOR_FLEETS,
  payload: payload,
});

const setVendorFleets = (payload: object) => ({
  type: SET_VENDOR_FLEETS,
  payload: payload,
});

const getTruckCounts = (payload: object) => ({
  type: GET_TRUCK_COUNT,
  payload: payload,
});

const setTruckCounts = (payload: object) => ({
  type: SET_TRUCK_COUNT,
  payload: payload,
});

export const allVendorFleets = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllVendorFleets(data));
    // Handling for admin
    if(data?.vendor_id && data?.user === PLATFORM_USERS.ADMIN) {
      await FleetService.getAllFleetsForVendor(data)
      .then((result) => {
        dispatch(getAllVendorFleetsSuccess(structuredFleetSchema(result)));
      })
      .catch((error) => {
        dispatch(getAllVendorFleetsError(error));
      });
    }
    else {
      await FleetService.getAllFleets(data)
      .then((result) => {
        dispatch(getAllVendorFleetsSuccess(structuredFleetSchema(result)));
      })
      .catch((error) => {
        dispatch(getAllVendorFleetsError(error));
      });
    }
  };
};

export const getVendorFleetBasedId = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getVendorFleet(data));
    await FleetService.getIdBasedFleet(data)
      .then((result) => {
        dispatch(getVendorFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(getVendorFleetError(error));
      });
  };
};

export const addVendorsFleet = (data) => {
  console.log("rrrr")
  console.log(data)
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(addVendorFleet(data));
    await FleetService.addVendorFleet(data)
      .then((result) => {
        dispatch(addVendorFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(addVendorFleetError(error));
      });
  };
};

export const editVendorsFleet = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(editVendorFleet(data));
    await FleetService.editVendorFleet(data)
      .then((result) => {
        dispatch(editVendorFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(editVendorFleetError(error));
      });
  };
};

export const deleteVendorsFleet = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(deleteVendorFleet(data));
    await FleetService.deleteVendorFleet(data)
      .then((result) => {
        dispatch(deleteVendorFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteVendorFleetError(error));
      });
  };
};

export const fetchAllVendorRelatedFleets = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(vendorFleets(data));
    await FleetService.getAllFleetsForVendor(data)
    .then((result) => {
      dispatch(
        setVendorFleets(
          modifySchema(structuredFleetSchema(result), API_CONSTANTS.success)
        )
      );
    })
    .catch((error) => {
      dispatch(
        setVendorFleets(
          modifySchema(error, API_CONSTANTS.error)
        )
      );
    });
  };
};

export const fetchTruckCount = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getTruckCounts(data));
    await FleetService.getTruckCount(data)
    .then((result) => {
      dispatch(
        setTruckCounts(
          modifySchema(result, API_CONSTANTS.success)
        )
      );
    })
    .catch((error) => {
      dispatch(
        setTruckCounts(
          modifySchema(error, API_CONSTANTS.error)
        )
      );
    });
  };
};
