import { Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentIcon, CalenderIcon } from '../../../../icons';
import { fetchAllClientBookings } from '../../../../ReduxActions';
import { useCommonStyles } from '../../../../styles/common';
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT } from '../../../../utils/constants';
import CommonButton from '../../../Button';
import SelectModal from '../Bookings/SelectModal';
import DashboardComponent from './DashBoardComponent';

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}
/**
 * 
 * @param props { key, match, routeKey, history }
 * @returns 
 */
const DashboardClient: React.FC<Props> = (props) => {
  // props
  const { key, match, routeKey, history } = props;
  const classes = useCommonStyles();
  const param = routeKey;
  // dispatch
  const dispatch = useDispatch();
  // selectors 
  const allBookingSelector = useSelector((state:any)=>state.BookingReducer.allClientBookings);
  const [allBookings, setAllBookings] = useState([]);
  const [loading, setLoading] = useState({
    get : false,
  });
  const [openSelectmodal, setOpenSelectmodal] = useState(false);

  useEffect(() => {
    dispatch(fetchAllClientBookings({ user : param}));
  }, [])

  useEffect(() => {
    let toastId : any;
    if(allBookingSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, get:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allBookingSelector.status === API_CONSTANTS.success && loading.get){
      setLoading({...loading, get:false});
      setAllBookings([...allBookingSelector.data?.data?.map((items)=>{
        items.booking_id = <p onClick={()=>viewBookingDetails(items.id)} className="text-brand-primary-blue cursor-pointer">{items.booking_id}</p>
        return items
      })]);
      toast.dismiss(toastId);
    }
    if(allBookingSelector.status === API_CONSTANTS.error && loading.get){
      setLoading({...loading, get:false});
      toast.dismiss(toastId);
      toast.error(allBookingSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allBookingSelector])

    // view booking details
    const viewBookingDetails = (id) => {
      history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS+'/'+id)
    }

    const onClickCards = (key) => {
      history.push(APP_USER_ROUTES[param].BOOKING_CREATE_ALIAS(key));
    }
  
  return (
    <>
      <SelectModal
        open={openSelectmodal}
        setOpen={setOpenSelectmodal}
        onClickCards={onClickCards}
      />
      <Paper className={classes.boxShadowNone+' '+classes.p1t5+' '+ 'mb-8'}>
        <Grid container>
          <Grid container item lg={10} md={12} xs={12} className="flex justify-start items-center">
            <Grid item lg={1} md={1} xs={12}>
              <AppointmentIcon className='bg-brand-extra-background w-12 h-12 rounded-lg mr-4 p-3'/>
            </Grid>
            <Grid item lg={11} md={11} xs={12}>
              <h5 className='text-brand-text-title text-xl font-bold'>Add new Booking</h5>
              <p className='text-base font-light text-brand-text-caption'>Want to transport your cargo safely all over India? Create your booking with Transportganj now</p>
            </Grid>
          </Grid>
          <Grid item lg={2} md={12} xs={12}>
            <CommonButton text='+ Add Booking' onClick={()=>{setOpenSelectmodal(true)}}/>
          </Grid>
        </Grid>
      </Paper>
      <DashboardComponent {...props} bookingData={allBookings}/>
    </>
  )
}

export default DashboardClient