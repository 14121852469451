import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { PLATFORM_USERS } from "../utils/constants";

export const StaticService = {

    getPincodeRelatedArea :async (data : { pincode : string, location?: string, searchByVendor ?: string, tosearchByVendor?:string, searchFtlLocation?:string, is_rate_selected?: boolean }) =>{
        if(data.location)return axiosInstance.get(API_URLS.static.location + `?query=${data.location}&page=1&limit=50`);
        if(data.searchByVendor) return axiosInstance.get(API_URLS[PLATFORM_USERS.CLIENT].searchByVendor + `?from_search_query=${data.searchByVendor}&page=1&limit=50${data.is_rate_selected ? `&is_rate_selected=${data.is_rate_selected}` : `&is_rate_selected=false`}`);
        if(data.tosearchByVendor) return axiosInstance.get(API_URLS[PLATFORM_USERS.CLIENT].searchByVendor + `?to_search_query=${data.tosearchByVendor}&page=1&limit=50${data.is_rate_selected ? `&is_rate_selected=${data.is_rate_selected}` : `&is_rate_selected=false`}`);
        if(data.searchFtlLocation) return axiosInstance.get(API_URLS[PLATFORM_USERS.CLIENT].searchftlLocations + `?query=${data.searchFtlLocation}&page=1&limit=50`);
        const result =await axiosInstance.get(API_URLS.static.pincode + `?number=${data.pincode}&page=1&limit=50`);
        return result
    },

    getStates :async (data :{ state: string}) =>{
        let result : any;
        if(!data.state){
            result =await axiosInstance.get(API_URLS.static.state)
        }else{
            result =await axiosInstance.get(API_URLS.static.state + `?name=${data.state}`)
        }
        return result
    },

    getCountry :async (data) =>{
        const result =await axiosInstance.get(API_URLS.static.country)
        return result
    },

    getCity :async (data) =>{
        if(data?.city){
            const result =await axiosInstance.get(API_URLS.static.city+ `?name=${data?.city}`)
            return result
        }
        else if(data?.city_id){
            const result =await axiosInstance.get(API_URLS.static.cityId(data?.city_id))
            return result
        }
    },
}