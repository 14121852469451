import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  bookingStatusFilterClient,
  BOOKING_STATUS_CONSTANTS,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
  LOAD_TYPES,
} from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import { ParamsTypes } from "../../../../utils/ObjectTypes";
import RecipeReviewCard from "../../../Cards/RateCard";
import ComingSoon from "../../../ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { fetchAllClientBookings } from "../../../../ReduxActions";
import SelectModal from "./SelectModal";
import NoBookingImage from "../../../../assets/img/NoBooking.png";
import { AppointmentIcon } from "../../../../icons";
import BasicSelect from "../../../Dropdown/SimpleSelect";
import SearchInput from "../../../SearchInput";
interface Data {
  booking_id: number;
  route: string;
  material: any;
  type: string;
  pickup_at: string;
  status: string;
  last_activity_updated_at: string;
  final_unit_price: string;
  least_unit_price: string;
  pickup_address: string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "route",
    numeric: false,
    disablePadding: false,
    label: "Route",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "final_unit_price",
    numeric: false,
    disablePadding: false,
    label: "Final Price",
  },
  {
    id: "least_unit_price",
    numeric: false,
    disablePadding: false,
    label: "Minimum Price",
  },
  {
    id: "last_activity_updated_at",
    numeric: false,
    disablePadding: false,
    label: "Last Activity",
  },
  {
    id: "pickup_at",
    numeric: false,
    disablePadding: false,
    label: "Pickup at",
  },
  {
    id: "material",
    numeric: false,
    disablePadding: false,
    label: "Material",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
];

interface Props {
  key?: string;
  match?: any;
  routeKey?: string;
  history?: any;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Bookings: React.FC<Props> = (props) => {
  let uelparams: any = useQuery();
  const { key, match, routeKey, history } = props;
  const param = routeKey;
  const [filterBookings, setFilterBookings] = useState({
    status: BOOKING_STATUS_CONSTANTS.all,
  });

  // dispatch
  const dispatch = useDispatch();
  // selectors
  const allBookingSelector = useSelector(
    (state: any) => state.BookingReducer.allClientBookings
  );
  //States Hook
  const [loading, setLoading] = useState({
    get: false,
  });
  const [openAddBookingmodal, setOpenAddBookingmodal] = useState(false);
  const [allBookings, setAllBookings] = useState([]);
  const [allBookingsMeta, setAllBookingsMeta] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [resultText, setResultText] = useState(false);
  const getAllBookings = (page?: number, limit?: number, search?: string, status?: string) => {
    dispatch(fetchAllClientBookings({ user: param, page, limit, search, status }));
  };

  useEffect(() => {
    const bookingsFilter = uelparams.get("bookings");

    bookingsFilter &&
      setFilterBookings({ status: BOOKING_STATUS_CONSTANTS[bookingsFilter] });
    getAllBookings(1, 50, searchValue, bookingsFilter);
  }, []);

  // table actions
  const actions = [
    {
      id: "view",
      label: "View Details",
      callbackArguments: ["id"],
      onClick: (id) => {
        viewBookingDetails(id);
      },
    },
    // {
    //   id: "delete",
    //   label: "Delete",
    //   callbackArguments: ["id"],
    //   onClick: (id) => {},
    // },
  ];

  // view booking details
  const viewBookingDetails = (id) => {
    history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS + "/" + id);
  };

  useEffect(() => {
    let toastId: any;
    if (allBookingSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, get: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (allBookingSelector.status === API_CONSTANTS.success && loading.get) {
      setLoading({ ...loading, get: false });
      setAllBookings([
        ...allBookingSelector?.data?.data?.map((items) => {
          items.booking_id = (
            <p
              onClick={() => viewBookingDetails(items.id)}
              className="text-brand-primary-blue cursor-pointer"
            >
              {items.booking_id}
            </p>
          );
          return items;
        }),
      ]);
      setAllBookingsMeta(allBookingSelector.data?.meta);
      searchValue != "" && setResultText(true)
      toast.dismiss(toastId);
    }
    if (allBookingSelector.status === API_CONSTANTS.error && loading.get) {
      setLoading({ ...loading, get: false });
      toast.dismiss(toastId);
      toast.error(allBookingSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allBookingSelector]);

  const handleAddBooking = () => {
    setOpenAddBookingmodal(true);
  };

  const handleBookingFilter = (key, value) => {
    if (value === bookingStatusFilterClient[0].value) {
      setFilterBookings({
        ...filterBookings,
        [key]: value,
      });
      getAllBookings(1, 50, searchValue);
    } else {
      setFilterBookings({
        ...filterBookings,
        [key]: value,
      });
      getAllBookings(1, 50, searchValue, value);
    }
  };

  const onClickCards = (key) => {
    history.push(APP_USER_ROUTES[param].BOOKING_CREATE_ALIAS(key));
  };

  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchValue(value)
      if (filterBookings["status"] === bookingStatusFilterClient[0].value) {
        getAllBookings(1, 50, value)
      }
      else {
        getAllBookings(1, 50, value, filterBookings["status"])
      }
    }
    else {
      toast.error("Please enter a value")
    }
  }

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      handleSearch(value)
    }
  }

  const resetSearch = () => {
    setSearchValue("");
    setResultText(false)
    if (filterBookings["status"] === bookingStatusFilterClient[0].value) {
      getAllBookings(1, 50, "")
    }
    else {
      getAllBookings(1, 50, "", filterBookings["status"])
    }
  }

  return (
    <>
      <SelectModal
        open={openAddBookingmodal}
        setOpen={setOpenAddBookingmodal}
        onClickCards={onClickCards}
      />
      <div className="">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={12}>
            <PageTitle className="mb-0">My Bookings</PageTitle>
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            {match.path === APP_USER_ROUTES[param].BOOKINGS ? (
              <Button
                text="Add New Booking"
                type="button"
                onClick={handleAddBooking}
              />
            ) : (
              <Button
                text="View all"
                tag={Link}
                to={APP_USER_ROUTES[param].BOOKINGS}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          className="my-2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={4} hidden>
            <p className="mt-4 text-sm">Filter Bookings</p>
          </Grid>
          <Grid item lg={2} md={4} xs={8}>
            <BasicSelect
              label="Filter bookings"
              options={bookingStatusFilterClient}
              onChange={(e) => handleBookingFilter("status", e.target.value)}
              value={filterBookings["status"]}
            />
          </Grid>

          <Grid item lg={2} md={4} xs={8}
            style={{ maxWidth: "80%" }}
          >
            <SearchInput
              placeholder="Search here"
              handleSearch={handleSearch}
              handleEnterSearch={handleEnterSearch}
              resultText={resultText}
              total={allBookingsMeta["total"]}
              resetSearch={resetSearch}
              searchValue={searchValue}
              filter={bookingStatusFilterClient.filter(item => { return item.value === filterBookings.status })}
            />
          </Grid>
        </Grid>
        <Grid container className="">
          <Grid item lg={12} xs={12}>
            {/* <RecipeReviewCard/> */}
            <TableComponent
              columns={columns}
              rows={allBookings}
              actions={actions}
              onClickFunc={handleAddBooking}
              onPageChange={getAllBookings}
              meta={allBookingsMeta}
              text="+ Add booking"
              image={
                <AppointmentIcon className="bg-brand-extra-background w-12 h-12 rounded-lg p-3 mb-4 md:mx-auto my-auto" />
              }
              description="You don't have any bookings right now"
              headingText="No Bookings"
              status={
                filterBookings["status"] !== BOOKING_STATUS_CONSTANTS.all
                  ? filterBookings["status"]
                  : undefined
              }
              searchValue={searchValue}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Bookings;
