import React, { useEffect, useState } from "react";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import {
  LoadOptions, LoadOptionsForWeight, MaterialType, MaterialTypes,
} from "../../../../utils/ObjectTypes";
import Input from "../../../Input/MaterialInput";
import { handleCheckValidation } from "../../../../ValidationHelpers";
import MaterialSingleSearchDropdown from "../../../Dropdown/SingleSearchDropdown";
import CommonTextArea from "../../../TextArea";
import CommonInputGroup from "../../../Input/InputGroup";
import { Trash2Icon } from "../../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { getCityData, getCountryData, getPinCodeData, getStatesData } from "../../../../ReduxActions/staticAction";
import { staructureCityData, structure30MinuteIntervalTimeArray, structureCityObjectData, structureCountryData, structurePincodeCityData, structurePincodeCityDataForBookings } from "../../../../utils/common";
import { API_CONSTANTS, LOAD_TYPES } from "../../../../utils/constants";
import { useParams } from "react-router-dom";

interface BookingDetailObject {
  pickup_location?: string;
  drop_location?: string;
  pickup_address?: string;
  drop_address?: string;
  date?: string;
  time?: string;
  remarks?: string;
}

interface IVendorDetailsObject extends BookingDetailObject {
  from_zone ?: string,
  from_zone_pincodes ?: any[],
  to_zone ?: string,
  to_zone_pincodes ?: any[],
  vendor_id ?: string,
  vendor_name ?: string,
  rate ?: any,
  rate_unit ?: string,
  id ?: any
}

interface ImaterialType {
  material_type: string;
  weight: string;
  weight_unit: string;
}

interface Props {
  bookingData?: IVendorDetailsObject;
  setBookingData?: any;
  onSubmit?: any;
  onCancel?: any;
}

const BookingForm: React.FC<Props> = (props) => {
  // props destructuring
  const { bookingData, onCancel } = props;
  console.log("ddd")
  console.log(bookingData)
  // type of booking
  const { type } = useParams<{type:string}>()
  // dispatch action
  const dispatch = useDispatch();
  // selectors 
  const pincodeDataSelector = useSelector((state: any)=> state.StaticReducer.pincodeData);

  const [bookingDetails, setBookingDetails] = useState<IVendorDetailsObject>({
    pickup_location: "",
    drop_location: "",
    pickup_address: "",
    drop_address: "",
    date: "",
    time: "",
    remarks: "",
  });
  const [validationObj, setValidationObj] = useState<BookingDetailObject>({});
  const [materilaValidationObj, setMaterilaValidationObj] = useState<
    ImaterialType[]
  >([]);
  const [materialWeightAndUnit, setMaterialWeightAndUnit] = useState<
    ImaterialType[]
  >([{ material_type: "", weight: "", weight_unit: "tonne" }]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchText, setSearchText] = useState({key : '', value: ''});
  const [cityPincodeData, setCityPincodeData] = useState([]);

  // search text change
  const handleChangeSearchText = (key, value) => {
    setSearchText({key :key, value:value})
  }

  // search data on change pincode
  useEffect(() => {
    if(searchText.key==='pincode' && searchText.value.length>=3){
      dispatch(
        getPinCodeData({
          searchFtlLocation : searchText.value
        })
      )
    }
  }, [searchText])

  useEffect(() => {
    if(pincodeDataSelector.status === API_CONSTANTS.loading){
      setCityPincodeData([]);
    }
    if(pincodeDataSelector.status === API_CONSTANTS.success){
      setCityPincodeData(structurePincodeCityDataForBookings(pincodeDataSelector.data.data));
    }
  }, [pincodeDataSelector])

  // get booking Data from ancestor components
  useEffect(() => {
    if(bookingData?.from_zone_pincodes && bookingData?.to_zone_pincodes){
      setCityPincodeData(structurePincodeCityDataForBookings([...bookingData.from_zone_pincodes, ...bookingData?.to_zone_pincodes]))
      setBookingDetails({...bookingData, pickup_location : bookingData?.from_zone_pincodes[0]?.id, drop_location : bookingData?.to_zone_pincodes[0]?.id})
      materialWeightAndUnit[0].weight_unit = bookingData?.rate_unit;
      setMaterialWeightAndUnit(materialWeightAndUnit)
    }else if(bookingData?.from_zone_pincodes){
      setCityPincodeData(structurePincodeCityDataForBookings([...bookingData.from_zone_pincodes]))
      setBookingDetails({...bookingData, pickup_location : bookingData?.from_zone_pincodes[0]?.id, drop_location : bookingData?.to_zone_pincodes[0]?.id})
      materialWeightAndUnit[0].weight_unit = bookingData?.rate_unit;
      setMaterialWeightAndUnit(materialWeightAndUnit)
    }else{
      setBookingDetails({ ...bookingData });
    }
  }, [bookingData]);

  const handleInputChange = (key, value) => {
    setBookingDetails({ ...bookingDetails, [key]: value });
  };

  // add material info row
  const handleAddRow = () => {
    if(materialWeightAndUnit.length<5){
      setMaterialWeightAndUnit([
        ...materialWeightAndUnit,
        { material_type: "", weight: "", weight_unit: materialWeightAndUnit[0].weight_unit },
      ]);
    }
  };

  // delete material specific info row
  const handleDeleteRows = (index) => {
    materialWeightAndUnit.splice(index, 1);
    setMaterialWeightAndUnit([...materialWeightAndUnit]);
  };

  // change material information
  const handleChangeMaterialDetails = (key, value, index) => {
    if(key==='weight_unit'){
      setMaterialWeightAndUnit([...materialWeightAndUnit.map((item)=>{
        item[key]=value
        return item
      })]);
    }else{
      materialWeightAndUnit[index][key] = value;
      setMaterialWeightAndUnit([...materialWeightAndUnit]);
    }
  };

  // check material validation
  const materialValidation = () => {
    let validation: ImaterialType[] = [];
    materialWeightAndUnit.forEach((item) => {
      const v: any = handleCheckValidation({ ...item });
      validation.push(v);
    });
    setMaterilaValidationObj(validation);
    return validation.map((val) => {
      if (!val.material_type && !val.weight_unit && !val.weight) return false;
      return true;
    });
  };

  // submit details
  const onSubmit = (e) => {
    e.preventDefault();
    const validation: BookingDetailObject =
      handleCheckValidation(bookingDetails);
    const materialValidate = materialValidation();
    setValidationObj(validation);
    if (
      validation &&
      !validation.date &&
      // !validation.drop_location &&
      !validation.pickup_location &&
      !validation.time &&
      materialValidate.every((i) => !i)
    ) {
      // console.log(bookingDetails, materialWeightAndUnit);
      const materialWeightNew = [...materialWeightAndUnit.map((i:any)=>{i.weight=Number(i.weight); return i;})]
      props.onSubmit({ ...bookingDetails, drop_location: bookingDetails.drop_location||undefined, pickup_at: `${bookingDetails.date}:${bookingDetails.time}`, materials: materialWeightAndUnit });
    }
  };
  return (
    <div>
      <form onSubmit={onSubmit}>
        <Grid container className="px-8 py-8 bg-brand-primary-white">
          <Grid item lg={6} xs={12} className="md:pr-6">
            <MaterialSingleSearchDropdown
              label="Pickup Location"
              placeholder={`Enter your Pickup Location`}
              onChange={(value) =>
                handleInputChange("pickup_location", value)
              }
              value={bookingDetails.pickup_location}
              validationKey={validationObj.pickup_location}
              onChangeText={(e)=>handleChangeSearchText('pincode', e.target.value)}
              // searchType={true}
              options={cityPincodeData}
              disabled={type===LOAD_TYPES.PART_TRUCK}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <MaterialSingleSearchDropdown
              label="Drop Location (Optional)"
              placeholder={`Enter Drop Location (Leave empty if unknown)`}
              onChange={(value) =>
                handleInputChange("drop_location", value)
              }
              value={bookingDetails.drop_location}
              // validationKey={validationObj.drop_location}
              onChangeText={(e)=>handleChangeSearchText('pincode', e.target.value)}
              // searchType={true}
              options={cityPincodeData}
              disabled={type===LOAD_TYPES.PART_TRUCK}
            />
          </Grid>
          <Grid item lg={6} xs={12} className="md:pr-6">
            <Input
              label="Pickup Address (Optional)"
              placeholder={`Enter your pickup location`}
              onChange={(e) => handleInputChange("pickup_address", e.target.value)}
              value={bookingDetails.pickup_address}
            />
          </Grid>
          <Grid item lg={6} xs={12} className="md:pr-6">
            <Input
              label="Drop Address (Optional)"
              placeholder={`Enter your drop location`}
              onChange={(e) => handleInputChange("drop_address", e.target.value)}
              value={bookingDetails.drop_address}
            />
          </Grid>
          <Grid item lg={6} xs={12} className="md:pr-6">
            <Input
              label="Date"
              type="date"
              placeholder={`Enter your date`}
              onChange={(e) => handleInputChange("date", e.target.value)}
              value={bookingDetails.date}
              validationKey={validationObj.date}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <MaterialSingleSearchDropdown
              label="Time"
              // type="time"
              placeholder={`Enter your time`}
              onChange={(value) => handleInputChange("time", value)}
              value={bookingDetails.time}
              validationKey={validationObj.time}
              options={structure30MinuteIntervalTimeArray()}
            />
          </Grid>
          <Grid container lg={12} xs={12}>
            {materialWeightAndUnit.map((items, index) => (
              <>
                <Grid item lg={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="Material Type"
                    placeholder={`Enter your Material type`}
                    onChange={(value) =>
                      handleChangeMaterialDetails("material_type", value, index)
                    }
                    options={MaterialTypes}
                    value={items.material_type}
                    validationKey={
                      materilaValidationObj[index]
                        ? materilaValidationObj[index]?.material_type
                        : null
                    }
                  />
                </Grid>
                <Grid item lg={index > 0 ? 5 : 6} xs={index > 0 ? 11 : 12}>
                  <CommonInputGroup
                    label="Material Weight"
                    type="number"
                    placeholder={`Material weight`}
                    onChange={(e) =>
                      handleChangeMaterialDetails(
                        "weight",
                        e.target.value,
                        index
                      )
                    }
                    value={items.weight}
                    validationKey={
                      materilaValidationObj[index]
                        ? materilaValidationObj[index]?.weight
                        : null
                    }
                    onChangeSelect={(e) =>
                      handleChangeMaterialDetails("weight_unit", e.target.value, index)
                    }
                    selectValue={items.weight_unit}
                    options={LoadOptionsForWeight}
                  />
                </Grid>
                {index > 0 && index < 5 && (
                  <Grid
                    lg={1}
                    xs={1}
                    className="flex justify-center items-center"
                  >
                    <Trash2Icon
                      onClick={() => handleDeleteRows(index)}
                      className="mx-auto text-sm mt-8 cursor-pointer"
                    />
                  </Grid>
                )}
              </>
            ))}
            <p
              className=" text-brand-primary-blue text-sm my-2 mb-0 cursor-pointer"
              onClick={handleAddRow}
              style={materialWeightAndUnit.length>4?{opacity:'0.5', pointerEvents:'none'}:{}}
            >
              + Add More
            </p>
          </Grid>

          <Grid item lg={12} xs={12}>
            <CommonTextArea
              label="Remarks"
              placeholder={`Remarks`}
              onChange={(e) => handleInputChange("remarks", e.target.value)}
              value={bookingDetails.remarks}
              //  validationKey={validationObj.remarks}
            />
          </Grid>
          <Grid item lg={1} md={4} xs={12} marginTop="1rem" display={"flex"} alignItems="center">
            <Button text="Save" type="submit" />
            <Button text="Cancel" type="button" onClick={onCancel} className="mt-4 ml-4 box-border border-brand-text-title text-brand-text-tableHead bg-brand-text-white hover:bg-brand-extra-background"/>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default BookingForm;
