import React from 'react';
import ComingSoon from '../../components/ComingSoon';
import PageTitle from '../../components/Typography/PageTitle';
import VendorPaymentComponent from '../../components/role/vendor/Payments';
import { PLATFORM_USERS } from '../../utils/constants';

interface Props {
  routeKey ?: string;
}

const Payments: React.FC<Props> = (props) => {
  const { routeKey } = props;
  return (
    <>
      {
        (routeKey === PLATFORM_USERS.VENDOR||routeKey === PLATFORM_USERS.VENDOR_BRANCH)?
        <VendorPaymentComponent {...props} />
        :
        <ComingSoon {...props}/>
      }
    </>
  )
}

export default Payments