import React, { useState, useEffect } from 'react';
import TableComponent from "../../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../../Typography/PageTitle";
import Button from '../../../../Button';
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT} from '../../../../../utils/constants';
import { Link, useParams } from "react-router-dom";
import { ParamsTypes, UserObject } from "../../../../../utils/ObjectTypes";
import ComingSoon from '../../../../ComingSoon';
import { fetchAllVendorRelatedBranch } from '../../../../../ReduxActions/branch.action';
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import DeleteModal from "../../../../Modal/DeleteModal";
import { fetchVendorRelatedRatecard } from '../../../../../ReduxActions';

interface Data {
  from_zone: string;
  to_zone: string;
  rate: string;
  active : string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "from_zone",
    numeric: false,
    disablePadding: false,
    label: "From Zone",
    callBackArguments: ["id"],
    customCell: false,
  },
  {
    id: "to_zone",
    numeric: false,
    disablePadding: false,
    label: "To Zone",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
];
  
  interface Props {
    key ?: string;
    match ?: any;
    history ?: any;
    routeKey ?: string;
    userProfileData ?: UserObject;
    id?:any;
  }

const RateCards : React.FC<Props> = (props) => {

    const { key, match, history, userProfileData, routeKey, id } = props;

    const param = routeKey;

    // dispatch
    const dispatch = useDispatch();
    // selector
    const allRateCardSelector = useSelector(
      (state: any) => state.RatecardReducer.allRatecards
    );

    const [allBranchData, setallBranchData] = useState([]);
    const [allBranchMeta, setallBranchMeta] = useState({});
    const [loading, setLoading] = useState({
      allBranch: false,
      deleteBranch: false,
    });

    const getAllRatecards = (page?:number, limit?:number) => {
      dispatch(fetchVendorRelatedRatecard({ user: param, branch_id: id, page, limit }));
    };
  
    useEffect(() => {
      getAllRatecards(1, 10);
    }, []);

    useEffect(() => {
      let toastId: any;
      if (allRateCardSelector.status === API_CONSTANTS.loading) {
        setLoading({ ...loading, allBranch: true });
        toastId = toast.loading(LOADING_TEXT.fetching);
      }
      if (allRateCardSelector.status === API_CONSTANTS.success && loading.allBranch) {
        setLoading({ ...loading, allBranch: false });
        setallBranchData(allRateCardSelector.data?.data);
        setallBranchMeta(allRateCardSelector.data?.meta);
        toast.dismiss(toastId);
      }
      if (allRateCardSelector.status === API_CONSTANTS.error && loading.allBranch) {
        setLoading({ ...loading, allBranch: false });
        toast.dismiss(toastId);
        toast.error(allRateCardSelector.data);
      }
    }, [allRateCardSelector]);

  return (
    <>
      <div className=''>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item lg={2} md={4} xs={12}>
            <PageTitle className="mb-0">
              Branches
            </PageTitle>
          </Grid>
          <Grid item lg={2} md={4} xs={12} className="pl-12">
          </Grid>
        </Grid>
        <Grid container className='mt-4'>
          {/* <ComingSoon {...props}/> */}
        <TableComponent columns={columns} rows={allBranchData} stroke={true} onPageChange={getAllRatecards} meta={allBranchMeta}/>
        </Grid>
      </div>
    </>
  )
}

export default RateCards;