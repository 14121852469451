import React from 'react';
import VendordetailsComponent from '../../components/role/admin/ShowVendors/Vendordetails';

const VendorDetails = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <VendordetailsComponent {...props}/>
    </div>
  )
}

export default VendorDetails