import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { IUpdateUserObject } from "../utils/ObjectTypes";

interface IRateCardObject{
    user : string,
    id   : string,
    body ?: object
}

export const RateCardService = {

    getAllRateCard :async (data : { user : string, page?:number, limit?: number}) =>{
        const request = { data }
        const result =await axiosInstance.get(API_URLS[data.user].getAllRateCards+`?page=${data?.page}&limit=${data?.limit}`);
        return result
    },

    getRateCard :async (data : IRateCardObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.get(API_URLS[data.user].getIdBasedRateCard(data.id))
        return result
    },

    addRateCard :async (data : IRateCardObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].addNewRateCard, request)
        return result
    },

    updateRateCard :async (data : IRateCardObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.put(API_URLS[data.user].editRateCard(data.id), request)
        return result
    },
    
    deleteRateCard :async (data : IRateCardObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.delete(API_URLS[data.user].deleteRateCard(data.id), request)
        return result
    },

    getAllVendorsRateCard :async (data : { user : string, vendor_id?: string, branch_id?: string, page?:number, limit?: number}) =>{
        const request = { data }
        if(data?.branch_id) return axiosInstance.get(API_URLS[data.user].getBranchRatecards(data?.branch_id)+`?page=${data?.page}&limit=${data?.limit}`)
        const result =await axiosInstance.get(API_URLS[data.user].getVendorRatecards(data?.vendor_id)+`?page=${data?.page}&limit=${data?.limit}`);
        return result
    },

}