import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { capitalize } from "../../utils/common";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useCommonStyles } from "../../styles/common";
import CommonButton from "../Button";
import { Grid, Link } from "@mui/material";

interface Data {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
): Data {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headerCells: any;
  actions: Array<object>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headerCells,
    actions,
  } = props;

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
  };

  const checkIfCustomCellExist = () => {
    return headerCells.find(i=>i.customCell);
  }

  return (
    <TableHead className="bg-brand-extra-bg_blue relative">
      <TableRow>
        {
          !checkIfCustomCellExist() &&
          <TableCell
            align={"left"}
            padding={"normal"}
          >
            <p className="text-brand-text-tableHead pl-8">S.no</p>
          </TableCell>
        }
        {headerCells.map((headCell) => (
          <>
            {headCell.customCell && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  size="small"
                  sx={{
                    color: "#9DACB4",
                  }}
                />
              </TableCell>
            )
          }
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <p className="text-brand-text-tableHead whitespace-nowrap truncate w-24">{capitalize(headCell.label)}</p>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </>
        ))}
        {actions && actions.length && (
          <TableCell
            key={"hjcvdvsc"}
            align={"left"}
            padding={"normal"}
            sortDirection={false}
            className="text-brand-text-tableHead bg-brand-extra-bg_blue font-dm_sans"
            sx={{
              fontFamily: 'DM Sans',
              position:'sticky',
              right:'0'
            }}
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

/**
 * 
 * @param props numSelected
 * @returns Toolbar at the top if any element is selected with check box
 */
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <>
      {numSelected > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            <p className="text-brand-text-title font-dm_sans">
              {numSelected} selected
            </p>
          </Typography>
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      )}
    </>
  );
};

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
}
interface Props{
  rows ?: any[];
  columns ?: any;
  actions ?: any[];
  onClickFunc ?: any;
  text ?: string;
  stroke ?: any;
  image ?: any;
  description ?: string;
  headingText ?: string;
  onPageChange ?: any;
  meta ?: any;
  status?: string | undefined;
  searchValue ?: string | undefined; 
}
/**
 * 
 * @param props (rows, columns, actions)
 * @returns Customized table based on props
 */
const EnhancedTable: React.FC<Props> = (props) => {
  const { rows, columns, actions, onClickFunc, text, stroke, image, description, headingText, onPageChange, meta, status,searchValue } = props;
  const classes = useCommonStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("calories");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const compoRef = React.useRef<HTMLDivElement>(null);
  const handleClickAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setPage(meta?.page?meta?.page-1:0);
    setRowsPerPage(meta?.limit||50);
  }, [meta])
  

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getIntoView = () => {
    if(compoRef.current){
      compoRef?.current?.scrollIntoView();
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onPageChange(newPage+1, rowsPerPage,searchValue, status) 
    getIntoView()
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(page+1, parseInt(event.target.value, 10),searchValue, status)
    // setPage(0);
    getIntoView()
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getCustomCell = () => {
    if(columns.find(i=>i.customCell)) return true;
    return false
  }

  const onSelectActions = (action, row) => {
    // console.log(row, action);
    if(!action?.callbackArguments?.length){
      action.onClick();
    }else if(action?.callbackArguments?.length===1){
      action.onClick(row[action?.callbackArguments[0]]);
    }else if(action?.callbackArguments?.length===2){
      action.onClick(row[action?.callbackArguments[0]], row[action?.callbackArguments[1]]);
    }else if(action?.callbackArguments?.length===3){
      action.onClick(row[action?.callbackArguments[0]], row[action?.callbackArguments[1]], row[action?.callbackArguments[1]]);
    }else if(action?.callbackArguments?.length===4){
      action.onClick(row[action?.callbackArguments[0]], row[action?.callbackArguments[1]], row[action?.callbackArguments[1]], row[action?.callbackArguments[1]]);
    }
    handleClose();
  }
  
  return (
    <Box sx={{ width: "100%",  overflow:'hidden' }} ref={compoRef} className="table_container_common">
      <Paper
        sx={{ width: "100%", mb: 2, boxShadow: "none", overflow:'hidden' }}
        className={`shadow-card ${stroke ? "border border-brand-extra-border":""}`}
      >
        <TableContainer className="shadow-card">
          <EnhancedTableToolbar numSelected={selected.length} />
          <Table
            // sx={{ minWidth: 1200 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headerCells={columns}
              actions={actions}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      // hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {
                        getCustomCell() ?
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row.name)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            className="border-brand-extra-border"
                            size="small"
                            sx={{
                              color: "#9DACB4",
                            }}
                          />
                        </TableCell>
                        :
                        <TableCell>
                          <p className="text-brand-text-title pl-8">{meta ? (meta?.page - 1) * rowsPerPage + (index + 1) :index+1}</p>
                        </TableCell>
                      }
                      {
                        columns.map((i, index)=>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding={"normal"}
                            align="left"
                            title={typeof row[i.id]==='string' && row[i.id]}
                            sx={{
                              '&':{
                                maxWidth:'14rem'
                              }
                            }}
                          >
                            {i.makeLinkable ?
                              (<a href="" onClick={()=> i.onClickAction && i.onClickAction(row[i.callBackArguments[0]])} className="text-brand-primary-blue truncate">{row[i.id]}</a> ):
                              <p className="text-brand-text-title truncate"> {i.getCustomColumn
                                ? i.getColData(row)
                                : row[i.id]} </p>
                            }
                            
                          </TableCell>
                        )
                      }
                      {actions && actions.length && (
                        <TableCell
                          component="td"
                          id={"cgvdhcvhd"}
                          scope="row"
                          padding="normal"
                          align="left"
                          className="text-brand-text-tableHead bg-brand-primary-white font-dm_sans"
                          sx={{
                            position:'sticky',
                            right:'0'
                          }}
                        >
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                               {/* <IconButton 
                              style={{padding:'0'}} {...bindTrigger(popupState)}>
                                <MoreHorizIcon className="text-brand-extra-icon1" fontSize="medium"/>
                              </IconButton> */}
                                 <button className="text-brand-primary-blue w-20" style={{padding:'0'}} {...bindTrigger(popupState)}>
                                   {"Action"}
                                   <ArrowDropDownIcon color="primary" />
                                   </button>
                                 
                                 
                              <Menu
                                  id="basic-menu"
                                  {...bindMenu(popupState)}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                    className:"shadow-card"
                                  }}
                                  style={{ boxShadow: "none" }}
                                >
                                  {actions.map((i: any) => {
                                  
                                    return (
                                    <> 
                                      {
                                        !i?.hidden?.value?.includes(row[i.hidden.key]) &&
                                        <MenuItem onClick={()=>{onSelectActions(i, row); popupState.close();}}><p className={"font-dm_sans"+ `${i.id==='delete'?' text-brand-primary-red':' text-brand-text-title'}`}>{i?.customLabel ? i?.customLabel(row) : i.label}</p></MenuItem>
                                      }
                                    </>
                                    )
                                  }
                                  )}
                                </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {rows.length === 0 && text && (
                <>
                  <TableRow
                    style={{
                      height: (100),
                    }}
                  >
                    <TableCell colSpan={8} style={{padding:'2rem'}}>
                      {
                        image
                        // <img src={image} className="mx-auto w-64"/>
                      }
                      <h5 className='text-left md:text-center text-brand-text-title text-2xl font-bold'>{headingText}</h5>
                      <p className="text-left md:text-center text-base text-brand-text-title mt-4">{description}</p>
                      <Grid container display="flex" justifyContent="center" alignItems="center">
                        <Grid item lg={2} md={4} xs={12} className={classes.mpt5}>
                          <CommonButton className="md:w-full w-fit" text={text} onClick={onClickFunc}/>
                        </Grid>
                      </Grid>
                      </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
          {
            meta &&
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25, 50]}
              component="div"
              count={meta?.total||rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={"text-brand-text-title bg-brand-primary-white"}
              style={{overflow:'auto'}}
            />
          }
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
};

export default EnhancedTable;
