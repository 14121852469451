import React from 'react';
import { Divider, Grid, Step, Stepper, StepLabel, Box } from '@mui/material';
import { useCommonStyles } from '../../styles/common';
import { TIME_FORMAT_STRING, TRACKING_STATUS_CONSTANTS } from '../../utils/constants';
import moment from 'moment';

interface Props{
    vehicle_no ?: string;
    status ?: string,
    remarks ?: string,
    history ?: any[],
}

const StepIconComponent: React.FC<{isActive:boolean}> = (props) => {
    const { isActive } = props;
    return(
        <>
        <div className='mb-4 flex items-center justify-between'>
            <div className={'w-6 h-6 rounded-full border-4 ' + ' ' + isActive ? 'bg-brand-primary-green border-brand-disabled-green' : 'bg-brand-extra-grey border-brand-extra-grey'}></div>
        </div>
        </>
    )
}

const TrackingCard: React.FC<Props> = (props) => {
    const classes = useCommonStyles();
    const {
        vehicle_no ,
        status,
        remarks,
        history,
    } = props;
    // console.log( history );
    
    const getHistoryRelatedToStatus = (status) => {
        const data = history?.find((item)=>item.status==status);
        return data;
    }

    const getStatusColor = (key?: string) => {
        switch (status) {    
            case TRACKING_STATUS_CONSTANTS.dispatch: {
                if(key===status) return 'bg-brand-primary-green border-brand-disabled-green';
                return 'bg-brand-extra-grey border-brand-extra-grey';
            }     
            case TRACKING_STATUS_CONSTANTS.in_transit: {
                if(key===TRACKING_STATUS_CONSTANTS.dispatch) return 'bg-brand-primary-blue border-brand-primary-blue';
                else if(key===TRACKING_STATUS_CONSTANTS.delivered) return 'bg-brand-extra-grey border-brand-extra-grey';
                return 'bg-brand-primary-green border-brand-disabled-green'
            }     
            case TRACKING_STATUS_CONSTANTS.delivered: {
                if(key!==status) return 'bg-brand-primary-blue border-brand-primary-blue';
                return 'bg-brand-primary-green border-brand-disabled-green'
            }     
            default:
                return 'bg-brand-extra-grey border-brand-extra-grey';
        } 
    }

    const getTextColor = (key?: string) => {
        switch (status) {    
            case TRACKING_STATUS_CONSTANTS.dispatch: {
                if(key===status) return 'text-brand-primary-green font-bold';
                return 'text-brand-text-caption font-bold';
            }     
            case TRACKING_STATUS_CONSTANTS.in_transit: {
                if(key===TRACKING_STATUS_CONSTANTS.dispatch) return 'text-brand-primary-blue font-bold';
                else if(key===TRACKING_STATUS_CONSTANTS.delivered) return 'text-brand-text-caption font-bold';
                return 'text-brand-primary-green font-bold'
            }     
            case TRACKING_STATUS_CONSTANTS.delivered: {
                if(key!==status) return 'text-brand-primary-blue font-bold';
                return 'text-brand-primary-green font-bold'
            }     
            default:
                return 'text-brand-text-caption font-bold';
        } 
    }
  return (
    <div className='w-full'>
        <p className='text-base text-brand-extra-tableHead font-bold'>Truck No : <span className='font-normal text-brand-text-title'>{vehicle_no}</span></p>
        <Grid container className='mt-6'>
            <Grid item lg={4} md={4} xs={12}>
                <div className='mb-4 flex items-center justify-between'>
                    <div className={`w-6 h-6 rounded-full border-4 ${getStatusColor(TRACKING_STATUS_CONSTANTS.dispatch)}`}></div>
                    <Divider className='w-11/12'/>
                </div>
                <p className={getTextColor(TRACKING_STATUS_CONSTANTS.dispatch)}>Dispatched</p>
                <p className='text-xs text-brand-text-caption'>{getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch) && (moment(getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch)?.last_updated_at).format(TIME_FORMAT_STRING))}</p>
                {getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch)?.loading_weight && <>
                    <p className='text-sm text-brand-text-caption mt-4 mb-1'><span className='font-semibold'>Loading Weight: </span>{getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch) && ((getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch)?.loading_weight) + ' ' + (getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch)?.loading_weight_unit))}</p>
                </>}
                <p className='text-xs text-brand-text-caption'>{getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch) && ((getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.dispatch)?.remarks))}</p>
            </Grid>
            <Grid item lg={4} md={4} xs={12} display="flex" justifyContent="" flexDirection="column" alignItems="" textAlign="center">
                <div className='mb-4 flex items-center justify-between'>
                    <Divider className='w-5/12'/>
                    <div className={`w-6 h-6 rounded-full border-4 hidden' ${getStatusColor(TRACKING_STATUS_CONSTANTS.in_transit)}`}></div>
                    <Divider className='w-5/12'/>
                </div>
                <p className={getTextColor(TRACKING_STATUS_CONSTANTS.in_transit) + ' hidden'}>InTransit</p>
                <p className='text-xs text-brand-text-caption hidden'>{getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.in_transit) && (moment(getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.in_transit)?.last_updated_at).format(TIME_FORMAT_STRING))}</p>
                <p className='text-xs text-brand-text-caption mt-4 hidden'>{getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.in_transit) && ((getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.in_transit)?.remarks))}</p>
            </Grid>
            <Grid item lg={4} md={4} xs={12} display="flex" justifyContent="" flexDirection="column" alignItems="" textAlign="end">
                <div className='mb-4 flex items-center justify-between'>
                    <Divider className='w-11/12'/>
                    <div className={`w-6 h-6 rounded-full border-4 ' ${getStatusColor(TRACKING_STATUS_CONSTANTS.delivered)}`}></div>
                </div>
                <p className={getTextColor(TRACKING_STATUS_CONSTANTS.delivered)}>Delivered</p>
                <p className='text-xs text-brand-text-caption '>{ getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered) && moment(getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered)?.last_updated_at).format(TIME_FORMAT_STRING)}</p>
                {getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered)?.unloading_weight && <>
                    <p className='text-sm text-brand-text-caption mt-4 mb-1'><span className='font-semibold'>UnLoading Weight: </span>{getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered) && ((getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered)?.unloading_weight) + ' ' + (getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered)?.unloading_weight_unit))}</p>
                </>}
                <p className='text-xs text-brand-text-caption '>{ getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered) && (getHistoryRelatedToStatus(TRACKING_STATUS_CONSTANTS.delivered)?.remarks)}</p>
            </Grid>
        </Grid>
    </div>
  )
}

export default TrackingCard