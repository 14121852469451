import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCommonStyles } from '../../styles/common';
import CommonButton from '../Button';

interface Props {
    open ?: boolean;
    setOpen ?: any;
    content ?: any;
    agreeButtonText ?: string;
    cancelButtonText ?: string;
    headingText ?: string;
    onClickAgree ?: any;
}

const DeleteModal:React.FC<Props> = (props) => {
    const classes = useCommonStyles();
    const { open, setOpen, content, agreeButtonText, cancelButtonText, headingText, onClickAgree } = props;

    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className={classes.p32px} style={{paddingBottom:'0.5rem'}}><p className='text-2xl'>{headingText}</p></DialogTitle>
          <DialogContent className={classes.p32px} style={{paddingBottom:'0rem', paddingTop:'0rem'}}>
              <p className='text-brand-text-title text-base'>
                {
                    content
                }
              </p>
          </DialogContent>
          <DialogActions className={classes.p32px} style={{paddingTop:'1.2rem'}}>
              {
                  cancelButtonText &&
                  <>
                  <CommonButton className=' box-border w-20 border-brand-text-title text-brand-text-tableHead bg-brand-text-white mt-0 mr-1  hover:bg-brand-extra-background' onClick={handleClose} text={cancelButtonText}/>
                  </>
                  // <Button onClick={handleClose} disableRipple style={{textTransform:'none'}} variant="outlined" className={classes.borderSecondary + ' ' + classes.mr1}><span className='text-brand-text-title'>{cancelButtonText}</span></Button>
              }
              {
                  agreeButtonText &&
                  <>
                    <CommonButton className='w-20 bg-brand-primary-red hover:bg-brand-hover-red mt-0 ' onClick={onClickAgree} text={agreeButtonText}/>
                  </>
                  // <Button onClick={onClickAgree} color={"error"} style={{textTransform:'none', margin:'0'}} disableRipple variant="contained" className={classes.boxShadowNone + " " + classes.borderRounded}><span>{agreeButtonText}</span></Button>
              }
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default DeleteModal