import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  GET_PINCODE,
  GET_PINCODE_ERROR,
  GET_PINCODE_SUCCESS,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_ERROR,
  GET_COUNTRY,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR
} from "../ReduxConstants";
import { StaticService } from "../sevices";

// get pincode dispatch actions
const getPincode = (payload: object) => ({
  type: GET_PINCODE,
  payload: payload,
});
const getPincodeSuccess = (payload: object) => ({
  type: GET_PINCODE_SUCCESS,
  payload: payload,
});
const getPincodeError = (payload: object) => ({
  type: GET_PINCODE_ERROR,
  payload: payload,
});

//  state dispatch actions
const getState = (payload: object) => ({
  type: GET_STATES,
  payload: payload,
});
const getStateSuccess = (payload: object) => ({
  type: GET_STATES_SUCCESS,
  payload: payload,
});
const getStateError = (payload: object) => ({
  type: GET_STATES_ERROR,
  payload: payload,
});

// country dispatch actions
const getCountry = (payload: object) => ({
  type: GET_COUNTRY,
  payload: payload,
});
const getCountrySuccess = (payload: object) => ({
  type: GET_COUNTRY_SUCCESS,
  payload: payload,
});
const getCountryError = (payload: object) => ({
  type: GET_COUNTRY_ERROR,
  payload: payload,
});

// city dispatch actions
const getCity = (payload: object) => ({
  type: GET_CITY,
  payload: payload,
});
const getCitySuccess = (payload: object) => ({
  type: GET_CITY_SUCCESS,
  payload: payload,
});
const getCityError = (payload: object) => ({
  type: GET_CITY_ERROR,
  payload: payload,
});

export const getCountryData = (data: {pincode ?: string}) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getCountry(data));
      await StaticService.getCountry(data)
        .then((result) => {
          dispatch(getCountrySuccess((result)));
        })
        .catch((error) => {
          dispatch(getCountryError(error));
        });
    };
  };

export const getCityData = (data) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getCity(data));
      await StaticService.getCity(data)
        .then((result) => {
          dispatch(getCitySuccess((result)));
        })
        .catch((error) => {
          dispatch(getCityError(error));
        });
    };
  };

export const getStatesData = (data) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getState(data));
      await StaticService.getStates(data)
        .then((result) => {
          dispatch(getStateSuccess((result)));
        })
        .catch((error) => {
          dispatch(getStateError(error));
        });
    };
  };

export const getPinCodeData = (data) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      await dispatch(getPincode(data));
      await StaticService.getPincodeRelatedArea(data)
        .then((result) => {
          dispatch(getPincodeSuccess((result)));
        })
        .catch((error) => {
          dispatch(getPincodeError(error));
        });
    };
  };