import { Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  EmailIcon,
  InfoIcon,
  MappinIcon,
  PhoneIcon,
  UserIcon,
} from "../../../../icons";
import { fetchUsers, getVendorBookingbyId } from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
  TIME_FORMAT_STRING,
} from "../../../../utils/constants";
import PageTitle from "../../../Typography/PageTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import VendorsBooking from "./VendorsBooking";
import VendorsBookingRequests from "./VendorsBookingRequests";
import VendorFleets from "./VendorFleets";
import VendorBranches from "./VendorBranches";
import VendorRatecard from "./VendorRatecard";
import { areaFormattedValue } from "../../../../utils/common";
import moment from "moment";

const tabMaps = {
  "bookings": 0,
  "bookingrequests": 1,
  "fleets": 2,
  "branches": 3,
  "ratecards": 4,
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const VendorDetails = (props) => {
  const classes = useCommonStyles();
  // props destructuring
  const { routeKey, match, history } = props;
  const param = routeKey;
  // id route param
  const { id } = useParams<{ id; string }>();
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  const userDetailSelector = useSelector(
    (state: any) => state.UserReducer.user
  );
  // react state hooks
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [value, setValue] = React.useState(null);

  let urlParams: any = useQuery();

  const searchQuery = urlParams.get("tab");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchUsers({ user: param, id }));
    if(searchQuery && tabMaps[searchQuery]) {
      setValue(tabMaps[searchQuery]);
    }
    else {
      setValue(0);
    }
  }, []);

  useEffect(() => {
    let toastId: any;
    if (userDetailSelector.status === API_CONSTANTS.loading) {
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (userDetailSelector.status === API_CONSTANTS.success && loading) {
      setLoading(false);
      setUserData(userDetailSelector.data);
      toast.dismiss(toastId);
    }
    if (userDetailSelector.status === API_CONSTANTS.error && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(userDetailSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [userDetailSelector]);
  return (
    <div>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <PageTitle className="flex items-center mb-0">
            Vendor Details
          </PageTitle>
          <div className="text-brand-text-title mt-0 mb-6 flex justify-start items-center text-xs">
            <p className="text-xs">
              Registered on{" "}
              {moment(userData?.created_at).format(TIME_FORMAT_STRING)}
            </p>
          </div>
        </Grid>
        <Grid item lg={12}>
          <Paper
            className={
              "bg-brand-primary-white" + " " + classes.boxShadowNone + " "
            }
          >
            <Grid container item lg={12} xs={12} className={classes.p1t5}>
              {
                <>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        Name
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.name || "NA"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <UserIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        Company Name
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.company_name || "NA"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <PhoneIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        Phone No
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.contact_number || "NA"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4"
                  >
                    <EmailIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        Email
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.email || "NA"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <MappinIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        {"Address & Area"}
                      </p>
                      <p className="text-sm text-brand-text-title">
                        <span>{userData?.address || "N/A"}</span>
                        <br />
                        <span>Area: {userData?.area || "N/A"}</span>
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <MappinIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        {"Selected Pincode"}
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {/* <span>{userData?._pincode?.pincode || 'N/A'}</span>,
                                  <span> {userData?._pincode?.district || 'N/A'}</span> */}
                        {userData?._pincode
                          ? areaFormattedValue({
                              ...userData?._pincode,
                              _city: userData?._city,
                              _state: userData?._state,
                              area: userData?.area,
                            })
                          : "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <MappinIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        {"Selected City & State"}
                      </p>
                      <p className="text-sm text-brand-text-title">
                        <span>{userData?._city?.name || "N/A"}</span>,
                        <span> {userData?._state?.name || "N/A"}</span>
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <InfoIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        {"PAN Number"}
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.pan_number || "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <InfoIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        {"GST Number"}
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.gst_number || "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    md={6}
                    display="flex"
                    alignItems="center"
                    className="py-4 pr-4"
                  >
                    <InfoIcon className="bg-brand-extra-bg_blue stroke-brand-primary-blue rounded-lg px-3 py-3 w-12 h-12 mr-4" />
                    <div>
                      <p className="text-sm font-bold text-brand-text-tableHead">
                        {"Preferences (State)"}
                      </p>
                      <p className="text-sm text-brand-text-title">
                        {userData?.preferences &&
                          (userData?.preferences
                            .filter((pref) => pref.type === "state")
                            .map((k) => k.preference_value)
                            .join(", ") ||
                            "NA")}
                      </p>
                    </div>
                  </Grid>
                </>
              }
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12} xs={12} className={classes.mt1pt5}>
          <Paper
            className={
              "bg-brand-primary-white" + " " + classes.boxShadowNone + " "
            }
          >
            <Grid container item lg={12} xs={12} className={classes.p1t5}>
              <Box
              className="overflow-x-auto"
                sx={{
                  maxWidth: { xs: 320, sm: 480, lg: 600 },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab label="Bookings" />
                  <Tab label="Booking Requests" />
                  <Tab label="Fleets" />
                  <Tab label="Branches" />
                  <Tab label="RateCards" />
                </Tabs>
              </Box>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.p1t5}>
              {userData?.id &&
                (value === 0 ? (
                  <VendorsBooking id={userData?.id} {...props} />
                ) : value === 1 ? (
                  <VendorsBookingRequests id={userData.id} {...props} />
                ) : value === 2 ? (
                  <VendorFleets id={userData.id} {...props} />
                ) : value === 3 ? (
                  <VendorBranches id={userData.id} {...props} />
                ) : (
                  value === 4 && <VendorRatecard id={userData.id} {...props} />
                ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default VendorDetails;
