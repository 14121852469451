import {
  SET_LOGIN,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_REGISTER,
  SET_REGISTER_SUCCESS,
  SET_REGISTER_ERROR,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_SUCCESS,
  SET_FORGOT_PASSWORD_ERROR,
  SET_RESET_PASSWORD,
  SET_RESET_PASSWORD_SUCCESS,
  SET_RESET_PASSWORD_ERROR,
  GET_VERIFY_PASSWORD_TOKEN,
  SET_VERIFY_PASSWORD_TOKEN,
  SET_VERIFY_PASSWORD_TOKEN_ERROR,
  RESEND_EMAIL_VERIFICATION,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_ERROR,
} from "../ReduxConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  login: SubstateObject;
  register: SubstateObject;
  forgotPassword: SubstateObject;
  resetPassword: SubstateObject;
  verifyPasswordToken : SubstateObject;
  resendEmailVerify : SubstateObject;
}

const InitialState: InitialState = {
  login: {
    data: null,
    status: null,
  },
  register: {
    data: null,
    status: null,
  },
  forgotPassword: {
    data: null,
    status: null
  },
  resetPassword: {
    data: null,
    status: null,
  },
  verifyPasswordToken: {
    data: null,
    status: null,
  },
  resendEmailVerify: {
    data: null,
    status: null,
  },
};

const AuthReducer = (initialState = InitialState, { type, payload }) => {
  switch (type) {
    case SET_LOGIN: {
      return {
        ...initialState,
        login: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_LOGIN_SUCCESS: {
      return {
        ...initialState,
        login: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case SET_LOGIN_ERROR: {
      return {
        ...initialState,
        login: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case RESEND_EMAIL_VERIFICATION: {
      return {
        ...initialState,
        resendEmailVerify: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case RESEND_EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...initialState,
        resendEmailVerify: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case RESEND_EMAIL_VERIFICATION_ERROR: {
      return {
        ...initialState,
        resendEmailVerify: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case SET_REGISTER: {
      return {
        ...initialState,
        register: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_REGISTER_SUCCESS: {
      return {
        ...initialState,
        register: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case SET_REGISTER_ERROR: {
      return {
        ...initialState,
        register: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case SET_FORGOT_PASSWORD: {
      return {
        ...initialState,
        forgotPassword: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        forgotPassword: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case SET_FORGOT_PASSWORD_ERROR: {
      return {
        ...initialState,
        forgotPassword: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case SET_RESET_PASSWORD: {
      return {
        ...initialState,
        resetPassword: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_RESET_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        resetPassword: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case SET_RESET_PASSWORD_ERROR: {
      return {
        ...initialState,
        resetPassword: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_VERIFY_PASSWORD_TOKEN: {
      return {
        ...initialState,
        verifyPasswordToken: {
          data : null,
          status : API_CONSTANTS.loading
        }
      }
    }
    case SET_VERIFY_PASSWORD_TOKEN: {
      return {
        ...initialState,
        verifyPasswordToken: {
          data : payload,
          status : API_CONSTANTS.success
        }
      }
    }
    case SET_VERIFY_PASSWORD_TOKEN_ERROR: {
      return {
        ...initialState,
        verifyPasswordToken: {
          data : payload,
          status : API_CONSTANTS.error
        }
      }
    }
    default:
      return {
        ...initialState,
      };
  }
};

export default AuthReducer;
