import React from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import ShowClients from './Service';

interface Props{

}

const Clients: React.FC<Props> = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <ShowClients {...props}/>
    </div>
  )
}

export default Clients