import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import toast from "react-hot-toast";
import { API_CONSTANTS, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, PAYMENTS_METHODS, PAYMENT_STATUS, STATUS_JSX, TAX_TYPES } from "../../../../utils/constants";
import PageTitle from '../../../Typography/PageTitle';
import MaterialSingleSearchDropdown from "../../../../components/Dropdown/SingleSearchDropdown";
import CommonButton from "../../../../components/Button";
import CommonInput from "../../../../components/Input/MaterialInput";
import { useCommonStyles } from '../../../../styles/common';
import BasicSelect from '../../../Dropdown/SimpleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { addNewPayment, fetchAllVendorBookings, getPayemntById, updatePayments } from '../../../../ReduxActions';
import { handleCheckValidation } from '../../../../ValidationHelpers';
import CommonInputGroup from '../../../Input/InputGroup';


interface Props {
    match?: any;
    routeKey ?: string;
    history ?: any;
}

interface IValidationObj {
  booking_id?: string,
  initial_amount?: string,
  tax_percentage?: string,
  tax_type?: string,
  status?: string,
  payment_time?: string,
  total_amount?: string,
  discount ?: string,
  payment_method ?: string
}

const AddEditPayments: React.FC<Props> = (props): JSX.Element => {
  // props destructuring
  const { routeKey, history } = props;
  // id params
  const { id } = useParams<{id : string}>();
  // user role key 
  const param = routeKey;
  const classes = useCommonStyles();
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  const allbookingsSelector = useSelector((state: any)=>state.BookingReducer.allVendorBookings);
  const paymentSelector = useSelector((state: any)=>state.PaymentReducer.getPayment);
  const editPaymentSelector = useSelector((state:any)=>state.PaymentReducer.editPayment)
  const addPaymentSelector = useSelector((state:any)=>state.PaymentReducer.createPayment)
    // react states hook
    const [loading, setLoading] = useState({
      all: false,
      get : false,
      add : false,
      edit : false,
    });
    const [allBookings, setAllBookings] = useState<any[]>([]);
    const [paymentDetails, setPaymentDetails] = useState({
      booking_id: '',
      initial_amount: '',
      tax_percentage: '',
      tax_type: '',
      status: '',
      payment_time: "",
      total_amount: '',
      remarks : "",
      payment_method : "",
      discount : ""
    });
    const [validationObj, setValidationObj] = useState<IValidationObj>({})

  useEffect(() => {
    dispatch(fetchAllVendorBookings({ user : param }));
    if(id){
      dispatch(getPayemntById({ user : param, id : id}));
    }
  }, [id]);

  const handleInputChange = (key, value) => {
    setPaymentDetails({...paymentDetails, [key] : value });
  }

  useEffect(() => {
    let toastId : any;
    if(paymentSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, get:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(paymentSelector.status === API_CONSTANTS.success){
      setLoading({...loading, get:false});
      setPaymentDetails({...paymentSelector?.data, payment_time: paymentSelector?.data?.payment_time?.split('.')[0] });
      toast.dismiss(toastId);
    }
    if(paymentSelector.status === API_CONSTANTS.error){
      setLoading({...loading, get:false});
      toast.dismiss(toastId);
      toast.error(paymentSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [paymentSelector])

  useEffect(() => {
    let toastId : any;
    if(editPaymentSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, edit:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(editPaymentSelector.status === API_CONSTANTS.success && loading.edit){
      setLoading({...loading, edit:false});
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
    }
    if(editPaymentSelector.status === API_CONSTANTS.error){
      setLoading({...loading, edit:false});
      toast.dismiss(toastId);
      toast.error(editPaymentSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [editPaymentSelector])

  useEffect(() => {
    let toastId : any;
    if(addPaymentSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, add:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(addPaymentSelector.status === API_CONSTANTS.success && loading.add){
      setLoading({...loading, add:false});
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
    }
    if(addPaymentSelector.status === API_CONSTANTS.error){
      setLoading({...loading, add:false});
      toast.dismiss(toastId);
      toast.error(addPaymentSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [addPaymentSelector])

  useEffect(() => {
    let toastId : any;
    if(allbookingsSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allbookingsSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      // converted array in form of dropdown array object for only filtered confirmed booking
      const confirmedBookings = allbookingsSelector.data?.data?.filter((item)=>item.status===STATUS_JSX['confirmed']);
      setAllBookings([...confirmedBookings.map((item)=>({
        text : item.booking_id,
        value : item.id,
        key : item.id
      }))]);
      toast.dismiss(toastId);
    }
    if(allbookingsSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allbookingsSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allbookingsSelector])
  // handle submit payment details
  const handleSubmit = (e) => {
    e.preventDefault();
    const validation:IValidationObj = handleCheckValidation(paymentDetails);
    if( validation && !validation.booking_id && !validation.total_amount && !validation.tax_type && !validation.payment_time && !validation.tax_type && !validation.tax_percentage && !validation.initial_amount ){
      const data = {...paymentDetails, 
        tax_percentage:Number(paymentDetails.tax_percentage), 
        initial_amount:Number(paymentDetails.initial_amount), 
        total_amount:Number(paymentDetails.total_amount), 
        discount:Number(paymentDetails.discount)
      };
      if(id){
        dispatch(
          updatePayments({
            user: param, body: data, id
          })
        )
      }else{
        dispatch(
          addNewPayment({user:param, body: data})
        )
      }
    }
  }

  return (
    <div className="">
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={3} md={4} xs={12}>
          <PageTitle>{id ? 'Edit' : 'Add'} Payment</PageTitle>
        </Grid>
      </Grid>
      <Paper className={classes.boxShadowNone+' '+classes.p1t5}>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <BasicSelect
                label="Confirmed Booking"
                onChange={(e) => handleInputChange("booking_id", e.target.value)}
                value={paymentDetails.booking_id}
                validationKey={validationObj.booking_id}
                options={allBookings}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <CommonInput
                label="Initial Amount"
                onChange={(e) =>
                  handleInputChange("initial_amount", e.target.value)
                }
                value={paymentDetails.initial_amount}
                rupee
                validationKey={validationObj.initial_amount}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <CommonInput
                label="Total Amount"
                onChange={(e) =>
                  handleInputChange("total_amount", e.target.value)
                }
                value={paymentDetails.total_amount}
                rupee
                validationKey={validationObj.total_amount}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <CommonInput
                  label="Discount"
                  onChange={(e) =>
                    handleInputChange("discount", e.target.value)
                  }
                  value={paymentDetails.discount}
                  rupee
                  validationKey={validationObj.discount}
                />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <CommonInputGroup
                  label="Tax"
                  onChange={(e) =>
                    handleInputChange("tax_percentage", e.target.value)
                  }
                  value={paymentDetails.tax_percentage}
                  selectValue={'%'}
                  options={[{text:'%', value:'%'}]}
                  group
                  validationKey={validationObj.tax_percentage}
                />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <BasicSelect
                  label="Tax Type"
                  onChange={(e) =>
                    handleInputChange("tax_type", e.target.value)
                  }
                  value={paymentDetails.tax_type}
                  options={TAX_TYPES}
                  validationKey={validationObj.tax_type}
                />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <BasicSelect
                  label="Payment Method"
                  onChange={(e) => handleInputChange("payment_method", e.target.value)}
                  value={paymentDetails.payment_method}
                  validationKey={validationObj.payment_method}
                  options={PAYMENTS_METHODS}
                />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <CommonInput
                  label="Remarks"
                  onChange={(e) =>
                    handleInputChange("remarks", e.target.value)
                  }
                  value={paymentDetails.remarks}
                  // validationKey={validationObj.country_id}
                />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <BasicSelect
                  label="Payment Status"
                  onChange={(e) =>
                    handleInputChange("status", e.target.value)
                  }
                  value={paymentDetails.status}
                  options={PAYMENT_STATUS}
                  // validationKey={validationObj.country_id}
                />
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="md:pr-6">
              <CommonInput
                  label="Payment Time"
                  type='datetime-local'
                  onChange={(e) =>
                    handleInputChange("payment_time", e.target.value)
                  }
                  value={paymentDetails.payment_time}
                  validationKey={validationObj.payment_time}
                />
            </Grid>
          </Grid>
          <Grid container className="mt-6">
            <Grid item lg={1} md={2} xs={12}>
              <CommonButton text={"Save"} type="submit" />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}

export default AddEditPayments