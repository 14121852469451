import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPinCodeData } from "../../ReduxActions";
import { structurePincodeCityData, structurePincodeCityDataForBookings } from "../../utils/common";
import { API_CONSTANTS } from "../../utils/constants";
import MaterialSingleSearchDropdown from "../Dropdown/SingleSearchDropdown";

interface Props {
  label?: string;
  value?: any;
  onChange?: any;
  validationKey?: string;
  placeholder?: string;
  context?: string;
  location?:string;
}

const SearchLocationPincode: React.FC<Props> = (props) => {
  const { label, placeholder, value, onChange, validationKey, context, location } = props;
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  const pincodeDataSelector = useSelector(
    (state: any) => state.StaticReducer.pincodeData
  );
  // react state hook
  const [searchText, setSearchText] = useState({ key: "", value: "" });
  const [cityPincodeData, setCityPincodeData] = useState([]);
  const [inputChnage, setInputChnage] = useState<any>();

  useEffect(() => {
    setInputChnage(value);
  }, [value])
  

  // search data on change pincode
  useEffect(() => {
    if (
      searchText.key === "pincode" && searchText.value.length >= 3
    ) {
      if(location==="from"){
        dispatch(
          getPinCodeData({
            searchByVendor: searchText.value,
            is_rate_selected: false,
          })
        );
      }else if(location==="to"){
        dispatch(
          getPinCodeData({
            tosearchByVendor: searchText.value,
            is_rate_selected: false,
          })
        );
      }else{
        dispatch(
          getPinCodeData({
            location: searchText.value,
          })
        );
      }
    }
  }, [searchText]);

  const handleInputChange = (value) => {
    onChange(value);
    setInputChnage(value)
  };

  // search text change
  const handleChangeSearchText = (key, value) => {
    setSearchText({ key: key, value: value });
  };

  // city pincode data selector
  useEffect(() => {
    if (pincodeDataSelector.status === API_CONSTANTS.loading) {
      // setCityPincodeData([]);
    }
    if (pincodeDataSelector.status === API_CONSTANTS.success) {
      setCityPincodeData([...structurePincodeCityData(pincodeDataSelector.data?.data)]);
    }
  }, [pincodeDataSelector]);

  return (
    <>
      <MaterialSingleSearchDropdown
        label={label}
        placeholder={placeholder}
        onChange={(value) => handleInputChange(value)}
        value={inputChnage}
        validationKey={validationKey}
        onChangeText={(e) => handleChangeSearchText(context, e.target.value)}
        // searchType={true}
        options={cityPincodeData}
        outline={true}
      />
    </>
  );
};

export default SearchLocationPincode;
