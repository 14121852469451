import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVendorBookingbyId } from '../../../../ReduxActions';
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT } from '../../../../utils/constants';
import Loader from '../../../Loader';
import ViewBookingDetails from '../../client/Bookings/ViewBookingDetails';

interface Props{
    routeKey ?: string;
    match ?: any;
    history ?: any;
}

const BookingDetails:React.FC<Props> = (props) => {
    // props destructuring
    const { routeKey, match, history } = props;
    const param = routeKey;
    // id route param
    const { id } = useParams<{id; string}>();
    // dispatch action
    const dispatch = useDispatch();
    // selectors
    const bookingDetailSelector = useSelector((state:any)=>state.BookingReducer.vendorBooking);
    // react state hooks
    const [loading, setLoading] = useState(false);
    const [bookingData, setBookingData] = useState(null);

    useEffect(() => {
      const request = match?.path === APP_USER_ROUTES[param].REQUEST_DETAILS;
      if(request){
        dispatch(getVendorBookingbyId({ user : param, requestId : id}))
      }else{
        dispatch(getVendorBookingbyId({ user : param, id : id}))
      }
    }, [])

    useEffect(() => {
        let toastId : any;
        if(bookingDetailSelector.status === API_CONSTANTS.loading){
          setLoading(true);
          toastId=toast.loading(LOADING_TEXT.fetching);
        }
        if(bookingDetailSelector.status === API_CONSTANTS.success && loading){
          setLoading(false);
          setBookingData(bookingDetailSelector.data);
          toast.dismiss(toastId);
        }
        if(bookingDetailSelector.status === API_CONSTANTS.error && loading){
          setLoading(false);
          toast.dismiss(toastId);
          toast.error(bookingDetailSelector.data||DEFAULT_ERROR_MESSAGE.failed);
        }
      }, [bookingDetailSelector])
    
  return (
    <div>
        {
            loading ?
            <Loader/>
            :
            <ViewBookingDetails {...props} bookingData={bookingData} />
        }
    </div>
  )
}

export default BookingDetails