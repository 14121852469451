import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
  bookingStatusFilterVendor,
  BOOKING_STATUS_CONSTANTS,
} from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {fetchAllVendorBookings} from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";
import BasicSelect from "../../../Dropdown/SimpleSelect";
import SearchInput from "../../../SearchInput";
interface Data {
  booking_id: number;
  route: string;
  material: any;
  client: string;
  type: string;
  pickup_at: string;
  status: string;
  pickup_address: string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "route",
    numeric: false,
    disablePadding: false,
    label: "Route",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "material",
    numeric: false,
    disablePadding: false,
    label: "Material",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "pickup_at",
    numeric: false,
    disablePadding: false,
    label: "Pickup at",
  },
];
interface Props {
  key?: string;
  match?: any;
  routeKey?: string;
  history?: any;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Bookings: React.FC<Props> = (props) => {
  let uelparams: any = useQuery();
  const [filterBookings, setFilterBookings] = useState({
    status: BOOKING_STATUS_CONSTANTS.all,
  });
  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  const allbookingsSelector = useSelector(
    (state: any) => state.BookingReducer.allVendorBookings
  );
  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request: false,
  });
  const [allBookings, setAllBookings] = useState<any[]>([]);
  const [bookingsMeta, setBookingsMeta] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [resultText, setResultText] = useState(false);

  const getAllBookings = (page?: number, limit?: number, search?: string,status?: string) => {
    dispatch(fetchAllVendorBookings({ user: param, page, limit, search, status }));
  };

  useEffect(() => {
    const bookingsFilter = uelparams.get("bookings");
    bookingsFilter &&
      setFilterBookings({ status: BOOKING_STATUS_CONSTANTS[bookingsFilter] });
    bookingsFilter
      ? getAllBookings(1, 10, searchValue,bookingsFilter)
      : getAllBookings(1, 10,searchValue,);
  }, []);


  useEffect(() => {
    let toastId: any;
    if (allbookingsSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, all: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (allbookingsSelector.status === API_CONSTANTS.success) {
      setLoading({ ...loading, all: false });
      setAllBookings([
        ...allbookingsSelector?.data?.data?.map((items) => {
          items.booking_id = (
            <p
              onClick={() => viewBookingDetails(items.id)}
              className="text-brand-primary-blue cursor-pointer"
            >
              {items.booking_id}
            </p>
          );
          return items;
        }),
      ]);
      setBookingsMeta(allbookingsSelector.data?.meta);
      searchValue !== "" && setResultText(true)
      toast.dismiss(toastId);
    }
    if (allbookingsSelector.status === API_CONSTANTS.error) {
      setLoading({ ...loading, all: false });
      toast.dismiss(toastId);
      toast.error(allbookingsSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allbookingsSelector]);

  // table confirmed booking actions
  const actions = [
    {
      id: "view",
      label: "View Details",
      callbackArguments: ["id"],
      onClick: (id) => {
        viewBookingDetails(id);
      },
    },
  ];

  // view booking details
  const viewBookingDetails = (id) => {
    history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS + "/" + id);
  };

  const handleBookingFilter = (key, value) => {
    if (value === BOOKING_STATUS_CONSTANTS.all) {
      setFilterBookings({
        ...filterBookings,
        [key]: value,
      });
      getAllBookings(1, 10, searchValue);
    } else {
      setFilterBookings({
        ...filterBookings,
        [key]: value,
      });
      getAllBookings(1, 10, searchValue, value);
    }
  };

  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchValue(value)
      if (filterBookings["status"] === BOOKING_STATUS_CONSTANTS.all) {
        getAllBookings(1, 10, value)
      }
      else {
        getAllBookings(1, 10, value, filterBookings["status"])
      }
    }
    else {
      toast.error("Please enter a value")
    }

  }

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      handleSearch(value)
    }

  }

  const resetSearch = () => {
    setSearchValue("");
    setResultText(false)
    if (filterBookings["status"] === BOOKING_STATUS_CONSTANTS.all) {
      getAllBookings(1, 10, "")
    }
    else {
      getAllBookings(1, 10, "", filterBookings["status"])
    }
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">Confirmed Bookings</PageTitle>
          <p className="text-brand-text-title text-sm">
            Bookings which are accepted and confirmed by the client{" "}
          </p>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <Grid
            container
            className="my-2"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item lg={2} md={4} xs={4} hidden>
              <p className="mt-4 text-sm">Filter Bookings</p>
            </Grid>
            <Grid item lg={2} md={4} xs={8}>
              <BasicSelect
                label="Filter bookings"
                options={bookingStatusFilterVendor}
                onChange={(e) => handleBookingFilter("status", e.target.value)}
                value={filterBookings["status"]}
              />
            </Grid>
            <Grid item lg={2} md={4} xs={8}
              style={{ maxWidth: "80%" }}
            >
              <SearchInput
                placeholder="Search here"
                handleSearch={handleSearch}
                handleEnterSearch={handleEnterSearch}
                resultText={resultText}
                total={bookingsMeta["total"]}
                resetSearch={resetSearch}
                searchValue={searchValue}
                filter={bookingStatusFilterVendor.filter(item => {return item.value === filterBookings.status})}
              />
            </Grid>
          </Grid>
          {/* <ComingSoon {...props}/> */}
          <TableComponent
            columns={columns}
            rows={allBookings}
            actions={actions}
            onPageChange={getAllBookings}
            meta={bookingsMeta}
            status={
              filterBookings["status"] !== BOOKING_STATUS_CONSTANTS.all
                ? filterBookings["status"]
                : undefined
            }
            searchValue={searchValue}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Bookings;
