import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";

/**
 * Auth service for make call to the server to get Auth Data
 */
export const AuthService = {

    login :async (data : any) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].login, request)
        return result
    },

    resendEmailVerfication :async (data : any) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].resendEmailVerifcation, request)
        return result
    },

    register: async(data: any) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].register, request)
        return result
    },
    forgotPassword: async(data: any) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].forgotPassword, request)
        return result
    },
    verifyPasswordToken: async(data: any) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].verifyPasswordToken, request)
        return result
    },
    resetPassword: async(data: any) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].resetPassword, request)
        return result
    },

}