import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  ADMIN_APPROVE_CLIENT,
  ADMIN_APPROVE_VENDOR,
  GET_ALL_CITIES,
  GET_ALL_CLIENTS,
  GET_ALL_PINCODES,
  GET_ALL_STATES,
  GET_ALL_VENDORS,
  GET_USERS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_SUCCESS,
  SET_ADMIN_APPROVE_CLIENT,
  SET_ADMIN_APPROVE_VENDOR,
  SET_ALL_CITIES,
  SET_ALL_CLIENTS,
  SET_ALL_PINCODES,
  SET_ALL_STATES,
  SET_ALL_VENDORS,
  SET_USERS,
  SET_USER_DASHBOARD_STATS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PROFILE_SUCCESS,
  USER_DASHBOARD_STATS,
  USER_DEFAULT_ACTIONS,
} from "../ReduxConstants";
import { UserService } from "../sevices/user.service";
import { IUpdateUserObject } from "../utils/ObjectTypes";
import { modifySchema } from "./Schema.tsx";
import { API_CONSTANTS } from "../utils/constants";
import { structuredCitiesListForAdmin, structuredClientDetails, structuredPincodeListForAdmin, structuredStatesListForAdmin, structuredVendorsDetails } from "./Schema.tsx/UserSchema";


export const userDefaultActions = (payload) => ({
  type: USER_DEFAULT_ACTIONS,
  payload,
});

const getUserProfile = (payload: object) => ({
  type: GET_USER_PROFILE,
  payload: payload,
});

const getUserProfileSuccess = (payload: object) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: payload,
});

const getUserProfileError = (payload: object) => ({
  type: GET_USER_PROFILE_ERROR,
  payload: payload,
});

const updateUserProfile = (payload: object) => ({
  type: UPDATE_USER_PROFILE,
  payload: payload,
});

const updateUserProfileSuccess = (payload: object) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: payload,
});

const updateUserProfileError = (payload: object) => ({
  type: UPDATE_USER_PROFILE_ERROR,
  payload: payload,
});

const getAllClients = (payload : object) => ({
  type: GET_ALL_CLIENTS,
  payload: payload
})

const setAllClients = (payload : object) => ({
  type : SET_ALL_CLIENTS,
  payload
})

const getAllVendors = (payload : object) => ({
  type: GET_ALL_VENDORS,
  payload: payload
})

const setAllVendors = (payload : object) => ({
  type : SET_ALL_VENDORS,
  payload
})

const getUsers = (payload : object) => ({
  type: GET_USERS,
  payload
})

const setUsers = (payload : object) => ({
  type : SET_USERS,
  payload
})

const adminApproveVendor = (payload : object) => ({
  type: ADMIN_APPROVE_VENDOR,
  payload
})

const setAdminApproveVendor = (payload : object) => ({
  type : SET_ADMIN_APPROVE_VENDOR,
  payload
})

const adminApproveClient = (payload : object) => ({
  type: ADMIN_APPROVE_CLIENT,
  payload
})

const setAdminApproveClient = (payload : object) => ({
  type : SET_ADMIN_APPROVE_CLIENT,
  payload
})

const dashboardStats = (payload : object) => ({
  type: USER_DASHBOARD_STATS,
  payload
})

const setDashboardStats = (payload : object) => ({
  type : SET_USER_DASHBOARD_STATS,
  payload
})

const getAllStates = (payload : object) => ({
  type: GET_ALL_STATES,
  payload: payload
})

const setAllStates = (payload : object) => ({
  type : SET_ALL_STATES,
  payload
})

const getAllCities = (payload : object) => ({
  type: GET_ALL_CITIES,
  payload: payload
})

const setAllCities = (payload : object) => ({
  type : SET_ALL_CITIES,
  payload
})

const getAllPincodes = (payload : object) => ({
  type: GET_ALL_PINCODES,
  payload: payload
})

const setAllPincodes = (payload : object) => ({
  type : SET_ALL_PINCODES,
  payload
})

export const getUserProfileDetails = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getUserProfile(data));
    await UserService.getUserProfile(data)
      .then((result) => {
        dispatch(getUserProfileSuccess(result));
      })
      .catch((error) => {
        dispatch(getUserProfileError(error));
      });
  };
};

export const updateUserProfileDetails = (data: IUpdateUserObject) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(updateUserProfile(data));
    await UserService.updateUserProfile(data)
      .then((result) => {
        dispatch(updateUserProfileSuccess(result));
      })
      .catch((error) => {
        dispatch(updateUserProfileError(error));
      });
  };
};

export const fetchAllClients = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllClients(data));
    await UserService.getAllClients(data)
      .then((result) => {
        dispatch(setAllClients(modifySchema(structuredClientDetails(result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setAllClients(modifySchema(error, API_CONSTANTS.error)));
      });
  };
};

export const fetchAllVendors = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllVendors(data));
    await UserService.getAllVendors(data)
      .then((result) => {
        dispatch(setAllVendors(modifySchema(structuredVendorsDetails(result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setAllVendors(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const fetchUsers = (data: { user : string, id?: string }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getUsers(data));
    await UserService.getUser(data)
      .then((result) => {
        dispatch(setUsers(modifySchema((result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setUsers(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const admminApproveDisapproveVendor = (data: { user : string, id?: string }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(adminApproveVendor(data));
    await UserService.approveDisapproveVendor(data)
      .then((result) => {
        dispatch(setAdminApproveVendor(modifySchema((result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setAdminApproveVendor(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const adminApproveDisapproveClient = (data: { user : string, id?: string }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(adminApproveClient(data));
    await UserService.approveDisapproveClient(data)
      .then((result) => {
        dispatch(setAdminApproveClient(modifySchema((result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setAdminApproveClient(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const fetchDashboardStats = (data: { user : string, id?: string }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(dashboardStats(data));
    await UserService.userDashBoardStats(data)
      .then((result) => {
        dispatch(setDashboardStats(modifySchema((result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setDashboardStats(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const fetchAllStatesForAdmin = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllStates(data));
    await UserService.getAllStatesForAdmin(data)
      .then((result) => {
        dispatch(setAllStates(modifySchema(structuredStatesListForAdmin(result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setAllStates(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const fetchAllCitiesForAdmin = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllCities(data));
    await UserService.getAllCitiesForAdmin(data)
      .then((result) => {
        dispatch(setAllCities(modifySchema(structuredCitiesListForAdmin(result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(setAllCities(modifySchema(error,API_CONSTANTS.error)));
      });
  };
};

export const fetchAllPincodesForAdmin = (data) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getAllPincodes(data));
    await UserService.getAllPincodesForAdmin(data)
      .then((result) => {
        dispatch(
          setAllPincodes(modifySchema(structuredPincodeListForAdmin(result), API_CONSTANTS.success)));
      })
      .catch((error) => {
        dispatch(
          setAllPincodes(modifySchema(error, API_CONSTANTS.error))
        );
      });
  };
};
