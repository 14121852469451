import React from 'react'
import PageTitle from '../../components/Typography/PageTitle'
import FleetsAdd from './Service'

const FleetsAddContainer = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <FleetsAdd {...props}/>
    </div>
  )
}

export default FleetsAddContainer