import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  LOADING_TEXT,
} from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import { ParamsTypes, UserObject } from "../../../../utils/ObjectTypes";
import ComingSoon from "../../../ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRateCardes,
  getAllRateCardList,
  editRateCardes,
} from "../../../../ReduxActions/ratecard.action";
import toast from "react-hot-toast";
import DeleteModal from "../../../Modal/DeleteModal";

interface Data {
  from_zone: string;
  to_zone: string;
  rate: string;
  active : string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "from_zone",
    numeric: false,
    disablePadding: false,
    label: "From Zone",
    callBackArguments: ["id"],
    customCell: false,
  },
  {
    id: "to_zone",
    numeric: false,
    disablePadding: false,
    label: "To Zone",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "active",
    numeric: false,
    disablePadding: false,
    label: "Active",
  },
];

interface Props {
  key?: string;
  match?: any;
  history?: any;
  routeKey?: string;
  userProfileData?: UserObject;
}

const Ratecard: React.FC<Props> = (props) => {
  const { key, match, history, userProfileData, routeKey } = props;

  const param = routeKey;

  // dispatch
  const dispatch = useDispatch();
  // selector
  const allRateCards = useSelector(
    (state: any) => state.RatecardReducer.allRatecards
  );
  const deleteRatecardSelector = useSelector(
    (state: any) => state.RatecardReducer.deleteRatecard
  );
  const editRatecardSelector = useSelector((state: any)=>state.RatecardReducer.editRatecard);

  const [loading, setLoading] = useState({
    allRateCard: false,
    deleteRatecard: false,
    editRatecard:false,
  });
  const [allRateCardData, setAllRateCardData] = useState([]);
  const [rateCardMeta, setRateCardMeta] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rateCardId, setRateCardId] = useState("");

  const getAllRatecards = (page?:number, limit?:number) => {
    dispatch(getAllRateCardList({ user: param, page, limit }));
  };

  useEffect(() => {
    getAllRatecards(1, 10);
  }, []);

  useEffect(() => {
    let toastId: any;
    if (allRateCards.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, allRateCard: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (allRateCards.status === API_CONSTANTS.success && loading.allRateCard) {
      setLoading({ ...loading, allRateCard: false });
      setAllRateCardData(allRateCards.data?.data);
      setRateCardMeta(allRateCards.data?.meta);
      toast.dismiss(toastId);
    }
    if (allRateCards.status === API_CONSTANTS.error && loading.allRateCard) {
      setLoading({ ...loading, allRateCard: false });
      toast.dismiss(toastId);
      toast.error(allRateCards.data);
    }
  }, [allRateCards]);

  useEffect(() => {
    let toastId: any;
    if (deleteRatecardSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, deleteRatecard: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (
      deleteRatecardSelector.status === API_CONSTANTS.success &&
      loading.deleteRatecard
    ) {
      setLoading({ ...loading, deleteRatecard: false });
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      getAllRatecards();
    }
    if (
      deleteRatecardSelector.status === API_CONSTANTS.error &&
      loading.deleteRatecard
    ) {
      setLoading({ ...loading, deleteRatecard: false });
      toast.dismiss(toastId);
      toast.error(deleteRatecardSelector.data);
    }
  }, [deleteRatecardSelector]);

  useEffect(() => {
    let toastId : any;
    if(editRatecardSelector.status === API_CONSTANTS.loading){
        toastId = toast.loading(LOADING_TEXT.fetching);
        setLoading({...loading, editRatecard:true});
    }
    if(editRatecardSelector.status === API_CONSTANTS.success && loading.editRatecard){
        setLoading({...loading, editRatecard:false});
        toast.dismiss(toastId);
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
        getAllRatecards();
    }
    if(editRatecardSelector.status === API_CONSTANTS.error && loading.editRatecard){
        setLoading({...loading, editRatecard:false});
        toast.dismiss(toastId);
        toast.error(editRatecardSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [editRatecardSelector])

  const actions = [
    {
      id: "edit",
      label: "Edit",
      callbackArguments: ["id"],
      onClick: (id) => {
        handleEditRatecard(id);
      },
    },
    {
      id: "delete",
      label: "Delete",
      callbackArguments: ["id"],
      onClick: (id) => {
        setOpenDeleteModal(true);
        setRateCardId(id);
      },
    },
    {
      id: "enable/disable",
      customLabel : (row)=> row.active==="Yes"?"Disable":"Enable",
      callbackArguments: ["id", "active"],
      onClick: (id, active) => {
        enabelAndDisableRateCard(id, active);
      },
    },
  ];

  const enabelAndDisableRateCard = (id, active) => { 
    dispatch(editRateCardes({ user: param, id, body:{ active: active==="Yes"?false:true}}))
  }

  const handleEditRatecard = (id) => {
    history.push(APP_USER_ROUTES[param].EDIT_RATECARD_ALIAS + "/" + id);
  };

  const handleDeleteRateCard = () => {
    dispatch(deleteRateCardes({ id: rateCardId, user: param, body: {} }));
    setOpenDeleteModal(false);
  };

  const addRateCard = () => {
    history.push(APP_USER_ROUTES[param].ADD_RATECARD);
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headingText="Delete Ratecard"
        agreeButtonText="Delete"
        cancelButtonText="Cancel"
        onClickAgree={handleDeleteRateCard}
        content={
          <p>Are you sure ? you want to delete this Rate card.</p>
        }
      />
      <div>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={12}>
            <PageTitle className="mb-0">Rate Cards</PageTitle>
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <Button text="Add Rate Card" type="button" onClick={addRateCard} className="mt-0"/>
          </Grid>
        </Grid>
        <Grid container className=" mt-8">
          {/* <ComingSoon {...props}/> */}
          <TableComponent
            columns={columns}
            rows={allRateCardData}
            actions={actions}
            onPageChange={getAllRatecards}
            meta={rateCardMeta}
            description="You don`t have any rate cards now. Add rate card to get bookings."
            onClickFunc={addRateCard} text="+ Add Rate Card"
          />
        </Grid>
      </div>
    </>
  );
};

export default Ratecard;
