import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "../Button";
import { useCommonStyles } from "../../styles/common";
import { capitalize } from "../../utils/common";
import Popup from "./Popup";

interface Props {
  name?: string;
  source?: string;
  destination?: string;
  rates?: any[];
  mindays?: number;
  maxdays?: number;
  pricepertonne?: number;
  booklink?: string;
  ratings?: number;
  onClick?: any;
  branches?: number;
  vendor_id?:any;
}

/**
 *
 * @param props { name, source, destination, weight, mindays, maxdays, pricepertonne, booklink, ratings }
 * @returns JSX
 */
const RateReviewCardV2: React.FC<Props> = (props) => {
  const classes = useCommonStyles();
  // props destructuring
  const {
    name,
    source,
    destination,
    rates,
    mindays,
    maxdays,
    pricepertonne,
    booklink,
    ratings,
    onClick,
    branches,
    vendor_id
  } = props;
  const [activeVendor, setActiveVendor] = useState<{
    id?: string;
  }>({});

  const Items = [
    {
      name: "Branches",
      value: branches || "N/A",
    },
    {
      name: "Delivery",
      value: "N/A",
    },
    {
      name: "Days",
      value: "3-4 days",
    },
    {
      name: "Success Rate",
      value: "New",
    },
  ];

  React.useEffect(() => {
    setActiveVendor({id: vendor_id})
  }, [])

  return (
    <Card sx={{ maxWidth: "100%" }} className={classes.rateCardsBoxShadow}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#3466E5" }} aria-label="recipe">
            {name.slice(0, 1)}
          </Avatar>
        }
        title={
          <p className="text-brand-text-tableHead text-lg font-semibold">
            {capitalize(name)}
          </p>
        }
        subheader={
          <>
            {Array.from(new Array(5)).map((i, index) => (
              <>
                {index + 1 <= ratings ? (
                  <StarIcon
                    className="text-brand-extra-yellow"
                    fontSize="small"
                    key={index}
                  />
                ) : (
                  <StarIcon
                    className="text-brand-extra-grey"
                    fontSize="small"
                    key={index}
                  />
                )}
              </>
            ))}
          </>
        }
      />
      <CardContent>
        <div className="flex justify-start items-center">
          <p className=" ml-0 mr-5 font-bold text-sm">{capitalize(source)}</p>
          <ArrowForwardIcon
            className="text-brand-primary-blue"
            fontSize="small"
          />
          <p className=" mx-4 font-semibold text-sm">
            {capitalize(destination)}
          </p>
        </div>
        <div className="flex justify-between items-center py-4">
          {Items.map((item, i) => (
            <div className="flex flex-col items-start mr-6" key={i}>
              <p className="text-xs text-brand-text-caption">{item.name}</p>
              <p className=" font-bold text-sm text-brand-text-tableHead">
                {item.value}
              </p>
            </div>
          ))}
        </div>
      </CardContent>
      <Divider className="border-brand-extra-border" />
      <CardActions
        className="flex justify-end items-center"
        style={{ padding: "1rem" }}
      >
        <Button
          text="Book Now"
          className="w-40 mt-0"
          onClick={() => onClick(activeVendor)}
        />
      </CardActions>
    </Card>
  );
};

export default RateReviewCardV2;
