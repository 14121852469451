import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import TableComponent from "../../../Table";
import PageTitle from "../../../Typography/PageTitle";
import { Grid } from "@mui/material";
import {
  API_CONSTANTS,
  APP_USER_ROUTES,
  DEFAULT_ERROR_MESSAGE,
  LOADING_TEXT,
  PLATFORM_USERS,
  bookingStatusFilterAdmin,
  bookingRequestStatusFilterAdmin,
  BOOKING_STATUS_CONSTANTS,
} from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllBookingRequests,
  fetchAllVendorBookings,
  fetchAllVendors,
} from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";
import BookingRequestModal from "./BookingRequestModal";
import BasicSelect from "../../../Dropdown/SimpleSelect";
import SearchInput from "../../../SearchInput";
interface Data {
  booking_id: number;
  route: string;
  material: any;
  client: string;
  type: string;
  pickup_at: string;
  status: string;
  pickup_address: string;
}

interface HeadCell {
  disablePadding?: boolean;
  id?: keyof Data;
  label?: string;
  numeric?: boolean;
  callBackArguments?: Array<string>;
  customCell?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "route",
    numeric: false,
    disablePadding: false,
    label: "Route",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "material",
    numeric: false,
    disablePadding: false,
    label: "Material",
  },
  {
    id: "client",
    numeric: false,
    disablePadding: false,
    label: "Client",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "pickup_at",
    numeric: false,
    disablePadding: false,
    label: "Pickup at",
  },
];

const requestColumns: readonly HeadCell[] = [
  {
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments: ["id", "isItemSelected", "labelId"],
    customCell: false,
  },
  {
    id: "route",
    numeric: false,
    disablePadding: false,
    label: "Route",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "material",
    numeric: false,
    disablePadding: false,
    label: "Material",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "pickup_at",
    numeric: false,
    disablePadding: false,
    label: "Pickup at",
  }
];

interface Props {
  key?: string;
  match?: any;
  routeKey?: string;
  history?: any;
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Bookings: React.FC<Props> = (props) => {
  let uelparams: any = useQuery()
  const [filterBookings, setFilterBookings] = useState({
    status: BOOKING_STATUS_CONSTANTS.all,
  });
  const [filterBookingsReq, setFilterBookingsReq] = useState({
    status: BOOKING_STATUS_CONSTANTS.all,
  });
  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors
  const allbookingsSelector = useSelector(
    (state: any) => state.BookingReducer.allVendorBookings
  );
  const allbookingsRequestsSelector = useSelector(
    (state: any) => state.BookingReducer.bookingRequests
  );
  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request: false,
  });

  const [allBookings, setAllBookings] = useState<any[]>([]);
  const [allBookingrequests, setAllBookingrequests] = useState<any[]>([]);
  const [allBookingMeta, setAllBookingMeta] = useState({});
  const [bookingRequestMeta, setBookingRequestMeta] = useState({});
  const [openBookingRequestmodal, setOpenBookingRequestmodal] = useState(false);
  const [allVendors, setAllVendors] = useState<any[]>([]);
  const [selectedBooking, setSelectedBooking] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [resultText, setResultText] = useState(false);
  const [searchReqValue, setSearchReqValue] = useState("");
  const [resultReqText, setResultReqText] = useState(false);
  const allVendorsSelector = useSelector(
    (state: any) => state.UserReducer.allVendors
  );

  const getAllBookings = (page?: number, limit?: number,search?: string, status?: string) => {
    dispatch(fetchAllVendorBookings({ user: param, page, limit, search, status }));
  };

  const getAllBookingRequests = (
    page?: number,
    limit?: number,
    search?: string,
    status?: string
  ) => {
    dispatch(fetchAllBookingRequests({ user: param, page, limit,search, status }));
  };

  const getAllVendors = () => {
    dispatch(fetchAllVendors({ user: param }));
  };

  const asncFunc = async (bookingsFilter?: string, bookingsReqFilter?: string) => {
    try {
      await getAllBookings(1, 10,searchValue, bookingsFilter);
      await getAllBookingRequests(1, 10,searchReqValue, bookingsReqFilter);
      await getAllVendors();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const bookingsFilter = uelparams.get("bookings")
    const bookingsReqFilter = uelparams.get("bookingsreq")
    bookingsFilter &&
      setFilterBookings({ status: BOOKING_STATUS_CONSTANTS[bookingsFilter] });
    bookingsReqFilter &&
      setFilterBookingsReq({
        status: BOOKING_STATUS_CONSTANTS[bookingsReqFilter],
      });
    asncFunc(bookingsFilter, bookingsReqFilter);
  }, []);

  useEffect(() => {
    let toastId: any;
    if (allbookingsRequestsSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, request: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (
      allbookingsRequestsSelector.status === API_CONSTANTS.success &&
      loading.request
    ) {
      setLoading({ ...loading, request: false });
      setAllBookingrequests([
        ...allbookingsRequestsSelector?.data?.data?.map((items) => {
          items.booking_id = (
            <p
              onClick={() => viewRequestDetails(items.id)}
              className="text-brand-primary-blue cursor-pointer"
            >
              {items.booking_id}
            </p>
          );
          return items;
        }),
      ]);
      setBookingRequestMeta(allbookingsRequestsSelector.data.meta);
      searchReqValue !== "" && setResultReqText(true)
      toast.dismiss(toastId);
    }
    if (
      allbookingsRequestsSelector.status === API_CONSTANTS.error &&
      loading.request
    ) {
      setLoading({ ...loading, request: false });
      toast.dismiss(toastId);
      toast.error(
        allbookingsRequestsSelector.data || DEFAULT_ERROR_MESSAGE.failed
      );
    }
  }, [allbookingsRequestsSelector]);

  useEffect(() => {
    let toastId: any;
    if (allbookingsSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, all: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (allbookingsSelector.status === API_CONSTANTS.success) {
      setLoading({ ...loading, all: false });
      setAllBookings([
        ...allbookingsSelector?.data?.data?.map((items) => {
          items.booking_id = (
            <p
              onClick={() => viewBookingDetails(items.id)}
              className="text-brand-primary-blue cursor-pointer"
            >
              {items.booking_id}
            </p>
          );
          return items;
        }),
      ]);
      setAllBookingMeta(allbookingsSelector.data.meta);
      searchValue != "" && setResultText(true)
      toast.dismiss(toastId);
    }
    if (allbookingsSelector.status === API_CONSTANTS.error) {
      setLoading({ ...loading, all: false });
      toast.dismiss(toastId);
      toast.error(allbookingsSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allbookingsSelector]);

  useEffect(() => {
    let toastId: any;
    if (allVendorsSelector.status === API_CONSTANTS.loading) {
      setLoading({ ...loading, all: true });
      toastId = toast.loading(LOADING_TEXT.fetching);
    }
    if (allVendorsSelector.status === API_CONSTANTS.success) {
      setLoading({ ...loading, all: false });
      setAllVendors(
        [...allVendorsSelector?.data?.data].filter(
          (vendor) => vendor.is_approved
        )
      );
      toast.dismiss(toastId);
    }
    if (allVendorsSelector.status === API_CONSTANTS.error) {
      setLoading({ ...loading, all: false });
      toast.dismiss(toastId);
      toast.error(allVendorsSelector.data || DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allVendorsSelector]);

  // table confirmed booking actions
  const actions = () => {
    if (param === PLATFORM_USERS.ADMIN) {
      return [
        {
          id: "view",
          label: "View Details",
          callbackArguments: ["id"],
          onClick: (id) => {
            viewBookingDetails(id);
          },
        },
        {
          id: "sendRequest",
          label: "Send booking request",
          callbackArguments: ["id"],
          onClick: (id) => {
            handleOpenBookingRequestmodal(id);
          },
        },
      ];
    } else {
      return [
        {
          id: "view",
          label: "View Details",
          callbackArguments: ["id"],
          onClick: (id) => {
            viewBookingDetails(id);
          },
        },
      ];
    }
  };

  // table request booking actions
  const requestActions = [
    {
      id: "view",
      label: "View Details",
      callbackArguments: ["id"],
      onClick: (id) => {
        viewRequestDetails(id);
      },
    },
  ];

  // view booking details
  const viewBookingDetails = (id) => {
    history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS + "/" + id);
  };

  // view requests details
  const viewRequestDetails = (id) => {
    history.push(APP_USER_ROUTES[param].REQUEST_DETAILS_ALIAS + "/" + id);
  };
  const handleOpenBookingRequestmodal = (id) => {
    setSelectedBooking(id);
    setOpenBookingRequestmodal(true);
  };

  const handleBookingFilter = (key, value) => {
    if (value === BOOKING_STATUS_CONSTANTS.all) {
      setFilterBookings({
        ...filterBookings,
        [key]: value,
      });
      getAllBookings(1, 10, searchValue);
    } else {
      setFilterBookings({
        ...filterBookings,
        [key]: value,
      });
      getAllBookings(1, 10, searchValue, value);
    }
  };

  const handleBookingReqFilter = (key, value) => {
    if (value === BOOKING_STATUS_CONSTANTS.all) {
      setFilterBookingsReq({
        ...filterBookingsReq,
        [key]: value,
      });
      getAllBookingRequests(1, 10,searchReqValue);
    } else {
      setFilterBookingsReq({
        ...filterBookingsReq,
        [key]: value,
      });
      getAllBookingRequests(1, 10,searchReqValue, value);
    }
  };
  const handleSearch = (value: string) => {
    if (value !== "") {
      setSearchValue(value)
      if (filterBookings["status"] === BOOKING_STATUS_CONSTANTS.all) {
        getAllBookings(1, 10, value)
      }
      else {
        getAllBookings(1, 10, value, filterBookings["status"])
      }
    }
    else {
      toast.error("Please enter a value")
    }

  }

  const handleEnterSearch = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      handleSearch(value);
    }

  }

  const resetSearch = () => {
    setSearchValue("");
    setResultText(false)
    if (filterBookings["status"] === BOOKING_STATUS_CONSTANTS.all) {
      getAllBookings(1, 10, "")
    }
    else {
      getAllBookings(1, 10, "", filterBookings["status"])
    }
  }

  const handleRequestSearch = (value: string) => {
    if (value !== "") {
      setSearchReqValue(value)
      if (filterBookingsReq["status"] === BOOKING_STATUS_CONSTANTS.all) {
        getAllBookingRequests(1, 10, value)
      }
      else {
        getAllBookingRequests(1, 10, value, filterBookingsReq["status"])
      }
    }
    else {
      toast.error("Please enter a value")
    }

  }

  const handleEnterReqSearch = (e: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (e.key === "Enter") {
      handleRequestSearch(value)
    }

  }

  const resetSearchReq = () => {
    setSearchReqValue("");
    setResultReqText(false)
    if (filterBookingsReq["status"] === BOOKING_STATUS_CONSTANTS.all) {
      getAllBookingRequests(1, 10, "")
    }
    else {
      getAllBookingRequests(1, 10, "", filterBookingsReq["status"])
    }
  }
  return (
    <div>
      {param === PLATFORM_USERS.ADMIN && (
        <BookingRequestModal
          open={openBookingRequestmodal}
          setOpen={setOpenBookingRequestmodal}
          allVendors={allVendors}
          bookingId={selectedBooking}
          param={param}
        />
      )}
      <Grid
        container
        className={classes.mt1pt5}
      >
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">All Bookings</PageTitle>
        </Grid>
        <Grid
          container
          className="my-2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={8}>
            <BasicSelect
              label="Filter bookings"
              options={bookingStatusFilterAdmin}
              onChange={(e) => handleBookingFilter("status", e.target.value)}
              value={filterBookings["status"]}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={8}
              style={{ maxWidth: "80%" }}
            >
              <SearchInput
                placeholder="Search here"
                handleSearch={handleSearch}
                handleEnterSearch={handleEnterSearch}
                resultText={resultText}
                total={allBookingMeta["total"]}
                resetSearch={resetSearch}
                searchValue={searchValue}
                filter={bookingStatusFilterAdmin.filter(item => {return item.value === filterBookings.status})}
              />
            </Grid>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          {/* <ComingSoon {...props}/> */}
          <TableComponent
            columns={columns}
            rows={allBookings}
            actions={actions()}
            onPageChange={getAllBookings}
            meta={allBookingMeta}
            status={
              filterBookings["status"] !== BOOKING_STATUS_CONSTANTS.all
                ? filterBookings["status"]
                : undefined
            }
            searchValue={searchValue}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.mt1pt5}>
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-2">All Bookings Requests</PageTitle>
        </Grid>
        <Grid
          container
          className="my-2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={8}>
            <BasicSelect
              label="Filter bookings"
              options={bookingRequestStatusFilterAdmin}
              onChange={(e) => handleBookingReqFilter("status", e.target.value)}
              value={filterBookingsReq["status"]}
            />
          </Grid>
          <Grid item lg={2} md={4} xs={8}
              style={{ maxWidth: "80%" }}
            >
              <SearchInput
                placeholder="Search here"
                handleSearch={handleRequestSearch}
                handleEnterSearch={handleEnterReqSearch}
                resultText={resultReqText}
                total={bookingRequestMeta["total"]}
                resetSearch={resetSearchReq}
                searchValue={searchReqValue}
                filter={bookingRequestStatusFilterAdmin.filter(item => {return item.value === filterBookingsReq.status})}
              />
            </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          <TableComponent
            columns={requestColumns}
            rows={allBookingrequests}
            actions={requestActions}
            onPageChange={getAllBookingRequests}
            meta={bookingRequestMeta}
            status={
              filterBookingsReq["status"] !== BOOKING_STATUS_CONSTANTS.all
                ? filterBookingsReq["status"]
                : undefined
            }
            searchValue={searchReqValue}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Bookings;
