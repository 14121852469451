import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { IUpdateUserObject } from "../utils/ObjectTypes";
import { PLATFORM_USERS } from "../utils/constants";

export const UserService = {
  getUserProfile: async (data: { user: string }) => {
    const request = { data };
    const result = await axiosInstance.get(API_URLS[data.user].currentUser);
    return result;
  },

  updateUserProfile: async (data: IUpdateUserObject) => {
    const request = { ...data.body };
    const result = await axiosInstance.put(
      API_URLS[data.user].profileUpdate(data.id),
      request
    );
    return result;
  },

  getAllClients: async (data: {
    user: string;
    page?: number;
    limit?: number;
  }) => {
    const result = await axiosInstance.get(
      API_URLS[data.user].clients + `?page=${data?.page}&limit=${data?.limit}`
    );
    return result;
  },

  getAllVendors: async (data: {
    user: string;
    page?: number;
    limit?: number;
  }) => {
    if (data?.page && data?.limit) {
      const result = await axiosInstance.get(
        API_URLS[data.user].vendors + `?page=${data?.page}&limit=${data?.limit}`
      );
      return result;
    } else {
      const result = await axiosInstance.get(API_URLS[data.user].vendors);
      return result;
    }
  },

  postBookingResquest: async (data: {
    user: string;
    id: number;
    data: { vendors: any[] };
  }) => {
    try {
      const result = await axiosInstance.post(
        API_URLS[data.user].postBookingRequest(data.id),
        data.data
      );
      console.log(result)
      return result;
    } catch (e) {
      console.log(e);
    }
  },

  postUserPreferences: async (data: {
    user: string;
    data: { vendor_id: string, preferences: any[] };
  }) => {
    try {
      const result = await axiosInstance.post(
        API_URLS[data.user].postUserPreferences,
        data.data
      );
      console.log(result)
      return result;
    } catch (e) {
      console.log(e);
    }
  },

  getUser: async (data: { user: string; id?: string }) => {
    const result = await axiosInstance.get(
      API_URLS[data.user].getUsers(data?.id)
    );
    return result;
  },

  approveDisapproveVendor: async (data: { user: string; id?: string }) => {
    const result = await axiosInstance.patch(
      API_URLS[data.user].approveDisapproveVendor(data?.id)
    );
    return result;
  },

  approveDisapproveClient: async (data: { user: string; id?: string }) => {
    const result = await axiosInstance.patch(
      API_URLS[data.user].approveDisapproveClient(data?.id)
    );
    return result;
  },

  userDashBoardStats: async (data: { user: string }) => {
    const result = await axiosInstance.get(API_URLS[data.user].adminStats);
    return result;
  },

  // States for Admin
  getAllStatesForAdmin: async (data: {
    user: string;
  }) => {
    const result = await axiosInstance.get(API_URLS[data.user].states);
    return result;
  },

  // Cities for Admin
  getAllCitiesForAdmin: async (data: {
    user: string, search?: string
  }) => {
    const result = await axiosInstance.get(API_URLS[data.user].cities + `${data.search ? `?name=${data.search}` : ''}`);
    return result;
  },

  // Pincodes for Admin
  getAllPincodesForAdmin: async (data: {
    user: string, page?: number, limit?: number, search?: string
  }) => {
    const result = await axiosInstance.get(API_URLS[data.user].pincodes+ `?page=${data?.page}&limit=${data?.limit}${data.search ? `&query=${data.search}` : ''}`);
    return result;
  },

};
