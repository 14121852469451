import React, { lazy } from 'react';
import AppContainer from './containers/App';
import {Toaster} from 'react-hot-toast'; 
import { toastOptions } from './utils/constants';
import NotFound from './containers/NotFound';
import MuiThemeProvider from '../src/utils/ThemeProvider';

interface State {
  errorInfo ?: any;
  error ?: any
}

interface Props {
  children ?: any;
}

class ErrorManage extends React.Component<Props, State> {
  constructor(props : Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return <NotFound />;
    }
    return this.props.children;
  }
}

function App() {
  return (
    <>
    <MuiThemeProvider>
      <ErrorManage>
        <Toaster toastOptions={toastOptions} />
        <AppContainer/>
      </ErrorManage>
    </MuiThemeProvider>
    </>
  );
}
export default App;
