import React, { useState, useEffect } from "react";
import PageTitle from "../../components/Typography/PageTitle";
import CommonInput from "../../components/Input/MaterialInput";
import CommonDropdown from "../../components/Dropdown";
import { Grid } from "@mui/material";
import { handleCheckValidation } from "../../ValidationHelpers";
import CommonButton from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileDetails, getUserProfileDetails } from "../../ReduxActions";
import toast from "react-hot-toast";
import { API_CONSTANTS, DEFAULT_ERROR_MESSAGE, LOADING_TEXT } from "../../utils/constants";
import { LoadTypes, UserObject } from "../../utils/ObjectTypes";
import { getCityData, getCountryData, getPinCodeData, getStatesData } from "../../ReduxActions/staticAction";
import MaterialSingleSearchDropdown from "../../components/Dropdown/SingleSearchDropdown";
import { staructureCityData, structureCityObjectData, structureCountryData, structurePincodeCityData } from "../../utils/common";
import CommonTextArea from "../TextArea";

interface Props {
  history?: any;
  match?: any;
  location?: any;
  userProfileData ?: UserObject;
  handleSubmitDetails ?: any;
  routeKey ?: string;
  additional: boolean;
  context ?: string;
}

interface ValidationObject {
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  pin_code?: string;
  city?: string;
  state?: string;
  country?: string;
  gst_number?: string;
  pan_number?: string;
  pincode_id ?: string;
  city_id ?: string;
  state_id ?: string;
  country_id ?: string;
  type ?: string;
  address ?: string;
  area ?: string
}

const inputFields = [
  {
    label: "Contact First name",
    key: "first_name",
    type: "text",
    required :true,
    disbaled : false,
  },
  {
    label: "Contact Last name",
    key: "last_name",
    type: "text",
    required :false,
    disbaled : false,
  },
  {
    label: "Contact Phone No.",
    key: "phone",
    type: "number",
    required :true,
    disbaled : false,
  },
  {
    label: "Contact Email",
    key: "email",
    type: "email",
    required :true,
    disbaled : false,
  },
];

const UserDetails: React.FC<Props> = (props) => {

  const { userProfileData, handleSubmitDetails, routeKey, additional, context } = props;
  
  const dispatch = useDispatch();
  const countrySelector = useSelector((state: any) => state.StaticReducer.country);
  const pincodeDataSelector = useSelector((state: any)=> state.StaticReducer.pincodeData);
  const cityDataSelector = useSelector((state: any)=> state.StaticReducer.city);
  const stateDataSelector = useSelector((state: any)=> state.StaticReducer.states);
  const [countryData, setCountryData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    id : '',
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    gst_number: "",
    pan_number: "",
    pincode_id : "",
    city_id : "",
    state_id : "",
    country_id: "",
    type : "ftl",
    address : "",
    area: ""
  });
  const [validationObj, setValidationObj] = useState<ValidationObject>({});
  const [searchText, setSearchText] = useState({key : '', value: ''});
  const [cityPincodeData, setCityPincodeData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    // dispatch(getUserProfileDetails({user : userProfileData.role}));
    // get state data
    dispatch( getCountryData({}) );
    getStateById('');
    if(userProfileData.city_id){
      getCityById(userProfileData.city_id);
    }
  }, [])

  useEffect(() => {
    if(userProfileData?._pincode) setCityPincodeData(structurePincodeCityData([userProfileData?._pincode]));
    if(userProfileData?._city) setCityData(staructureCityData([userProfileData?._city]));
    if(userProfileData?._state) setStateData(staructureCityData([userProfileData?._state]))
    setUserDetails({
        ...userDetails,
        first_name : userProfileData.first_name,
        last_name  : userProfileData.last_name,
        email : userProfileData.email,
        pan_number : userProfileData.pan_number,
        gst_number : userProfileData.gst_number,
        pincode_id : userProfileData.pincode_id,
        city_id : userProfileData.city_id,
        state_id : userProfileData.state_id,
        country_id: userProfileData.country_id,
        phone : userProfileData.contact_number,
        type: userProfileData.type,
        address : userProfileData.address,
        area : userProfileData?.area
    });
  }, [userProfileData])
  

  const handleChangeSearchText = (key, value) => {
    setSearchText({key :key, value:value})
  }
  
  const getStateById = (id) => {
    dispatch( getStatesData({state: id}) );
  }

  const getCityById = (id) => { dispatch( getCityData( { city_id : id })) }

  useEffect(() => {
    if(searchText.key==='pincode' && searchText.value.length>=4 || searchText.value.length<=7 && (typeof searchText.value==="number")){
      dispatch(
        getPinCodeData({
          location : searchText.value
        })
      )
    }
    else if(searchText.key==='city' && searchText.value.length>0){
      dispatch(getCityData({city : searchText.value}))
    }
    else if(searchText.key==='state' && searchText.value.length>0){
      getStateById(searchText.value)
    }
  }, [searchText])

  useEffect(() => {
   if(countrySelector.status === API_CONSTANTS.success){
    setCountryData(structureCountryData(countrySelector.data?.data))
   }
  }, [countrySelector])

  useEffect(() => {
    if(pincodeDataSelector.status === API_CONSTANTS.loading){
      setCityPincodeData([]);
    }
    if(pincodeDataSelector.status === API_CONSTANTS.success){
      setCityPincodeData(structurePincodeCityData(pincodeDataSelector.data?.data));
    }
  }, [pincodeDataSelector])

  useEffect(() => {
    if(cityDataSelector.status === API_CONSTANTS.loading){
      setCityData([]);
    }
    if(cityDataSelector.status === API_CONSTANTS.success){
      if(cityDataSelector.data?.data?.length){
        setCityData(staructureCityData(cityDataSelector.data?.data));
      }else{
        setCityData([structureCityObjectData(cityDataSelector.data)]);
      }
    }
  }, [cityDataSelector])

  useEffect(() => {
    if(stateDataSelector.status === API_CONSTANTS.success){
      setStateData(staructureCityData(stateDataSelector.data?.data));
    }
  }, [stateDataSelector])
  
  const handleInputChange = (key, value) => {
    setUserDetails({ ...userDetails, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation: ValidationObject = handleCheckValidation(userDetails);
    setValidationObj(validation);
    if (
      validation &&
      !validation.first_name &&
      !validation.last_name &&
      !validation.city_id &&
      !validation.phone &&
      !validation.email &&
      !validation.state_id &&
      !validation.area &&
      !validation.pincode_id
    ) {
        handleSubmitDetails({body : {
        first_name : userDetails.first_name,
        last_name  : userDetails.last_name,
        pincode_id : userDetails.pincode_id,
        email : userDetails.email,
        city_id : userDetails.city_id,
        state_id : userDetails.state_id,
        country_id : userDetails.country_id||countryData[0]?.value,
        type : userDetails.type,
        address : userDetails.address,
        contact_number : userDetails.phone,
        area : userDetails.area,
        gst_number : additional ? userDetails.gst_number : null,
        pan_number : additional ? userDetails.pan_number : null,
      }});
    }
  };
  
  return (
      <div className="bg-brand-primary-white px-8 py-8 rounded-lg">
        <div>
          <p className="text-brand-text-title text-xl">General Information</p>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <Grid container>
                {inputFields.map(
                  (field: { label: string; key: string; type: string; required?:boolean; disabled?: boolean }) => (
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <CommonInput
                        label={field.label}
                        type={field.type}
                        disabled={field.key==='email'&&context==='edit'?true:false}
                        placeholder={`Enter your ${field.label.toLowerCase()}`}
                        onChange={(e) =>
                          handleInputChange(field.key, e.target.value)
                        }
                        value={userDetails[field.key]}
                        validationKey={field.required && validationObj[field.key]}
                      />
                    </Grid>
                  )
                )}
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="Type"
                    onChange={(value) => handleInputChange("type", value)}
                    value={userDetails.type}
                    validationKey={validationObj.type}
                    options={LoadTypes}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}/>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <CommonInput
                    label="Area"
                    onChange={(e) =>
                      handleInputChange("area", e.target.value)
                    }
                    value={userDetails?.area}
                    validationKey={validationObj?.area}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <CommonInput
                    label="Address"
                    onChange={(e) =>
                      handleInputChange("address", e.target.value)
                    }
                    // value={userDetails.country}
                    value={userDetails?.address}
                    // validationKey={validationObj.country_id}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="Pincode"
                    onChange={(value) => handleInputChange("pincode_id", value)}
                    value={userDetails.pincode_id}
                    onChangeText={(e)=>handleChangeSearchText('pincode', e.target.value)}
                    // searchType={true}
                    validationKey={validationObj.pincode_id}
                    options={cityPincodeData}
                    placeholder="Type initial 4 digits"
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="City"
                    onChange={(value) => handleInputChange("city_id", value)}
                    value={userDetails.city_id}
                    onChangeText={(e)=>handleChangeSearchText('city', e.target.value)}
                    // searchType={true}
                    validationKey={validationObj.city_id}
                    options={cityData}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="State"
                    onChange={(value) => handleInputChange("state_id", value)}
                    value={userDetails.state_id}
                    // onChangeText={(e)=>handleChangeSearchText('state', e.target.value)}
                    validationKey={validationObj.state_id}
                    options={stateData}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="Country"
                    onChange={(value) =>
                      handleInputChange("country_id", value)
                    }
                    // value={userDetails.country}
                    value={countryData[0]?.value}
                    // validationKey={validationObj.country_id}
                    options={countryData}
                    disabled={true}
                  />
                </Grid>
              </Grid>
                {
                  additional &&
                  <>
              <Grid container className="mt-8">
                  <Grid item lg={12} md={12} xs={12} className="py-3">
                    <p className="text-brand-text-title text-xl">
                      Additional Information
                    </p>
                    <p className="text-brand-text-caption hidden">
                      Fill you additional data info
                    </p>
                  </Grid>
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <CommonInput
                        label={"GST Number"}
                        type={"text"}
                        placeholder={`Enter GST Number`}
                        onChange={(e) =>
                          handleInputChange("gst_number", e.target.value)
                        }
                        value={userDetails["gst_number"]}
                        // validationKey={validationObj["gst_number"]}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <CommonInput
                        label={"PAN Number"}
                        type={"text"}
                        placeholder={`Enter PAN Number`}
                        onChange={(e) =>
                          handleInputChange("pan_number", e.target.value)
                        }
                        value={userDetails["pan_number"]}
                        // validationKey={validationObj["pan_number"]}
                      />
                    </Grid>
                    </Grid>
                  </>
                }
              <Grid container className="mt-6">
                <Grid item lg={1} md={2} xs={12}>
                  <CommonButton text={"Save"} type="submit" />
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
  );
};

export default UserDetails;
