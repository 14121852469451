import React from 'react';
import AddEditRatecard from '../../components/role/vendor/RateCard/AddEditRatecards';

interface Props {

}

const AddEditRateCardContainer: React.FC<Props> = (props) => {
  return (
    <div className='px-6 py-6 overflow-x-auto'>
        <AddEditRatecard {...props}/>
    </div>
  )
}

export default AddEditRateCardContainer