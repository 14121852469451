import {
  CREATE_BOOKING_FTL,
  CREATE_BOOKING_PTL,
  GET_ALL_BOOKING_REQUESTS,
  GET_ALL_CLIENT_BOOKINGS,
  GET_ALL_VENDOR_BOOKINGS,
  GET_BOOKING,
  GET_VENDOR_BOOKING_DETAILS,
  SET_ALL_BOOKING_REQUESTS,
  SET_BOOKING,
  SET_CREATE_BOOKING_FTL,
  SET_CREATE_BOOKING_PTL,
  SET_GET_ALL_CLIENT_BOOKINGS,
  SET_GET_ALL_VENDOR_BOOKINGS,
  SET_VENDOR_BOOKING_DETAILS,
  ALL_VENDOR_LIST,
  SET_ALL_VENDOR_LIST,
  CONFIRM_CLIENT_BOOKING_REQUEST,
  SET_CONFIRM_CLIENT_BOOKING_REQUEST,
  CLIENT_RECOSIDER_BOOKING,
  SET_CLIENT_RECONSIDER_BOOKING,
  VENDOR_ACCEPT_BOOKING_REQUEST,
  SET_VENDOR_ACCEPT_BOOKING_REQUEST,
  VENDOR_REJECT_BOOKING_REQUEST,
  SET_VENDOR_REJECT_BOOKING_REQUEST,
  VENDOR_NEGOTIATE_BOOKING_REQUEST,
  SET_VENDOR_NEGOTIATE_BOOKING_REQUEST,
  UPDATE_TRACKING,
  SET_UPDATE_TRACKING,
  GET_VENDOR_BOOKINGS,
  SET_VENDOR_BOOKINGS,
  GET_VENDOR_BOOKING_REQUESTS,
  SET_VENDOR_BOOKING_REQUESTS,
  GET_BOOKING_STATS,
  SET_BOOKING_STATS,
  GET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS,
  SET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS
} from "../ReduxConstants/BookingConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  // client
  searchSpecificbookingReq : SubstateObject;
  allClientBookings: SubstateObject;
  createBookingFtl: SubstateObject;
  createBookingPtl: SubstateObject;
  clientBookingDetails : SubstateObject;
  allVendors: SubstateObject;
  confirmBooking : SubstateObject;
  reconsiderBooking : SubstateObject;
  
  // vendor
  allVendorBookings: SubstateObject;
  bookingRequests: SubstateObject;
  vendorBooking: SubstateObject;
  acceptBooking: SubstateObject;
  rejectBooking: SubstateObject;
  negotiateBooking: SubstateObject;
  updateTracking: SubstateObject;

  //admin
  vendorAllBookings: SubstateObject;
  vendorAllBookingRequests: SubstateObject;

  bookingStats: SubstateObject;
}

const initialState: InitialState = {
  allClientBookings: {
    data: null,
    status: null,
  },
  searchSpecificbookingReq: {
    data: null,
    status: null,
  },
  createBookingFtl: {
    data: null,
    status: null,
  },
  createBookingPtl: {
    data: null,
    status: null,
  },
  clientBookingDetails: {
    data: null,
    status: null,
  },
  allVendors: {
    data: null,
    status: null,
  },
  confirmBooking: {
    data: null,
    status: null,
  },
  reconsiderBooking: {
    data: null,
    status: null,
  },
  allVendorBookings: {
    data: null,
    status: null,
  },
  bookingRequests: {
    data: null,
    status: null,
  },
  vendorBooking: {
    data: null,
    status: null,
  },
  acceptBooking: {
    data: null,
    status: null,
  },
  rejectBooking: {
    data: null,
    status: null,
  },
  negotiateBooking: {
    data: null,
    status: null,
  },
  updateTracking: {
    data: null,
    status: null,
  },
  vendorAllBookings: {
    data: null,
    status: null,
  },
  vendorAllBookingRequests: {
    data: null,
    status: null,
  },
  bookingStats: {
    data: null,
    status: null,
  }
  
};

const BookingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS: {
      return {
        ...initialState,
        searchSpecificbookingReq: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_SPECIFIC_SEARCH_BOOKINGS_REQUESTS: {
      return {
        ...initialState,
        searchSpecificbookingReq: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    case GET_ALL_CLIENT_BOOKINGS: {
      return {
        ...initialState,
        allClientBookings: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_GET_ALL_CLIENT_BOOKINGS: {
      return {
        ...initialState,
        allClientBookings: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    case ALL_VENDOR_LIST: {
      return {
        ...initialState,
        allVendors: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_ALL_VENDOR_LIST: {
      return {
        ...initialState,
        allVendors: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    case GET_ALL_VENDOR_BOOKINGS: {
      return {
        ...initialState,
        allVendorBookings: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_GET_ALL_VENDOR_BOOKINGS: {
      return {
        ...initialState,
        allVendorBookings: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case CONFIRM_CLIENT_BOOKING_REQUEST: {
      return {
        ...initialState,
        confirmBooking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_CONFIRM_CLIENT_BOOKING_REQUEST: {
      return {
        ...initialState,
        confirmBooking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case CLIENT_RECOSIDER_BOOKING: {
      return {
        ...initialState,
        reconsiderBooking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_CLIENT_RECONSIDER_BOOKING: {
      return {
        ...initialState,
        reconsiderBooking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case CREATE_BOOKING_FTL: {
      return {
        ...initialState,
        createBookingFtl: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_CREATE_BOOKING_FTL: {
      return {
        ...initialState,
        createBookingFtl: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case CREATE_BOOKING_PTL: {
      return {
        ...initialState,
        createBookingPtl: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_CREATE_BOOKING_PTL: {
      return {
        ...initialState,
        createBookingPtl: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_BOOKING: {
      return {
        ...initialState,
        clientBookingDetails: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_BOOKING: {
      return {
        ...initialState,
        clientBookingDetails: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_ALL_VENDOR_BOOKINGS: {
      return {
        ...initialState,
        allVendorBookings: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_GET_ALL_VENDOR_BOOKINGS: {
      return {
        ...initialState,
        allVendorBookings: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_ALL_BOOKING_REQUESTS: {
      return {
        ...initialState,
        bookingRequests: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_ALL_BOOKING_REQUESTS: {
      return {
        ...initialState,
        bookingRequests: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_VENDOR_BOOKING_DETAILS: {
      return {
        ...initialState,
        vendorBooking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_BOOKING_DETAILS: {
      return {
        ...initialState,
        vendorBooking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case VENDOR_ACCEPT_BOOKING_REQUEST: {
      return {
        ...initialState,
        acceptBooking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_ACCEPT_BOOKING_REQUEST: {
      return {
        ...initialState,
        acceptBooking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case VENDOR_REJECT_BOOKING_REQUEST: {
      return {
        ...initialState,
        rejectBooking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_REJECT_BOOKING_REQUEST: {
      return {
        ...initialState,
        rejectBooking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case VENDOR_NEGOTIATE_BOOKING_REQUEST: {
      return {
        ...initialState,
        negotiateBooking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_NEGOTIATE_BOOKING_REQUEST: {
      return {
        ...initialState,
        negotiateBooking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case UPDATE_TRACKING: {
      return {
        ...initialState,
        updateTracking: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_UPDATE_TRACKING: {
      return {
        ...initialState,
        updateTracking: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_VENDOR_BOOKINGS: {
      return {
        ...initialState,
        vendorAllBookings: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_BOOKINGS: {
      return {
        ...initialState,
        vendorAllBookings: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_VENDOR_BOOKING_REQUESTS: {
      return {
        ...initialState,
        vendorAllBookingRequests: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_BOOKING_REQUESTS: {
      return {
        ...initialState,
        vendorAllBookingRequests: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    case GET_BOOKING_STATS: {
      return {
        ...initialState,
        bookingStats: {
            data: null,
            status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_BOOKING_STATS: {
      return {
        ...initialState,
        bookingStats: {
            data: payload.data,
            status: payload.status,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default BookingReducer;
