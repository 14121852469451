// get all zone
export const GET_ALL_ZONES = 'app/zones/getAllZones';
export const GET_ALL_ZONES_SUCCESS = 'app/zones/getAllZoneSuccess';
export const GET_ALL_ZONES_ERROR = 'app/Zones/getAllZoneError';

// get zone
export const GET_ZONE = 'app/Zone/getZone';
export const GET_ZONE_SUCCESS = 'app/Zone/getZoneSuccess';
export const GET_ZONE_ERROR = 'app/Zone/getZoneError';

// add zone
export const ADD_ZONE = 'app/zones/addZones';
export const ADD_ZONE_SUCCESS = 'app/zones/addZoneSuccess';
export const ADD_ZONE_ERROR = 'app/Zones/addZoneError';

//edit zone
export const EDIT_ZONE = 'app/zones/editZones';
export const EDIT_ZONE_SUCCESS = 'app/zones/editZoneSuccess';
export const EDIT_ZONE_ERROR = 'app/Zones/editZoneError';

//delete zone
export const DELETE_ZONE = 'app/zones/deleteZones';
export const DELETE_ZONE_SUCCESS = 'app/zones/deleteZoneSuccess';
export const DELETE_ZONE_ERROR = 'app/Zones/deleteZoneError';