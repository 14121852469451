import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from '../../../Button';
import { Grid } from "@mui/material";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT} from '../../../../utils/constants';
import { Link, useParams } from "react-router-dom";
import { ParamsTypes } from "../../../../utils/ObjectTypes";
import ComingSoon from "../../../ComingSoon";
import { useDispatch, useSelector } from "react-redux";
import { admminApproveDisapproveVendor, fetchAllBookingRequests, fetchAllVendorBookings, fetchAllVendors, getStatesData } from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";
import { PLATFORM_USERS } from '../../../../utils/constants';
import VendorPreferencesModal from "../Bookings/VendorPreferencesModal";
import { staructureCityData, structureStateData } from "../../../../utils/common";

interface Data {
  name: string;
  address: string;
  company_name: any;
  city: string;
  pincode: string;
  is_email_verified:string;
  is_approved: string;
  type: string;
  created_at: string;
  last_accessed: string;
  preferences: string;
  preferences_raw: any;
}

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    callBackArguments : ['id'],
    customCell: false,
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: false,
    label: "Company Name",
    // callBackArguments : ['id'],
    // customCell: false,
  },
  {
    id: "is_email_verified",
    numeric: false,
    disablePadding: false,
    label: "Verified",
  },
  {
    id: "is_approved",
    numeric: false,
    disablePadding: false,
    label: "Approved",
  },
  {
    id: "last_accessed",
    numeric: false,
    disablePadding: false,
    label: "Last Activity",
  },
  {
    id: "preferences",
    numeric: false,
    disablePadding: false,
    label: "State Preferences",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "pincode",
    numeric: false,
    disablePadding: false,
    label: "Pincode",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Registered on",
  },
];

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}

const ShowVendors: React.FC<Props> = (props) => {
  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch();
  // selectors 
  const allVendorsSelector = useSelector((state: any)=>state.UserReducer.allVendors)
  const approveSelector = useSelector((state: any)=>state.UserReducer.approve)
  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request : false,
  });
  const [allVendors, setAllVendors] = useState<any[]>([]);
  const [allVendorsMeta, setAllVendorsMeta] = useState({})
  const [openVendorPreferencesmodal, setOpenVendorPreferencesmodal] = useState(false);
  const [selectedVendorForPreference, setSelectedVendorForPreference] = useState();
  const [pastVendorPreferences, setPastVendorPreferences] = useState([]);
  const stateDataSelector = useSelector((state: any)=> state.StaticReducer.states);
  const [stateData, setStateData] = useState([]);
  
  const getAllVendors = (page?:number, limit?:number) => {
    dispatch(fetchAllVendors({ user : param, page, limit }));
  }

  const handleVendorPreferencesModal = (id, preferences_raw) => {
    setSelectedVendorForPreference(id);
    setPastVendorPreferences(preferences_raw);
    setOpenVendorPreferencesmodal(true);
  };

  const getStateById = (id) => {
    dispatch( getStatesData({state: id}) );
  }

  useEffect(() => {
    getAllVendors(1, 10);
    getStateById('');
  }, [])

  useEffect(() => {
    if(!openVendorPreferencesmodal) {
      getAllVendors(1, 10);
    }
  }, [openVendorPreferencesmodal])

  useEffect(() => {
    if(stateDataSelector.status === API_CONSTANTS.success){
      setStateData(structureStateData(stateDataSelector.data?.data));
    }
  }, [stateDataSelector])

  useEffect(() => {
    let toastId : any;
    if(allVendorsSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allVendorsSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      setAllVendors([...allVendorsSelector?.data?.data.filter(itms => itms.role === PLATFORM_USERS.VENDOR).map((items)=>{
        items.name = <p onClick={()=>viewVendorDetails(items.id)} className="text-brand-primary-blue cursor-pointer">{items.name}</p>
        return items
      })]);
      setAllVendorsMeta(allVendorsSelector.data?.meta);
      toast.dismiss(toastId);
    }
    if(allVendorsSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allVendorsSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allVendorsSelector])

  useEffect(() => {
    if(approveSelector.status ===API_CONSTANTS.success){
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      getAllVendors()
    }
  }, [approveSelector])
  
  
  // table confirmed booking actions
  const actions = [
    {
      id: "view",
      label: "View Details",
      callbackArguments: ["id"],
      onClick: (id) => {viewVendorDetails(id)},
    },
    {
      id: "approve/disapprove",
      label: "Approve/Disapprove",
      callbackArguments: ["id"],
      customLabel:(row)=>`${row?.is_approved==="Yes"?"Disapprove":"Approve"}`,
      onClick: (id) => {approveDisapproveVendor(id)},
    },
    {
      id: "sendRequest",
      label: "Booking Preferences",
      callbackArguments: ["id", "preferences_raw"],
      onClick: (id, preferences_raw) => {
        handleVendorPreferencesModal(id, preferences_raw);
      },
    },
  ];

  const approveDisapproveVendor = (id) => {
    dispatch(admminApproveDisapproveVendor({ user: routeKey, id}));
  }

  // view booking details
  const viewVendorDetails = (id) => {
    history.push(APP_USER_ROUTES[param].SHOW_VENDORS_ALIAS+'/'+id)
  }

  return (
    <div>
      <VendorPreferencesModal
          open={openVendorPreferencesmodal}
          setOpen={setOpenVendorPreferencesmodal}
          allVendors={allVendors}
          vendorId={selectedVendorForPreference}
          param={param}
          allStatesData={stateData}
          pastVendorPreferences={pastVendorPreferences}
        />
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">
            All Vendors
          </PageTitle>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <TableComponent columns={columns} rows={allVendors} actions={actions} onPageChange={getAllVendors} meta={allVendorsMeta}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowVendors;
