import React, { useState } from "react";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, LOAD_TYPES } from "../../../../utils/constants";
import { Link, useParams } from "react-router-dom";
import BookingForm from "./BookingForm";
import { useDispatch, useSelector } from "react-redux";
import { createBookingForClientFtl } from "../../../../ReduxActions";
import toast from "react-hot-toast";

interface Props {
  match?: any;
  routeKey ?: string;
  history?: any;
}

interface BookingDetailObject{
  pickup_location?: string;
  drop_location?: string;
  date          ?: string;
  time          ?: string;
  remarks       ?: string;
}

/**
 * 
 * @param props { routeKey, history } 
 * @returns 
 */
const AddBooking:React.FC<Props> = (props) => {
  // props destructing
  const { routeKey, history } = props;
  // param as routekey
  const  param  = routeKey;
  // dispatch method
  const dispatch = useDispatch();
  // selectors 
  const createFtlBookingSelector = useSelector((state:any)=>state.BookingReducer.createBookingFtl)
  // react hook states
  const [bookingDetails, setBookingDetails] = useState<BookingDetailObject>({
    pickup_location: "",
    drop_location: "",
    date          : '',
    time          : '',
    remarks       : '',
  });
  const [loading, setLoading] = useState(false);
  const [validationObj, setValidationObj] = useState<BookingDetailObject>({});

  React.useEffect(() => {
    let toastId:any;
    if(createFtlBookingSelector.status===API_CONSTANTS.loading){
      setLoading(true);
      toastId=toast.loading(LOADING_TEXT.processing);
    }
    if(createFtlBookingSelector.status===API_CONSTANTS.success && loading){
     toast.dismiss(toastId);
     toast.success(DEFAULT_SUCCESS_MESSAGE.success);
     setLoading(false);
     history.push(APP_USER_ROUTES[param].BOOKINGS);
    }
    if(createFtlBookingSelector.status===API_CONSTANTS.error && loading){
     toast.dismiss(toastId);
     toast.error(createFtlBookingSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [createFtlBookingSelector])

  const onSubmit = (data) => {
    dispatch(createBookingForClientFtl({ user : param, body : {...data, type:LOAD_TYPES.FULL_TRUCK }}));
  }

  const onCancel = () => {
    history.push(APP_USER_ROUTES[param].BOOKINGS)
  }

  return (
    <div className="">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className=" mb-8"
      >
        <Grid item lg={2} md={4} xs={12}>
          <PageTitle className="mb-0">Add Booking</PageTitle>
        </Grid>
      </Grid>
        <BookingForm onSubmit={onSubmit} bookingData={bookingDetails} setBookingData={setBookingDetails} onCancel={onCancel}/>
    </div>
  );
};

export default AddBooking;
