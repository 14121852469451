import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ImageDark from '../../assets/img/splash-image.png';
import { GithubIcon, TwitterIcon } from '../../icons';
import { Label, Input, Button } from '@windmill/react-ui';
import { API_CONSTANTS, LOADING_TEXT, DEFAULT_ERROR_MESSAGE, PLATFORM_USERS, DEFAULT_SUCCESS_MESSAGE, APP_USER_ROUTES, PLATFORM_USERS_NAME } from '../../utils/constants';
import { handleCheckValidation } from '../../ValidationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, resendEmailVerification } from '../../ReduxActions/index';
import toast from 'react-hot-toast';
import { HelperText } from '@windmill/react-ui';
import CommonInput from '../../components/Input/MaterialInput';
import { ParamsTypes } from '../../utils/ObjectTypes';
import AuthHelpers from '../../helpers/AuthHelpers';
import AppLogo from '../../components/AppLogo';

interface Props{
  history ?: any;
  routeKey ?: string;
}

interface ValidationObject {
  email ?: string;
  password ?: string;
}

const Login : React.FC<Props> = (props) => {

  const newHistory = useHistory();
  const { history, routeKey } = props;

  const dispatch = useDispatch();

  // Query params handling for errors
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email_verified = query.get('ev');

  const utm_pwa = query.get('utm_source');
  if(utm_pwa === "pwa") {
    newHistory.push(APP_USER_ROUTES.external.SELECTUSERTYPE);
  }

  let externalMessage = {
    "success": "Your email is successfully verified",
    "token_expired": "Email link is expired. Please request the link again",
    "invalid_user": "Invalid token in the link",
    "already_verified": "Your email is already verified. Please login",
  };

  const  param  = routeKey;

  const loginSelector = useSelector((state:any)=>state.AuthReducer.login);
  const resendEmailVerifySelector = useSelector((state:any)=>state.AuthReducer.resendEmailVerify);

  const [loginDetails, setLoginDetails] = useState({
    email : '',
    password : '',
  })
  const [validationObj, setValidationObj] = useState<ValidationObject>({});
  const [loading, setLoading] = useState(false);
  const [showResendEmail, setshowResendEmail] = useState(false);
  
  useEffect(() => {
    if(externalMessage[email_verified]) {
      if(email_verified === 'success') {
        toast.success(externalMessage[email_verified]);
      }
      else {
        toast.error(externalMessage[email_verified]);
      }
    }
  }, [])

  useEffect(() => {
    let toastId:any;
    if(resendEmailVerifySelector?.status === API_CONSTANTS.loading){
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(resendEmailVerifySelector?.status === API_CONSTANTS.success && loading){
      setLoading(false);
      toast.dismiss(toastId);
      setshowResendEmail(false)
      toast.success(DEFAULT_SUCCESS_MESSAGE.varification)
    }
    if(resendEmailVerifySelector?.status === API_CONSTANTS.error && loading){
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(resendEmailVerifySelector.data||DEFAULT_ERROR_MESSAGE.error1);
    }
  }, [resendEmailVerifySelector])
  

  useEffect(() => {
    let toastId:any;
    if(loginSelector?.status === API_CONSTANTS.loading){
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(loginSelector?.status === API_CONSTANTS.success && loading){
      setLoading(false);
      toast.dismiss(toastId);
      AuthHelpers.saveTokenToLocalStorage(loginSelector.data?.token);
      if(process.env.REACT_APP_BASENAME==='/app'){
        window.location.pathname=process.env.REACT_APP_BASENAME+(APP_USER_ROUTES[param].DASHBOARD);
      }else{
        window.location.pathname=(APP_USER_ROUTES[param].DASHBOARD);
      }
    }
    if(loginSelector?.status === API_CONSTANTS.error && loading){
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(loginSelector.data||DEFAULT_ERROR_MESSAGE.error1);
      if(loginSelector.data==='Please verify your email first'){
        setshowResendEmail(true);
      }
    }
  }, [loginSelector])

  const handleInputChange = (key, value) => {
    setLoginDetails({...loginDetails, [key]:value})
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const validation: ValidationObject = handleCheckValidation(loginDetails);
    setValidationObj(validation);
    if(validation && !validation.email && !validation.password){
      /// callback login function
      dispatch(loginUser({body :{...loginDetails, r:param}, user: param}));
    }
  }

  const resendEmailForVerification = () => {
    dispatch(resendEmailVerification({
      body :{
        email : loginDetails.email,
        r : param
      },
      user: param
    }))
  }

  return (
    <>

      <div className="flex items-center min-h-screen p-6 bg-gray-50 bg-brand-extra-background">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-card">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-44 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className="object-cover w-full h-full"
                src={ImageDark}
                alt="splash-image"
              />
            </div>
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <div className='mb-4'>
                  <AppLogo/>
                </div>
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Login for {PLATFORM_USERS_NAME[param]}</h1>
                <form onSubmit={onSubmit}>
                    <CommonInput
                      type="email" 
                      label='Email'
                      placeholder="john@doe.com" 
                      value={loginDetails.email}
                      onChange={(e:any)=>handleInputChange('email', e.target.value)}
                      validationKey={validationObj?.email}
                    />
                    <CommonInput
                      type="password" 
                      label='Password'
                      placeholder="Password" 
                      value={loginDetails.password}
                      onChange={(e:any)=>handleInputChange('password', e.target.value)}
                      validationKey={validationObj?.password}
                    />
                  <Button className="mt-4 py-3 bg-brand-primary-blue hover:bg-brand-hover-blue text-brand-extra-background" block  type="submit">
                    Log in
                  </Button>
                </form>

                {
                  showResendEmail &&
                  <p className='text-brand-primary-red text-sm'>
                    {DEFAULT_ERROR_MESSAGE.prompt} <a href="#" onClick={resendEmailForVerification} className="text-brand-primary-blue">Click here</a> to resend verification email.
                  </p>
                }

                {/* <hr className="my-8" />

                <Button block layout="outline">
                  <GithubIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                  Github
                </Button>
                <Button className="mt-4" block layout="outline">
                  <TwitterIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                  Twitter
                </Button> */}
                  <>
                    <p className="mt-4">
                      {
                        routeKey !== PLATFORM_USERS.ADMIN &&
                      <Link
                        className="text-sm text-brand-extra-icon font-medium hover:underline"
                        to={APP_USER_ROUTES[param].FORGOT_PASSWORD}
                      >
                        Forgot your password?
                      </Link>
                      }
                    </p>
                    <p className="mt-1">
                      {
                          (routeKey !== PLATFORM_USERS.ADMIN && routeKey !== PLATFORM_USERS.VENDOR_BRANCH) &&
                        <Link
                          className="text-sm text-brand-extra-icon font-medium hover:underline"
                          to={APP_USER_ROUTES[param].REGISTER}
                        >
                          Register
                        </Link>
                      }
                    </p>
                  </>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
