import { capitalize } from "../../utils/common";
import { LOAD_OPTIONS } from "../../utils/constants";

export const structureBranchData = (response) => {
    if(response?.data?.length){
        response.data = response?.data?.map((item)=>({
            name : item.first_name + ' ' + item.last_name,
            email : item.email,
            phone : item.contact_number,
            type : LOAD_OPTIONS[(item.type)],
            id : item.id,
            pincode : item?._pincode?.pincode,
            city : item?._city?.name,
            is_email_verified : item?.is_email_verified ? 'Yes' : 'No',
            is_approved : item?.is_approved ? 'Yes' : 'No',
            zones : item?._pincode?.zones?.map((i)=><p>{capitalize(i.name)},</p>)||''
        }))
    }
    return response;
}