import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ImageDark from '../../assets/img/splash-image.png';
import { Label, Input, Button } from '@windmill/react-ui';
import { API_CONSTANTS, LOADING_TEXT, DEFAULT_ERROR_MESSAGE, APP_USER_ROUTES, DEFAULT_SUCCESS_MESSAGE, PLATFORM_USERS_NAME } from '../../utils/constants';
import { handleCheckValidation } from '../../ValidationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, verifyPasswordToken } from '../../ReduxActions/index';
import toast from 'react-hot-toast';
import { extractParamsFromUrl } from '../../utils/common';
import CommonInput from '../../components/Input/MaterialInput';
import { ParamsTypes } from '../../utils/ObjectTypes';
import AppLogo from '../../components/AppLogo';
import Loader from '../../components/Loader';

interface Props{
    history ?: any;
    routeKey ?: string;
}

interface ValidationObject{
  token ?: string;
  password ?: string;
  confirmPassword ?: string;
}

interface ParamsObject {
  token ?: string
}

const ResetPassword: React.FC<Props> = (props) => {

  const { history, routeKey } = props;

  const param = routeKey;

  const dispatch = useDispatch();
  const resetPasswordSelector = useSelector((state:any)=>state.AuthReducer.resetPassword);
  const verifyPasswordTokenSelector = useSelector((state:any)=> state.AuthReducer.verifyPasswordToken)

  const uelparams : ParamsObject = extractParamsFromUrl();

  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    token : uelparams.token,
    password : '',
    confirmPassword : ''
  });
  const [validationObj, setValidationObj] = useState<ValidationObject>({});
  const [loading, setLoading] = useState({
    verifyPassword : false,
    resetPassword : false
  });

  useEffect(() => {
    dispatch(verifyPasswordToken({
      body : {token : uelparams.token},
      user : param
    }))
  }, [])

  useEffect(() => {
    let toastId:any;
    if(verifyPasswordTokenSelector?.status === API_CONSTANTS.loading){
      setLoading({
        ...loading,
        verifyPassword:true
      });
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(verifyPasswordTokenSelector?.status === API_CONSTANTS.success && loading.verifyPassword){
      setLoading({
        ...loading,
        verifyPassword:false
      });
      toast.dismiss(toastId);
    }
    if(verifyPasswordTokenSelector?.status === API_CONSTANTS.error && loading.verifyPassword){
      setLoading({
        ...loading,
        verifyPassword:false
      });
      toast.dismiss(toastId);
      toast.error(verifyPasswordTokenSelector.data||DEFAULT_ERROR_MESSAGE.error1);
      history.push(APP_USER_ROUTES[param].LOGIN);
    }
  }, [verifyPasswordTokenSelector])
  
  
  const handleInputChange = (key, value) => {
    setResetPasswordDetails({...resetPasswordDetails, [key]:value})
  }

  useEffect(() => {
    let toastId:any;
    if(resetPasswordSelector?.status === API_CONSTANTS.loading){
      setLoading({
        ...loading,
        resetPassword:true
      });
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(resetPasswordSelector?.status === API_CONSTANTS.success && loading.resetPassword){
      setLoading({
        ...loading,
        resetPassword:false
      });
      toast.dismiss(toastId);
      toast.success(resetPasswordSelector.data?.message);
      history.push(APP_USER_ROUTES[routeKey].LOGIN);
    }
    if(resetPasswordSelector?.status === API_CONSTANTS.error && loading.resetPassword){
      setLoading({
        ...loading,
        resetPassword:false
      });
      toast.dismiss(toastId);
      toast.error(resetPasswordSelector.data||DEFAULT_ERROR_MESSAGE.error1);
    }
  }, [resetPasswordSelector])

  const onSubmit = (e) => {
    e.preventDefault();
    const validation: ValidationObject = handleCheckValidation(resetPasswordDetails);
    setValidationObj(validation);
    if(validation && !validation.token && !validation.password && !validation.confirmPassword ){
      /// callback send email function
      if(resetPasswordDetails.password===resetPasswordDetails.confirmPassword) {
        dispatch(resetPassword({body :resetPasswordDetails, user: param}));
      }
    }
  }
  return (
    <>
      {
        loading.verifyPassword ?
        <Loader/>
        :
      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-72 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className=" object-cover w-full h-full"
                src={ImageDark}
                alt="splash-image"
              />
            </div>
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <div className='mb-4'>
                  <AppLogo/>
                </div>
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Reset password for {PLATFORM_USERS_NAME[param]}</h1>
                <form onSubmit={onSubmit}>
                  <CommonInput
                    type="password" 
                    label='New Password'
                    placeholder="New Password" 
                    value={resetPasswordDetails.password}
                    onChange={(e:any)=>handleInputChange('password', e.target.value)}
                    validationKey={validationObj?.password}
                  />
                  <CommonInput
                    type="password" 
                    label='Confirm Password'
                    placeholder="Confirm Password" 
                    value={resetPasswordDetails.confirmPassword}
                    onChange={(e:any)=>handleInputChange('confirmPassword', e.target.value)}
                    validationKey={validationObj?.confirmPassword}
                  />
                  <Button type="submit" block className="mt-4 py-3 bg-brand-primary-blue hover:bg-brand-hover-blue text-brand-extra-background">
                    Recover password
                  </Button>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
      }
    </>
  );
}
export default ResetPassword;
