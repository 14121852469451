import React from 'react';
import AppLogoImage from '../../assets/img/AppLogo.png';

const AppLogo = (props) => {
  return (
    <div>
        <img src={AppLogoImage} alt="splash-image" className="w-40 -ml-2 inline-block" />
        <p className="mt-4 text-xs text-brand-text-title ml-1 inline-block align-sub" style={{verticalAlign:"sub"}}>v2.0</p>
    </div>
  )
}

export default AppLogo