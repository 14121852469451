import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useCommonStyles } from '../../styles/common';
import { debounceMethod } from '../../utils/common';

type Options = {
  value : string;
  text  : string;
}

interface Props {
  value ?: any;
  onChange ?: any;
  onChangeText ?: any;
  validationKey ?: any;
  label ?: string;
  options ?: Array<Options>;
  disabled?:boolean;
  placeholder?: string;
  searchType?:boolean;
  outline ?: boolean;
}

interface IDropdownVal{
  text ?: string;
  value ?: string;
}

const MaterialSingleSearchDropdown: React.FC<Props> = (props) => {
  const classes = useCommonStyles();
  const { value, onChange, validationKey, label, options, onChangeText, disabled, placeholder, searchType, outline } = props;

  const [dropdownValue, setdropdownValue] = React.useState<IDropdownVal|null>(null);
  
  React.useEffect(() => {
    setdropdownValue(getExactValuefromDropdownOptions(value));
  }, [value])

  const handleChangeInput = (e, value) => {
    onChange(value?.value)
  }

  const getExactValuefromDropdownOptions = (value: any) => {
    if(options.length){
      return options.find(i=>i.value===value);
    }
  }
  // Debounce OnChange Text
  const updateDebouceText = React.useCallback(debounceMethod(onChangeText,500),[])

  return (
    <>
      <Stack spacing={3} className=" w-full mt-4 font-dm_sans">
        <label htmlFor="mui_search_multiple_select" className='text-brand-extra-icon font-medium text-sm'>{label}</label>
        <Autocomplete
          id="mui_search_multiple_select"
          aria-label={label}
          options={options.length?options:[{text:"",value:""}]}
          renderOption={(props, option) => <li {...props} className="text-sm px-2 py-2 cursor-pointer">{option.text}</li>}
          getOptionLabel={(option) => option.text}
          value={dropdownValue||null}
          onChange={handleChangeInput}
          filterSelectedOptions
          disabled={disabled}
          // onInputChange={(e:any)=>{handleChangeInput(e, e?.target?.value)}}
          style={{
            marginTop:'0.25rem'
          }}
          sx={
            outline ?
            {
              '& .MuiOutlinedInput-input' :{
                padding:'3px 3px 3px 0px !important',
                fontSize:'14px',
              },
              '& .MuiOutlinedInput-root':{
                paddingLeft:'0px'
              },
              '& .MuiOutlinedInput-root:focus-visible':{
                outline : '0px !important',
                border : '0px solid !important'
              },
              '& .MuiOutlinedInput-notchedOutline' : {
                border :'0px solid #EBEEF3'
              },
              '& .Mui-disabled .MuiOutlinedInput-notchedOutline' :{
                border :'1px solid #EBEEF3 !important'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline' :{
                borderWidth : '0px !important',
                borderColor : 'unset !important'
              }
            }
            :
            {
            '& .MuiOutlinedInput-input' :{
              padding:'3px !important',
              fontSize:'14px',
            },
            '& .MuiOutlinedInput-notchedOutline' : {
              border :'1px solid #EBEEF3'
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline' :{
              border :'1px solid #EBEEF3 !important'
            },
            '&:hover .MuiOutlinedInput-notchedOutline':{
              border : '1px solid #3466E5 !important'
            },
            // '&:focus-within' : {
            //   border :"1px solid #3466E5",
            // },
            '&:focus-within .MuiOutlinedInput-notchedOutline':{
              border :"1px solid #3466E5 !important",
            },
          }
        }
          className={classes.mpt5}
          renderInput={(params) => (
            <TextField
              {...params}
              // label={label}
              placeholder={placeholder||'Type to search'}
              onChange={updateDebouceText}
              helperText={validationKey}
              error={validationKey?true:false}
              FormHelperTextProps={{
                className:classes.ml0
              }}
              // value={textChange}
            />
          )}
        />
      </Stack>
    </>
  );
}

export default MaterialSingleSearchDropdown;
