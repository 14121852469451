import React from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import { ShowCitiesAdminService, ShowPincodesAdminService, ShowStatesAdminService } from './Service';

interface Props{

}

export const StatesWrapperForAdmin: React.FC<Props> = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <ShowStatesAdminService {...props}/>
    </div>
  )
}

export const CitiesWrapperForAdmin: React.FC<Props> = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <ShowCitiesAdminService {...props}/>
    </div>
  )
}

export const PincodesWrapperForAdmin: React.FC<Props> = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <ShowPincodesAdminService {...props}/>
    </div>
  )
}