import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import PageTitle from "../../../Typography/PageTitle";
import Button from "../../../Button";
import TableComponent from '../../../Table';
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT, PLATFORM_USERS } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { allVendorFleets, deleteVendorsFleet, fetchAllVendorRelatedFleets } from "../../../../ReduxActions";
import toast from "react-hot-toast";
import DeleteModal from '../../../Modal/DeleteModal';

interface Data {
  load_capacity: number;
  load_capacity_unit: string;
  owner_name : string;
  contact_no : string;
  ownership : string;
  truck_no: string;
  remarks : string
  created_at? : string;
  }
  
  interface HeadCell {
    disablePadding ?: boolean;
    id ?: keyof Data;
    label ?: string;
    numeric ?: boolean;
    callBackArguments ? : Array<string>;
    customCell ?: any;
    created_at? : string;
  }
  
  const columns: readonly HeadCell[] = [
    {
      id: "truck_no",
      numeric: false,
      disablePadding: false,
      label: "Truck No.",
      callBackArguments : ['id'],
      customCell: false,
    },
    {
      id: "load_capacity",
      numeric: false,
      disablePadding: false,
      label: "Load Capacity",
    },
    {
      id: "owner_name",
      numeric: false,
      disablePadding: false,
      label: "Owner Name",
    },
    {
      id: "contact_no",
      numeric: false,
      disablePadding: false,
      label: "Contact",
    },
    {
      id: "ownership",
      numeric: false,
      disablePadding: false,
      label: "Ownership",
    },
    {
      id: "remarks",
      numeric: false,
      disablePadding: false,
      label: "Remarks",
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: "Added on",
    },
  ];
  
interface Props {
  routeKey?: string;
  history ?: any;
  id?:string;
}



const VendorFleets: React.FC<Props> = (props) => {
  const { routeKey, history, id } = props;

  const dispatch = useDispatch();
  const allVendorFleetsSelector = useSelector((state: any) => state.FleetReducer.vendorFleets);
  const deleteFleetSelector = useSelector((state: any) => state.FleetReducer.deleteVendorFleet);

  const [loading, setloading] = useState(false);
  const [allFleetsData, setallFleetsData] = useState<Array<object>>([]);
  const [allFleetMeta, setallFleetMeta] = useState({});

  const getAllVendorFleets = (page?:number, limit?:number) => {
    dispatch(fetchAllVendorRelatedFleets({
      user : routeKey, vendor_id:id, page, limit
    }));  
  }

  const editTruck = (truckId) => {
    history.push(APP_USER_ROUTES[routeKey].EDIT_FLEETS_ALIAS + '/' + truckId + '?vendor_id=' + id)
  }

  const deleteTruck = (id) => {
   dispatch(
     deleteVendorsFleet({
       id : id,
       user : routeKey
     })
   ) 
  }

  useEffect(() => {
    let toastId:any;
    if(deleteFleetSelector.status === API_CONSTANTS.loading){
        setloading(true);
        toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(deleteFleetSelector.status === API_CONSTANTS.success && loading){
        setloading(false);
        toast.dismiss(toastId);
        toast.success('Successfully Deleted');
        getAllVendorFleets();
    }
    if(deleteFleetSelector.status === API_CONSTANTS.error && loading){
        toast.dismiss(toastId);
        toast.error(deleteFleetSelector.data || DEFAULT_ERROR_MESSAGE.error1)
        setloading(false);
    }
  }, [deleteFleetSelector])

  const actions = [
    {
      id: "edit",
      label: "Edit",
      callbackArguments: ["id"],
      onClick: (id) => {editTruck(id);
      },
    },
    {
      id: "delete",
      label: "Delete",
      callbackArguments: ["id"],
      onClick: (id) => {deleteTruck(id);
      },
    },
  ];

  const addFleets = () => {
    history.push(APP_USER_ROUTES[routeKey].ADD_FLEETS + '?vendor_id=' + id)
  }

  useEffect(() => {
    getAllVendorFleets(1, 100);
  }, []);

  useEffect(() => {
    let toastId:any;
    if(allVendorFleetsSelector.status === API_CONSTANTS.loading){
        setloading(true);
        toastId = toast.loading(LOADING_TEXT.processing);
    }
    if(allVendorFleetsSelector.status === API_CONSTANTS.success && loading){
        setloading(false);
        toast.dismiss(toastId);
        setallFleetsData(allVendorFleetsSelector?.data?.data);
        setallFleetMeta(allVendorFleetsSelector.data?.meta);
    }
    if(allVendorFleetsSelector.status === API_CONSTANTS.error && loading){
        toast.dismiss(toastId);
        toast.error(allVendorFleetsSelector.data || DEFAULT_ERROR_MESSAGE.error1)
        setloading(false);
    }
  }, [allVendorFleetsSelector])
  
  return (
    <>
      <div>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={2} md={4} xs={12}>
            <PageTitle className="mb-0">Trucks</PageTitle>
          </Grid>
          <Grid item lg={2} md={4} xs={12}>
            <Button text="Add Truck" type="button" onClick={addFleets} />
          </Grid>
        </Grid>
        <Grid container className="mt-4">
          <Grid item lg={12} xs={12}>
              <TableComponent columns={columns} rows={allFleetsData} actions={actions} stroke={true} onPageChange={getAllVendorFleets} meta={allFleetMeta} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VendorFleets;
