import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  SET_LOGIN,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_REGISTER,
  SET_REGISTER_SUCCESS,
  SET_REGISTER_ERROR,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_SUCCESS,
  SET_FORGOT_PASSWORD_ERROR,
  SET_RESET_PASSWORD,
  SET_RESET_PASSWORD_SUCCESS,
  SET_RESET_PASSWORD_ERROR,
  GET_VERIFY_PASSWORD_TOKEN,
  SET_VERIFY_PASSWORD_TOKEN,
  SET_VERIFY_PASSWORD_TOKEN_ERROR,
  RESEND_EMAIL_VERIFICATION,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_ERROR,
} from "../ReduxConstants";
import { AuthService } from "../sevices";
import { API_CONSTANTS } from "../utils/constants";

interface LoginObject {
  email: string;
  password: string;
  r : string;
}

interface LoginData {
  body : LoginObject;
  user : string
}

interface RegisterObject {
  first_name: string;
  last_name: string;
  company_name ?: string;
  email: string;
  password: string;
  master_key ?: string;
}

interface RegisterData {
  body : RegisterObject,
  user : string,
}
interface ForgotPasswordObject {
  body?:{
    email: string;
    r: string
  };
  user ?: string;
}
interface ResetPasswordObject {
  body ?: {
    token: string;
    password : string;
  };
  user ?: string;
}

const setLogin = (payload: object) => ({
  type: SET_LOGIN,
  payload: payload,
});

const setLoginSuccess = (payload: object) => ({
  type: SET_LOGIN_SUCCESS,
  payload: payload,
});

const setLoginError = (payload: object) => ({
  type: SET_LOGIN_ERROR,
  payload: payload,
});

const resendEmailVerfication = (payload: object) => ({
  type: RESEND_EMAIL_VERIFICATION,
  payload: payload,
});

const resendEmailVerficationSuccess = (payload: object) => ({
  type: RESEND_EMAIL_VERIFICATION_SUCCESS,
  payload: payload,
});

const resendEmailVerficationError = (payload: object) => ({
  type: RESEND_EMAIL_VERIFICATION_ERROR,
  payload: payload,
});

const setRegister = (payload: object) => ({
  type: SET_REGISTER,
  payload: payload,
});

const setRegisterSuccess = (payload: object) => ({
  type: SET_REGISTER_SUCCESS,
  payload: payload,
});

const setRegisterError = (payload: object) => ({
  type: SET_REGISTER_ERROR,
  payload: payload,
});

const setForgotpassword = (payload: object) => ({
  type: SET_FORGOT_PASSWORD,
  payload: payload,
});

const setForgotpasswordSuccess = (payload: object) => ({
  type: SET_FORGOT_PASSWORD_SUCCESS,
  payload: payload,
});

const setForgotpasswordError = (payload: object) => ({
  type: SET_FORGOT_PASSWORD_ERROR,
  payload: payload,
});

const setResetPassword = (payload: object) => ({
  type: SET_RESET_PASSWORD,
  payload: payload,
});

const setResetPasswordSuccess = (payload: object) => ({
  type: SET_RESET_PASSWORD_SUCCESS,
  payload: payload,
});

const setResetPasswordError = (payload: object) => ({
  type: SET_RESET_PASSWORD_ERROR,
  payload: payload,
});

const getVerifyPasswordToken = (payload: object) => ({
  type: GET_VERIFY_PASSWORD_TOKEN,
  payload: payload,
});

const setVerifyPasswordToken = (payload: object) => ({
  type: SET_VERIFY_PASSWORD_TOKEN,
  payload: payload,
});

const setVerifyPasswordTokenError = (payload: object) => ({
  type: SET_VERIFY_PASSWORD_TOKEN_ERROR,
  payload: payload,
});

export const loginUser = (data: LoginData) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(setLogin(data));
    await AuthService.login(data)
      .then((result) => {
        dispatch(setLoginSuccess(result));
      })
      .catch((error) => {
        dispatch(setLoginError(error));
      });
  };
};

export const resendEmailVerification = (data: {body:{email : string, r: string}, user: string}) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(resendEmailVerfication(data));
    await AuthService.resendEmailVerfication(data)
      .then((result) => {
        dispatch(resendEmailVerficationSuccess(result));
      })
      .catch((error) => {
        dispatch(resendEmailVerficationError(error));
      });
  };
};

export const registerUser = (data: RegisterData) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(setRegister(data));
    await AuthService.register(data)
      .then((result) => {
        dispatch(setRegisterSuccess(result));
      })
      .catch((error) => {
        dispatch(setRegisterError(error));
      });
  };
};

export const forgotPasswordSendEmail = (data: ForgotPasswordObject) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(setForgotpassword(data));
    await AuthService.forgotPassword(data)
      .then((result) => {
        dispatch(setForgotpasswordSuccess(result));
      })
      .catch((error) => {
        dispatch(setForgotpasswordError(error));
      });
  };
};

export const verifyPasswordToken = (data: {body : { token : string}; user: string}) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(getVerifyPasswordToken(data));
    await AuthService.verifyPasswordToken(data)
      .then((result) => {
        dispatch(setVerifyPasswordToken(result));
      })
      .catch((error) => {
        dispatch(setVerifyPasswordTokenError(error));
      });
  };
};



export const resetPassword = (data: ResetPasswordObject) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dispatch(setResetPassword(data));
    await AuthService.resetPassword(data)
      .then((result) => {
        dispatch(setResetPasswordSuccess(result));
      })
      .catch((error) => {
        dispatch(setResetPasswordError(error));
      });
  };
};
