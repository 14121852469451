import React from 'react';
import NotFoundComponent from '../../components/NotFound';

function NotFound(props) {
  return (
    <>
     <NotFoundComponent {...props}/>
    </>
  );
}
export default NotFound;
