import React from 'react';
import ClientDetailsComponent from '../../components/role/admin/ShowClients/ClientDetails';

const ClientDetails = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <ClientDetailsComponent {...props}/>
    </div>
  )
}

export default ClientDetails