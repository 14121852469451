import {
  GET_ALL_VENDOR_FLEETS,
  GET_ALL_VENDOR_FLEETS_ERROR,
  GET_ALL_VENDOR_FLEETS_SUCCESS,
  GET_VENDOR_FLEET,
  GET_VENDOR_FLEET_SUCCESS,
  GET_VENDOR_FLEET_ERROR,
  ADD_VENDOR_FLEET,
  ADD_VENDOR_FLEET_SUCCESS,
  ADD_VENDOR_FLEET_ERROR,
  EDIT_VENDOR_FLEET,
  EDIT_VENDOR_FLEET_SUCCESS,
  EDIT_VENDOR_FLEET_ERROR,
  DELETE_VENDOR_FLEET,
  DELETE_VENDOR_FLEET_SUCCESS,
  DELETE_VENDOR_FLEET_ERROR,
  SET_VENDOR_FLEETS,
  GET_VENDOR_FLEETS,
  GET_TRUCK_COUNT,
  SET_TRUCK_COUNT
} from "../ReduxConstants/FleetsConstants";
import { API_CONSTANTS } from "../utils/constants";
import { SubstateObject } from "../utils/ObjectTypes";

interface InitialState {
  getAllVendorFleets: SubstateObject;
  getVendorFleet: SubstateObject;
  addVendorFleet: SubstateObject;
  editVendorFleet: SubstateObject;
  deleteVendorFleet: SubstateObject;
  vendorFleets: SubstateObject;
  truckCount: SubstateObject;
}

const initialState: InitialState = {
  getAllVendorFleets: {
    data: null,
    status: null,
  },
  getVendorFleet: {
    data: null,
    status: null,
  },
  addVendorFleet: {
    data: null,
    status: null,
  },
  editVendorFleet: {
    data: null,
    status: null,
  },
  deleteVendorFleet: {
    data: null,
    status: null,
  },
  vendorFleets: {
    data: null,
    status: null,
  },
  truckCount: {
    data: null,
    status: null,
  },
};

const FleetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_VENDOR_FLEETS: {
      return {
        ...initialState,
        getAllVendorFleets: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_ALL_VENDOR_FLEETS_SUCCESS: {
      return {
        ...initialState,
        getAllVendorFleets: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_ALL_VENDOR_FLEETS_ERROR: {
      return {
        ...initialState,
        getAllVendorFleets: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_VENDOR_FLEET: {
      return {
        ...initialState,
        getVendorFleet: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case GET_VENDOR_FLEET_SUCCESS: {
      return {
        ...initialState,
        getVendorFleet: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case GET_VENDOR_FLEET_ERROR: {
      return {
        ...initialState,
        getVendorFleet: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case ADD_VENDOR_FLEET: {
      return {
        ...initialState,
        addVendorFleet: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case ADD_VENDOR_FLEET_SUCCESS: {
      return {
        ...initialState,
        addVendorFleet: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case ADD_VENDOR_FLEET_ERROR: {
      return {
        ...initialState,
        addVendorFleet: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case EDIT_VENDOR_FLEET: {
      return {
        ...initialState,
        editVendorFleet: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case EDIT_VENDOR_FLEET_SUCCESS: {
      return {
        ...initialState,
        editVendorFleet: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case EDIT_VENDOR_FLEET_ERROR: {
      return {
        ...initialState,
        editVendorFleet: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case DELETE_VENDOR_FLEET: {
      return {
        ...initialState,
        deleteVendorFleet: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case DELETE_VENDOR_FLEET_SUCCESS: {
      return {
        ...initialState,
        deleteVendorFleet: {
          data: payload,
          status: API_CONSTANTS.success,
        },
      };
    }
    case DELETE_VENDOR_FLEET_ERROR: {
      return {
        ...initialState,
        deleteVendorFleet: {
          data: payload,
          status: API_CONSTANTS.error,
        },
      };
    }
    case GET_VENDOR_FLEETS: {
      return {
        ...initialState,
        vendorFleets: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_VENDOR_FLEETS: {
      return {
        ...initialState,
        vendorFleets: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    case GET_TRUCK_COUNT: {
      return {
        ...initialState,
        truckCount: {
          data: null,
          status: API_CONSTANTS.loading,
        },
      };
    }
    case SET_TRUCK_COUNT: {
      return {
        ...initialState,
        truckCount: {
          data: payload.data,
          status: payload.status,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default FleetReducer;
