import React from 'react';
import PageTitle from "../../components/Typography/PageTitle";
import Bookings from "./Service";
import AddBooking from '../../components/role/client/Bookings/AddBooking';
import { useParams } from 'react-router-dom';
import { ParamsTypes, UserObject } from '../../utils/ObjectTypes';
import { APP_USER_ROUTES } from '../../utils/constants';

interface Props {
  match ?: any;
  userProfileData ?: UserObject;
  routeKey ?: string;
}

const BookingContainer:React.FC<Props> = (props) => {

  const { match, userProfileData, routeKey } = props;
  const  param  = routeKey;

  return (
    <div className="py-6 px-6 overflow-x-auto">
      {
        <Bookings {...props}/>
      }
    </div>
  )
}

export default BookingContainer