import React, { useState, useEffect } from "react";
import TableComponent from "../../../Table";
import PageTitle from "../../../Typography/PageTitle";
import { Grid } from "@mui/material";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, LOADING_TEXT, PLATFORM_USERS} from '../../../../utils/constants';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCitiesForAdmin } from "../../../../ReduxActions";
import { useCommonStyles } from "../../../../styles/common";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";

interface Data {
  name: string;
  pincodes_count: number;
  state: string;
  created_at: string;
}

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
  getCustomColumn? : boolean;
  getColData?: (row: any) => void;
}

const columns: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    callBackArguments : ['id'],
    customCell: false,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "pincodes_count",
    numeric: false,
    disablePadding: false,
    label: "Pincodes",
    getCustomColumn: true,
    getColData: (row: any) => (
      <Link
        to={`${APP_USER_ROUTES[PLATFORM_USERS.ADMIN].PINCODES}?search=${row.name}`}
        className="text-brand-primary-blue text-sm"
      >
        {row.pincodes_count}
      </Link>
    ),
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Added On",
  },
];

interface Props {
  key ?: string;
  match ?: any;
  routeKey ?: string;
  history ?: any;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ShowCitiesForAdmin: React.FC<Props> = (props) => {

  let urlParams: any = useQuery();

  // material Ui common classes
  const classes = useCommonStyles();
  // prop desturcturing
  const { key, match, routeKey, history } = props;
  // routekey param
  const param = routeKey;
  // dispatch action
  const dispatch = useDispatch(); 
  // selectors 
  const allCitiesSelector = useSelector((state: any)=>state.UserReducer.allCities)


  // react states hook
  const [loading, setLoading] = useState({
    all: false,
    request : false,
  });
  const [allCities, setAllCities] = useState([]);
  
  const getAllCities = (search) => {
    dispatch(fetchAllCitiesForAdmin({ user : param, search}));
  }

  useEffect(() => {
    const searchQuery = urlParams.get("search");
    getAllCities(searchQuery);
  }, [])

  useEffect(() => {
    let toastId : any;
    if(allCitiesSelector.status === API_CONSTANTS.loading){
      setLoading({...loading, all:true});
      toastId=toast.loading(LOADING_TEXT.fetching);
    }
    if(allCitiesSelector.status === API_CONSTANTS.success){
      setLoading({...loading, all:false});
      setAllCities([...allCitiesSelector?.data?.data]);
      toast.dismiss(toastId);
    }
    if(allCitiesSelector.status === API_CONSTANTS.error){
      setLoading({...loading, all:false});
      toast.dismiss(toastId);
      toast.error(allCitiesSelector.data||DEFAULT_ERROR_MESSAGE.failed);
    }
  }, [allCitiesSelector])

  
  
  // table confirmed booking actions
  const actions = [
    {
      id: "view",
      label: "View"
    }
  ];

  // view booking details
  const viewVendorDetails = (id) => {
    history.push(APP_USER_ROUTES[param].SHOW_VENDORS_ALIAS+'/'+id)
  }

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item lg={12} md={4} xs={12}>
          <PageTitle className="mb-0">
            All Cities
          </PageTitle>
        </Grid>
        <Grid item lg={12} xs={12} className="mt-8">
          <TableComponent columns={columns} rows={allCities} actions={actions} onPageChange={getAllCities} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShowCitiesForAdmin;
