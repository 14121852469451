
import React, { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import AccessibleNavigationAnnouncer from '../../components/AccessibleNavigationAnnouncer';
import AuthHelpers from '../../helpers/AuthHelpers';
import { getUserProfileDetails } from '../../ReduxActions';
import { getUserData } from '../../utils/common';
import { API_CONSTANTS, APP_USER_RELATED_NAVIGATION, APP_USER_ROUTES, PLATFORM_USERS, ROUTE_CHECK_REGEX } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';
import { ALL_ROUTES } from './RouteObject';

const Layout = lazy(() => import('../Layout'));

interface Props {
  computedMatch?: {
    params: any,
    url: string
  };
  component?: any;
  exact?: boolean;
  match?: {
    params?: any
  };
  userProfileData?: UserObject;
  role?: Array<string>;
  routeKey?: string;
  path?: string;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }): JSX.Element => {
  let query = useQuery();
  const { computedMatch: { params }, userProfileData, routeKey } = rest;

  useEffect(() => {
    try {
      if (userData.data?.email && process.env.API_ENV === 'production') {
        const windowObject: any = window;
        if (windowObject?.Tawk_API) {
          windowObject?.Tawk_API.setAttributes({
            name: userData.data?.name,
            email: userData.data?.email,
            id: userData.data?.id,
          }, function (error) {
            console.log(error)
          });
        }
      }
    } catch (error) {
      //Add Sentry later
    }
  }, [userProfileData])


  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.UserReducer.userProfile);

  useEffect(() => {
    if (!userProfileData) dispatch(getUserProfileDetails({ user: rest.routeKey }));
  }, [])

  const checkUserProfileDetails = () => {
    if (routeKey === PLATFORM_USERS.CLIENT) {
      return userProfileData.gst_number || rest.path.includes('settings') || rest.path.includes('logout');
    } else if (routeKey === PLATFORM_USERS.VENDOR || routeKey === PLATFORM_USERS.VENDOR_BRANCH) {
      return (userProfileData.gst_number && userProfileData.pan_number && userProfileData.area && userProfileData.address) || rest.path.includes('settings') || rest.path.includes('logout');
    } else {
      return true;
    }
  }

  return (
    <Route
      exact={rest.exact}
      {...rest}
      render={(props) =>
        AuthHelpers.isAuthenticated() ? (
          (userProfileData?.email) ? (
            checkUserProfileDetails() ?
              <Layout {...props} {...rest}>
                <Component {...props} {...rest} />
              </Layout>
              :
              <Redirect
                to={{
                  ...props?.location,
                  pathname: APP_USER_ROUTES[routeKey].SETTINGS,
                  state: { from: props.location }
                }}
              />
          )
            :
            <Redirect
              to={{
                ...props?.location,
                pathname: rest.computedMatch?.url,
                state: { from: props.location },
              }}
            />
        ) : (
          <Redirect
            to={{
              pathname: APP_USER_ROUTES[routeKey].LOGIN,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

const PublicRoutes: React.FC<Props> = ({ component: Component, ...rest }): JSX.Element => {
  const { routeKey } = rest;
  const route = routeKey || localStorage.getItem("userRole") || PLATFORM_USERS.CLIENT;
  return (
    <Route
      {...rest}
      exact={rest.exact}
      render={(props) =>
        !AuthHelpers.isAuthenticated() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: APP_USER_ROUTES[route].DASHBOARD,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

interface RouteProps {
  userProfileData?: any;
}

const Routes: React.FC<RouteProps> = (props): JSX.Element => {
  const basename = process.env.REACT_APP_BASENAME || null;

  useEffect(() => {
    const regexp = /(client|vendor|admin)$/gm;
    if (regexp.test(window.location.pathname)) {
      window.location.pathname = window.location.pathname + '/login'
    }
  }, [])

  return (
    <>
      <Router basename={basename}>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Redirect from='/' to={APP_USER_ROUTES[PLATFORM_USERS.CLIENT].LOGIN} exact={true} />
          {
            ALL_ROUTES.map((route, i) => {
              const { path, component: Component, isProtected, ...rest } = route;
              return isProtected ? (
                <PrivateRoute key={i} exact={true} component={route.component} {...route} {...props} />
              ) : (
                <PublicRoutes key={i} exact={true} component={route.component} {...route} {...props} />
              )
            })
          }
          {
            !ROUTE_CHECK_REGEX.test(window.location.pathname) &&
            <Redirect to={APP_USER_ROUTES.external.NOT_FOUND} exact={true} />
          }
        </Switch>
      </Router>
    </>
  )
};

export default Routes;
