import React from 'react'
import ComingSoon from '../../components/ComingSoon';
import FleetsComponent from '../../components/role/vendor/Fleets';
import { PLATFORM_USERS } from '../../utils/constants';

interface Props {
  routeKey : string;
}

const Fleets : React.FC<Props> = (props) => {
  const { routeKey } = props;
  return (
    <>
      {
        (routeKey === PLATFORM_USERS.VENDOR||routeKey === PLATFORM_USERS.VENDOR_BRANCH || routeKey === PLATFORM_USERS.ADMIN) ?
        <FleetsComponent {...props}/>
        :
        <ComingSoon {...props}/>
      }
    </>
  )
}

export default Fleets