import { TRACKING_STATUS_CONSTANTS } from "./constants";

export interface ParamsTypes {
    param ?: string
}

export interface RouteObject {
    key: string,
    path : Function,
    navIcon : string,
    navIconHover : string,
    subRoutes : Array<string>
}

export interface SubstateObject {
    data ?:any,
    status : number, 
}

export interface UserObject {
    area ?: string;
    address ?: string;
    city_col ?: string;
    city_id ?: string;
    company_name ?: string;
    contact_number ?: string;
    country_col ?: string;
    country_id ?: string;
    created_at ?: string;
    email ?: string;
    first_name ?: string;
    gst_number ?: string;
    id ?: string;
    last_name ?: string;
    name ?: string;
    pan_number ?: string;
    pincode_col ?: string;
    pincode_id ?: string;
    role ?: string;
    state_col ?: string;
    state_id ?: string;
    type ?: string;
    _city ?: any;
    _state ?: any;
    _pincode ?: any;
    _country ?: any;
    _hq ?: any;
}

export interface IUpdateUserObject {
    body : {
      first_name ?: string;
      last_name ?: string;
      phone ?: string;
      email ?: string;
      pin_code ?: string;
      city ?: string;
      state ?: string;
      country ?: string;
      gst_number ?: string;
      pan_number ?: string;
      pincode_id ?: string;
      city_id ?: string;
      state_id ?: string;
      country_id ?: string;
      type ?: string;
      contact_number ?: string;
      address ?: string;
      area ?: string;
      company_name ?: string;
    };
    id: string;
    user : string;
  }

export interface ILoadCapacityWeightInterface {
    text : string;
    value : string;
}

export const LoadOptions: Array<ILoadCapacityWeightInterface> = [
    {
        text : 'Per Tonne(s)',
        value : 'tonne'
    },
    {
        text : 'Per Kg',
        value : 'kg'
    },
    {
        text : 'Per CFT',
        value : 'cft'
    },

]

export const LoadOptionsForWeight: Array<ILoadCapacityWeightInterface> = [
    {
        text : 'Tonne(s)',
        value : 'tonne'
    },
    {
        text : 'Kg',
        value : 'kg'
    },
    {
        text : 'CFT',
        value : 'cft'
    },

]

export const LoadTypes = [
    {
        key : 'ftl|ptl',
        value : 'ftl|ptl',
        text : 'Full and Part Truck Load'
    },
    {
      key: 'ftl',
      value : 'ftl',
      text : 'Full Truck Load'
    },
    {
      key : 'ptl',
      value : 'ptl',
      text : 'Part Truck Load'
    },
  ]

export const TrackingStatusObject = [
    {
        key: TRACKING_STATUS_CONSTANTS.dispatch,
        value : TRACKING_STATUS_CONSTANTS.dispatch,
        text : 'Dispatched'
      },
    //   {
    //     key : TRACKING_STATUS_CONSTANTS.in_transit,
    //     value : TRACKING_STATUS_CONSTANTS.in_transit,
    //     text : 'In Transit'
    //   },
      {
        key : TRACKING_STATUS_CONSTANTS.delivered,
        value : TRACKING_STATUS_CONSTANTS.delivered,
        text : 'Delivered'
      }
]

export const MaterialType = [
  {
    value : 'fraigel',
    text : 'Fraizal'
  }
]

export const MaterialTypes = [
  {
      "value": "Auto Parts",
      "text": "Auto Parts",
      "id": "Auto Parts"
  },
  {
      "value": "Bardana jute or plastic",
      "text": "Bardana jute or plastic",
      "id": "Bardana jute or plastic"
  },
  {
      "value": "Building Materials",
      "text": "Building Materials",
      "id": "Building Materials"
  },
  {
      "value": "Cement",
      "text": "Cement",
      "id": "Cement"
  },
  {
      "value": "Chemicals",
      "text": "Chemicals",
      "id": "Chemicals"
  },
  {
      "value": "Coal And Ash",
      "text": "Coal And Ash",
      "id": "Coal And Ash"
  },
  {
      "value": "Container",
      "text": "Container",
      "id": "Container"
  },
  {
      "value": "Cotton seed",
      "text": "Cotton seed",
      "id": "Cotton seed"
  },
  {
    "value": "Sugar",
    "text": "Sugar",
    "id": "Sugar"
  },
  {
      "value": "Electronics Consumer Durables",
      "text": "Electronics Consumer Durables",
      "id": "Electronics Consumer Durables"
  },
  {
      "value": "Fertilizers",
      "text": "Fertilizers",
      "id": "Fertilizers"
  },
  {
      "value": "Fruits And Vegetables",
      "text": "Fruits And Vegetables",
      "id": "Fruits And Vegetables"
  },
  {
      "value": "Furniture And Wood Products",
      "text": "Furniture And Wood Products",
      "id": "Furniture And Wood Products"
  },
  {
      "value": "House Hold Goods",
      "text": "House Hold Goods",
      "id": "House Hold Goods"
  },
  {
      "value": "Industrial Equipments",
      "text": "Industrial Equipments",
      "id": "Industrial Equipments"
  },
  {
      "value": "Iron sheets or bars or scraps",
      "text": "Iron sheets or bars or scraps",
      "id": "Iron sheets or bars or scraps"
  },
  {
      "value": "Liquids in drums",
      "text": "Liquids in drums",
      "id": "Liquids in drums"
  },
  {
      "value": "Liquids/Oil",
      "text": "Liquids/Oil",
      "id": "Liquids/Oil"
  },
  {
      "value": "Machinery new or old",
      "text": "Machinery new or old",
      "id": "Machinery new or old"
  },
  {
      "value": "Medicals",
      "text": "Medicals",
      "id": "Medicals"
  },
  {
      "value": "Metals",
      "text": "Metals",
      "id": "Metals"
  },
  {
      "value": "Mill Jute Oil",
      "text": "Mill Jute Oil",
      "id": "Mill Jute Oil"
  },
  {
      "value": "Others",
      "text": "Others",
      "id": "Others"
  },
  {
      "value": "Packed Food",
      "text": "Packed Food",
      "id": "Packed Food"
  },
  {
      "value": "Plastic Pipes or other products",
      "text": "Plastic Pipes or other products",
      "id": "Plastic Pipes or other products"
  },
  {
      "value": "powder bags",
      "text": "powder bags",
      "id": "powder bags"
  },
  {
      "value": "Printed books or Paper rolls",
      "text": "Printed books or Paper rolls",
      "id": "Printed books or Paper rolls"
  },
  {
      "value": "Refrigerated Goods",
      "text": "Refrigerated Goods",
      "id": "Refrigerated Goods"
  },
  {
      "value": "Rice or wheat or Agriculture Products",
      "text": "Rice or wheat or Agriculture Products",
      "id": "Rice or wheat or Agriculture Products"
  },
  {
      "value": "Scrap",
      "text": "Scrap",
      "id": "Scrap"
  },
  {
      "value": "Spices",
      "text": "Spices",
      "id": "Spices"
  },
  {
      "value": "Textiles",
      "text": "Textiles",
      "id": "Textiles"
  },
  {
      "value": "Tyres And Rubber Products",
      "text": "Tyres And Rubber Products",
      "id": "Tyres And Rubber Products"
  },
  {
      "value": "Vehicles or car",
      "text": "Vehicles or car",
      "id": "Vehicles or car"
  }
]

export const OwnershipArray = [
    {
        key : 'owned',
        text : 'Owned',
        value : 'owned'
    },
    {
        key : 'attached',
        text : 'Attached',
        value : 'attached'
    }
]
