import Payments from "./Service";

import React from 'react'
import PageTitle from "../../components/Typography/PageTitle";

const PaymentsContainer = (props) => {
  return (
    <div className="py-6 px-6 overflow-x-auto">
        <Payments {...props}/>
    </div>
  )
}

export default PaymentsContainer