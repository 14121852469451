import React from 'react';
import { FtlImage, PtlImage } from '../../../../icons';
import { LOAD_TYPES } from '../../../../utils/constants';
import Modal from '../../../Modal';

interface Props {
  open ?: boolean;
  setOpen ?: any;
  onClickCards ?: any;
}

const SelectModal = (props) => {
  const { open, setOpen, onClickCards } = props;

  return (
    <div>
        <Modal
            open={open}
            setOpen={setOpen}
            headingText="Select Booking Type"
            maxWidth={'lg'}
            content={
                <>
                  <div className='flex flex-wrap justify-between items-center text-center mt-4'>
                    <div onClick={()=>onClickCards(LOAD_TYPES.PART_TRUCK)} className='w-80 p-6 mt-2 hover:fill-brand-primary-blue text-brand-text-tableHead fill-brand-text-tableHead rounded-lg border border-brand-extra-border md:mr-3 hover:border-brand-primary-blue hover:text-brand-primary-blue cursor-pointer'>
                      <PtlImage className='mx-auto'/>
                      <p className='font-bold hover:text-brand-primary-blue mb-2'>Part Truck Load (PTL)</p>
                      <p className='text-xs hover:text-brand-primary-blue mb-2'>Create booking to transport small loads from various cities of India.</p>
                    </div>
                    <div onClick={()=>onClickCards(LOAD_TYPES.FULL_TRUCK)} className='w-80 p-6 mt-2 hover:fill-brand-primary-blue text-brand-text-tableHead fill-brand-text-tableHead rounded-lg border border-brand-extra-border md:ml-3 hover:border-brand-primary-blue hover:text-brand-primary-blue cursor-pointer'>
                      <FtlImage className='mx-auto'/>
                      <p className='font-bold hover:text-brand-primary-blue mb-2'>Full Truck Load (FTL)</p>
                      <p className='text-xs hover:text-brand-primary-blue mb-2'>Book Complete trucks to transport large loads from various cities of India.</p>
                    </div>
                  </div>
                </>
            }
            // agreeButtonText="Continue"
            // cancelButtonText='Cancel'
        />
    </div>
  )
}

export default SelectModal