import React, { useState ,useEffect } from 'react';
import TableComponent from "../../../Table";
import Checkbox from "@mui/material/Checkbox";
import PageTitle from "../../../Typography/PageTitle";
import Button from '../../../Button';
import { Grid } from "@mui/material";
import {API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, TIME_FORMAT_STRING} from '../../../../utils/constants';
import { Link, useParams } from "react-router-dom";
import { ParamsTypes } from "../../../../utils/ObjectTypes";
import { useDispatch, useSelector } from 'react-redux';
import { deletePayments, fetchAllPaymentsDetails } from '../../../../ReduxActions';
import toast from 'react-hot-toast';
import moment from 'moment';
import Loader from '../../../Loader';

interface Data {
  booking_id : string;
  tax_type?: string,
  tax_percentage?: string,
  status?: string,
  total_amount?: string
  payment_time?: string,
}

interface HeadCell {
  disablePadding ?: boolean;
  id ?: keyof Data;
  label ?: string;
  numeric ?: boolean;
  callBackArguments ? : Array<string>;
  customCell ?: any;
}

const columns: readonly HeadCell[] = [
  {
    id: "booking_id",
    numeric: false,
    disablePadding: false,
    label: "Booking ID",
    callBackArguments : ['id'],
    customCell: false,
  },
  {
    id: "tax_type",
    numeric: false,
    disablePadding: false,
    label: "Tax Type",
  },
  {
    id: "tax_percentage",
    numeric: false,
    disablePadding: false,
    label: "Tax Percentage",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "total_amount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "payment_time",
    numeric: false,
    disablePadding: false,
    label: "Payment Time",
  },
];

interface Props {
  key ?: string;
  match ?: any;
  routeKey?:string;
  history ?: any;
}

interface IPaymentsData {
  booking_id : string;
  tax_type?: string,
  tax_percentage?: string,
  status?: string,
  total_amount?: string
  payment_time?: string,
  id ?: string
}

/**
 * Payment Component for vendor all created payments
 * @param props { key, match, routeKey, history }
 * @returns JSX.Element
 */
const PaymentsComponent: React.FC<Props> = (props): JSX.Element => {
    // props destructuring
    const { key, match, routeKey, history } = props;

    const param = routeKey;
    // dispatch action
    const dispatch = useDispatch();
    // selectors
    const allPaymentSelectors = useSelector((state:any)=>state.PaymentReducer.allPayments);
    const deletePaymentSelector = useSelector((state:any)=>state.PaymentReducer.deletePayment);
    // states
    const [payments, setPayments] = useState<IPaymentsData[]>([]);
    const [paymentsMeta, setPaymentsMeta] = useState({});
    const [loading, setLoading] = useState(false);

    const getALLPayemnts = (page?:number, limit?:number) => {
      dispatch(fetchAllPaymentsDetails({ user: param, page, limit }));
    }

    useEffect(() => {
      getALLPayemnts(1, 10);
    }, [])

    useEffect(() => {
      if(allPaymentSelectors.status===API_CONSTANTS.loading){
        setLoading(true);
      }
      if(allPaymentSelectors.status===API_CONSTANTS.success){
        setLoading(false);
        setPayments([...allPaymentSelectors.data?.data?.map((items)=>({
          id : items.id,
          booking_id : <p onClick={()=>viewBookingDetails(items.booking_id)} className="text-brand-primary-blue cursor-pointer">{items._booking?.booking_no}</p>,
          tax_type : items.tax_type,
          tax_percentage : items.tax_percentage,
          status : items.status,
          total_amount : items.total_amount,
          payment_time : moment(items.payment_time).format(TIME_FORMAT_STRING)
        }))]);
        setPaymentsMeta(allPaymentSelectors.data?.meta);
      }
      if(allPaymentSelectors.status===API_CONSTANTS.error){
        setLoading(false);
        toast.error(allPaymentSelectors.data || DEFAULT_ERROR_MESSAGE.failed )
      }
    }, [allPaymentSelectors])

    useEffect(() => {
      let toastId : any;
      if(deletePaymentSelector.status===API_CONSTANTS.loading){
        setLoading(true);
        toastId=toast.loading(LOADING_TEXT.loading);
      }
      if(deletePaymentSelector.status===API_CONSTANTS.success){
        setLoading(false);
        toast.dismiss(toastId);
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
        getALLPayemnts();
      }
      if(deletePaymentSelector.status===API_CONSTANTS.error){
        setLoading(false);
        toast.dismiss(toastId);
        toast.error(deletePaymentSelector.data || DEFAULT_ERROR_MESSAGE.failed )
      }
    }, [deletePaymentSelector])

    // view booking details
  const viewBookingDetails = (id) => {
    history.push(APP_USER_ROUTES[param].BOOKING_DETAILS_ALIAS+'/'+id)
  }
    
    const actions = [
      {
        id: "edit",
        label: "Edit",
        callbackArguments: ["id"],
        onClick: (id) => {onEditPayemnts(id)},
      },
      {
        id: "delete",
        label: "Delete",
        callbackArguments: ["id"],
        onClick: (id) => {handleDeletePayments(id)},
      },
    ];

    const handleDeletePayments = (id) => {
      dispatch(deletePayments({ user: param, id}));
    }

    const onEditPayemnts = (id) => {
      history.push(APP_USER_ROUTES[param].EDIT_PAYMENTS_ALIAS + '/' + id)
    }
  
    const addPayments = () => {
      history.push(APP_USER_ROUTES[param].ADD_PAYMENTS)
    }

  return (
    <div>
      {
        loading && <Loader/>
      }
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" marginBottom={'2rem'}>
        <Grid item lg={2} md={4} xs={12}>
          <PageTitle className="mb-0">
            My Payments
          </PageTitle>
        </Grid>
        <Grid item lg={2} md={4} xs={12}>
          {
            <Button text="Add Payments" type="button" onClick={addPayments}/>
          }
        </Grid>
      </Grid>
      <TableComponent columns={columns} rows={payments} actions={actions} onPageChange={getALLPayemnts} meta={paymentsMeta} />
    </div>
  )
}

export default PaymentsComponent