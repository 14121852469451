import React, { useState, useEffect } from "react";
import PageTitle from "../../components/Typography/PageTitle";
import CommonInput from "../../components/Input/MaterialInput";
import CommonDropdown from "../../components/Dropdown";
import { Grid } from "@mui/material";
import { handleCheckValidation } from "../../ValidationHelpers";
import CommonButton from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileDetails, getUserProfileDetails } from "../../ReduxActions";
import toast from "react-hot-toast";
import { API_CONSTANTS, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT, PLATFORM_USERS } from "../../utils/constants";
import { LoadTypes, UserObject } from "../../utils/ObjectTypes";
import { getCityData, getCountryData, getPinCodeData, getStatesData } from "../../ReduxActions/staticAction";
import MaterialSingleSearchDropdown from "../../components/Dropdown/SingleSearchDropdown";
import { staructureCityData, structureCityObjectData, structureCountryData, structurePincodeCityData } from "../../utils/common";

interface Props {
  history?: any;
  match?: any;
  location?: any;
  userProfileData ?: UserObject;
  routeKey ?: string;
}

interface ValidationObject {
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  pin_code?: string;
  city?: string;
  state?: string;
  country?: string;
  gst_number?: string;
  pan_number?: string;
  pincode_id ?: string;
  city_id ?: string;
  state_id ?: string;
  country_id ?: string;
  type ?: string;
  contact_number ?: string;
  company_name ?: string;
  area ?: string;
}

const inputFields = [
  {
    label: "First Name",
    key: "first_name",
    type: "text",
    required :true,
    disabled : false,
  },
  {
    label: "Last Name",
    key: "last_name",
    type: "text",
    required :true,
    disabled : false,
  },
  {
    label: "Phone Number",
    key: "phone",
    type: "number",
    required :true,
    disabled : false,
  },
  {
    label: "Email",
    key: "email",
    type: "email",
    required :true,
    disabled : true,
  },
  {
    label: "Company Name",
    key: "company_name",
    type: "text",
    required :true,
    disabled : false,
  },
];

const ProfileSettings: React.FC<Props> = (props) => {

  const { userProfileData, routeKey } = props;

  const param = routeKey;
  
  const dispatch = useDispatch();
  const userProfileSelector = useSelector(
    (state: any) => state.UserReducer.userProfile
  );
  const updateProfileSelector = useSelector(
    (state: any) => state.UserReducer.updateUserProfile
  );
  const countrySelector = useSelector((state: any) => state.StaticReducer.country);
  const pincodeDataSelector = useSelector((state: any)=> state.StaticReducer.pincodeData);
  const cityDataSelector = useSelector((state: any)=> state.StaticReducer.city);
  const stateDataSelector = useSelector((state: any)=> state.StaticReducer.states);
  const [countryData, setCountryData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    id : '',
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    gst_number: "",
    pan_number: "",
    pincode_id : "",
    city_id : "",
    state_id : "",
    country_id: "",
    type : '',
    contact_number :"",
    company_name : "",
    address: "",
    area : "",
  });
  const [validationObj, setValidationObj] = useState<ValidationObject>({});
  const [searchText, setSearchText] = useState({key : '', value: ''});
  const [cityPincodeData, setCityPincodeData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    if(userProfileData?._pincode) setCityPincodeData(structurePincodeCityData([userProfileData?._pincode]));
    if(userProfileData?._city) setCityData(staructureCityData([userProfileData?._city]));
    if(userProfileData?._state) setStateData(staructureCityData([userProfileData?._state]))
    // dispatch(getUserProfileDetails({user : userProfileData.role}));
    setUserDetails({
      ...userDetails,
      first_name : userProfileData.first_name,
      last_name  : userProfileData.last_name,
      email : userProfileData.email,
      pan_number : userProfileData.pan_number,
      gst_number : userProfileData.gst_number,
      pincode_id : userProfileData.pincode_id,
      city_id : userProfileData.city_id,
      state_id : userProfileData.state_id,
      country_id: userProfileData.country_id,
      type : userProfileData.type,
      phone : userProfileData.contact_number,
      address : userProfileData.address,
      area : userProfileData.area,
      company_name : userProfileData.company_name,
    });
    // get state data
    dispatch( getCountryData({}) );
    getStateById('');
    if(userProfileData.city_id){
      getCityById(userProfileData.city_id);
    }
    if((routeKey===PLATFORM_USERS.CLIENT && !userProfileData.gst_number) || ((routeKey===PLATFORM_USERS.VENDOR||routeKey===PLATFORM_USERS.VENDOR_BRANCH) && (!userProfileData.gst_number || !userProfileData.pan_number || !userProfileData.area || !userProfileData.address))){
      toast('Please complete your profile',
        {
          icon: '⚠',
          style: {
            borderRadius: '10px',
            background: '#F5F8FF',
            color: '#3466E5',
            border: '1px solid #3466E5'
          },
          duration:4000
        }
      );
    }
  }, [])

  const handleChangeSearchText = (key, value) => {
    setSearchText({key :key, value:value})
  }
  
  const getStateById = (id) => {
    dispatch( getStatesData({state: id}) );
  }

  const getCityById = (id) => { dispatch( getCityData( { city_id : id })) }

  useEffect(() => {
    if(searchText.key==='pincode' && searchText.value.length>=3){
      dispatch(
        getPinCodeData({
          location : searchText.value
        })
      )
    }
    else if(searchText.key==='city' && searchText.value.length>0){
      dispatch(getCityData({city : searchText.value}))
    }
    else if(searchText.key==='state' && searchText.value.length>0){
      getStateById(searchText.value)
    }
  }, [searchText])

  useEffect(() => {
   if(countrySelector.status === API_CONSTANTS.success){
    setCountryData(structureCountryData(countrySelector.data?.data))
   }
  }, [countrySelector])

  useEffect(() => {
    if(pincodeDataSelector.status === API_CONSTANTS.loading){
      setCityPincodeData([]);
    }
    if(pincodeDataSelector.status === API_CONSTANTS.success){
      setCityPincodeData(structurePincodeCityData(pincodeDataSelector.data?.data));
    }
  }, [pincodeDataSelector])

  useEffect(() => {
    if(cityDataSelector.status === API_CONSTANTS.loading){
      setCityData([]);
    }
    if(cityDataSelector.status === API_CONSTANTS.success){
      if(cityDataSelector.data?.data?.length){
        setCityData(staructureCityData(cityDataSelector.data?.data));
      }else{
        setCityData([structureCityObjectData(cityDataSelector.data)]);
      }
    }
  }, [cityDataSelector])

  useEffect(() => {
    if(stateDataSelector.status === API_CONSTANTS.success){
      setStateData(staructureCityData(stateDataSelector.data?.data));
    }
  }, [stateDataSelector])
  
  useEffect(() => {
    let toastId: any;
    if (userProfileSelector?.status === API_CONSTANTS.loading) {
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (userProfileSelector?.status === API_CONSTANTS.success && loading) {
      setLoading(false);
      toast.dismiss(toastId);
    }
    if (userProfileSelector?.status === API_CONSTANTS.error && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(userProfileSelector.data || DEFAULT_ERROR_MESSAGE.error1);
    }
  }, [userProfileSelector]);

  const handleInputChange = (key, value) => {
    setUserDetails({ ...userDetails, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation: ValidationObject = handleCheckValidation(userDetails);
    const gstCheck = routeKey===PLATFORM_USERS.CLIENT ? true : !validation?.pan_number;
    const areaCheck = routeKey===PLATFORM_USERS.CLIENT ? true : !validation?.area;
    setValidationObj(validation);
    if (
      validation &&
      !validation.first_name &&
      !validation.last_name &&
      !validation.email &&
      !validation.city_id &&
      !validation.phone &&
      !validation.state_id &&
      !validation.company_name &&
      !validation.pincode_id &&
      !validation.gst_number &&
      gstCheck &&
      areaCheck
    ) {
      dispatch(updateUserProfileDetails({body : {
        first_name : userDetails.first_name,
        last_name  : userDetails.last_name,
        pincode_id : userDetails.pincode_id,
        city_id : userDetails.city_id,
        state_id : userDetails.state_id,
        country_id : userDetails.country_id||countryData[0]?.value,
        pan_number : userDetails.pan_number,
        gst_number : userDetails.gst_number,
        contact_number : userDetails.phone,
        type : userDetails.type,
        address : userDetails.address,
        area : userDetails.area,
        company_name : userDetails.company_name
      }, id: userProfileData.id, user: userProfileData.role}));
    }
  };

  useEffect(() => {
    let toastId: any;
    if (updateProfileSelector?.status === API_CONSTANTS.loading) {
      setLoading(true);
      toastId = toast.loading(LOADING_TEXT.processing);
    }
    if (updateProfileSelector?.status === API_CONSTANTS.success && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.success(DEFAULT_SUCCESS_MESSAGE.success);
    }
    if (updateProfileSelector?.status === API_CONSTANTS.error && loading) {
      setLoading(false);
      toast.dismiss(toastId);
      toast.error(updateProfileSelector.data || DEFAULT_ERROR_MESSAGE.error1);
    }
  }, [updateProfileSelector]);
  
  return (
    <div className="py-6 px-6">
      <PageTitle>Profile Settings</PageTitle>
      <div className="bg-brand-primary-white px-8 py-8 rounded-lg">
        <div>
          <p className="text-brand-text-title text-xl">General Information</p>
          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <Grid container>
                {inputFields.map(
                  (field: { label: string; key: string; type: string; required?:boolean; disabled?: boolean }) => (
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <CommonInput
                        label={field.label}
                        type={field.type}
                        disabled={(field.key==='company_name' && routeKey===PLATFORM_USERS.VENDOR_BRANCH) ? true:field.disabled}
                        placeholder={`Enter your ${field.label.toLowerCase()}`}
                        onChange={(e) =>
                          handleInputChange(field.key, e.target.value)
                        }
                        value={userDetails[field.key]}
                        validationKey={field.required && validationObj[field.key]}
                      />
                    </Grid>
                  )
                )}
                {
                  (param === PLATFORM_USERS.VENDOR || param === PLATFORM_USERS.VENDOR_BRANCH) &&
                  <>
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <MaterialSingleSearchDropdown
                        label="Type"
                        onChange={(value) => handleInputChange("type", value)}
                        value={userDetails.type}
                        validationKey={validationObj.type}
                        options={LoadTypes}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <CommonInput
                        label={"Area"}
                        type={"text"}
                        placeholder={`Enter Area`}
                        onChange={(e) =>
                          handleInputChange("area", e.target.value)
                        }
                        value={userDetails["area"]}
                        validationKey={validationObj?.area}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <MaterialSingleSearchDropdown
                        label="Pincode"
                        onChange={(value) => handleInputChange("pincode_id", value)}
                        value={userDetails.pincode_id}
                        onChangeText={(e)=>handleChangeSearchText('pincode', e.target.value)}
                        validationKey={validationObj.pincode_id}
                        options={cityPincodeData}
                        placeholder="Type initial 4 digits"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                      <CommonInput
                        label={"Address"}
                        type={"text"}
                        placeholder={`Enter Address`}
                        onChange={(e) =>
                          handleInputChange("address", e.target.value)
                        }
                        value={userDetails["address"]}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}/>
                  </>
                }
                {
                  param === PLATFORM_USERS.CLIENT &&
                  <>
                  <Grid item lg={6} md={6} xs={12}/>
                  <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                    <MaterialSingleSearchDropdown
                      label="Pincode"
                      onChange={(value) => handleInputChange("pincode_id", value)}
                      value={userDetails.pincode_id}
                      onChangeText={(e)=>handleChangeSearchText('pincode', e.target.value)}
                      validationKey={validationObj.pincode_id}
                      options={cityPincodeData}
                      placeholder="Type initial 4 digits"
                    />
                  </Grid>
                  </>
                }
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="City"
                    onChange={(value) => handleInputChange("city_id", value)}
                    value={userDetails.city_id}
                    onChangeText={(e)=>handleChangeSearchText('city', e.target.value)}
                    validationKey={validationObj.city_id}
                    options={cityData}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="State"
                    onChange={(value) => handleInputChange("state_id", value)}
                    value={userDetails.state_id}
                    validationKey={validationObj.state_id}
                    options={stateData}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <MaterialSingleSearchDropdown
                    label="Country"
                    onChange={(value) =>
                      handleInputChange("country_id", value)
                    }
                    // value={userDetails.country}
                    value={countryData[0]?.value}
                    // validationKey={validationObj.country_id}
                    options={countryData}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container className="mt-8">
                <Grid item lg={12} md={12} xs={12} className="py-3">
                  <p className="text-brand-text-title text-xl">
                    Additional Information
                  </p>
                  <p className="text-brand-text-caption hidden">
                    Fill you additional data info
                  </p>
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <CommonInput
                    label={"GST Number"}
                    type={"text"}
                    placeholder={`Enter GST Number`}
                    onChange={(e) =>
                      handleInputChange("gst_number", e.target.value)
                    }
                    value={userDetails["gst_number"]}
                    validationKey={validationObj["gst_number"]}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} className="md:pr-6">
                  <CommonInput
                    label={"PAN Number"}
                    type={"text"}
                    placeholder={`Enter PAN Number`}
                    onChange={(e) =>
                      handleInputChange("pan_number", e.target.value)
                    }
                    value={userDetails["pan_number"]}
                    validationKey={ routeKey===PLATFORM_USERS.CLIENT ? null :  validationObj["pan_number"]}
                  />
                </Grid>
              </Grid>
              <Grid container className="mt-6">
                <Grid item lg={1} md={2} xs={12}>
                  <CommonButton text={"Save"} type="submit" />
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
