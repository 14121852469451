import moment from "moment";
import { FRONTEND_PROD_DOMAIN } from "../utils/ApiConfig";
import { areaFormattedValue } from "../utils/common";
import { APP_USER_ROUTES, PLATFORM_USERS, TIME_FORMAT_STRING } from "../utils/constants";

// Returns the cost upto 2 decimal places
export const formatPrice = (value?: number, decimalPlaces = 2, returnNumber = false) => {
    if(value)
        return (Math.round(value * 100) / 100).toFixed(decimalPlaces); 
    else if(returnNumber) {
        return (Math.round(0 * 100) / 100).toFixed(decimalPlaces);
    }
    else {
        return value;
    }
};

export const createMapForStaticData = (array: any, key: string) => {
    const map = array?.reduce((acc: any, cum: any) => {
      return {
        ...acc,
        [cum.id]: {
          key: cum.id,
          value: cum.id,
          text: cum[key],
        },
      };
    }, {});
    return map;
};

export const createBookingRequestSummary = (requestId:any, bookingDetail: any) => {
  return `Booking No. ${bookingDetail.booking_no} \nPickup Date: ${moment.utc(bookingDetail?.pickup_at).format(TIME_FORMAT_STRING)} \nPickup Location: ${bookingDetail?._pickup_location ? areaFormattedValue(bookingDetail?._pickup_location): bookingDetail?.pickup_address || "N/A"}\nDrop Location: ${bookingDetail?._drop_location? areaFormattedValue(bookingDetail?._drop_location): bookingDetail?.drop_address || "N/A"}
          \nBooking Link: ${FRONTEND_PROD_DOMAIN}${APP_USER_ROUTES[PLATFORM_USERS.VENDOR].REQUEST_DETAILS_ALIAS}/${requestId}`;
}
