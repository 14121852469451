import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCommonStyles } from "../../styles/common";
import CommonButton from "../Button";

interface Props {
  open?: boolean;
  setOpen?: any;
  content?: any;
  agreeButtonText?: string;
  cancelButtonText?: string;
  headingText?: string;
  onClickAgree?: any;
  maxWidth ?: any;
}
/**
 *
 * @param props { open, setOpen, content, agreeButtonText, cancelButtonText, headingText, onClickAgree }
 * @returns Modal JSX
 */
const CommonModal: React.FC<Props> = (props) => {
  const classes = useCommonStyles();
  const {
    open,
    setOpen,
    content,
    agreeButtonText,
    cancelButtonText,
    headingText,
    onClickAgree,
    maxWidth,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
        <DialogTitle
          className={classes.p32px}
          style={{ paddingBottom: "0.5rem" }}
        >
          <p className="text-2xl">{headingText}</p>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 25,
            top: 25,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent
          className={classes.p32px}
          style={{ paddingBottom: "0rem", paddingTop: "0rem" }}
        >
          <p className="text-brand-text-title text-base">{content}</p>
        </DialogContent>
        <DialogActions
          className={classes.p32px}
          style={{ paddingTop: "1.2rem" }}
        >
          {
            cancelButtonText && (
              <>
                <CommonButton
                  className=" box-border w-32 border-brand-text-title text-brand-text-tableHead bg-brand-text-white mt-0 mr-1  hover:bg-brand-extra-background"
                  onClick={handleClose}
                  text={cancelButtonText}
                />
              </>
            )
          }
          {
            agreeButtonText && (
              <>
                <CommonButton
                  className="w-40 mt-0 "
                  onClick={onClickAgree}
                  text={agreeButtonText}
                />
              </>
            )
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CommonModal;
