import React from 'react';
import { useParams } from 'react-router-dom';
import AdminZones from '../../components/role/admin/Zones';
import { APP_USER_ROUTES, PLATFORM_USERS } from '../../utils/constants';
import { ParamsTypes } from '../../utils/ObjectTypes';

interface Props {
  match ?: any;
  routeKey ?: string;
}

const ZoneContainer = (props) => {

  const { match, routeKey } = props;
  const  param  = routeKey;

  return (
    <div className="py-6 px-6 overflow-x-auto">
      {
        param === PLATFORM_USERS.ADMIN &&
        <AdminZones {...props}/>
      }
    </div>
  )
}

export default ZoneContainer