// get all zone
export const GET_ALL_BRANCHES = 'app/branches/getAllBranchess';
export const GET_ALL_BRANCHES_SUCCESS = 'app/branches/getAllBranchesSuccess';
export const GET_ALL_BRANCHES_ERROR = 'app/branches/getAllBranchesError';

// get branch
export const GET_BRANCH = 'app/branches/getBranch';
export const GET_BRANCH_SUCCESS = 'app/branches/getBranchSuccess';
export const GET_BRANCH_ERROR = 'app/branches/getBranchError';

// add zone
export const ADD_BRANCHES = 'app/branches/addBranchess';
export const ADD_BRANCHES_SUCCESS = 'app/branches/addBranchesSuccess';
export const ADD_BRANCHES_ERROR = 'app/branches/addBranchesError';

//edit zone
export const EDIT_BRANCHES = 'app/branches/editBranchess';
export const EDIT_BRANCHES_SUCCESS = 'app/branches/editBranchesSuccess';
export const EDIT_BRANCHES_ERROR = 'app/branches/editBranchesError';

//delete zone
export const DELETE_BRANCHES = 'app/branches/Branchess';
export const DELETE_BRANCHES_SUCCESS = 'app/branches/BranchesSuccess';
export const DELETE_BRANCHES_ERROR = 'app/branches/BranchesError';

/**
 * Admin side apis
 */
// vendor branches
export const GET_VENDOR_BRANCHES = 'app/admin/vendor/BRANCHES';
export const SET_VENDOR_BRANCHES = 'app/admin/vendor/setBRANCHES';

// get Branch count
export const GET_BRANCH_COUNT = 'app/BRANCHES/count';
export const SET_BRANCH_COUNT = 'app/setBRANCHES/count';