import { PROD_DOMAIN } from "./ApiConfig";
import { PLATFORM_USERS } from "./constants";

export const API_URLS = {

    [PLATFORM_USERS.CLIENT] :{
        login : `${PROD_DOMAIN}/api/v1/auth/login`,
        register : `${PROD_DOMAIN}/api/v1/auth/register/client`,
        resendEmailVerifcation : `${PROD_DOMAIN}/api/v1/auth/resend-verification`,
        verifyEmailToken : `${PROD_DOMAIN}/api/v1/auth/verify-token`,
        forgotPassword : `${PROD_DOMAIN}/api/v1/auth/forgot-password`,
        verifyPasswordToken : `${PROD_DOMAIN}/api/v1/auth/verify-password`,
        resetPassword : `${PROD_DOMAIN}/api/v1/auth/reset-password`,
        currentUser : `${PROD_DOMAIN}/api/v1/client/me`,
        profileUpdate : (param) => `${PROD_DOMAIN}/api/v1/client/me`,
        allBookings : `${PROD_DOMAIN}/api/v1/client/booking`,
        vendorList : `${PROD_DOMAIN}/api/v1/client/booking/vendors`,
        searchByVendor : `${PROD_DOMAIN}/api/v1/client/booking/locations/searchByVendor`,
        searchftlLocations : `${PROD_DOMAIN}/api/v1/client/booking/locations/searchFtlLocation`,
        getBooking :(id)=> `${PROD_DOMAIN}/api/v1/client/booking/${id}`,
        downloadBookingPaymentSlip : (id) => `${PROD_DOMAIN}/api/v1/client/booking/${id}/download`,
        createBookingPtl :`${PROD_DOMAIN}/api/v1/client/booking/book-ptl`,
        createBookingFtl :`${PROD_DOMAIN}/api/v1/client/booking/book-ftl`,
        confirmBooking :(id)=>`${PROD_DOMAIN}/api/v1/client/booking/request/confirm/${id}`,
        reconsiderBooking :(id)=>`${PROD_DOMAIN}/api/v1/client/booking/request/reconsider/${id}`,
        updateBookingOrder : (id) => `${PROD_DOMAIN}/api/v1/client/booking/order/update/${id}`,
        stats : `${PROD_DOMAIN}/api/v1/client/booking/stats`,
        specificBookingRequestSearch:(id)=> `${PROD_DOMAIN}/api/v1/client/booking/requests/${id}`,
    },
    [PLATFORM_USERS.VENDOR] : {
        login : `${PROD_DOMAIN}/api/v1/auth/login`,
        register : `${PROD_DOMAIN}/api/v1/auth/register/vendor-hq`,
        resendEmailVerifcation : `${PROD_DOMAIN}/api/v1/auth/resend-verification`,
        verifyEmailToken : `${PROD_DOMAIN}/api/v1/auth/verify-token`,
        forgotPassword : `${PROD_DOMAIN}/api/v1/auth/forgot-password`,
        verifyPasswordToken : `${PROD_DOMAIN}/api/v1/auth/verify-password`,
        resetPassword : `${PROD_DOMAIN}/api/v1/auth/reset-password`,
        currentUser : `${PROD_DOMAIN}/api/v1/vendor/me`,
        profileUpdate : (param) => `${PROD_DOMAIN}/api/v1/vendor/me`,
        getAllFleets : `${PROD_DOMAIN}/api/v1/vendor/trucks`,
        getIdBasedFleet : (truck_id : string) => `${PROD_DOMAIN}/api/v1/vendor/trucks/${truck_id}`,
        addNewFleet : `${PROD_DOMAIN}/api/v1/vendor/trucks`,
        editFleet : (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/trucks/${truck_id}`,
        deleteFleet : (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/trucks/${truck_id}`,
        // all zone for vendor 
        getAllZones : `${PROD_DOMAIN}/api/v1/vendor/zones`,
        // rate cards end points
        getAllRateCards : `${PROD_DOMAIN}/api/v1/vendor/rate-cards`,
        getIdBasedRateCard: (ratecard_id : string) => `${PROD_DOMAIN}/api/v1/vendor/rate-cards/${ratecard_id}`,
        addNewRateCard: `${PROD_DOMAIN}/api/v1/vendor/rate-cards`,
        editRateCard: (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/rate-cards/${truck_id}`,
        deleteRateCard: (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/rate-cards/${truck_id}`,
        // branhces end points
        getAllBranches : `${PROD_DOMAIN}/api/v1/vendor/branches`,
        getIdBasedBranch: (truck_id : string) => `${PROD_DOMAIN}/api/v1/vendor/branches/${truck_id}`,
        addNewBranch: `${PROD_DOMAIN}/api/v1/vendor/branches`,
        editBranch: (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${truck_id}`,
        deleteBranch: (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${truck_id}`,
        // booking end points
        allBookings : `${PROD_DOMAIN}/api/v1/vendor/booking`,
        bookingRequests : `${PROD_DOMAIN}/api/v1/vendor/booking/requests`,
        bookingDetails : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/${id}`,
        downloadBookingPaymentSlip : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/${id}/download`,
        bookingRequestDetails : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/${id}`,
        acceptBooking : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/accept/${id}`,
        rejectBooking : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/reject/${id}`,
        negotiateBooking : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/negotiate/${id}`,
        updateTracking : (id) => `${PROD_DOMAIN}/api/v1/vendor/tracking/${id}`,
        stats : `${PROD_DOMAIN}/api/v1/vendor/booking/stats`,
        // truck and branch counts
        truckCount : `${PROD_DOMAIN}/api/v1/vendor/trucks/count`,
        branchCount : `${PROD_DOMAIN}/api/v1/vendor/branches/count`,
        //payments
        allPayments : `${PROD_DOMAIN}/api/v1/vendor/payments`,
        getPaymentbyId : (id) => `${PROD_DOMAIN}/api/v1/vendor/payments/${id}`,
        addPayment : `${PROD_DOMAIN}/api/v1/vendor/payments`,
        editPayment :(id)=> `${PROD_DOMAIN}/api/v1/vendor/payments/${id}`,
        deletePayment :(id)=> `${PROD_DOMAIN}/api/v1/vendor/payments/${id}`,
        bookingwithPaymentId: (id)=>`${PROD_DOMAIN}/api/v1/vendor/payments/booking/${id}`,
        // vendor branches related apis
        getBranchBookings: (id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${id}/bookings`,
        getBranchBookingRequests: (id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${id}/booking-requests`,
        getBranchFleets: (id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${id}/fleets`,
        getBranchRatecards: (id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${id}/rate-cards`,
        getBranchPayments: (id) => `${PROD_DOMAIN}/api/v1/vendor/branches/${id}/payments`,
    },
    [PLATFORM_USERS.VENDOR_BRANCH] : {
        login : `${PROD_DOMAIN}/api/v1/auth/login`,
        register : `${PROD_DOMAIN}/api/v1/auth/register/vendor-hq`,
        resendEmailVerifcation : `${PROD_DOMAIN}/api/v1/auth/resend-verification`,
        verifyEmailToken : `${PROD_DOMAIN}/api/v1/auth/verify-token`,
        forgotPassword : `${PROD_DOMAIN}/api/v1/auth/forgot-password`,
        verifyPasswordToken : `${PROD_DOMAIN}/api/v1/auth/verify-password`,
        resetPassword : `${PROD_DOMAIN}/api/v1/auth/reset-password`,
        currentUser : `${PROD_DOMAIN}/api/v1/vendor/me`,
        profileUpdate : (param) => `${PROD_DOMAIN}/api/v1/vendor/me`,
        getAllFleets : `${PROD_DOMAIN}/api/v1/vendor/trucks`,
        getIdBasedFleet : (truck_id : string) => `${PROD_DOMAIN}/api/v1/vendor/trucks/${truck_id}`,
        addNewFleet : `${PROD_DOMAIN}/api/v1/vendor/trucks`,
        editFleet : (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/trucks/${truck_id}`,
        deleteFleet : (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/trucks/${truck_id}`,
        // all zone for vendor 
        getAllZones : `${PROD_DOMAIN}/api/v1/vendor/zones`,
        // rate cards end points
        getAllRateCards : `${PROD_DOMAIN}/api/v1/vendor/rate-cards`,
        getIdBasedRateCard: (ratecard_id : string) => `${PROD_DOMAIN}/api/v1/vendor/rate-cards/${ratecard_id}`,
        addNewRateCard: `${PROD_DOMAIN}/api/v1/vendor/rate-cards`,
        editRateCard: (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/rate-cards/${truck_id}`,
        deleteRateCard: (truck_id) => `${PROD_DOMAIN}/api/v1/vendor/rate-cards/${truck_id}`,
        // booking end points
        allBookings : `${PROD_DOMAIN}/api/v1/vendor/booking`,
        bookingRequests : `${PROD_DOMAIN}/api/v1/vendor/booking/requests`,
        bookingDetails : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/${id}`,
        downloadBookingPaymentSlip : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/${id}/download`,
        bookingRequestDetails : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/${id}`,
        acceptBooking : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/accept/${id}`,
        rejectBooking : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/reject/${id}`,
        negotiateBooking : (id) => `${PROD_DOMAIN}/api/v1/vendor/booking/requests/negotiate/${id}`,
        updateTracking : (id) => `${PROD_DOMAIN}/api/v1/vendor/tracking/${id}`,
        stats : `${PROD_DOMAIN}/api/v1/vendor/booking/stats`,
        // truck and branch counts
        truckCount : `${PROD_DOMAIN}/api/v1/vendor/trucks/count`,
        branchCount : `${PROD_DOMAIN}/api/v1/vendor/branches/count`,
        //payments
        allPayments : `${PROD_DOMAIN}/api/v1/vendor/payments`,
        getPaymentbyId : (id) => `${PROD_DOMAIN}/api/v1/vendor/payments/${id}`,
        addPayment : `${PROD_DOMAIN}/api/v1/vendor/payments`,
        editPayment :(id)=> `${PROD_DOMAIN}/api/v1/vendor/payments/${id}`,
        deletePayment :(id)=> `${PROD_DOMAIN}/api/v1/vendor/payments/${id}`,
        bookingwithPaymentId: (id)=>`${PROD_DOMAIN}/api/v1/vendor/payments/booking/${id}`
    },
    [PLATFORM_USERS.ADMIN] :{
        login : `${PROD_DOMAIN}/api/v1/auth/login`,
        register : `${PROD_DOMAIN}/api/v1/auth/register`,
        resendEmailVerifcation : `${PROD_DOMAIN}/api/v1/auth/resend-verification`,
        verifyEmailToken : `${PROD_DOMAIN}/api/v1/auth/verify-token`,
        forgotPassword : `${PROD_DOMAIN}/api/v1/auth/forgot-password`,
        verifyPasswordToken : `${PROD_DOMAIN}/api/v1/auth/verify-password`,
        resetPassword : `${PROD_DOMAIN}/api/v1/auth/reset-password`,
        currentUser : `${PROD_DOMAIN}/api/v1/admin/me`,
        postBookingRequest: (id) => `${PROD_DOMAIN}/api/v1/admin/bookings/requests/${id}/send`,
        profileUpdate : (param) => `${PROD_DOMAIN}/api/v1/admin/me`,
        // zone end points
        getAllZones : `${PROD_DOMAIN}/api/v1/admin/zones`,
        getIdBasedZone: (truck_id : string) => `${PROD_DOMAIN}/api/v1/admin/zones/${truck_id}`,
        addNewZone: `${PROD_DOMAIN}/api/v1/admin/zones`,
        editZone: (truck_id) => `${PROD_DOMAIN}/api/v1/admin/zones/${truck_id}`,
        deleteZone: (truck_id) => `${PROD_DOMAIN}/api/v1/admin/zones/${truck_id}`,
        // admin all booking and booking requests
        allBookings : `${PROD_DOMAIN}/api/v1/admin/bookings`,
        bookingRequests : `${PROD_DOMAIN}/api/v1/admin/bookings/requests`,
        bookingDetails : (id) => `${PROD_DOMAIN}/api/v1/admin/bookings/${id}`,
        downloadBookingPaymentSlip : (id) => `${PROD_DOMAIN}/api/v1/admin/bookings/${id}/download`,
        bookingRequestDetails : (id) => `${PROD_DOMAIN}/api/v1/admin/bookings/requests/${id}`,
        clients : `${PROD_DOMAIN}/api/v1/admin/clients`,
        vendors : `${PROD_DOMAIN}/api/v1/admin/vendors`,
        getUsers: (id: string) => `${PROD_DOMAIN}/api/v1/admin/user/${id}`,
        getVendorBookings: (id) => `${PROD_DOMAIN}/api/v1/admin/bookings/vendor/${id}`,
        getVendorBookingRequests: (id) => `${PROD_DOMAIN}/api/v1/admin/bookings/requests/vendor/${id}`,
        getVendorFleets: (id) => `${PROD_DOMAIN}/api/v1/admin/trucks/vendor/${id}`,
        getVendorBranches: (id) => `${PROD_DOMAIN}/api/v1/admin/branches/vendor/${id}`,
        getVendorRatecards: (id) => `${PROD_DOMAIN}/api/v1/admin/rate-cards/vendor/${id}`,
        getVendorPayments: (id) => `${PROD_DOMAIN}/api/v1/admin/payments/vendor/${id}`,
        stats : `${PROD_DOMAIN}/api/v1/admin/bookings/stats`,
        adminStats : `${PROD_DOMAIN}/api/v1/admin/stats`,
        // truck and branch counts
        truckCount : `${PROD_DOMAIN}/api/v1/admin/trucks/count`,
        branchCount : `${PROD_DOMAIN}/api/v1/admin/branches/count`,
        // approve disapprove
        approveDisapproveVendor: (id) => `${PROD_DOMAIN}/api/v1/admin/access/vendor/${id}`,
        approveDisapproveClient: (id) => `${PROD_DOMAIN}/api/v1/admin/access/client/${id}`,
        postUserPreferences: `${PROD_DOMAIN}/api/v1/admin/user-preferences/vendor/sync`,
        states : `${PROD_DOMAIN}/api/v1/admin/location/state`,
        cities : `${PROD_DOMAIN}/api/v1/admin/location/city`,
        pincodes : `${PROD_DOMAIN}/api/v1/admin/location/pincode`,
        updateTracking : (id) => `${PROD_DOMAIN}/api/v1/admin/tracking/${id}`,
        addNewFleet : `${PROD_DOMAIN}/api/v1/admin/trucks`,
        editFleet : (truck_id) => `${PROD_DOMAIN}/api/v1/admin/trucks/${truck_id}`,
        deleteFleet : (truck_id) => `${PROD_DOMAIN}/api/v1/admin/trucks/${truck_id}`,
        getIdBasedFleet : (truck_id : string) => `${PROD_DOMAIN}/api/v1/admin/trucks/${truck_id}`,
        specificBookingRequestSearch:(id)=> `${PROD_DOMAIN}/api/v1/admin/bookings/requests`,
    },
    static : {
        city :`${PROD_DOMAIN}/api/v1/static/cities`,
        state : `${PROD_DOMAIN}/api/v1/static/states`,
        country : `${PROD_DOMAIN}/api/v1/static/countries`,
        pincode : `${PROD_DOMAIN}/api/v1/static/pincode`,
        cityId : (param:string) => `${PROD_DOMAIN}/api/v1/static/cities/${param}`,
        location : `${PROD_DOMAIN}/api/v1/static/location/search`,
        uploadFile: `${PROD_DOMAIN}/api/v1/assets/file`,
    }
}