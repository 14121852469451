import React from 'react';
import ViewBookingDetails from '../../components/role/client/Bookings/BookingDetails';
import VendorBookingDetail from '../../components/role/vendor/Bookings/BookingDetails';
import AdminBookingDetail from '../../components/role/admin/Bookings/BookingDetails';
import { PLATFORM_USERS } from '../../utils/constants';
import { UserObject } from '../../utils/ObjectTypes';

interface Props {
  match ?: any;
  userProfileData ?: UserObject;
  routeKey ?: string;
}

const BookingDetails: React.FC<Props> = (props) => {

  const { match, userProfileData, routeKey } = props;

  return (
    <div className="py-6 px-6 overflow-x-auto">
      {
        userProfileData?.role===PLATFORM_USERS.CLIENT &&
        <ViewBookingDetails {...props}/>
      }  
      {
        (userProfileData?.role===PLATFORM_USERS.VENDOR || userProfileData?.role===PLATFORM_USERS.VENDOR_BRANCH) &&
        <VendorBookingDetail {...props}/>
      }
      {
        userProfileData?.role===PLATFORM_USERS.ADMIN &&
        <AdminBookingDetail {...props}/>
      }
    </div>
  )
}

export default BookingDetails