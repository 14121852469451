import axiosInstance from "../utils/interceptor";
import { API_URLS } from "../utils/apiUrls";
import { IUpdateUserObject } from "../utils/ObjectTypes";

export const FleetService = {

    getAllFleets :async (data : { user : string, page?:number, limit?: number, type?: string}) =>{
        const request = { data }
        const result =await axiosInstance.get(API_URLS[data.user].getAllFleets+`?page=${data?.page}&limit=${data?.limit}${data.type ? `&type=${data.type}` : ''}`);
        return result
    },

    getIdBasedFleet :async (data : IUpdateUserObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.get(API_URLS[data.user].getIdBasedFleet(data.id))
        return result
    },

    addVendorFleet :async (data : IUpdateUserObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.post(API_URLS[data.user].addNewFleet, request)
        return result
    },

    editVendorFleet :async (data : IUpdateUserObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.put(API_URLS[data.user].editFleet(data.id), request)
        return result
    },

    deleteVendorFleet :async (data : IUpdateUserObject) =>{
        const request = { ...data.body }
        const result =await axiosInstance.delete(API_URLS[data.user].deleteFleet(data.id))
        return result
    },
    // admin apis
    getAllFleetsForVendor: async(data:{ user: string, vendor_id?: string, branch_id?: string}) =>{
        if(data?.branch_id) return axiosInstance.get(API_URLS[data.user].getBranchFleets(data?.branch_id) + `?type=all`)
        const result = await axiosInstance.get(API_URLS[data.user].getVendorFleets(data?.vendor_id) + `?type=all`);
        return result
    },

    // get count 
    getTruckCount: async(data:{ user: string}) =>{
        const result = await axiosInstance.get(API_URLS[data.user].truckCount);
        return result
    },

}