import React, { useEffect, useState } from 'react';
import TableComponent from "../../../Table";
import PageTitle from "../../../Typography/PageTitle";
import Button from '../../../Button';
import { Grid } from "@mui/material";
import { API_CONSTANTS, APP_USER_ROUTES, DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE, LOADING_TEXT } from '../../../../utils/constants';
import { Link, useParams } from "react-router-dom";
import { ParamsTypes, UserObject } from "../../../../utils/ObjectTypes";
import { useDispatch, useSelector } from 'react-redux';
import { deleteZonees, getAllZoneList } from '../../../../ReduxActions/zone.action';
import toast from 'react-hot-toast';
import DeleteModal from '../../../Modal/DeleteModal';

interface Data {
    name: string;
    pincodes : string;
  }
  
  interface HeadCell {
    disablePadding ?: boolean;
    id ?: keyof Data;
    label ?: string;
    numeric ?: boolean;
    callBackArguments ? : Array<string>;
    customCell ?: any;
  }
  
  const columns: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "NAME",
      callBackArguments : ['id'],
      customCell: false,
    },
    {
      id: "pincodes",
      numeric: false,
      disablePadding: false,
      label: "PINCODE",
      callBackArguments : ['id'],
    },
  ];   
  interface Props {
    key ?: string;
    match ?: any;
    history ?: any;
    userProfileData ?: UserObject;
    routeKey ?: string;
  }

const Zones : React.FC<Props> = (props) => {

    const { key, match, history, userProfileData, routeKey } = props;

    const param = routeKey;

    // dispatch event
    const dispatch = useDispatch();
    // selectors
    const allZoneSelector = useSelector((state: any) => state.ZoneReducer.allZones);
    const deleteZoneSelector = useSelector((state: any) => state.ZoneReducer.deleteZone);

    // States hook
    const [loading, setLoading] = useState({ allzone : false, deleteZone: false});
    const [allZones, setallZones] = useState([]);
    const [allZoneMeta, setallZoneMeta] = useState({});
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [zoneId, setZoneId] = useState('');

    const getAllZones = (page?:number, limit?:number) => {
      dispatch( getAllZoneList({ user: param, page, limit }) );
    }

    useEffect(() => {
      getAllZones(1, 10);
    }, []);

    useEffect(() => {
      let toastId : any;
      if(allZoneSelector.status === API_CONSTANTS.loading){
        setLoading({...loading, allzone: true});
        toastId=toast.loading(LOADING_TEXT.fetching);
      }
      if(allZoneSelector.status === API_CONSTANTS.success && loading.allzone){
        setLoading({...loading, allzone: false});
        toast.dismiss(toastId);
        setallZones([...allZoneSelector?.data?.data?.map((i)=>{
          i.pincodes = i?.pincodes?.map((items)=><p>{items.office_name}, </p>);
          return i;
        })]);
        setallZoneMeta(allZoneSelector.data?.meta);
      }
      if(allZoneSelector.status === API_CONSTANTS.error && loading.allzone){
        setLoading({...loading, allzone: false});
        toast.dismiss(toastId);
        toast.error(allZoneSelector.data||DEFAULT_ERROR_MESSAGE.failed);
      }
    }, [allZoneSelector])

    useEffect(() => {
      let toastId : any;
      if(deleteZoneSelector.status === API_CONSTANTS.loading){
        setLoading({...loading, deleteZone: true});
        toastId=toast.loading(LOADING_TEXT.fetching);
      }
      if(deleteZoneSelector.status === API_CONSTANTS.success && loading.deleteZone){
        setLoading({...loading, deleteZone: false});
        toast.dismiss(toastId);
        getAllZones();
        toast.success(DEFAULT_SUCCESS_MESSAGE.success);
      }
      if(deleteZoneSelector.status === API_CONSTANTS.error && loading.deleteZone){
        setLoading({...loading, deleteZone: false});
        toast.dismiss(toastId);
        toast.error(deleteZoneSelector.data||DEFAULT_ERROR_MESSAGE.failed);
      }
    }, [deleteZoneSelector])
    
    const actions = [
      {
        id: "edit",
        label: "Edit",
        callbackArguments: ["id"],
        onClick: (id) => {handleEditZone(id)},
      },
      {
        id: "delete",
        label: "Delete",
        callbackArguments: ["id"],
        onClick: (id) => {setZoneId(id);setOpenDeleteModal(true);},
      },
    ];

    const handleEditZone = (id) => {
      history.push(APP_USER_ROUTES[param].EDIT_ZONE_ALIAS+'/'+id);
    }

    const handleDeleteZone = () => {
      dispatch( deleteZonees({ id:zoneId, user: param, body:{ }}));setOpenDeleteModal(false);
    }
  
    const addZones = () => {
      history.push(APP_USER_ROUTES[param].ADD_ZONES);
    }

  return (
    <>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headingText="Delete Zone"
        agreeButtonText="Delete"
        cancelButtonText="Cancel"
        onClickAgree={handleDeleteZone}
        content={
          <p>Are you sure ? you want to delete this Zone.</p>
        }
      />
      <div>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" className=" mb-8">
            <Grid item lg={2} md={4} xs={12}>
              <PageTitle className="mb-0">
                My Zones
              </PageTitle>
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
                <Button text="Add Zone" type="button" onClick={addZones}/>
            </Grid>
          </Grid>
          <TableComponent columns={columns} rows={allZones} actions={actions} onClickFunc={addZones} text="Create new zone" onPageChange={getAllZones} meta={allZoneMeta}/>
        </div>
    </>
  )
}

export default Zones